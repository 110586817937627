import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";

export const generateColumns = (responsibilities) => {
  return [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
    { title: "Icon", field: "icon" },
    {
      title: "Responsibilities",
      field: "responsibilities",
      lookup: responsibilities,
      render: (rowData) => (
        <ul>
          {rowData.responsibilities && rowData.responsibilities.map((responsibility) => <li>{responsibility.name}</li>)}
        </ul>
      ),
      editComponent: (props) => {
        return (
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>Responsibilities</InputLabel>
            <Select
              id="Prefix"
              value={
                (props.value &&
                  props.value.map((val) => {
                    if (val.hasOwnProperty("id")) return val.id;
                    else return val;
                  })) ||
                []
              }
              renderValue={(selected) =>
                Object.keys(responsibilities)
                  .map((responsibility) =>
                    selected.includes(responsibility) ? responsibilities[responsibility] : null
                  )
                  .filter(Boolean)
                  .join(", ") || ""
              }
              onChange={(e) => props.onChange(e.target.value)}
              multiple
            >
              {Object.keys(responsibilities).map((responsibility) => {
                return (
                  <MenuItem key={responsibility} value={responsibility}>
                    <Checkbox
                      checked={
                        props.value &&
                        (props.value.includes(responsibility) || props.value.some((val) => val.id === responsibility))
                      }
                    />
                    <ListItemText primary={responsibilities[responsibility]} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      },
    },
  ];
};
