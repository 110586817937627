import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import MaterialTable from "../../components/MaterialTable/MaterialTable";
import Snackbar from "../../components/Snackbar/Snackbar";
import { constants } from "../../utils/common";
import {
  loadCompanies,
  fetchCompanyCount,
  saveCompany,
  updateCompany,
  deleteCompany,
  getAdminProfile,
} from "../../store/actions";
import { columns } from "./columns";

function Companies(props) {
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });

  const navCompanyDetails = (row) => props.history.push(`/organizations/${row.id}`);

  const createOrganization = async (row) => {
    try {
      const payload = { name: row.name, clientType: row.clientType || null };
      const result = await props.saveCompany(payload);
      await props.getAdminProfile();
      if (result.type.includes("SUCCESS")) {
        const target = Object.keys(result.response.entities.companies)[0];
        if (
          props.user &&
          props.user.adminAccess &&
          props.user.adminAccess.companies &&
          props.user.adminAccess.companies.update
        ) {
          props.history.push(`/organizations/${target}`);
        }
        setSnackbar({ isOpen: true, variant: "success", message: `Successfully created ${row.name}!` });
        return result;
      }
      return setSnackbar({ isOpen: true, variant: "error", message: `Oops... Something went wrong.` });
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to create ${row.name}.` });
    }
  };

  const editOrganization = async (row) => {
    try {
      const payload = {
        name: row.name,
        allowAssessments: row.allowAssessments,
        maxVendors: row.maxVendors,
        unlimitedVendors: row.unlimitedVendors,
        clientType: row.clientType || null,
      };
      const result = await props.updateCompany(row.id, payload);
      if (result.type.includes("SUCCESS")) {
        setSnackbar({ isOpen: true, variant: "success", message: `Successfully updated ${row.name}!` });
        return result;
      }
      return setSnackbar({ isOpen: true, variant: "error", message: `Oops... Something went wrong.` });
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to update ${row.name}.` });
    }
  };

  const deleteOrganization = async (row) => {
    try {
      const result = await props.deleteCompany(row.id);
      if (result.type.includes("SUCCESS")) {
        setSnackbar({ isOpen: true, variant: "success", message: `Successfully deleted ${row.name}!` });
        return result;
      }
      return setSnackbar({ isOpen: true, variant: "error", message: `Oops... Something went wrong.` });
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to delete ${row.name}.` });
    }
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Grid container justify="center">
        <Grid item xs={12}>
          <Box mr={3} ml={3} style={{ boxShadow: "3px 3px 10px 7px rgba(1,22,39,.2)", borderRadius: 15 }}>
            <MaterialTable
              backendPagination
              fetchCount={props.fetchCompanyCount}
              paginationCall={props.loadCompanies}
              title="Organizations"
              entity="companies"
              columns={columns}
              showActions
              customActions={
                props.update
                  ? [
                      {
                        tooltip: "View Details",
                        icon: () => <i className="fal fa-info-circle" />,
                        onClick: async (e, row) => navCompanyDetails(row),
                      },
                    ]
                  : null
              }
              addable={props.create}
              editable={props.update}
              deletable={props.delete}
              isDeletable={(rowData) => !rowData.name.toLowerCase().includes("cisoshare")}
              addFunction={(newData) => createOrganization(newData)}
              editFunction={(newData, oldData) => editOrganization(newData)}
              deleteFunction={(newData) => deleteOrganization(newData)}
              options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    companies: state.entities.companies,
    pageInfo: state.pagination.companies,
    theme: state.user && state.user.internalStore ? state.user.internalStore.theme : "light",
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadCompanies: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(loadCompanies(page, limit, refresh, searchTerm, sort, by)),
    fetchCompanyCount: (searchTerm) => dispatch(fetchCompanyCount(searchTerm)),
    saveCompany: (payload) => dispatch(saveCompany(payload)),
    updateCompany: (id, payload) => dispatch(updateCompany(id, payload)),
    deleteCompany: (id) => dispatch(deleteCompany(id)),
    getAdminProfile: () => dispatch(getAdminProfile()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Companies));
