import React from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadOrgImpacts,
  fetchOrgImpactsCount,
  saveOrgImpact,
  updateOrgImpactById,
  deleteOrgImpactById,
  loadDataTypesList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { getColumns } from "../utils/orgImpactColumns";

const OrgImpacts = (props) => {
  const columns = getColumns(props.dataTypes);

  React.useEffect(() => {
    props.loadDataTypesList();
  }, []);

  const saveOrgImpact = async (rowData) => {
    try {
      const payload = {
        ...rowData,
        dataTypeIds: rowData.dataTypes ? rowData.dataTypes.split(",") : [],
      };
      const response = await props.saveOrgImpact({ ...payload });
      return response;
    } catch (err) {}
  };

  const updateOrgImpact = async (newData, oldData) => {
    try {
      let payload = { ...newData };
      const dataTypeIds =
        newData.dataTypes && Array.isArray(newData.dataTypes)
          ? newData.dataTypes.map((dataType) => dataType.dataType)
          : newData.dataTypes
          ? newData.dataTypes.split(",")
          : [];
      payload = { ...payload, dataTypeIds };
      const response = await props.updateOrgImpactById(newData.id, { ...payload });
      return response;
    } catch (err) {}
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchOrgImpactsCount}
            paginationCall={props.loadOrgImpacts}
            title="Organizational Impacts"
            entity={"orgImpacts"}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => saveOrgImpact(rowData)}
            editFunction={(newData, oldData) => updateOrgImpact(newData, oldData)}
            deleteFunction={(rowData) => props.deleteOrgImpactById(rowData.id)}
            columns={columns}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orgImpacts: state.entities.orgImpacts,
    pageInfo: state.pagination.orgImpacts,
    dataTypes: (state.entities.lists && state.entities.lists.dataTypes && state.entities.lists.dataTypes.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadOrgImpacts: (page, limit, refresh) => dispatch(loadOrgImpacts(page, limit, refresh)),
    fetchOrgImpactsCount: () => dispatch(fetchOrgImpactsCount()),
    saveOrgImpact: (payload) => dispatch(saveOrgImpact(payload)),
    updateOrgImpactById: (id, payload) => dispatch(updateOrgImpactById(id, payload)),
    deleteOrgImpactById: (id) => dispatch(deleteOrgImpactById(id)),
    loadDataTypesList: () => dispatch(loadDataTypesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(OrgImpacts));
