import React, { useState, useEffect } from "react";
import { Box, Grid, Hidden, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Warning from "../../../components/Dialog/WarningDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import { DatePicker } from "../utils/helpers";
import BaseButton from "../../../components/BaseButton/BaseButton";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    background: "#F5F5F5",
    borderRadius: 10,
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  scrollBoxLower: {
    color: "black",
    fontSize: "1rem",
    cursor: "copy",
    fontFamily: "Lato",
  },
  light: {
    backgroundColor: "rgba(51,92,129,0.05)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
    },
  },
  dark: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
    },
  },
}));

export default function EditTemplate(props) {
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [queueId, setQueueId] = useState("");

  return (
    <Box className={classes.titleBox}>
      <Box fontWeight="bold" mb={4}>
        Fill the form with an existing project's data
      </Box>
      <Warning
        delete
        loading={props.loading}
        anchorEl={anchorEl}
        text={`This will replace all the data in the roles, work elements, and title section, but clear client 
        information, billing information, and project overview. Are you sure you want to duplicate this? 
        You can always go back without saving.`}
        confirm={() => {
          props.getProjectQueueItemById(queueId, true);
          setAnchorEl(null);
        }}
        close={() => setAnchorEl(null)}
      />
      {props.projectQueueList.map((item, index) => {
        return (
          <Box
            mb={1}
            onClick={(e) => {
              setQueueId(item.id);
              setAnchorEl(e.currentTarget);
            }}
            p={1}
            index={index}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            className={`${classes.scrollBoxLower} ${index % 2 === 0 ? classes.light : classes.dark}`}
          >
            <Box>
              {/* <Box>{isShown ? <i className="fal fa-cog fa-spin" /> : <i className="fal fa-copy" />}</Box> */}
              <Box fontSize="0.9rem" fontWeight="bold" style={{ textTransform: "uppercase" }}>
                {item.projectName}
              </Box>
            </Box>

            <Box fontSize="0.8rem">{item.company.name}</Box>
          </Box>
        );
      })}
    </Box>
  );
}
