import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import Search from "../../../components/SearchBar/Search";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import LineItem from "../components/LineItem";
import FrameTool from "../components/FrameTool";
import Snackbar from "../../../components/Snackbar/Snackbar";

export default function Table(props) {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });

  const openNewForm = () => {
    props.history.push(`/projectqueue/form`); // create new company and set id ${new project id}
  };

  const editProjectForm = (id, archived = false) => {
    if (archived) {
      return props.history.push(`/projectqueue/form/${id}/archives`);
    }
    props.history.push(`/projectqueue/form/${id}`);
  };

  return (
    <Box flexDirection="column" height="100%" width="100%" style={{ display: "flex", alignItems: "center" }}>
      <Snackbar {...snackbar} handleClose={() => props.setSnackbar({ ...snackbar, isOpen: false })} />
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Box
            ml={2}
            fontSize="1rem"
            fontWeight="bold"
            style={{ display: "flex", alignItems: "center", height: "70px" }}
          >
            Project Queue
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box
            height="100%"
            style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 16 }}
          >
            <FrameTool
              tip="Create New Project"
              light
              solid
              icon="plus-circle"
              color={"#2373D8"}
              onClick={() => openNewForm()}
            />
            <Search search={props.search} setSearch={props.setSearch} searching={props.searching} />
          </Box>
        </Grid>
        <Header setData={props.setData} data={props.data} sort={props.sort} setSort={props.setSort} />
      </Grid>
      <Box minHeight="45vh" display="flex" width="100%" flexDirection="column">
        {props.data.map((item, index) => (
          <LineItem
            archived={!!item.archived}
            item={item}
            snackbar={snackbar}
            setSnackbar={setSnackbar}
            index={index}
            data={props.data}
            editProjectForm={editProjectForm}
            setDialog={props.setDialog}
          />
        ))}
      </Box>

      <Pagination
        count={props.count}
        limit={props.limit}
        setLimit={props.setLimit}
        page={props.page}
        setPage={props.setPage}
      />
    </Box>
  );
}
