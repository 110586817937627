export const settings = {
  radius: "8px",
  shadow: "3px 3px 10px 7px rgba(1,22,39,.2)",
  transition: "0.05s ease-in-out",
};

// FONT AWESOME
export const fonts = {
  solid: "fas", // PRO NOT REQUIRED
  reg: "far", // PRO REQUIRED
  light: "fal", // PRO REQUIRED
  duo: "fad",
};

// SET FONT AWESOME TYPE GLOBALLY
export const fontType = fonts.reg;
