/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const SAVE_PROJECT_REQUEST = "SAVE_PROJECT_REQUEST";
export const SAVE_PROJECT_SUCCESS = "SAVE_PROJECT_SUCCESS";
export const SAVE_PROJECT_FAILURE = "SAVE_PROJECT_FAILURE";

const createProject = (payload) => ({
  [CALL_API]: {
    types: [SAVE_PROJECT_REQUEST, SAVE_PROJECT_SUCCESS, SAVE_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROJECTS_ARRAY,
  },
});

export const saveProject = (payload) => (dispatch) => {
  return dispatch(createProject(payload));
};

export const GET_PROJECTS_LIST_REQUEST = "GET_PROJECTS_LIST_REQUEST";
export const GET_PROJECTS_LIST_SUCCESS = "GET_PROJECTS_LIST_SUCCESS";
export const GET_PROJECTS_LIST_FAILURE = "GET_PROJECTS_LIST_FAILURE";

const fetchProjectsList = () => ({
  [CALL_API]: {
    types: [GET_PROJECTS_LIST_REQUEST, GET_PROJECTS_LIST_SUCCESS, GET_PROJECTS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getProjectsList = () => (dispatch) => {
  return dispatch(fetchProjectsList());
};

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

const changeProject = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROJECTS,
  },
});

export const updateProject = (id, payload) => (dispatch) => {
  return dispatch(changeProject(id, payload));
};

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

const removeProject = (id) => ({
  [CALL_API]: {
    types: [DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PROJECTS,
  },
});

export const deleteProject = (id) => (dispatch) => {
  return dispatch(removeProject(id));
};

export const GET_ALL_PROJECTS_REQUEST = "GET_ALL_PROJECTS_REQUEST";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GET_ALL_PROJECTS_FAILURE = "GET_ALL_PROJECTS_FAILURE";

const fetchAllProjects = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_PROJECTS_REQUEST, GET_ALL_PROJECTS_SUCCESS, GET_ALL_PROJECTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROJECTS_ARRAY,
  },
  refresh,
});

export const getAllProjects = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllProjects(`${ADMIN_URL}/projects/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllProjects(
        `${ADMIN_URL}/projects/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllProjects(
        `${ADMIN_URL}/projects/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllProjects(`${ADMIN_URL}/projects/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_PROJECTS_REQUEST = "GET_COUNT_PROJECTS_REQUEST";
export const GET_COUNT_PROJECTS_SUCCESS = "GET_COUNT_PROJECTS_SUCCESS";
export const GET_COUNT_PROJECTS_FAILURE = "GET_COUNT_PROJECTS_FAILURE";

const fetchProjectsCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_PROJECTS_REQUEST, GET_COUNT_PROJECTS_SUCCESS, GET_COUNT_PROJECTS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getProjectsCount = (search) => (dispatch) => {
  return dispatch(fetchProjectsCount(search));
};

export const CREATE_COMPANY_PROJECT_REQUEST = "CREATE_COMPANY_PROJECT_REQUEST";
export const CREATE_COMPANY_PROJECT_SUCCESS = "CREATE_COMPANY_PROJECT_SUCCESS";
export const CREATE_COMPANY_PROJECT_FAILURE = "CREATE_COMPANY_PROJECT_FAILURE";

const addCompanyProject = (projectId, companyId) => ({
  [CALL_API]: {
    types: [CREATE_COMPANY_PROJECT_REQUEST, CREATE_COMPANY_PROJECT_SUCCESS, CREATE_COMPANY_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/company/${projectId}/${companyId}`,
      method: "POST",
      auth: true,
    },
    schema: Schemas.PROJECTS,
  },
});

export const createCompanyProject = (projectId, companyId) => (dispatch) => {
  return dispatch(addCompanyProject(projectId, companyId));
};

export const UPDATE_COMPANY_PROJECT_REQUEST = "UPDATE_COMPANY_PROJECT_REQUEST";
export const UPDATE_COMPANY_PROJECT_SUCCESS = "UPDATE_COMPANY_PROJECT_SUCCESS";
export const UPDATE_COMPANY_PROJECT_FAILURE = "UPDATE_COMPANY_PROJECT_FAILURE";

const changeCompanyProject = (companyProjectId, milestoneId, status) => ({
  [CALL_API]: {
    types: [UPDATE_COMPANY_PROJECT_REQUEST, UPDATE_COMPANY_PROJECT_SUCCESS, UPDATE_COMPANY_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/company/${companyProjectId}/${milestoneId}/${status}`,
      method: "PUT",
      auth: true,
    },
    schema: Schemas.PROJECTS,
  },
});

export const updateCompanyProject = (companyProjectId, milestoneId, status) => (dispatch) => {
  return dispatch(changeCompanyProject(companyProjectId, milestoneId, status));
};

export const RESET_PROJECT_MILESTONES_REQUEST = "RESET_PROJECT_MILESTONES_REQUEST";
export const RESET_PROJECT_MILESTONES_SUCCESS = "RESET_PROJECT_MILESTONES_SUCCESS";
export const RESET_PROJECT_MILESTONES_FAILURE = "RESET_PROJECT_MILESTONES_FAILURE";

const refreshMilestones = (companyProjectId) => ({
  [CALL_API]: {
    types: [RESET_PROJECT_MILESTONES_REQUEST, RESET_PROJECT_MILESTONES_SUCCESS, RESET_PROJECT_MILESTONES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/reset/milestones/${companyProjectId}`,
      method: "PUT",
      auth: true,
    },
    schema: Schemas.PROJECTS,
  },
});

export const resetMilestones = (companyProjectId) => (dispatch) => {
  return dispatch(refreshMilestones(companyProjectId));
};

export const DELETE_COMPANY_PROJECT_REQUEST = "DELETE_COMPANY_PROJECT_REQUEST";
export const DELETE_COMPANY_PROJECT_SUCCESS = "DELETE_COMPANY_PROJECT_SUCCESS";
export const DELETE_COMPANY_PROJECT_FAILURE = "DELETE_COMPANY_PROJECT_FAILURE";

const removeCompanyProject = (projectId, companyId) => ({
  [CALL_API]: {
    types: [DELETE_COMPANY_PROJECT_REQUEST, DELETE_COMPANY_PROJECT_SUCCESS, DELETE_COMPANY_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/projects/company/${projectId}/${companyId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PROJECTS,
  },
});

export const deleteCompanyProject = (projectId, companyId) => (dispatch) => {
  return dispatch(removeCompanyProject(projectId, companyId));
};
