import React from "react";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

export const getColumns = (requirements = [], states = [], dataTypes = [], industries = []) => {
  return [
    {
      title: "Framework",
      field: "name",
      render: (row) => <div style={{ minWidth: "160px" }}>{row.name}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Client Facing",
      field: "clientFacing",
      type: "boolean",
    },
    {
      title: "Data Types",
      field: "dataTypes",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {Array.isArray(row.dataTypes)
            ? row.dataTypes
                .map((dataType) => dataType.dataType && dataType.dataType.label)
                .filter(Boolean)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => <Selection multiple target="dataType" options={dataTypes} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Industries",
      field: "industries",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {Array.isArray(row.industries)
            ? row.industries
                .map((industry) => (industry.industry && industry.industry.name) || null)
                .filter(Boolean)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => <Selection multiple target="industry" options={industries} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "States",
      field: "states",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {Array.isArray(row.states)
            ? row.states
                .map((state) => state.state && state.state.name)
                .filter(Boolean)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => <Selection multiple target="state" options={states} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    // {
    //   title: "Requirements",
    //   field: "requirements",
    //   render: (row) => (
    //     <div style={{ width: "10rem" }}>
    //       {Array.isArray(row.requirements) && row.requirements.length > 0
    //         ? row.requirements
    //             .map((requirement) => (requirement.requirement && requirement.requirement.name) || null)
    //             .filter((requirement) => requirement !== null)
    //             .join(", ")
    //         : null}
    //     </div>
    //   ),
    //   editComponent: (props) => <ReactSelect {...props} options={requirements} />,
    //   headerStyle: { fontWeight: "bold" },
    //   sorting: false,
    // },
    {
      title: "Best Practice",
      field: "bestPractice",
      type: "boolean",
    },
    {
      title: "Credit Card",
      field: "creditCard",
      type: "boolean",
    },
    {
      title: "Publicly Traded",
      field: "publiclyTraded",
      type: "boolean",
    },
    {
      title: "Global",
      field: "global",
      type: "boolean",
    },
  ];
};
