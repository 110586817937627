import React from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createTimeEntryTask,
  getWorkElementsList,
  getTimeEntryTasksCount,
  getTimeEntryTasksList,
  loadTimeEntryTasks,
  removeTimeEntryTask,
  updateTimeEntryTask,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { Selection } from "../../../components/MaterialTable/Inputs";

const TimeEntryTasks = (props) => {
  const { workElements } = props;
  const columns = [
    {
      title: "Task",
      field: "name",
    },
    {
      title: "Work Elements",
      field: "workElements",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {row.workElements && Array.isArray(row.workElements)
            ? row.workElements
                .map((workElement) => workElement.workElement && workElement.workElement.name)
                .filter(Boolean)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => <Selection multiple target="workElement" options={workElements} {...props} />,
    },
  ];

  React.useEffect(() => {
    props.getWorkElementsList();
  }, []);

  const postTimeEntryTask = async (rowData) => {
    try {
      const payload = {
        timeEntryTask: { name: rowData.name },
        workElementIds:
          rowData.workElements && Array.isArray(rowData.workElements)
            ? rowData.workElements.map((workElement) => workElement.workElementId)
            : rowData.workElements
            ? rowData.workElements.split(",")
            : [],
      };
      return await props.createTimeEntryTask({ ...payload });
    } catch (err) {}
  };

  const editTimeEntryTask = async (newData, oldData) => {
    try {
      let payload = { timeEntryTask: { name: newData.name } };
      payload.workElementIds = newData.workElements.split(",");

      return await props.updateTimeEntryTask(newData.id, { ...payload });
    } catch (err) {}
  };

  const deleteTimeEntryTask = async (rowData) => {
    try {
      return await props.removeTimeEntryTask(rowData.id);
    } catch (err) {}
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getTimeEntryTasksCount}
            paginationCall={props.loadTimeEntryTasks}
            title="Time Entry Tasks"
            entity="timeEntryTasks"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => postTimeEntryTask(rowData)}
            editFunction={(newData, oldData) => editTimeEntryTask(newData, oldData)}
            deleteFunction={(rowData) => deleteTimeEntryTask(rowData)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataTypes: state.entities.dataTypes,
    pageInfo: state.pagination.dataTypes,
    workElements:
      (state.entities.lists && state.entities.lists.workElements && state.entities.lists.workElements.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadTimeEntryTasks: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(loadTimeEntryTasks(page, limit, refresh, searchTerm, sort, by)),
    getTimeEntryTasksCount: () => dispatch(getTimeEntryTasksCount()),
    createTimeEntryTask: (payload) => dispatch(createTimeEntryTask(payload)),
    updateTimeEntryTask: (id, payload) => dispatch(updateTimeEntryTask(id, payload)),
    removeTimeEntryTask: (id) => dispatch(removeTimeEntryTask(id)),
    getTimeEntryTasksList: () => dispatch(getTimeEntryTasksList()),
    getWorkElementsList: () => dispatch(getWorkElementsList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(TimeEntryTasks));
