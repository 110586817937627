/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "/hub";

export const SAVE_PROJECT_TYPE_REQUEST = "SAVE_PROJECT_TYPE_REQUEST";
export const SAVE_PROJECT_TYPE_SUCCESS = "SAVE_PROJECT_TYPE_SUCCESS";
export const SAVE_PROJECT_TYPE_FAILURE = "SAVE_PROJECT_TYPE_FAILURE";

const postProjectType = (payload) => ({
  [CALL_API]: {
    types: [SAVE_PROJECT_TYPE_REQUEST, SAVE_PROJECT_TYPE_SUCCESS, SAVE_PROJECT_TYPE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/project/types/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROJECT_TYPES,
  },
});

export const createProjectType = (payload) => (dispatch) => {
  return dispatch(postProjectType(payload));
};

export const UPDATE_PROJECT_TYPE_REQUEST = "UPDATE_PROJECT_TYPE_REQUEST";
export const UPDATE_PROJECT_TYPE_SUCCESS = "UPDATE_PROJECT_TYPE_SUCCESS";
export const UPDATE_PROJECT_TYPE_FAILURE = "UPDATE_PROJECT_TYPE_FAILURE";

const changeProjectType = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PROJECT_TYPE_REQUEST, UPDATE_PROJECT_TYPE_SUCCESS, UPDATE_PROJECT_TYPE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/project/types/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROJECT_TYPES,
  },
});

export const updateProjectType = (id, payload) => (dispatch) => {
  return dispatch(changeProjectType(id, payload));
};

export const DELETE_PROJECT_TYPE_REQUEST = "DELETE_PROJECT_TYPE_REQUEST";
export const DELETE_PROJECT_TYPE_SUCCESS = "DELETE_PROJECT_TYPE_SUCCESS";
export const DELETE_PROJECT_TYPE_FAILURE = "DELETE_PROJECT_TYPE_FAILURE";

const deleteProjectType = (id) => ({
  [CALL_API]: {
    types: [DELETE_PROJECT_TYPE_REQUEST, DELETE_PROJECT_TYPE_SUCCESS, DELETE_PROJECT_TYPE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/project/types/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PROJECT_TYPES,
  },
});

export const removeProjectType = (id) => (dispatch) => {
  return dispatch(deleteProjectType(id));
};
export const GET_ALL_PROJECT_TYPES_REQUEST = "GET_ALL_PROJECT_TYPES_REQUEST";
export const GET_ALL_PROJECT_TYPES_SUCCESS = "GET_ALL_PROJECT_TYPES_SUCCESS";
export const GET_ALL_PROJECT_TYPES_FAILURE = "GET_ALL_PROJECT_TYPES_FAILURE";

const fetchAllProjectTypes = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_PROJECT_TYPES_REQUEST, GET_ALL_PROJECT_TYPES_SUCCESS, GET_ALL_PROJECT_TYPES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROJECT_TYPES_ARRAY,
  },
  refresh,
});

export const loadProjectTypes = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllProjectTypes(
        `${BASE_URL}/project/types/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllProjectTypes(
        `${BASE_URL}/project/types/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllProjectTypes(
        `${BASE_URL}/project/types/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllProjectTypes(`${BASE_URL}/project/types/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const PROJECT_TYPE_LIST_REQUEST = "PROJECT_TYPE_LIST_REQUEST";
export const PROJECT_TYPE_LIST_SUCCESS = "PROJECT_TYPE_LIST_SUCCESS";
export const PROJECT_TYPE_LIST_FAILURE = "PROJECT_TYPE_LIST_FAILURE";

const fetchProjectTypesList = () => ({
  [CALL_API]: {
    types: [PROJECT_TYPE_LIST_REQUEST, PROJECT_TYPE_LIST_SUCCESS, PROJECT_TYPE_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/project/types/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getProjectTypesList = () => (dispatch) => {
  return dispatch(fetchProjectTypesList());
};

export const PROJECT_TYPE_COUNT_REQUEST = "PROJECT_TYPE_COUNT_REQUEST";
export const PROJECT_TYPE_COUNT_SUCCESS = "PROJECT_TYPE_COUNT_SUCCESS";
export const PROJECT_TYPE_COUNT_FAILURE = "PROJECT_TYPE_COUNT_FAILURE";

const fetchProjectTypesCount = () => ({
  [CALL_API]: {
    types: [PROJECT_TYPE_COUNT_REQUEST, PROJECT_TYPE_COUNT_SUCCESS, PROJECT_TYPE_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/project/types/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getProjectTypesCount = () => (dispatch) => {
  return dispatch(fetchProjectTypesCount());
};
