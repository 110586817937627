import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { constants } from "../../../utils/common";
import { updateCompanyLicense, integrateCompanyWithHubspot } from "../../../store/actions";

const useStyles = makeStyles({
  root: {
    height: 225,
    boxShadow: "2px 2px 10px 1px #c2cfd6 !important",
    marginTop: "1rem",
    marginLeft: "1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: { fontSize: 14 },
  pos: { marginBottom: 12 },
  lightBlueButton: {
    padding: "5px 10px",
    textTransform: "capitalize",
    border: "1px solid #2373D8",
    color: "white",
    backgroundColor: "#2373D8",
    transition: "0.4s",
    "&:hover": {
      backgroundColor: "white",
      color: "#2373D8",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  redButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: `1px solid ${"#EB6B6C"}`,
    color: "#EB6B6C",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#EB6B6C",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
});

function Details(props) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState((props.license && props.license.expirationDate) || new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(props.license && props.license.type);
  const [dealId, setDealId] = useState(props.dealId);
  const [licenseSaving, setLicenseSaving] = useState(false);
  const [dealSaving, setDealSaving] = useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDateChange = (date) => setSelectedDate(date);

  const updateLicense = async () => {
    setLicenseSaving(true);
    const result = await props.updateCompanyLicense(props.companyId, { expirationDate: selectedDate, type });
    setLicenseSaving(false);
    if (!result.type.includes("SUCCESS")) {
      return props.setSnackbar({ isOpen: true, message: "Licence could not be saved", variant: "error" });
    }
    return props.setSnackbar({ isOpen: true, message: "License Saved Successfully!", variant: "success" });
  };

  const createHubSpotIntegration = async (clear = false) => {
    setDealSaving(true);
    const result = await props.integrateCompanyWithHubspot(clear ? "clear" : dealId, props.companyId);
    setDealSaving(false);
    if (!result.type.includes("SUCCESS")) {
      return props.setSnackbar({ isOpen: true, message: "Deal could not be found on HubSpot", variant: "error" });
    }
    if (clear) setDealId("");
    return props.setSnackbar({ isOpen: true, message: "Integrated Successfully!", variant: "success" });
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3} style={{ height: "100%" }}>
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              License
            </Typography>
            <Box display="flex" flexDirection="column" height="100%">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Expiration Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <Box display="flex" alignItems="center" justifyContent="flex-start">
                <Button
                  style={{ marginRight: "1rem" }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Edit Type
                </Button>
                <Box>
                  Type: <span style={{ fontWeight: "bold" }}>{type}</span>
                </Box>
              </Box>

              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    setType(constants.LICENSE_TYPES.EARLY_ACCESS);
                    setAnchorEl(null);
                  }}
                >
                  Early Access
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setType(constants.LICENSE_TYPES.FREE);
                    setAnchorEl(null);
                  }}
                >
                  Free
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setType(constants.LICENSE_TYPES.BASIC);
                    setAnchorEl(null);
                  }}
                >
                  Basic
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setType(constants.LICENSE_TYPES.ENTERPRISE);
                    setAnchorEl(null);
                  }}
                >
                  Enterprise
                </MenuItem>
              </Menu>
            </Box>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "center" }}>
            {licenseSaving ? (
              <CircularProgress />
            ) : (
              <Button className={classes.lightBlueButton} size="small" onClick={() => updateLicense()}>
                Save License
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const dispatchToProps = (dispatch) => {
  return {
    updateCompanyLicense: (companyId, data) => dispatch(updateCompanyLicense(companyId, data)),
    integrateCompanyWithHubspot: (dealId, companyId) => dispatch(integrateCompanyWithHubspot(dealId, companyId)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Details));
