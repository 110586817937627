import React, { useState, useEffect } from "react";
import { MaxText } from "../utils/helpers";
import { Grid, Box, Checkbox } from "@material-ui/core";
import FrameTool from "../components/FrameTool";
import { useStyles } from "../styles/CustomTable.styles";

export default function LineItem(props) {
  const { item, index, data } = props;
  const classes = useStyles();

  const companyName = (item.company && item.company.name) || "";
  const projectName = item.projectName;
  const salesRep =
    (item.projectBackground &&
      item.projectBackground.salesRep &&
      `${item.projectBackground.salesRep.firstName} ${item.projectBackground.salesRep.lastName}`) ||
    "";
  const salesEngineer =
    (item.projectBackground &&
      item.projectBackground.salesEngineer &&
      `${item.projectBackground.salesEngineer.firstName} ${item.projectBackground.salesEngineer.lastName}`) ||
    "";

  return (
    <Box onDoubleClick={() => props.editProjectForm(item.id, props.archived)}>
      <Grid
        key={index}
        style={{ paddingTop: 10, paddingBottom: 10 }}
        className={`${classes.scrollBoxLower} ${index % 2 === 0 ? classes.light : classes.dark}`}
        container
        alignItems="center"
        onClick={props.onClick}
      >
        <Grid item xs={2}>
          <Box height="100%" style={{ marginRight: 10, paddingLeft: 10, borderRight: "1px dashed rgba(0,0,0,.2)" }}>
            <MaxText
              description="Click here to copy the text"
              snackbar={props.snackbar}
              setSnackbar={props.setSnackbar}
              title={companyName}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box height="100%" style={{ borderRight: "1px dashed rgba(0,0,0,.2)", marginRight: 10 }}>
            <MaxText
              description="Click here to copy the text"
              snackbar={props.snackbar}
              setSnackbar={props.setSnackbar}
              title={projectName}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box height="100%" style={{ borderRight: "1px dashed rgba(0,0,0,.2)", marginRight: 10 }}>
            <MaxText
              description="Click here to copy the text"
              snackbar={props.snackbar}
              setSnackbar={props.setSnackbar}
              title={salesRep}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box height="100%" style={{ borderRight: "1px dashed rgba(0,0,0,.2)", marginRight: 10 }}>
            <MaxText
              description="Click here to copy the text"
              snackbar={props.snackbar}
              setSnackbar={props.setSnackbar}
              title={salesEngineer}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box height="100%" style={{ borderRight: "1px dashed rgba(0,0,0,.2)", marginRight: 10 }}>
            <Box fontSize="0.7rem">
              <b>Date Created</b>: {item.createdAt && new Date(item.createdAt).toDateString()}
            </Box>
            <Box fontSize="0.7rem">
              <b>Last Updated</b>:{" "}
              {item.hasOwnProperty("updatedAt") ? new Date(item.updatedAt).toDateString() : " - - -"}
            </Box>
            <Box fontSize="0.7rem">
              <b>Project Start Date</b>: {item.hasOwnProperty("startDate") ? new Date(item.startDate).toDateString() : " - - -"}
            </Box>
            <Box fontSize="0.7rem">
              <b>Project End Date</b>: {item.hasOwnProperty("endDate") ? new Date(item.endDate).toDateString() : " - - -"}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box height="100%" style={{ borderRight: "1px dashed rgba(0,0,0,.2)", marginRight: 10 }}>
            {item.documents.length > 0 ? (
              <FrameTool
                tip="View SOW"
                light
                icon="file-contract"
                color={"#2373D8"}
                onClick={() => props.setDialog({ open: true, url: item.documents[0].url })}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
