import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getControls,
  fetchControlsCount,
  createControl,
  updateControl,
  deleteControl,
  loadLawsList,
} from "../../../store/actions";
import { constants, humanizeLabels } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

const getColumns = (laws = []) => [
  {
    title: "Name",
    field: "name",
    editComponent: (props) => <MultiLineEdit {...props} />,
  },
  {
    title: "Frameworks",
    field: "law",
    render: (row) => <div style={{ minWidth: "50px" }}>{row.law && row.law.name ? row.law.name : null}</div>,
    editComponent: (props) => {
      return (
        <div style={{ minWidth: "80px" }}>
          <Selection target="law" options={laws} {...props} />
        </div>
      );
    },
    headerStyle: { fontWeight: "bold" },
    sorting: false,
  },
];

function Controls(props) {
  useEffect(() => {
    props.loadLawsList();
  }, []);

  const postControl = async (rowData) => {
    try {
      let response = null;
      if (rowData.name && rowData.name.length > 0) {
        const lawId = rowData.law && rowData.law.lawId ? rowData.law.lawId : rowData.law ? rowData.law : null;
        response = await props.createControl({ name: rowData.name, lawId });
      }
      return response;
    } catch (err) {}
  };

  const changeControl = async (rowData, oldData) => {
    try {
      const lawId = rowData.law && rowData.law.lawId ? rowData.law.lawId : rowData.law ? rowData.law : null;
      const response = await props.updateControl(rowData.id, { name: rowData.name, lawId });
      return response;
    } catch (err) {}
  };

  const removeControl = async (rowData) => {
    const response = await props.deleteControl(rowData.id);
    return response;
  };

  const columns = getColumns(props.laws);

  return (
    <Grid container spacing={2}>
      <AuthComponent />
      <Grid item xs={12}>
        <MaterialTable
          backendPagination
          totalCount={0}
          fetchCount={props.fetchControlsCount}
          paginationCall={props.getControls}
          title="Controls"
          entity="controls"
          addable={props.create}
          addFunction={(rowData) => postControl(rowData)}
          editable={props.update}
          editFunction={(newData, oldData) => changeControl(newData, oldData)}
          deletable={props.delete}
          deleteFunction={(rowData) => removeControl(rowData)}
          columns={columns}
          options={{
            search: true,
            paging: true,
            pageSize: constants.DEFAULT_PAGE_LIMIT,
          }}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: state.entities.classes,
    laws: state.entities && state.entities.lists && state.entities.lists.laws && state.entities.lists.laws.list,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getControls: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getControls(page, limit, refresh, searchTerm, sort, by)),
    fetchControlsCount: (searchTerm) => dispatch(fetchControlsCount(searchTerm)),
    createControl: (payload) => dispatch(createControl(payload)),
    updateControl: (id, payload) => dispatch(updateControl(id, payload)),
    deleteControl: (id) => dispatch(deleteControl(id)),
    loadLawsList: () => dispatch(loadLawsList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Controls));
