import { makeStyles, fade } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  scrollBoxLower: {
    [theme.breakpoints.up("sm")]: {
      width: "1469px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  scrollBox: {
    overflowX: "auto",
    whiteSpace: "nowrap",
  },
  scrollBoxY: {
    overflowY: "auto",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    height: "52vh",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      display: "contents",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  iconHoverDark: {
    "&:hover": {
      background: "rgba(255,255,255,.1)",
    },
  },
  iconHoverLight: {
    "&:hover": {
      background: "rgba(0,0,0,.1)",
    },
  },
  hoverLight: {
    color: "rgba(255,255,255,0.6)",
    transition: "0.1s ease-in-out",
    "&:hover": {
      color: "rgba(255,255,255,1)",
      transition: "0.1s ease-in-out",
    },
  },
  hoverDark: {
    color: "rgba(0,0,0,1)",
    transition: "0.01s ease-in-out",
    "&:hover": {
      color: "#2373D8",
      transition: "0.01s ease-in-out",
    },
  },
  search: {
    position: "relative",
    borderRadius: 50,
    border: "1px solid transparent",
    backgroundColor: "rgba(51,92,129,.05)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
      border: "1px solid #2373D8",
    },
    "&:focus": {
      border: "1px solid #2373D8",
    },
    marginLeft: 10,
    width: "fit-content",
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(1),
    //   width: "auto",
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2373D8",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "25ch",
        border: "1px solid #2373D8",
        borderRadius: 50,
      },
    },
  },
}));
