import React from "react";
import { MaxText } from "../utils/helpers";
import { Grid, Box, Checkbox } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../styles/CustomTable.styles";

export default function ProjectResourceItem(props) {
  const { resource, index } = props;
  const classes = useStyles();
  return (
    <Grid
      key={resource.id}
      className={classes.scrollBoxLower}
      container
      style={{
        color: "black",
        backgroundColor: index % 2 === 0 ? "rgba(0,0,0,0.05)" : "white",
        fontSize: "1rem",
        fontFamily: "Lato",
      }}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={2}>
        <Box p={2}>
          <MaxText
            description="Click here to copy the text"
            title={resource ? `${resource.firstName} ${resource.lastName}` : ""}
            snackbar={props.snackbar}
            setSnackbar={props.setSnackbar}
          />
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box p={2} display="flex" flexDirection="column">
          {resource.companyProjects &&
            resource.companyProjects.map((companyProject) => {
              return (
                <Box key={companyProject.id}>
                  <MaxText
                    nowrap
                    description="Click here to copy the text"
                    title={
                      (companyProject &&
                        companyProject.project &&
                        companyProject.project.company &&
                        companyProject.project.company.name) ||
                      ""
                    }
                    snackbar={props.snackbar}
                    setSnackbar={props.setSnackbar}
                  />
                </Box>
              );
            })}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box p={2} display="flex" flexDirection="column">
          {resource.companyProjects &&
            resource.companyProjects.map((companyProject) => {
              return (
                <Box key={companyProject.id}>
                  <MaxText
                    description="Click here to copy the text"
                    title={companyProject.project && companyProject.project.type}
                    snackbar={props.snackbar}
                    setSnackbar={props.setSnackbar}
                  />
                </Box>
              );
            })}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box p={2} display="flex" flexDirection="column">
          {resource.companyProjects &&
            resource.companyProjects.map((companyProject) => {
              return (
                <Box key={companyProject.id}>
                  <MaxText
                    nowrap
                    description="Click here to copy the text"
                    title={companyProject && companyProject.project.projectName}
                    snackbar={props.snackbar}
                    setSnackbar={props.setSnackbar}
                  />
                </Box>
              );
            })}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box p={2} display="flex" flexDirection="column">
          {resource.companyProjects &&
            resource.companyProjects.map((companyProject) => {
              return (
                <Box key={companyProject.id}>
                  <MaxText
                    nowrap
                    description="Click here to copy the text"
                    title={companyProject && companyProject.role && companyProject.role.name}
                    snackbar={props.snackbar}
                    setSnackbar={props.setSnackbar}
                  />
                </Box>
              );
            })}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              {resource.companyProjects &&
                resource.companyProjects.map((companyProject) => {
                  return (
                    <Box
                      display="flex"
                      mt="3px"
                      mb="3px"
                      alignItems="center"
                      justifyContent="center"
                      width="50px"
                      key={companyProject.id}
                      style={{ background: "white", borderRadius: 5, boxShadow: "3px 3px 1px 1px rgba(1,22,39,.1)" }}
                    >
                      {companyProject.allocation}
                    </Box>
                  );
                })}
            </Box>

            <Box
              ml={1}
              p={1}
              style={{ background: "white", borderRadius: 5, boxShadow: "3px 3px 1px 1px rgba(1,22,39,.1)" }}
            >
              <span style={{ fontWeight: "bold" }}>
                Total:{" "}
                {resource.companyProjects && resource.companyProjects.reduce((acc, cp) => acc + cp.allocation, 0)}
              </span>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
