/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "/hub";

export const SAVE_WORK_ELEMENT_REQUEST = "SAVE_WORK_ELEMENT_REQUEST";
export const SAVE_WORK_ELEMENT_SUCCESS = "SAVE_WORK_ELEMENT_SUCCESS";
export const SAVE_WORK_ELEMENT_FAILURE = "SAVE_WORK_ELEMENT_FAILURE";

const postWorkElement = (payload) => ({
  [CALL_API]: {
    types: [SAVE_WORK_ELEMENT_REQUEST, SAVE_WORK_ELEMENT_SUCCESS, SAVE_WORK_ELEMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/work/elements/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.WORK_ELEMENTS,
  },
});

export const createWorkElement = (payload) => (dispatch) => {
  return dispatch(postWorkElement(payload));
};

export const UPDATE_WORK_ELEMENT_REQUEST = "UPDATE_WORK_ELEMENT_REQUEST";
export const UPDATE_WORK_ELEMENT_SUCCESS = "UPDATE_WORK_ELEMENT_SUCCESS";
export const UPDATE_WORK_ELEMENT_FAILURE = "UPDATE_WORK_ELEMENT_FAILURE";

const changeWorkElement = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_WORK_ELEMENT_REQUEST, UPDATE_WORK_ELEMENT_SUCCESS, UPDATE_WORK_ELEMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/work/elements/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.WORK_ELEMENTS,
  },
});

export const updateWorkElement = (id, payload) => (dispatch) => {
  return dispatch(changeWorkElement(id, payload));
};

export const DELETE_WORK_ELEMENT_REQUEST = "DELETE_WORK_ELEMENT_REQUEST";
export const DELETE_WORK_ELEMENT_SUCCESS = "DELETE_WORK_ELEMENT_SUCCESS";
export const DELETE_WORK_ELEMENT_FAILURE = "DELETE_WORK_ELEMENT_FAILURE";

const deleteWorkElement = (id) => ({
  [CALL_API]: {
    types: [DELETE_WORK_ELEMENT_REQUEST, DELETE_WORK_ELEMENT_SUCCESS, DELETE_WORK_ELEMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/work/elements/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.WORK_ELEMENTS,
  },
});

export const removeWorkElement = (id) => (dispatch) => {
  return dispatch(deleteWorkElement(id));
};
export const GET_ALL_WORK_ELEMENTS_REQUEST = "GET_ALL_WORK_ELEMENTS_REQUEST";
export const GET_ALL_WORK_ELEMENTS_SUCCESS = "GET_ALL_WORK_ELEMENTS_SUCCESS";
export const GET_ALL_WORK_ELEMENTS_FAILURE = "GET_ALL_WORK_ELEMENTS_FAILURE";

const fetchAllWorkElements = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_WORK_ELEMENTS_REQUEST, GET_ALL_WORK_ELEMENTS_SUCCESS, GET_ALL_WORK_ELEMENTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.WORK_ELEMENTS_ARRAY,
  },
  refresh,
});

export const loadWorkElements = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllWorkElements(
        `${BASE_URL}/work/elements/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllWorkElements(
        `${BASE_URL}/work/elements/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllWorkElements(
        `${BASE_URL}/work/elements/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllWorkElements(
      `${BASE_URL}/work/elements/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`
    )
  );
};

export const WORK_ELEMENT_LIST_REQUEST = "WORK_ELEMENT_LIST_REQUEST";
export const WORK_ELEMENT_LIST_SUCCESS = "WORK_ELEMENT_LIST_SUCCESS";
export const WORK_ELEMENT_FAILURE = "REQUEST_LIST_FAILUREWORK_ELEMENT_FAILURE";

const fetchWorkElementsList = (internal) => ({
  [CALL_API]: {
    types: [WORK_ELEMENT_LIST_REQUEST, WORK_ELEMENT_LIST_SUCCESS, WORK_ELEMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/work/elements/list?internal=${!!internal}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getWorkElementsList = (internal) => (dispatch) => {
  return dispatch(fetchWorkElementsList(internal));
};

export const WORK_ELEMENT_COUNT_REQUEST = "WORK_ELEMENT_COUNT_REQUEST";
export const WORK_ELEMENT_COUNT_SUCCESS = "WORK_ELEMENT_COUNT_SUCCESS";
export const WORK_ELEMENT_COUNT_FAILURE = "WORK_ELEMENT_COUNT_FAILURE";

const fetchWorkElementsCount = () => ({
  [CALL_API]: {
    types: [WORK_ELEMENT_COUNT_REQUEST, WORK_ELEMENT_COUNT_SUCCESS, WORK_ELEMENT_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/work/elements/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getWorkElementsCount = () => (dispatch) => {
  return dispatch(fetchWorkElementsCount());
};
