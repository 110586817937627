import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import Frame from "../../components/Frame/Frame";
import Snackbar from "../../components/Snackbar/Snackbar";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import ProjectQueue from "./views/ProjectQueue";
import ProjectArchived from "./views/ProjectArchived";
import {
  getProjectsList,
  getAllProjectQueueItems,
  getProjectQueueCount,
  getAllArchivedProjectQueueItems,
  getArchivedProjectQueueCount,
  verifyUserPassword,
} from "../../store/actions";

function ProjectInitiation(props) {
  //   const classes = useStyles();
  const [value, setValue] = useState(0);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });

  const tabsView = () => {
    switch (value) {
      case 0:
        return <ProjectQueue {...props} />;
      case 1:
        return <ProjectArchived {...props} />;
    }
  };

  const tabs = ["Queue", "Archived"];

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    adminProjects:
      (state.entities && state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list) ||
      [],
    count:
      (state.entities.counts && state.entities.counts.projectQueue && state.entities.counts.projectQueue.count) || 0,
    archivedCount:
      (state.entities.counts &&
        state.entities.counts.archivedProjectQueue &&
        state.entities.counts.archivedProjectQueue.count) ||
      0,
    projectQueue: (state.entities && state.entities.projectQueue && Object.values(state.entities.projectQueue)) || [],
    archivedProjectQueue:
      (state.entities && state.entities.archivedProjectQueue && Object.values(state.entities.archivedProjectQueue)) ||
      [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getProjectsList: () => dispatch(getProjectsList()),
    getAllProjectQueueItems: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getAllProjectQueueItems(page, limit, refresh, searchTerm, sort, by)),
    getAllArchivedProjectQueueItems: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getAllArchivedProjectQueueItems(page, limit, refresh, searchTerm, sort, by)),
    getProjectQueueCount: (search) => dispatch(getProjectQueueCount(search)),
    getArchivedProjectQueueCount: (search) => dispatch(getArchivedProjectQueueCount(search)),
    verifyUserPassword: (payload) => dispatch(verifyUserPassword(payload)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(ProjectInitiation));
