import React, { useState, useEffect } from "react";
import { Box, IconButton, Backdrop } from "@material-ui/core";
import { useStyles } from "./styles/TableStyles.styles";
import ClientInfo from "./components/ClientInfo";
import BillingInfo from "./components/BillingInfo";
import ProjectOverview from "./components/ProjectOverview";
import Roles from "./components/Roles";
import Deliverables from "./components/Deliverables";
import Title from "./components/Title";
import DropZone from "./components/DropZone";
import { host } from "../../config/api_config";
import axios from "axios";
import * as auth from "../../utils/auth_helper";
import BaseButton from "../../components/BaseButton/BaseButton";
import NavigationBlocker from "../../components/NavigationBlocker/NavigationBlocker";
import Warning from "../../components/Dialog/WarningDialog";
import Snackbar from "../../components/Snackbar/Snackbar";
import { validationErrors } from "./utils/error_helpers";

export default function Form(props) {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAl, setAnchorAl] = useState(null);
  const [state, setState] = useState({ checkedA: false });
  const [isRoleBased, setIsRoleBased] = useState(false);
  const [project, setProject] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [projectType, setProjectType] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgLoc, setOrgLoc] = useState("");
  const [orgBack, setOrgBack] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactTitle, setContactTitle] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [billingName, setBillingName] = useState("");
  const [billingTitle, setBillingTitle] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [purchaseOrderReq, setPurchaseOrderReq] = useState("Yes");
  const [billingTerms, setBillingTerms] = useState("Net 30");
  const [businessDrivers, setBusinessDrivers] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [futureState, setFutureState] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [travel, setTravel] = useState("No");
  const [timeframe, setTimeframe] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [salesEngineer, setSalesEngineer] = useState("");
  const [projectRoles, setProjectRoles] = useState([{ roleId: "", allocation: 0 }]);
  const [deliverableId, setDeliverableId] = useState([""]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [deliverable, setDeliverable] = useState("");
  const [timeElementInterval, setTimeElementInterval] = useState("");
  const [timeElementUnit, setTimeElementUnit] = useState("");
  const [projectStartDate, setProjectStartDate] = useState(new Date());

  useEffect(() => {
    if (!!props.projectQueueId) {
      getProjectQueueItemById(props.projectQueueId);
    }
  }, [props.projectQueueId]);

  const getProjectQueueItemById = async (id, duplicate = false) => {
    setLoading(true);
    await props.loadRolesList();
    let workElementsList = await props.getWorkElementsList();
    workElementsList = workElementsList.response.entities.lists.workElements.list;
    const result = await props.getProjectQueueItemById(id);
    const resultBody = result.response.entities.projectQueue[id];
    const { billingInformation, clientInformation, projectBackground, projectTypes, workElements } = resultBody;

    //! SOW
    if (!duplicate && resultBody.documents.length > 0) {
      setDocumentId(resultBody.documents[0].id);
      setFileName(resultBody.documents[0].name);
    } else {
      setDocumentId("");
      setFileName("");
    }

    //! TITLE
    setOrgId(!duplicate ? resultBody.company : "");
    setProjectName(resultBody.projectName);
    setProjectType(resultBody.type);
    setIsRoleBased(resultBody.isRoleBased);
    setProjectStartDate(resultBody.startDate);
    let formattedProjectData = {};
    for (const workElement of workElements) {
      formattedProjectData[workElement.workElementId] = {
        startDate: new Date(workElement.startDate),
        duration: workElement.duration,
        durationUnit: workElement.durationUnit,
        deliverableIds: workElement.deliverables.map((d) => d.deliverableId),
      };
    }
    setProjectData(formattedProjectData);
    setProject(workElementsList.filter((workElement) => formattedProjectData.hasOwnProperty(workElement.id)));

    //! CLIENT INFO
    setOrgLoc(!duplicate ? clientInformation.location : "");
    setOrgBack(!duplicate ? clientInformation.background : "");
    setContactName(!duplicate ? clientInformation.contactName : "");
    setContactTitle(!duplicate ? clientInformation.contactTitle : "");
    setContactEmail(!duplicate ? clientInformation.contactEmail : "");
    setContactPhone(!duplicate ? clientInformation.contactPhone : "");

    //! BILLING INFO
    if (
      billingInformation.contactName === clientInformation.contactName &&
      billingInformation.contactTitle === clientInformation.contactTitle &&
      billingInformation.contactEmail === clientInformation.contactEmail &&
      billingInformation.contactPhone === clientInformation.contactPhone
    ) {
      setState({ checkedA: !duplicate && true });
      setBillingName(!duplicate ? billingInformation.contactName : "");
      setBillingTitle(!duplicate ? billingInformation.contactTitle : "");
      setBillingEmail(!duplicate ? billingInformation.contactEmail : "");
      setBillingPhone(!duplicate ? billingInformation.contactPhone : "");
    } else {
      setBillingName(!duplicate ? billingInformation.contactName : "");
      setBillingTitle(!duplicate ? billingInformation.contactTitle : "");
      setBillingEmail(!duplicate ? billingInformation.contactEmail : "");
      setBillingPhone(!duplicate ? billingInformation.contactPhone : "");
    }

    //! BACKGROUND
    setPurchaseOrderReq(!duplicate ? (projectBackground.purchaseOrderRequired === true ? "Yes" : "No") : false);
    setBillingTerms(!duplicate ? projectBackground.billingTerms : "NET 30");
    setBusinessDrivers(!duplicate ? projectBackground.businessDrivers : "");
    setCurrentState(!duplicate ? projectBackground.currentState : "");
    setFutureState(!duplicate ? projectBackground.futureState : "");
    setProjectDescription(!duplicate ? projectBackground.description : "");
    setTravel(!duplicate ? (projectBackground.travelRequired === true ? "Yes" : "No") : false);
    setTimeframe(!duplicate ? projectBackground.timeFrame : "");
    setSalesRep(!duplicate ? projectBackground.salesRep : "");
    setSalesEngineer(!duplicate ? projectBackground.salesEngineer : "");

    //! ROLES
    setProjectRoles([]);
    setTimeout(() => {
      setProjectRoles([
        ...projectBackground.roles.map((role) => {
          return { roleId: role.roleId, allocation: role.allocation };
        }),
      ]);
    }, 500);

    //! Project Types
    setServiceTypes([]);
    setTimeout(() => {
      setServiceTypes([...projectTypes.map((projectType) => projectType.projectType)]);
    }, 500);
    setLoading(false);
  };

  const createForm = async (kickoff = false) => {
    setLoading(true);
    try {
      let payload = {};
      payload = {
        companyId: orgId.hasOwnProperty("id") ? orgId.id : orgId,
        projectName: projectName,
        type: projectType,
        startDate: projectStartDate,
        isRoleBased: isRoleBased,
        client: {
          contactName: contactName,
          contactTitle: contactTitle,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
          location: orgLoc,
          background: orgBack,
        },
        billing: {
          contactName: state.checkedA ? contactName : billingName,
          contactTitle: state.checkedA ? contactTitle : billingTitle,
          contactEmail: state.checkedA ? contactEmail : billingEmail,
          contactPhone: state.checkedA ? contactPhone : billingPhone,
        },
        background: {
          purchaseOrderRequired: purchaseOrderReq === "Yes",
          billingTerms: billingTerms,
          businessDrivers: businessDrivers,
          currentState: currentState,
          futureState: futureState,
          description: projectDescription,
          travelRequired: travel === "Yes",
          timeFrame: timeframe,
          salesRepId: (salesRep && salesRep.hasOwnProperty("id") && salesRep.id) || null,
          salesEngineerId: (salesEngineer && salesEngineer.hasOwnProperty("id") && salesEngineer.id) || null,
        },
        roles: projectRoles,
        workElements: projectData,
        projectTypeIds: serviceTypes.map((st) => st.id),
      };

      const error = validationErrors(payload);
      if (error) {
        setLoading(false);
        return setSnackbar({ isOpen: true, variant: "error", message: "Fill out all fields" });
      }

      if (!!props.projectQueueId) {
        const result = await props.updateProjectQueueItem(props.projectQueueId, payload);
        setChangesMade(false);

        if (!!file) {
          await uploadSOW(props.userId, payload.orgId, props.projectQueueId);
        }
        return setLoading(false);
      }
      setChangesMade(false);
      const result = await props.addProjectToQueue(payload);

      if (!!kickoff) {
        await projectKickoff(Object.keys(result.response.entities.projectQueue)[0]);
      }

      setChangesMade(false);
      if (!!file) {
        const projectQueueId = Object.keys(result.response.entities.projectQueue)[0];
        await uploadSOW(props.userId, payload.orgId, projectQueueId);
      }

      if (!props.projectQueueId) {
        props.history.goBack();
      }

      return setLoading(false);
    } catch (err) {}
  };

  const removeProject = async (id) => {
    try {
      await props.removeProjectQueueItem(id);
      props.history.goBack();
    } catch (err) {}
  };

  const projectKickoff = async (id) => {
    try {
      if (!!props.projectQueueId) {
        await createForm();
        await props.projectKickoff(props.projectQueueId);
        props.history.goBack();
      } else {
        await props.projectKickoff(id);
      }
    } catch (err) {}
  };

  const uploadSOW = async (userId, companyId, projectQueueId) => {
    const token = await auth.getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let data = new FormData();
    data.append("file", file);
    return axios
      .post(`${host}/documents/sow/${userId}/${companyId}/${projectQueueId}`, data, config)
      .catch((err) => {});
  };

  return (
    <Box className={classes.formBox} style={{ cursor: props.archives ? "not-allowed" : "normal" }} mt={2} mb={3}>
      <NavigationBlocker navigationBlocked={changesMade} />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Warning
        anchorEl={anchorEl}
        text={`This will move the project over to the Project Roster. Would you like to proceed?`}
        confirm={async () => {
          if (!!props.projectQueueId) return projectKickoff();
          await createForm(true);
        }}
        close={() => setAnchorEl(null)}
      />
      <Warning
        delete
        anchorEl={anchorAl}
        text={`Are you sure you want to delete this project? This is permanent.`}
        confirm={() => removeProject(props.projectQueueId)}
        close={() => setAnchorAl(null)}
      />
      <Title
        archives={props.archives}
        loading={loading}
        setProjectData={setProjectData}
        projectData={projectData}
        project={project}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        setProject={setProject}
        projectType={projectType}
        setProjectType={setProjectType}
        orgId={orgId}
        setOrgId={setOrgId}
        getProjectQueueItemById={getProjectQueueItemById}
        projectQueueList={props.projectQueueList}
        getProjectQueueList={props.getProjectQueueList}
        companies={props.companies}
        projectQueue={props.projectQueue}
        workElementsList={props.workElementsList}
        saveCompany={props.saveCompany}
        projectTypesList={props.projectTypesList}
        getCompaniesList={props.getCompaniesList}
        serviceTypes={serviceTypes}
        setServiceTypes={setServiceTypes}
        projectName={projectName}
        setProjectName={setProjectName}
        projectStartDate={projectStartDate}
        setProjectStartDate={setProjectStartDate}
        isRoleBased={isRoleBased}
        setIsRoleBased={setIsRoleBased}
      />
      <ClientInfo
        archives={props.archives}
        loading={loading}
        orgLoc={orgLoc}
        setOrgLoc={setOrgLoc}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        orgBack={orgBack}
        setOrgBack={setOrgBack}
        contactName={contactName}
        setContactName={setContactName}
        contactTitle={contactTitle}
        setContactTitle={setContactTitle}
        contactEmail={contactEmail}
        setContactEmail={setContactEmail}
        contactPhone={contactPhone}
        setContactPhone={setContactPhone}
      />
      <BillingInfo
        archives={props.archives}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        loading={loading}
        state={state}
        setState={setState}
        contactName={contactName}
        setContactName={setContactName}
        contactTitle={contactTitle}
        setContactTitle={setContactTitle}
        contactEmail={contactEmail}
        setContactEmail={setContactEmail}
        contactPhone={contactPhone}
        setContactPhone={setContactPhone}
        billingName={billingName}
        setBillingName={setBillingName}
        billingTitle={billingTitle}
        setBillingTitle={setBillingTitle}
        billingEmail={billingEmail}
        setBillingEmail={setBillingEmail}
        billingPhone={billingPhone}
        setBillingPhone={setBillingPhone}
      />
      <DropZone
        archives={props.archives}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        loading={loading}
        file={file}
        setFile={setFile}
        fileName={fileName}
        setFileName={setFileName}
        documentId={documentId}
        deleteDocument={props.deleteDocument}
      />
      <ProjectOverview
        archives={props.archives}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        loading={loading}
        purchaseOrderReq={purchaseOrderReq}
        setPurchaseOrderReq={setPurchaseOrderReq}
        billingTerms={billingTerms}
        setBillingTerms={setBillingTerms}
        businessDrivers={businessDrivers}
        setBusinessDrivers={setBusinessDrivers}
        currentState={currentState}
        setCurrentState={setCurrentState}
        futureState={futureState}
        setFutureState={setFutureState}
        projectDescription={projectDescription}
        setProjectDescription={setProjectDescription}
        travel={travel}
        setTravel={setTravel}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        resources={props.resources}
        salesRep={salesRep}
        setSalesRep={setSalesRep}
        salesEngineer={salesEngineer}
        setSalesEngineer={setSalesEngineer}
      />
      <Roles
        archives={props.archives}
        roles={props.roles}
        projectRoles={projectRoles}
        setProjectRoles={setProjectRoles}
        loading={loading}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        projectData={projectData}
        setProjectData={setProjectData}
      />
      <Deliverables
        archives={props.archives}
        loading={loading}
        changesMade={changesMade}
        setChangesMade={setChangesMade}
        projectData={projectData}
        setProjectData={setProjectData}
        deliverableId={deliverableId}
        setDeliverableId={setDeliverableId}
        deliverable={deliverable}
        setDeliverable={setDeliverable}
        deliverablesList={props.deliverablesList}
        workElementsList={props.workElementsList}
        timeElementInterval={timeElementInterval}
        setTimeElementInterval={setTimeElementInterval}
        timeElementUnit={timeElementUnit}
        setTimeElementUnit={setTimeElementUnit}
        projectStartDate={projectStartDate}
      />
      {!props.archives ? (
        <Box width="95%" display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton style={{ padding: "7px 12px" }} onClick={() => props.history.goBack()}>
            <i className="fas fa-chevron-left" style={{ color: "black" }} />
          </IconButton>
          <Box mr={1} ml={1} fontWeight="bold">
            {" "}
            |{" "}
          </Box>
          {!!props.projectQueueId ? (
            <Box mt={2} mb={2} alignSelf="flex-end">
              <BaseButton red text="Delete Project" clickHandler={(e) => setAnchorAl(e.currentTarget)} />
            </Box>
          ) : null}
          <Box
            mt={2}
            mb={2}
            ml={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: !!props.projectQueueId ? 140 : 200,
            }}
          >
            {loading ? (
              <i className="fal fa-cog fa-spin fa-2x" />
            ) : (
              <BaseButton
                white
                text={!!props.projectQueueId ? "Save Changes" : "Create New Project"}
                clickHandler={async () => {
                  await createForm();
                }}
              />
            )}
          </Box>
          <Box mr={1} ml={1} fontWeight="bold">
            {" "}
            |{" "}
          </Box>
          <Box mt={2} mb={2} alignSelf="flex-end">
            <BaseButton green text="Kick Off!" clickHandler={(e) => setAnchorEl(e.currentTarget)} />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
