import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Checkbox,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  IconButton,
} from "@material-ui/core";
import BaseButton from "../../../components/BaseButton/BaseButton";
import { useStyles } from "../styles/ProjectAndTasks.styles";
import ProjectItem from "./ProjectItem";

export default function Table(props) {
  const classes = useStyles();
  const [timeEntryId, setTimeEntryId] = useState("");
  const [currentTimeEntry, setCurrentTimeEntry] = useState({});

  return (
    <Box
      display="flex"
      width="100%"
      className={classes.mainTable}
      style={{ background: "white", flexDirection: "column", height: "63vh", overflow: "auto" }}
    >
      {props.projects &&
        props.projects
          .filter((p) => {
            if (props.currentFilters.includes("COMPLETED") && p.archived) {
              return p;
            } else {
              return !p.archived;
            }
          })
          .map((d, i) => {
            return (
              <ProjectItem
                key={d.id}
                setStartRefresh={props.setStartRefresh}
                managedServices={props.managedServices}
                proServices={props.proServices}
                archived={props.archived}
                updateUserCompanyProject={props.updateUserCompanyProject}
                updateTimeEntry={props.updateTimeEntry}
                setRefresh={props.setRefresh}
                deleteTimeEntry={props.deleteTimeEntry}
                companyProjectId={props.companyProjectId}
                setCompanyProjectId={props.setCompanyProjectId}
                setAddTime={props.setAddTime}
                getWorkElementTasks={props.getWorkElementTasks}
                createTimeEntryTask={props.createTimeEntryTask}
                index={i}
                number={i}
                name={d.name}
                project={d}
                type={d.type}
                timeEntryId={timeEntryId}
                setTimeEntryId={setTimeEntryId}
                currentTimeEntry={currentTimeEntry}
                setCurrentTimeEntry={setCurrentTimeEntry}
                refresh={props.refresh}
                preFillAddTimeDialog={props.preFillAddTimeDialog}
                getWorkElementsList={props.getWorkElementsList}
              />
            );
          })}
    </Box>
  );
}
