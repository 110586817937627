import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Table from "../components/Table";
import VerifyPassword from "../components/VerifyPassword";
import { useDebounce } from "../../../utils/hooks/useDebounce";

export default function ProjectArchived(props) {
  // const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ column: "", direction: "" });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [dialog, setDialog] = useState({ open: false, url: "" });
  const [searching, setSearching] = useState(false);
  const debouncedSearch = useDebounce(search, 750);

  useEffect(() => {
    fetchData();
  }, [page, limit, sort]);

  useEffect(() => {
    fetchData(true);
  }, [debouncedSearch]);

  const fetchData = async () => {
    if (search.length > 0) setSearching(true);
    await props.getArchivedProjectQueueCount(search);
    const result = await props.getAllArchivedProjectQueueItems(page, limit, false, search, sort.direction, sort.column);
    setData(
      (result.response &&
        result.response.entities &&
        result.response.entities.projectQueue &&
        Object.values(result.response.entities.projectQueue)) ||
        []
    );
    setSearching(false);
  };

  return (
    <Box height="100%">
      <VerifyPassword
        open={dialog.open}
        url={dialog.url}
        handleClose={() => {
          setDialog({ open: false, url: "" });
        }}
        verifyUserPassword={props.verifyUserPassword}
      />
      <Table
        archived
        history={props.history}
        setData={setData}
        setDialog={setDialog}
        data={data}
        sort={sort}
        setSort={setSort}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        count={props.archivedCount}
        setCount={setCount}
        search={search}
        setSearch={setSearch}
        searching={searching}
      />
    </Box>
  );
}
