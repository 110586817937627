/**
 *
 */
import {
  LOGIN,
  LOGOUT,
  GET_ADMIN_PROFILE,
  VERIFY_ADMIN_PROFILE,
  IS_LOGGED_IN,
  IS_LOGGING_IN,
  UPDATE_INTERNAL_STORE
} from "../actions/user_actions";

const initialState = {
  isLoggedIn: false,
  isLoggingIn: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLoggingIn: false };
    case LOGOUT:
      return { isLoggedIn: false };
    case GET_ADMIN_PROFILE:
      return { ...state, ...action.payload };
    case VERIFY_ADMIN_PROFILE:
      return { ...state, isLoggedIn: action.payload.qrVerified };
    case IS_LOGGED_IN:
      return { ...state, ...action.isLoggedIn };
    case IS_LOGGING_IN:
      return { ...state, ...action.payload };
    case UPDATE_INTERNAL_STORE:
      return { ...state, internalStore: { ...action.payload } };
    default:
      return state;
  }
};
