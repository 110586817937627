import React from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadProcesses, fetchProcessCount, saveProcess, updateProcess, deleteProcess } from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { getColumns } from "../utils/processColumns";

const Processes = (props) => {
  const columns = getColumns();

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchProcessCount}
            paginationCall={props.loadProcesses}
            title="Processes"
            entity={"processes"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(row) => props.saveProcess(row)}
            editFunction={(row, oldData) => props.updateProcess(row.id, row)}
            deleteFunction={(row) => props.deleteProcess(row.id)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    processes: state.entities.processes,
    pageInfo: state.pagination.processes,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadProcesses: (page, limit, refresh) => dispatch(loadProcesses(page, limit, refresh)),
    fetchProcessCount: () => dispatch(fetchProcessCount()),
    saveProcess: (payload) => dispatch(saveProcess(payload)),
    updateProcess: (id, payload) => dispatch(updateProcess(id, payload)),
    deleteProcess: (id) => dispatch(deleteProcess(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Processes));
