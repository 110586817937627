import React, { useState, useEffect } from "react";
import { Box, Grid, Checkbox, TextField, FormControlLabel, Tooltip } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import FreeSolo from "../components/FreeSolo";
import Skeleton from "@material-ui/lab/Skeleton";
import { DatePicker } from "../utils/helpers";
import BaseButton from "../../../components/BaseButton/BaseButton";
import Drawer from "../components/Drawer";

const customFilter = createFilterOptions();

export default function Title(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDateStart = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    props.getProjectQueueList();
  }, [open]);

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.titleBox}>
      <Drawer
        open={open}
        setOpen={setOpen}
        projectQueueList={props.projectQueueList}
        getProjectQueueItemById={props.getProjectQueueItemById}
        loading={props.loading}
      />
      <Box style={{ color: "#2373D8", textTransform: "uppercase", fontWeight: "bold", fontSize: "1.2rem" }}>
        Project Initiation Form
      </Box>
      <Box style={{ color: "#000" }}>
        These items are to be completed by Sales to trigger the start the delivery of a project.
      </Box>
      <Box mt={3} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <FreeSolo
                changesMade={props.changesMade}
                setChangesMade={props.setChangesMade}
                label="Organization"
                options={props.companies || []}
                saveCompany={props.saveCompany}
                orgId={props.orgId}
                setOrgId={props.setOrgId}
                getCompaniesList={props.getCompaniesList}
                dialogTitle="Add an Organization"
                dialogTextLabel="Organization"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <TextField
                value={props.projectName}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setProjectName(e.target.value);
                }}
                size="small"
                label="Project Name"
                required
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="dense"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <Autocomplete
                style={{ display: "flex", alignItems: "flex-end" }}
                size="small"
                options={["MS", "PS"]}
                value={props.projectType}
                getOptionLabel={(option) => option}
                onChange={(e, newValue) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setProjectType(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    label="Service Type"
                    required
                    margin="dense"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ whiteSpace: "nowrap" }}>
                <Checkbox
                  small
                  checked={props.isRoleBased}
                  onChange={() => props.setIsRoleBased(!props.isRoleBased)}
                  name="isRoleBased"
                />
                <Box fontSize="0.9rem" fontWeight="bold" pt={"3px"}>
                  Role Based
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <DatePicker
                style={{ width: "100%" }}
                label="Project Start Date"
                value={props.projectStartDate}
                inputVariant="outlined"
                marginType="dense"
                onChange={(date) => props.setProjectStartDate(date)}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={6}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <React.Fragment>
                <Autocomplete
                  style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                  size="small"
                  options={props.projectTypesList}
                  multiple
                  value={props.serviceTypes}
                  getOptionLabel={(option) => option && option.name}
                  onChange={(e, newValue) => {
                    try {
                      if (!props.changesMade) props.setChangesMade(true);
                      let tempProjectData = props.projectData;
                      for (const projectType of newValue) {
                        const workElements = projectType.workElements;
                        if (workElements) {
                          for (const workElement of workElements) {
                            if (!tempProjectData.hasOwnProperty(workElement.workElementId)) {
                              tempProjectData[workElement.workElementId] = {
                                startDate: new Date(),
                                duration: 0,
                                durationUnit: "",
                                deliverableIds: workElement.workElement.deliverables.map((d) => d.deliverableId),
                              };
                            }
                          }
                        }
                      }

                      props.setServiceTypes(newValue);
                      props.setProjectData({ ...tempProjectData });
                      return props.setProject(
                        props.workElementsList.filter((we) => tempProjectData.hasOwnProperty(we.id))
                      );
                    } catch (e) {}
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      label="Project Type"
                    />
                  )}
                />{" "}
              </React.Fragment>
            )}
          </Grid>
          <Grid item>
            <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <Tooltip title="Adding a new project type will add all work elements, but removing a project type will not remove any work elements.">
                <i className="fas fa-question-circle" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <BaseButton text={"Copy from Existing Project"} clickHandler={() => handleDrawerOpen()} />
          </Grid>
        </Grid>

        {props.loading ? (
          <Skeleton variant="text" animation="wave" height={50} />
        ) : (
          <Autocomplete
            fullWidth
            size="small"
            options={props.workElementsList || []}
            value={
              props.workElementsList.filter((workElement) => !!props.project.find((we) => we.id === workElement.id)) ||
              []
            }
            getOptionLabel={(option) => option && option.name}
            multiple
            onChange={(e, newVal) => {
              let currentProjectData = props.projectData;
              if (
                Object.keys(currentProjectData).length > 0 &&
                newVal.length < Object.keys(currentProjectData).length
              ) {
                let filteredProjectData = {};
                Object.keys(currentProjectData).forEach((key) => {
                  if (!!newVal.find((val) => val.id === key)) {
                    filteredProjectData[key] = currentProjectData[key];
                  }
                });
                props.setProjectData({ ...filteredProjectData });
                return props.setProject(newVal);
              }

              const newItem = newVal[newVal.length - 1];

              currentProjectData = {
                ...currentProjectData,
                [newItem.id]: {
                  startDate: new Date(),
                  duration: 0,
                  durationUnit: "",
                  deliverableIds: newItem.deliverables.map((d) => d.deliverableId),
                },
              };

              props.setProjectData({ ...currentProjectData });
              props.setProject(newVal);
            }}
            filterSelectedOptions
            disableCloseOnSelect
            limitTags={4}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%", borderRadius: 50 }}
                variant="outlined"
                label="Add Work Elements"
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
}
