import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getCategories,
  fetchCategoriesCount,
  createCategory,
  updateCategory,
  deleteCategory,
  loadPoliciesList,
  getComponentsList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { Selection } from "../../../components/MaterialTable/Inputs";
import { Grid } from "@material-ui/core";

const getColumns = (policies = [], classifications = [], components = []) => [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Domains",
    field: "policies",
    render: (row) => (
      <div style={{ minWidth: "50px" }}>{row.policies && row.policies.policy ? row.policies.policy.name : null}</div>
    ),
    editComponent: (props) => <Selection target="policy" options={policies} {...props} />,
  },
  {
    title: "Components",
    field: "components",
    render: (row) => (
      <div style={{ minWidth: "50px" }}>
        {row.components && row.components.component ? row.components.component.name : null}
      </div>
    ),
    editComponent: (props) => <Selection target="component" options={components} {...props} />,
  },
  {
    title: "Classification",
    field: "classification",
    render: (row) => <div style={{ minWidth: "50px" }}>{row.classification ? row.classification : null}</div>,
    editComponent: (props) => <Selection target={null} options={classifications} {...props} />,
  },
];

const Categories = (props) => {
  useEffect(() => {
    props.loadPoliciesList();
    props.getComponentsList();
  }, []);

  const createCategory = async (newData) => {
    const response = await props.createCategory({
      name: newData.name || null,
      classification: newData.classification || null,
      policyIds: newData.policies ? [newData.policies] : [],
      componentIds: newData.components ? [newData.components] : [],
    });

    return response;
  };

  const updateCategory = async (newData) => {
    const policyIds =
      newData.policies && newData.policies.policyId
        ? [newData.policies.policyId]
        : newData.policies
        ? [newData.policies]
        : [];
    const componentIds =
      newData.components && newData.components.componentId
        ? [newData.components.componentId]
        : newData.components
        ? [newData.components]
        : [];

    const response = await props.updateCategory(newData.id, {
      name: newData.name || null,
      classification: newData.classification || null,
      policyIds,
      componentIds,
    });

    return response;
  };

  const columns = getColumns(
    props.policiesList,
    Object.values(constants.PROCESS_CATEGORIES).map((c) => ({
      value: c,
      label: c,
    })),
    props.componentsList
  );

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MaterialTable
                backendPagination
                fetchCount={props.fetchCategoriesCount}
                paginationCall={props.getCategories}
                title="Categories"
                entity="categories"
                columns={columns}
                addable={props.create}
                editable={props.update}
                deletable={props.delete}
                addFunction={(newData) => createCategory(newData)}
                editFunction={(newData, oldData) => updateCategory(newData)}
                deleteFunction={(oldData) => props.deleteCategory(oldData.id)}
                options={{
                  search: true,
                  paging: true,
                  pageSize: constants.DEFAULT_PAGE_LIMIT,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    policies: state.entities.policies,
    policiesList:
      state.entities && state.entities.lists && state.entities.lists.policies && state.entities.lists.policies.list,
    componentsList:
      state.entities && state.entities.lists && state.entities.lists.components && state.entities.lists.components.list,
    pageInfo: state.pagination.policies,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getCategories: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getCategories(page, limit, refresh, searchTerm, sort, by)),
    fetchCategoriesCount: (searchTerm) => dispatch(fetchCategoriesCount(searchTerm)),
    createCategory: (payload) => dispatch(createCategory(payload)),
    updateCategory: (id, payload) => dispatch(updateCategory(id, payload)),
    deleteCategory: (id) => dispatch(deleteCategory(id)),
    loadPoliciesList: () => dispatch(loadPoliciesList()),
    getComponentsList: () => dispatch(getComponentsList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Categories));
