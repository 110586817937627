import { makeStyles, fade } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  banner: {
    background: "radial-gradient(farthest-corner at 40px 40px,#122F54 0%, #011627 100%)",
    borderRadius: 20,
    width: "100%",
    height: "190px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardsSection: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  box: {
    height: 240,
    width: 240,
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 15px 0px",
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 20px 0px",
    },
  },
  staticBox: {
    height: 240,
    width: 240,
    border: "1px dashed rgba(0, 0, 0, 0.18)",
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
  },
  removeButton: {
    color: "lightgray",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.8rem",
    "&:hover": {
      color: "black",
    },
  },
  search: {
    position: "relative",
    borderRadius: 50,
    border: "1px solid transparent",
    backgroundColor: "rgba(51,92,129,.05)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
      border: "1px solid #2373D8",
    },
    "&:focus": {
      border: "1px solid #2373D8",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2373D8",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "25ch",
        border: "1px solid #2373D8",
        borderRadius: 50,
      },
    },
  },
}));
