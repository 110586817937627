/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";
import { Directions } from "@material-ui/icons";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const UPDATE_LICENSE_REQUEST = "UPDATE_LICENSE_REQUEST";
export const UPDATE_LICENSE_SUCCESS = "UPDATE_LICENSE_SUCCESS";
export const UPDATE_LICENSE_FAILURE = "UPDATE_LICENSE_FAILURE";

const changeCompanyLicense = (companyId, data) => ({
  [CALL_API]: {
    types: [UPDATE_LICENSE_REQUEST, UPDATE_LICENSE_SUCCESS, UPDATE_LICENSE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/license/${companyId}`,
      body: data,
      method: "PUT",
      auth: true,
    },
    schema: Schemas.COMPANY,
  },
});

export const updateCompanyLicense = (companyId, data) => (dispatch) => {
  return dispatch(changeCompanyLicense(companyId, data));
};

export const COMPANY_REQUEST = "COMPANY_REQUEST";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAILURE = "COMPANY_FAILURE";

const fetchCompany = (companyId) => ({
  [CALL_API]: {
    types: [COMPANY_REQUEST, COMPANY_SUCCESS, COMPANY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/${companyId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COMPANY,
  },
});

export const loadCompany = (companyId, requiredFields = []) => (dispatch, getState) => {
  return dispatch(fetchCompany(companyId));
};

export const COMPANIES_DELETE_REQUEST = "COMPANIES_DELETE_REQUEST";
export const COMPANIES_DELETE_SUCCESS = "COMPANIES_DELETE_SUCCESS";
export const COMPANIES_DELETE_FAILURE = "COMPANIES_DELETE_FAILURE";

const removeCompany = (companyId) => ({
  [CALL_API]: {
    types: [COMPANIES_DELETE_REQUEST, COMPANIES_DELETE_SUCCESS, COMPANIES_DELETE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/${companyId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.COMPANY,
  },
});

export const deleteCompany = (companyId) => (dispatch, getState) => {
  return dispatch(removeCompany(companyId));
};

export const SAVE_COMPANY_REQUEST = "SAVE_COMPANY_REQUEST";
export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS";
export const SAVE_COMPANY_FAILURE = "SAVE_COMPANY_FAILURE";

const postCompany = (payload) => ({
  [CALL_API]: {
    types: [SAVE_COMPANY_REQUEST, SAVE_COMPANY_SUCCESS, SAVE_COMPANY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.COMPANY,
  },
});

export const saveCompany = (payload) => (dispatch) => {
  return dispatch(postCompany(payload));
};

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

const changeCompany = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.COMPANY,
  },
});

export const updateCompany = (id, payload) => (dispatch) => {
  return dispatch(changeCompany(id, payload));
};

export const COMPANY_COUNT_REQUEST = "COMPANY_COUNT_REQUEST";
export const COMPANY_COUNT_SUCCESS = "COMPANY_COUNT_SUCCESS";
export const COMPANY_COUNT_FAILURE = "COMPANY_COUNT_FAILURE";

const getCompanyCount = (searchTerm) => ({
  [CALL_API]: {
    types: [COMPANY_COUNT_REQUEST, COMPANY_COUNT_SUCCESS, COMPANY_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/companies/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchCompanyCount = () => (dispatch) => {
  return dispatch(getCompanyCount());
};

const COMPANIES_REQUEST = "COMPANIES_REQUEST";
const COMPANIES_SUCCESS = "COMPANIES_SUCCESS";
const COMPANIES_FAILURE = "COMPANIES_FAILURE";

const fetchCompanies = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [COMPANIES_REQUEST, COMPANIES_SUCCESS, COMPANIES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COMPANY_ARRAY,
  },
  refresh,
});

export const loadCompanies = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchCompanies(`${ADMIN_URL}/companies/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchCompanies(
        `${ADMIN_URL}/companies/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchCompanies(
        `${ADMIN_URL}/companies/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchCompanies(`${ADMIN_URL}/companies/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const COMPANIES_LIST_REQUEST = "COMPANIES_LIST_REQUEST";
export const COMPANIES_LIST_SUCCESS = "COMPANIES_LIST_SUCCESS";
export const COMPANIES_LIST_FAILURE = "COMPANIES_LIST_FAILURE";

const fetchCompaniesList = (id, type) => ({
  [CALL_API]: {
    types: [COMPANIES_LIST_REQUEST, COMPANIES_LIST_SUCCESS, COMPANIES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/list/`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getCompaniesList = (id, type) => (dispatch) => {
  return dispatch(fetchCompaniesList(id, type));
};

export const ACTIVE_COMPANIES_LIST_REQUEST = "ACTIVE_COMPANIES_LIST_REQUEST";
export const ACTIVE_COMPANIES_LIST_SUCCESS = "ACTIVE_COMPANIES_LIST_SUCCESS";
export const ACTIVE_COMPANIES_LIST_FAILURE = "ACTIVE_COMPANIES_LIST_FAILURE";

const fetchActiveCompaniesList = () => ({
  [CALL_API]: {
    types: [ACTIVE_COMPANIES_LIST_REQUEST, ACTIVE_COMPANIES_LIST_SUCCESS, ACTIVE_COMPANIES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/active/list/`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getActiveCompaniesList = () => (dispatch) => {
  return dispatch(fetchActiveCompaniesList());
};

export const INTEGRATE_COMPANY_HUBSPOT_REQUEST = "INTEGRATE_COMPANY_HUBSPOT_REQUEST";
export const INTEGRATE_COMPANY_HUBSPOT_SUCCESS = "INTEGRATE_COMPANY_HUBSPOT_SUCCESS";
export const INTEGRATE_COMPANY_HUBSPOT_FAILURE = "INTEGRATE_COMPANY_HUBSPOT_FAILURE";

const generateIntegrationWithHubspot = (payload) => ({
  [CALL_API]: {
    types: [INTEGRATE_COMPANY_HUBSPOT_REQUEST, INTEGRATE_COMPANY_HUBSPOT_SUCCESS, INTEGRATE_COMPANY_HUBSPOT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/companies/hubspot/deal`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMPANY,
  },
});

export const integrateCompanyWithHubspot = (payload) => (dispatch) => {
  return dispatch(generateIntegrationWithHubspot(payload));
};

export const EDIT_HUBSPOT_DEAL_REQUEST = "EDIT_HUBSPOT_DEAL_REQUEST";
export const EDIT_HUBSPOT_DEAL_SUCCESS = "EDIT_HUBSPOT_DEAL_SUCCESS";
export const EDIT_HUBSPOT_DEAL_FAILURE = "EDIT_HUBSPOT_DEAL_FAILURE";

const editHubSpotDeal = (id, payload) => ({
  [CALL_API]: {
    types: [EDIT_HUBSPOT_DEAL_REQUEST, EDIT_HUBSPOT_DEAL_SUCCESS, EDIT_HUBSPOT_DEAL_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/companies/hubspot/deal/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMPANY,
  },
});

export const changeHubSpotDeal = (id, payload) => (dispatch) => {
  return dispatch(editHubSpotDeal(id, payload));
};

export const DELETE_HUBSPOT_DEAL_REQUEST = "DELETE_HUBSPOT_DEAL_REQUEST";
export const DELETE_HUBSPOT_DEAL_SUCCESS = "DELETE_HUBSPOT_DEAL_SUCCESS";
export const DELETE_HUBSPOT_DEAL_FAILURE = "DELETE_HUBSPOT_DEAL_FAILURE";

const removeHubSpotDeal = (id) => ({
  [CALL_API]: {
    types: [DELETE_HUBSPOT_DEAL_REQUEST, DELETE_HUBSPOT_DEAL_SUCCESS, DELETE_HUBSPOT_DEAL_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/companies/hubspot/deal/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.COMPANY,
  },
});

export const deleteHubSpotDeal = (id) => (dispatch) => {
  return dispatch(removeHubSpotDeal(id));
};

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

const fetchDashboard = () => ({
  [CALL_API]: {
    types: [DASHBOARD_REQUEST, DASHBOARD_SUCCESS, DASHBOARD_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/companies/dashboard`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.DASHBOARD,
  },
});

export const getDashboard = () => (dispatch) => {
  return dispatch(fetchDashboard());
};

export const PROJECT_ROSTER_REQUEST = "PROJECT_ROSTER_REQUEST";
export const PROJECT_ROSTER_SUCCESS = "PROJECT_ROSTER_SUCCESS";
export const PROJECT_ROSTER_FAILURE = "PROJECT_ROSTER_FAILURE";

const fetchProjectRoster = (companyId, search, page, limit, archive, sortBy, direction) => ({
  [CALL_API]: {
    types: [PROJECT_ROSTER_REQUEST, PROJECT_ROSTER_SUCCESS, PROJECT_ROSTER_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/project/roster/by/account/${companyId}?search=${search}&limit=${limit}&page=${page}&archive=${archive}&sortBy=${sortBy}&direction=${direction}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROJECT_ROSTER,
  },
});

export const getProjectRosterByCompanyId = (companyId, search, page, limit, archive, sortBy, direction) => (
  dispatch
) => {
  return dispatch(fetchProjectRoster(companyId, search, page, limit, archive, sortBy, direction));
};

export const PROJECT_ROSTER_BY_RESOURCE_REQUEST = "PROJECT_ROSTER_BY_RESOURCE_REQUEST";
export const PROJECT_ROSTER_BY_RESOURCE_SUCCESS = "PROJECT_ROSTER_BY_RESOURCE_SUCCESS";
export const PROJECT_ROSTER_BY_RESOURCE_FAILURE = "PROJECT_ROSTER_BY_RESOURCE_FAILURE";

const fetchProjectRosterByResource = (companyId, search, page, limit) => ({
  [CALL_API]: {
    types: [PROJECT_ROSTER_BY_RESOURCE_REQUEST, PROJECT_ROSTER_BY_RESOURCE_SUCCESS, PROJECT_ROSTER_BY_RESOURCE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/project/roster/by/resource/${companyId}?search=${search}&page=${page}&limit=${limit}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROJECT_ROSTER,
  },
});

export const getProjectRosterResourceByCompanyId = (companyId, search, page, limit) => (dispatch) => {
  return dispatch(fetchProjectRosterByResource(companyId, search, page, limit));
};

export const GET_COMPANY_PROJECTS_REQUEST = "GET_COMPANY_PROJECTS_REQUEST";
export const GET_COMPANY_PROJECTS_SUCCESS = "GET_COMPANY_PROJECTS_SUCCESS";
export const GET_COMPANY_PROJECTS_FAILURE = "GET_COMPANY_PROJECTS_FAILURE";

const fetchCompanyProjects = (companyId) => ({
  [CALL_API]: {
    types: [GET_COMPANY_PROJECTS_REQUEST, GET_COMPANY_PROJECTS_SUCCESS, GET_COMPANY_PROJECTS_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/company/projects/${companyId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COMPANY_PROJECTS_ARRAY,
  },
});

export const getCompanyProjects = (companyId) => (dispatch) => {
  return dispatch(fetchCompanyProjects(companyId));
};

export const CHANGE_COMPANY_PROJECT_REQUEST = "CHANGE_COMPANY_PROJECT_REQUEST";
export const CHANGE_COMPANY_PROJECT_SUCCESS = "CHANGE_COMPANY_PROJECT_SUCCESS";
export const CHANGE_COMPANY_PROJECT_FAILURE = "CHANGE_COMPANY_PROJECT_FAILURE";

const putCompanyProject = (companyProjectId, payload) => ({
  [CALL_API]: {
    types: [CHANGE_COMPANY_PROJECT_REQUEST, CHANGE_COMPANY_PROJECT_SUCCESS, CHANGE_COMPANY_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/update/company/project/${companyProjectId}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMPANY_PROJECTS_ARRAY,
  },
});

export const changeCompanyProject = (companyProjectId, payload) => (dispatch) => {
  return dispatch(putCompanyProject(companyProjectId, payload));
};

export const KICKOFF_COMPANY_PROJECT_REQUEST = "KICKOFF_COMPANY_PROJECT_REQUEST";
export const KICKOFF_COMPANY_PROJECT_SUCCESS = "KICKOFF_COMPANY_PROJECT_SUCCESS";
export const KICKOFF_COMPANY_PROJECT_FAILURE = "KICKOFF_COMPANY_PROJECT_FAILURE";

const startCompanyProjects = (payload) => ({
  [CALL_API]: {
    types: [KICKOFF_COMPANY_PROJECT_REQUEST, KICKOFF_COMPANY_PROJECT_SUCCESS, KICKOFF_COMPANY_PROJECT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/companies/kickoff/new/company/project`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMPANY_PROJECTS,
  },
});

export const kickoffCompanyProjects = (payload) => (dispatch) => {
  return dispatch(startCompanyProjects(payload));
};
