import React, { useEffect, useState, useLayoutEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  addWeeks,
  subWeeks,
  isSameMonth,
  isSameDay,
  parse,
  addDays,
  require,
  isSameWeek,
} from "date-fns";
import { IconButton, Box, Tooltip, Divider, Backdrop, CircularProgress } from "@material-ui/core";
import BaseButton from "../../components/BaseButton/BaseButton";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles((theme, props) => ({
  tButton: {
    borderRadius: 50,
    color: "#2373D8",
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: 1,
    textTransform: "none",
    letterSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "nowrap",
    direction: "ltr",
    WebkitFontSmoothing: "antialiased",
    textRendering: "optimizeLegibility",
    MozOsxFontSmoothing: "grayscale",
    fontFeatureSettings: "liga",
  },
  root: {
    MainColor: "#1a8fff",
    TextColor: "#777",
    TextColorLight: "#ccc",
    BorderColor: "#eee",
    BgColor: "#f9f9f9",
    NeutralColor: "#fff",
  },
  body: {
    fontSize: "1em",
    height: "100%",
    fontWeight: 300,
    lineHeight: 1.5,
    color: "#000",
    background: "#fff",
    position: "relative",
  },
  header: {
    display: "block",
    width: "100%",
    padding: "1.75em 0",
  },
  main: {
    display: "block",
    margin: "0 auto",
    marginTop: "5em",
    maxWidth: "50em",
  },
  row: {
    height: "100%",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  rowMiddle: {
    alignItems: "center",
  },
  col: {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: "100%",
  },
  colStart: {
    justifyContent: "flex-end",
    textAlign: "right",
    display: "flex",
  },
  colCenter: {
    justifyContent: "center",
    textAlign: "center",
  },
  colEnd: {
    justifyContent: "flex-start",
    textAlign: "left",
    display: "flex",
  },
  calendar: {
    display: "block",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: "white",
    borderRadius: 20,
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  disabled: {
    color: "#C1DFF8",
  },
  // calDateHeight: {
  //   height: 65,
  //   [theme.breakpoints.up("xl")]: {
  //     height: 130,
  //   },
  // },
  cell: {
    borderRadius: 20,
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    fontWeight: "bold",
    margin: 2,
    // cursor: "pointer",
    border: "1px dashed #F0F4F7",
    transition: "0.5s ease-in-out",
    // "&:hover": {
    //   backgroundColor: "#F0F4F7",
    //   transition: "0.1s ease-in-out",
    // },
    "&:active": {
      backgroundColor: "rgba(240,244,247,.5)",
    },
  },
  selected: {
    border: "2px solid #088FE5",
    borderRadius: 5,
    boxShadow: "1px 0px 7px 2px #c2cfd6",
  },
  renderDays: {
    borderRadius: 20,
  },
  topContainer: {
    borderRadius: "20px 20px 0 0",
  },
  lineItem: {
    margin: 1,
    borderRadius: 5,
    padding: 5,
    position: "relative",
    fontSize: "0.7rem",
    color: "white",
    transition: "0.1s ease-in-out",
    "&:hover": {
      background: "#011627 !important",
      // boxShadow: "3px 5px 7px 2px #c2cfd6",
      transition: "0.1s ease-in-out",
    },
    "&:active": {
      background: "rgba(1,22,39,.7)",
    },
  },
  addButton: {
    background: "rgba(1,22,39,.0)",
    cursor: "pointer",
    borderRadius: 5,
    marginBottom: 10,
    width: "95%",
    color: "rgba(0,0,0,0.1)",
    border: "1px dashed rgba(0,0,0,0.2)",
    alignSelf: "center",
    transition: "0.2s ease-in-out",
    "&:hover": {
      background: "#011627 !important",
      border: "1px dashed rgba(0,0,0,0.0)",
      // boxShadow: "3px 5px 7px 2px #c2cfd6",
      transition: "0.2s ease-in-out",
      color: "#fff",
    },
    "&:active": {
      background: "rgba(1,22,39,.7)",
    },
  },
}));

const WeekView = (props) => {
  const classes = useStyles();
  const [tip, setTip] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(
    new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarData, setCalendarData] = useState({});
  const [boxWidth, setBoxWidth] = useState("");
  let start = new Date();
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  end.setHours(23, 59, 59, 999);
  const [fromWeek, setFromWeek] = useState(new Date(start.setDate(start.getDate() - start.getDay(), 1)));
  const [toWeek, setToWeek] = useState(new Date(end.setDate(end.getDate() - end.getDay() + 6, 1)));
  const [weeklyHours, setWeeklyHours] = useState("");
  const [invalidTimeEntries, setInvalidTimeEntries] = useState([]);

  useEffect(() => {
    props.getWeeklyHours(props.userId).then((result) => {
      const r = result.response.entities.counts.weeklyHours.count;

      setWeeklyHours(r.length > 1 ? r[0].split(":") : "");
      setInvalidTimeEntries(r.length > 1 ? r[1] : []);
    });
  }, [props.refresh]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const size = useWindowSize();

  useEffect(() => {}, [boxWidth]);

  useEffect(() => {
    // boxID();
  }, [size[0]]);

  const SpecialBox = withStyles({
    root: {
      position: "absolute",
      paddingLeft: 3,
      paddingRight: 8,
      whiteSpace: "nowrap",
      transform: "translateX(0)",
      transition: "4s",
      "&:hover": {
        transform: `translateX(calc(${boxWidth}px - 100%))`,
      },
    },
  })(Box);

  useEffect(() => {
    fetchCalendarData();
  }, [props.startRefresh]);

  useEffect(() => {
    fetchCalendarData();
  }, [fromWeek, toWeek]);

  const fetchCalendarData = async () => {
    const result = await props.fetchWeeklyCalendarTimeEntries(
      props.userId,
      new Date(fromWeek).getTime(),
      new Date(toWeek).getTime()
    );
    setCalendarData(result.response.entities.calendarTimeEntries.calendarTimeEntries);
    props.setChangeCalendar(false);
  };

  const dismissTip = () => {
    setTip(false);
  };

  const onDateClick = (day) => {
    props.setTimeEntered(day);
    // setSelectedDate(day);
  };

  const nextWeek = () => {
    setCalendarData({});
    const newWeek = addWeeks(currentWeek, 1);
    setFromWeek(addWeeks(fromWeek, 1));
    setToWeek(addWeeks(toWeek, 1));
    setCurrentWeek(newWeek);
  };

  const prevWeek = () => {
    setCalendarData({});
    const newWeek = subWeeks(currentWeek, 1);
    setFromWeek(subWeeks(fromWeek, 1));
    setToWeek(subWeeks(toWeek, 1));
    setCurrentWeek(newWeek);
  };

  function renderHeader() {
    let date = new Date(currentWeek);
    const firstDay = date.getDate() - date.getDay();
    date.setDate(firstDay);
    return (
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        style={{ height: 50 }}
        className={`${classes.header} ${classes.row} ${classes.flexMiddle}`}
      >
        <div className={`${classes.col} ${classes.colStart}`}>
          <div className={`${classes.icon}`}>
            <IconButton style={{ color: "white" }} onClick={() => prevWeek()}>
              <i className="fad fa-chevron-circle-left" />
            </IconButton>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colCenter}`}>
          <span style={{ color: "white", fontWeight: "bold" }}>{`Week of ${new Date(date).toDateString()}`}</span>
        </div>
        <div className={`${classes.col} ${classes.colEnd}`}>
          <div className={`${classes.icon}`}>
            <IconButton style={{ color: "white" }} onClick={() => nextWeek()}>
              <i className="fad fa-chevron-circle-right" />
            </IconButton>
          </div>
        </div>
      </Box>
    );
  }

  function renderDays() {
    const dateFormat = "E";
    const days = [];

    let startDate = startOfWeek(currentWeek);

    for (let i = 0; i < 7; i++) {
      days.push(
        <Box style={{ color: "white", fontWeight: "bold" }} className={`${classes.col} ${classes.colCenter}`} key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </Box>
      );
    }

    return (
      <Box
        className={`${classes.days} ${classes.row} ${classes.renderDays}`}
        style={{ height: 50, alignItems: "center", display: "flex" }}
      >
        {days}
      </Box>
    );
  }

  const lineItemHeight = (key) => {
    const multiplier = 1.5;
    switch (key) {
      case 0.0:
        return (20 + 20) * multiplier;
      case 0.25:
        return (20 + 20) * multiplier;
      case 0.5:
        return (25 + 20) * multiplier;
      case 0.75:
        return (30 + 20) * multiplier;
      case 1.0:
        return (35 + 20) * multiplier;
      case 1.25:
        return (40 + 20) * multiplier;
      case 1.5:
        return (45 + 20) * multiplier;
      case 1.75:
        return (50 + 20) * multiplier;
      case 2.0:
        return (55 + 20) * multiplier;
      case 2.25:
        return (60 + 20) * multiplier;
      case 2.5:
        return (65 + 20) * multiplier;
      case 2.75:
        return (70 + 20) * multiplier;
      case 3.0:
        return (80 + 20) * multiplier;
      case 3.25:
        return (85 + 20) * multiplier;
      case 3.5:
        return (90 + 20) * multiplier;
      case 3.75:
        return (95 + 20) * multiplier;
      case 4.0:
        return (100 + 20) * multiplier;
      case 4.25:
        return (105 + 20) * multiplier;
      case 4.5:
        return (110 + 20) * multiplier;
      case 4.75:
        return (115 + 20) * multiplier;
      case 5.0:
        return (120 + 20) * multiplier;
      case 5.25:
        return (125 + 20) * multiplier;
      case 5.5:
        return (130 + 20) * multiplier;
      case 5.75:
        return (135 + 20) * multiplier;
      case 6.0:
        return (140 + 20) * multiplier;
      case 6.25:
        return (145 + 20) * multiplier;
      case 6.5:
        return (150 + 20) * multiplier;
      case 6.75:
        return (155 + 20) * multiplier;
      case 7.0:
        return (160 + 20) * multiplier;
      case 7.25:
        return (165 + 20) * multiplier;
      case 7.5:
        return (170 + 20) * multiplier;
      case 7.75:
        return (175 + 20) * multiplier;
      case 8.0:
        return (180 + 20) * multiplier;
      case 8.25:
        return (185 + 20) * multiplier;
      case 8.5:
        return (190 + 20) * multiplier;
      case 8.75:
        return (195 + 20) * multiplier;
      case 9.0:
        return (200 + 20) * multiplier;
      default:
        return (200 + 20) * multiplier;
    }
  };

  function renderCells() {
    const weekStart = startOfWeek(currentWeek);
    const dateFormat = "d";
    const weekrow = [];
    let formattedDate = "";

    let days = [];
    let day = weekStart;

    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;
      days.push(
        <Box
          key={formattedDate}
          className={`${classes.col} ${classes.cell} ${
            !isSameWeek(day, weekStart) ? classes.disabled : isSameDay(day, selectedDate) ? classes.selected : ""
          }`}
        >
          <Box mb={0.5} display="flex" width="100%" justifyContent="space-between">
            <Box ml={1}>
              <span>{formattedDate}</span>
            </Box>
            <Box overflow="auto" className={classes.calDateHeight}>
              {isSameDay(day, selectedDate) ? <Box mr={1}>Today</Box> : null}
            </Box>
          </Box>

          <Box width="100%" display="flex" flexDirection="column">
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              p={1}
              className={classes.addButton}
              onClick={() => {
                props.setAddTime(true);
                onDateClick(cloneDay);
              }}
            >
              <i className="fas fa-plus" />
            </Box>
            {calendarData.hasOwnProperty(formattedDate) &&
              calendarData[formattedDate].map((d, i) => {
                let key = d.hours;
                let time = d.hours ? d.hours : 0;
                return (
                  <Box
                    key={d.id}
                    style={{
                      background: d.color,
                      cursor: "pointer",
                      marginBottom: 5,
                      overflow: "auto",
                      height: lineItemHeight(key),
                    }}
                    className={classes.lineItem}
                    onClick={(e) => {
                      try {
                        e.stopPropagation();
                        onDateClick(cloneDay);
                        props.setEditTime(true);
                        props.setNewTime(time);
                        props.setTimeEntryId(d.id);
                        props.setColor(d.color);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    <Box style={{ fontSize: "0.6rem", fontWeight: "lighter" }}>
                      <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                        {Math.floor(time)}h{" "}
                        {time % 1 === 0 ? "00" : time % 1 === 0.25 ? "15" : time % 1 === 0.75 ? "45" : "30"}m
                      </span>
                    </Box>
                    <Box style={{ fontWeight: "lighter", fontSize: "0.6rem" }}>{d.name.split("-")[0]}</Box>
                    <Box style={{ fontWeight: "lighter", fontSize: "0.6rem", color: "rgba(255,255,255,.7)" }}>
                      {d.name.split("-")[1]}
                    </Box>
                    {d.workElement && (
                      <Box
                        mt={1}
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          color: "rgba(255,255,255,1)",
                        }}
                      >
                        {d.workElement}
                      </Box>
                    )}

                    <Divider style={{ backgroundColor: "#fff" }} />

                    <Box mt={0.5} style={{ overflowWrap: "anywhere", fontSize: "0.8rem", fontWeight: "lighter" }}>
                      {d.task ? d.task : null}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      );
      day = addDays(day, 1);
    }

    return (
      <Box style={{ borderRadius: 20 }} className={`${classes.body}`}>
        <Box className={`${classes.row}`}>{days}</Box>
      </Box>
    );
  }

  return (
    <Box className={`${classes.calendar}`}>
      <Box style={{ background: props.background, position: "relative" }} className={classes.topContainer}>
        {!props.drawerOpen && (
          <Box style={{ whiteSpace: "nowrap", top: 5, left: 20, zIndex: 1, color: "white", position: "absolute" }}>
            {weeklyHours.length > 0 ? (
              <React.Fragment>
                <span style={{ fontSize: "0.8rem" }}>{weeklyHours[0]}:</span>
                <br />
                <span>{weeklyHours[1]}:</span>
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  {weeklyHours[2] && weeklyHours[2].split("/")[0]}
                </span>

                <span>/ {weeklyHours[2].split("/")[1]}</span>
                {invalidTimeEntries.length > 0 ? (
                  <Tooltip
                    title={`You have not entered a full 8 hours for the following days: ${invalidTimeEntries.join(
                      ", "
                    )}`}
                  >
                    <span>
                      <i className={"fad fa-exclamation-triangle"} style={{ color: "#EB6B6C", marginLeft: "1rem" }} />
                    </span>
                  </Tooltip>
                ) : null}
              </React.Fragment>
            ) : (
              ""
            )}
          </Box>
        )}

        {renderHeader()}
        {renderDays()}

        {!props.drawerOpen && (
          <Tooltip title="Open Projects">
            <Box position="absolute" style={{ top: 5, right: 60, zIndex: 1 }}>
              <IconButton
                onClick={async () => {
                  await props.setDrawerOpen(true);
                  await props.updateUser(props.user.id, { showTimeEntryProjects: !props.drawerOpen });
                  await props.getAdminProfile();
                }}
              >
                <i className="fad fa-tasks-alt" style={{ color: "#fff" }} />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        <Tooltip title="Switch to month view">
          <Box position="absolute" style={{ top: 5, right: 10, zIndex: 1 }}>
            <IconButton
              onClick={async () => {
                props.setChangeCalendar(true);
                await props.updateTimeEntryView("month");
                props.getAdminProfile();
                props.setChangeCalendar(false);
              }}
            >
              <i className="fad fa-calendar-week" style={{ color: "#fff" }} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Box
        height="66vh"
        overflow="auto"
        width="100%"
        style={{
          position: "relative",
          filter: tip ? "blur(1px) brightness(40%) grayscale(100%)" : null,
          borderRadius: "0 0 20px 20px",
        }}
      >
        <Backdrop open={props.changeCalendar} style={{ position: "absolute", zIndex: 1 }}>
          <i className="fad fa-clock fa-spin fa-3x" style={{ color: "#fff" }} />
        </Backdrop>
        {renderCells()}
      </Box>

      {tip ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          position="absolute"
          style={{
            top: "50%",
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
            borderRadius: !tip ? "0 0 20px 0" : null,
          }}
        >
          <Box mb={2}>Click on a date to start a project / task</Box>
          <BaseButton noShadow skinnyBlue text="Dismiss hint" clickHandler={() => dismissTip()} />
        </Box>
      ) : null}
    </Box>
  );
};

export default WeekView;
