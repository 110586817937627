import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, TextField, InputBase, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: 50,
    border: "1px solid transparent",
    backgroundColor: "rgba(51,92,129,.05)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
      border: "1px solid #2373D8",
    },
    "&:focus": {
      border: "1px solid #2373D8",
    },
    marginLeft: 10,
    width: "fit-content",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2373D8",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "25ch",
        border: "1px solid #2373D8",
        borderRadius: 50,
      },
    },
  },
}));

export default function ControlledAccordions(props) {
  const classes = useStyles();

  return (
    <Box className={`${classes.search}`}>
      <div className={classes.searchIcon}>
        {!props.searching ? <i className="fas fa-search" /> : <CircularProgress size={20} />}
      </div>
      <InputBase
        placeholder="Search…"
        onChange={(e) => props.setSearch(e.target.value)}
        value={props.search}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </Box>
  );
}
