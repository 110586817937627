import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadIndustries,
  fetchIndustriesCount,
  saveIndustry,
  updateIndustryById,
  deleteIndustryById,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { Grid } from "@material-ui/core";

const columns = [
  {
    title: "Name",
    field: "name",
  },
];

const Industries = (props) => {
  const addIndustry = async (rowData) => {
    try {
      const response = await props.saveIndustry({ ...rowData });
      return response;
    } catch (err) {}
  };

  const editIndustry = async (newData, oldData) => {
    const response = await props.updateIndustryById(newData.id, { ...newData });
    return response;
  };

  const deleteIndustry = async (rowData) => {
    const response = await props.deleteIndustryById(rowData.id);
    return response;
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchIndustriesCount}
            paginationCall={props.loadIndustries}
            title="Industries"
            entity={"industries"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => addIndustry(rowData)}
            editFunction={(newData, oldData) => editIndustry(newData, oldData)}
            deleteFunction={(rowData) => deleteIndustry(rowData)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    industries: state.entities.industries,
    pageInfo: state.pagination.industries,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadIndustries: (page, limit, refresh) => dispatch(loadIndustries(page, limit, refresh)),
    fetchIndustriesCount: () => dispatch(fetchIndustriesCount()),
    saveIndustry: (payload) => dispatch(saveIndustry(payload)),
    updateIndustryById: (id, payload) => dispatch(updateIndustryById(id, payload)),
    deleteIndustryById: (id) => dispatch(deleteIndustryById(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Industries));
