import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import configureStore from "./store/Store";
import { Provider } from "react-redux";
import "whatwg-fetch";
import CssBaseline from "@material-ui/core/CssBaseline";

// Containers
import Full from "./routes";

// View
import Login from "./containers/Login";

const history = createBrowserHistory();

function init() {
  configureStore().then((store) => {
    ReactDOM.render(
      <Provider store={store}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/" name="Home" component={Full} />
          </Switch>
        </Router>
      </Provider>,
      document.getElementById("root")
    );
  });
}

init();
