/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const SAVE_STUDENT_REQUEST = "SAVE_STUDENT_REQUEST";
export const SAVE_STUDENT_SUCCESS = "SAVE_STUDENT_SUCCESS";
export const SAVE_STUDENT_FAILURE = "SAVE_STUDENT_FAILURE";

const createStudent = (payload) => ({
  [CALL_API]: {
    types: [SAVE_STUDENT_REQUEST, SAVE_STUDENT_SUCCESS, SAVE_STUDENT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/students/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.STUDENTS_ARRAY,
  },
});

export const saveStudent = (payload) => (dispatch) => {
  return dispatch(createStudent(payload));
};

export const UPDATE_STUDENT_REQUEST = "UPDATE_STUDENT_REQUEST";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAILURE = "UPDATE_STUDENT_FAILURE";

const changeStudent = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_STUDENT_REQUEST, UPDATE_STUDENT_SUCCESS, UPDATE_STUDENT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/students/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.STUDENTS,
  },
});

export const updateStudent = (id, payload) => (dispatch) => {
  return dispatch(changeStudent(id, payload));
};

export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";

const removeStudent = (id) => ({
  [CALL_API]: {
    types: [DELETE_STUDENT_REQUEST, DELETE_STUDENT_SUCCESS, DELETE_STUDENT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/students/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.STUDENTS,
  },
});

export const deleteStudent = (id) => (dispatch) => {
  return dispatch(removeStudent(id));
};

export const GET_ALL_STUDENTS_REQUEST = "GET_ALL_STUDENTS_REQUEST";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_ALL_STUDENTS_FAILURE = "GET_ALL_STUDENTS_FAILURE";

const fetchAllStudents = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_STUDENTS_REQUEST, GET_ALL_STUDENTS_SUCCESS, GET_ALL_STUDENTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.STUDENTS_ARRAY,
  },
  refresh,
});

export const getAllStudents = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllStudents(`${ADMIN_URL}/students/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllStudents(
        `${ADMIN_URL}/students/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllStudents(
        `${ADMIN_URL}/students/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllStudents(`${ADMIN_URL}/students/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_STUDENTS_REQUEST = "GET_COUNT_STUDENTS_REQUEST";
export const GET_COUNT_STUDENTS_SUCCESS = "GET_COUNT_STUDENTS_SUCCESS";
export const GET_COUNT_STUDENTS_FAILURE = "GET_COUNT_STUDENTS_FAILURE";

const fetchStudentsCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_STUDENTS_REQUEST, GET_COUNT_STUDENTS_SUCCESS, GET_COUNT_STUDENTS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/students/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getStudentsCount = (search) => (dispatch) => {
  return dispatch(fetchStudentsCount(search));
};

export const EMAIL_COURSE_REQUEST = "EMAIL_COURSE_REQUEST";
export const EMAIL_COURSE_SUCCESS = "EMAIL_COURSE_SUCCESS";
export const EMAIL_COURSE_FAILURE = "EMAIL_COURSE_FAILURE";

const sendCourseEmail = (courseId, templateId) => ({
  [CALL_API]: {
    types: [EMAIL_COURSE_REQUEST, EMAIL_COURSE_SUCCESS, EMAIL_COURSE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/students/email/course/${courseId}/${templateId}`,
      method: "POST",
      auth: true,
    },
    schema: Schemas.SENT_EMAILS,
  },
});

export const sendEmailToCourse = (courseId, templateId) => (dispatch) => {
  return dispatch(sendCourseEmail(courseId, templateId));
};

export const EMAIL_STUDENTS_REQUEST = "EMAIL_STUDENTS_REQUEST";
export const EMAIL_STUDENTS_SUCCESS = "EMAIL_STUDENTS_SUCCESS";
export const EMAIL_STUDENTS_FAILURE = "EMAIL_STUDENTS_FAILURE";

const sendStudentsEmail = (templateId, payload) => ({
  [CALL_API]: {
    types: [EMAIL_STUDENTS_REQUEST, EMAIL_STUDENTS_SUCCESS, EMAIL_STUDENTS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/students/email/students/${templateId}`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.SENT_EMAILS,
  },
});

export const sendEmailToStudents = (templateId, payload) => (dispatch) => {
  return dispatch(sendStudentsEmail(templateId, payload));
};
