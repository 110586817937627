/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

const fetchUserList = () => ({
  [CALL_API]: {
    types: [USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getUserList = () => (dispatch) => {
  return dispatch(fetchUserList());
};

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

const removeUser = (userId) => ({
  [CALL_API]: {
    types: [USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/${userId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.USER,
  },
});

export const deleteUser = (userId) => (dispatch, getState) => {
  return dispatch(removeUser(userId));
};

export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";

const postSaveUser = (template, payload) => ({
  [CALL_API]: {
    types: [SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER,
  },
});

export const saveUser = (template, userData) => (dispatch) => {
  return dispatch(postSaveUser(template, userData));
};

export const SAVE_USER_ROLE_REQUEST = "SAVE_USER_ROLE_REQUEST";
export const SAVE_USER_ROLE_SUCCESS = "SAVE_USER_ROLE_SUCCESS";
export const SAVE_USER_ROLE_FAILURE = "SAVE_USER_ROLE_FAILURE";

const postUserRole = (roleId, payload) => ({
  [CALL_API]: {
    types: [SAVE_USER_ROLE_REQUEST, SAVE_USER_ROLE_SUCCESS, SAVE_USER_ROLE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/roles/users/${roleId}`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER_ROLE_ARRAY,
  },
});

export const addUserRole = (roleId, payload) => (dispatch) => {
  return dispatch(postUserRole(roleId, payload));
};

export const SAVE_USER_ROLES_REQUEST = "SAVE_USER_ROLES_REQUEST";
export const SAVE_USER_ROLES_SUCCESS = "SAVE_USER_ROLES_SUCCESS";
export const SAVE_USER_ROLES_FAILURE = "SAVE_USER_ROLES_FAILURE";

const attachRolesToUser = (userId, payload) => ({
  [CALL_API]: {
    types: [SAVE_USER_ROLES_REQUEST, SAVE_USER_ROLES_SUCCESS, SAVE_USER_ROLES_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/roles/${userId}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER_ARRAY,
  },
});

export const addRolesToUser = (userId, payload) => (dispatch) => {
  return dispatch(attachRolesToUser(userId, payload));
};

export const GENERATE_USER_QR_CODE_REQUEST = "GENERATE_USER_QR_CODE_REQUEST";
export const GENERATE_USER_QR_CODE_SUCCESS = "GENERATE_USER_QR_CODE_SUCCESS";
export const GENERATE_USER_QR_CODE_FAILURE = "GENERATE_USER_QR_CODE_FAILURE";

const putGenerateQRCode = (id) => ({
  [CALL_API]: {
    types: [GENERATE_USER_QR_CODE_REQUEST, GENERATE_USER_QR_CODE_SUCCESS, GENERATE_USER_QR_CODE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/generate/qr/${id}`,
      method: "PUT",
      auth: true,
    },
    schema: Schemas.USER,
  },
});

export const generateQRCode = (id) => (dispatch) => {
  return dispatch(putGenerateQRCode(id));
};

export const UPDATE_PASS_USER_REQUEST = "UPDATE_PASS_USER_REQUEST";
export const UPDATE_PASS_USER_SUCCESS = "UPDATE_PASS_USER_SUCCESS";
export const UPDATE_PASS_USER_FAILURE = "UPDATE_PASS_USER_FAILURE";

const submitUpdateUserPassword = (userId, data) => ({
  [CALL_API]: {
    types: [UPDATE_PASS_USER_REQUEST, UPDATE_PASS_USER_SUCCESS, UPDATE_PASS_USER_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/forgot-password/${userId}`,
      method: "PUT",
      auth: true,
      body: data,
    },
    schema: Schemas.USER,
  },
});

export const updateUserPassword = (userId, data) => (dispatch) => {
  return dispatch(submitUpdateUserPassword(userId, data));
};

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

const changeUser = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER,
  },
});

export const updateUser = (id, payload) => (dispatch) => {
  return dispatch(changeUser(id, payload));
};

export const GET_CISO_USERS_REQUEST = "GET_CISO_USERS_REQUEST";
export const GET_CISO_USERS_SUCCESS = "GET_CISO_USERS_SUCCESS";
export const GET_CISO_USERS_FAILURE = "GET_CISO_USERS_FAILURE";

const getCisoUsersList = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_CISO_USERS_REQUEST, GET_CISO_USERS_SUCCESS, GET_CISO_USERS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CISO_USERS_ARRAY,
  },
  refresh,
});

export const getCisoshareUsers = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(getCisoUsersList(`${ADMIN_URL}/users/cisoshare/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(getCisoUsersList(`${ADMIN_URL}/users/cisoshare/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(getCisoUsersList(`${ADMIN_URL}/users/cisoshare/all?limit=${limit}&page=${page}`));
  }

  return dispatch(getCisoUsersList(`${ADMIN_URL}/users/cisoshare/all?limit=${limit}`));
};

export const GET_CISO_USERS_COUNT_REQUEST = "GET_CISO_USERS_COUNT_REQUEST";
export const GET_CISO_USERS_COUNT_SUCCESS = "GET_CISO_USERS_COUNT_SUCCESS";
export const GET_CISO_USERS_COUNT_FAILURE = "GET_CISO_USERS_COUNT_FAILURE";

const getCisoUsersCount = () => ({
  [CALL_API]: {
    types: [GET_CISO_USERS_COUNT_REQUEST, GET_CISO_USERS_COUNT_SUCCESS, GET_CISO_USERS_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/users/cisoshare/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getCisoshareUsersCount = () => (dispatch) => {
  return dispatch(getCisoUsersCount());
};

export const ADMIN_CHANGE_PASSWORD_REQUEST = "ADMIN_CHANGE_PASSWORD_REQUEST";
export const ADMIN_CHANGE_PASSWORD_SUCCESS = "ADMIN_CHANGE_PASSWORD_SUCCESS";
export const ADMIN_CHANGE_PASSWORD_FAILURE = "ADMIN_CHANGE_PASSWORD_FAILURE";

const adminChangeUserPassword = (id, payload) => ({
  [CALL_API]: {
    types: [ADMIN_CHANGE_PASSWORD_REQUEST, ADMIN_CHANGE_PASSWORD_SUCCESS, ADMIN_CHANGE_PASSWORD_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/users/password/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.USER,
  },
});

export const changeUserPassword = (id, body) => (dispatch) => {
  return dispatch(adminChangeUserPassword(id, body));
};

export const SAVE_COMMENT_REQUEST = "SAVE_COMMENT_REQUEST";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";

const postComment = (payload) => ({
  [CALL_API]: {
    types: [SAVE_COMMENT_REQUEST, SAVE_COMMENT_SUCCESS, SAVE_COMMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/comment`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.COMMENT,
  },
});

export const saveComment = (payload) => (dispatch) => {
  return dispatch(postComment(payload));
};

export const UPDATE_PERMISSIONS_REQUEST = "UPDATE_PERMISSIONS_REQUEST";
export const UPDATE_PERMISSIONS_SUCCESS = "UPDATE_PERMISSIONS_SUCCESS";
export const UPDATE_PERMISSIONS_FAILURE = "UPDATE_PERMISSIONS_FAILURE";

const putPermissions = (accessId, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PERMISSIONS_REQUEST, UPDATE_PERMISSIONS_SUCCESS, UPDATE_PERMISSIONS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/users/cisoshare/user/access/${accessId}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.CISO_USERS_ARRAY,
  },
});

export const updatePermissions = (id, data) => (dispatch) => {
  return dispatch(putPermissions(id, data));
};

export const ATTACH_USER_TO_COMPANY_PROJECT_REQUEST = "ATTACH_USER_TO_COMPANY_PROJECT_REQUEST";
export const ATTACH_USER_TO_COMPANY_PROJECT_SUCCESS = "ATTACH_USER_TO_COMPANY_PROJECT_SUCCESS";
export const ATTACH_USER_TO_COMPANY_PROJECT_FAILURE = "ATTACH_USER_TO_COMPANY_PROJECT_FAILURE";

const postUserToCompanyProject = (companyProjectId, payload) => ({
  [CALL_API]: {
    types: [
      ATTACH_USER_TO_COMPANY_PROJECT_REQUEST,
      ATTACH_USER_TO_COMPANY_PROJECT_SUCCESS,
      ATTACH_USER_TO_COMPANY_PROJECT_FAILURE,
    ],
    reqInfo: {
      endpoint: `${BASE_URL}/users/attach/user/to/project/${companyProjectId}`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER_COMPANY_PROJECTS,
  },
});

export const attachUserToCompanyProject = (companyProjectId, payload) => (dispatch) => {
  return dispatch(postUserToCompanyProject(companyProjectId, payload));
};

export const ATTACH_ROLE_TO_COMPANY_PROJECT_REQUEST = "ATTACH_ROLE_TO_COMPANY_PROJECT_REQUEST";
export const ATTACH_ROLE_TO_COMPANY_PROJECT_SUCCESS = "ATTACH_ROLE_TO_COMPANY_PROJECT_SUCCESS";
export const ATTACH_ROLE_TO_COMPANY_PROJECT_FAILURE = "ATTACH_ROLE_TO_COMPANY_PROJECT_FAILURE";

const postRoleToCompanyProject = (companyProjectId, payload) => ({
  [CALL_API]: {
    types: [
      ATTACH_ROLE_TO_COMPANY_PROJECT_REQUEST,
      ATTACH_ROLE_TO_COMPANY_PROJECT_SUCCESS,
      ATTACH_ROLE_TO_COMPANY_PROJECT_FAILURE,
    ],
    reqInfo: {
      endpoint: `${BASE_URL}/users/attach/role/to/project/${companyProjectId}`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER_COMPANY_PROJECTS,
  },
});

export const attachRoleToCompanyProject = (companyProjectId, payload) => (dispatch) => {
  return dispatch(postRoleToCompanyProject(companyProjectId, payload));
};

export const UPDATE_USER_COMPANY_PROJECT_REQUEST = "UPDATE_USER_COMPANY_PROJECT_REQUEST";
export const UPDATE_USER_COMPANY_PROJECT_SUCCESS = "UPDATE_USER_COMPANY_PROJECT_SUCCESS";
export const UPDATE_USER_COMPANY_PROJECT_FAILURE = "UPDATE_USER_COMPANY_PROJECT_FAILURE";

const putUserToCompanyProject = (companyProjectId, payload) => ({
  [CALL_API]: {
    types: [
      UPDATE_USER_COMPANY_PROJECT_REQUEST,
      UPDATE_USER_COMPANY_PROJECT_SUCCESS,
      UPDATE_USER_COMPANY_PROJECT_FAILURE,
    ],
    reqInfo: {
      endpoint: `${BASE_URL}/users/change/user/company/project/${companyProjectId}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.USER_COMPANY_PROJECTS,
  },
});

export const updateUserCompanyProject = (companyProjectId, payload) => (dispatch) => {
  return dispatch(putUserToCompanyProject(companyProjectId, payload));
};

export const DELETE_USER_COMPANY_PROJECT_REQUEST = "DELETE_USER_COMPANY_PROJECT_REQUEST";
export const DELETE_USER_COMPANY_PROJECT_SUCCESS = "DELETE_USER_COMPANY_PROJECT_SUCCESS";
export const DELETE_USER_COMPANY_PROJECT_FAILURE = "DELETE_USER_COMPANY_PROJECT_FAILURE";

const deleteUserToCompanyProject = (companyProjectId) => ({
  [CALL_API]: {
    types: [
      DELETE_USER_COMPANY_PROJECT_REQUEST,
      DELETE_USER_COMPANY_PROJECT_SUCCESS,
      DELETE_USER_COMPANY_PROJECT_FAILURE,
    ],
    reqInfo: {
      endpoint: `${BASE_URL}/users/detach/user/from/project/${companyProjectId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.USER_COMPANY_PROJECTS,
  },
});

export const detachUserCompanyProject = (companyProjectId) => (dispatch) => {
  return dispatch(deleteUserToCompanyProject(companyProjectId));
};

export const GET_TIME_ENTRIES_REQUEST = "GET_TIME_ENTRIES_REQUEST";
export const GET_TIME_ENTRIES_SUCCESS = "GET_TIME_ENTRIES_SUCCESS";
export const GET_TIME_ENTRIES_FAILURE = "GET_TIME_ENTRIES_FAILURE";

const fetchTimeEntries = (userId) => ({
  [CALL_API]: {
    types: [GET_TIME_ENTRIES_REQUEST, GET_TIME_ENTRIES_SUCCESS, GET_TIME_ENTRIES_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/${userId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.TIME_ENTRIES_BY_PROJECT_ARRAY,
  },
});

export const getTimeEntries = (userId) => (dispatch) => {
  return dispatch(fetchTimeEntries(userId));
};

export const GET_TIME_ENTRIES_BY_ID_SUCCESS = "GET_TIME_ENTRIES_BY_ID_SUCCESS";
export const GET_TIME_ENTRIES_BY_ID_REQUEST = "GET_TIME_ENTRIES_BY_ID_REQUEST";
export const GET_TIME_ENTRIES_BY_ID_FAILURE = "GET_TIME_ENTRIES_BY_ID_FAILURE";

const fetchTimeEntryById = (timeEntryId) => ({
  [CALL_API]: {
    types: [GET_TIME_ENTRIES_BY_ID_REQUEST, GET_TIME_ENTRIES_BY_ID_SUCCESS, GET_TIME_ENTRIES_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/timeEntry/${timeEntryId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.TIME_ENTRIES,
  },
});

export const getTimeEntryById = (timeEntryId) => (dispatch) => {
  return dispatch(fetchTimeEntryById(timeEntryId));
};

export const GET_COMPANY_PROJECT_WORK_ELEMENTS_REQUEST = "GET_COMPANY_PROJECT_WORK_ELEMENTS_REQUEST";
export const GET_COMPANY_PROJECT_WORK_ELEMENTS_SUCCESS = "GET_COMPANY_PROJECT_WORK_ELEMENTS_SUCCESS";
export const GET_COMPANY_PROJECT_WORK_ELEMENTS_FAILURE = "GET_COMPANY_PROJECT_WORK_ELEMENTS_FAILURE";

const fetchCompanyProjectWorkElements = (companyProjectId) => ({
  [CALL_API]: {
    types: [
      GET_COMPANY_PROJECT_WORK_ELEMENTS_REQUEST,
      GET_COMPANY_PROJECT_WORK_ELEMENTS_SUCCESS,
      GET_COMPANY_PROJECT_WORK_ELEMENTS_FAILURE,
    ],
    reqInfo: {
      endpoint: `${BASE_URL}/time/company/project/work/elements/${companyProjectId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getCompanyProjectWorkElements = (companyProjectId) => (dispatch) => {
  return dispatch(fetchCompanyProjectWorkElements(companyProjectId));
};

export const GET_PROJECT_REPORT_REQUEST = "GET_PROJECT_REPORT_REQUEST";
export const GET_PROJECT_REPORT_SUCCESS = "GET_PROJECT_REPORT_SUCCESS";
export const GET_PROJECT_REPORT_FAILURE = "GET_PROJECT_REPORT_FAILURE";

const fetchProjectReport = (startDate, endDate) => ({
  [CALL_API]: {
    types: [GET_PROJECT_REPORT_REQUEST, GET_COMPANY_PROJECT_WORK_ELEMENTS_SUCCESS, GET_PROJECT_REPORT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/project/report/from/${startDate}/to/${endDate}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getProjectReport = (startDate, endDate) => (dispatch) => {
  return dispatch(fetchProjectReport(startDate, endDate));
};

export const GET_WORK_ELEMENT_TASKS_REQUEST = "GET_WORK_ELEMENT_TASKS_REQUEST";
export const GET_WORK_ELEMENT_TASKS_SUCCESS = "GET_WORK_ELEMENT_TASKS_SUCCESS";
export const GET_WORK_ELEMENT_TASKS_FAILURE = "GET_WORK_ELEMENT_TASKS_FAILURE";

const fetchWorkElementTasks = (workElementId) => ({
  [CALL_API]: {
    types: [GET_WORK_ELEMENT_TASKS_REQUEST, GET_WORK_ELEMENT_TASKS_SUCCESS, GET_WORK_ELEMENT_TASKS_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/work/element/tasks/${workElementId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getWorkElementTasks = (workElementId) => (dispatch) => {
  return dispatch(fetchWorkElementTasks(workElementId));
};

export const GET_WEEKLY_HOURS_REQUEST = "GET_WEEKLY_HOURS_REQUEST";
export const GET_WEEKLY_HOURS_SUCCESS = "GET_WEEKLY_HOURS_SUCCESS";
export const GET_WEEKLY_HOURS_FAILURE = "GET_WEEKLY_HOURS_FAILURE";

const getWeeklyTimeEntryHours = (userId) => ({
  [CALL_API]: {
    types: [GET_WEEKLY_HOURS_REQUEST, GET_WEEKLY_HOURS_SUCCESS, GET_WEEKLY_HOURS_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/weekly/hours/count/${userId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getWeeklyHours = (userId) => (dispatch) => {
  return dispatch(getWeeklyTimeEntryHours(userId));
};

export const GET_CALENDAR_TIME_ENTRIES_REQUEST = "GET_CALENDAR_TIME_ENTRIES_REQUEST";
export const GET_CALENDAR_TIME_ENTRIES_SUCCESS = "GET_CALENDAR_TIME_ENTRIES_SUCCESS";
export const GET_CALENDAR_TIME_ENTRIES_FAILURE = "GET_CALENDAR_TIME_ENTRIES_FAILURE";

const fetchCalendarTimeEntries = (userId, from, to) => ({
  [CALL_API]: {
    types: [GET_CALENDAR_TIME_ENTRIES_REQUEST, GET_CALENDAR_TIME_ENTRIES_SUCCESS, GET_CALENDAR_TIME_ENTRIES_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/calendar/${userId}?from=${from}&to=${to}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CALENDAR_TIME_ENTRIES,
  },
});

export const getCalendarTimeEntries = (userId, from, to) => (dispatch) => {
  return dispatch(fetchCalendarTimeEntries(userId, from, to));
};

export const CREATE_NEW_TIME_ENTRY_REQUEST = "CREATE_NEW_TIME_ENTRY_REQUEST";
export const CREATE_NEW_TIME_ENTRY_SUCCESS = "CREATE_NEW_TIME_ENTRY_SUCCESS";
export const CREATE_NEW_TIME_ENTRY_FAILURE = "CREATE_NEW_TIME_ENTRY_FAILURE";

const postTimeEntry = (payload) => ({
  [CALL_API]: {
    types: [CREATE_NEW_TIME_ENTRY_REQUEST, CREATE_NEW_TIME_ENTRY_SUCCESS, CREATE_NEW_TIME_ENTRY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.TIME_ENTRIES,
  },
});

export const createNewTimeEntry = (payload) => (dispatch) => {
  return dispatch(postTimeEntry(payload));
};

export const UPDATE_TIME_ENTRY_REQUEST = "UPDATE_TIME_ENTRY_REQUEST";
export const UPDATE_TIME_ENTRY_SUCCESS = "UPDATE_TIME_ENTRY_SUCCESS";
export const UPDATE_TIME_ENTRY_FAILURE = "UPDATE_TIME_ENTRY_FAILURE";

const changeTimeEntry = (timeEntryId, payload) => ({
  [CALL_API]: {
    types: [UPDATE_TIME_ENTRY_REQUEST, UPDATE_TIME_ENTRY_SUCCESS, UPDATE_TIME_ENTRY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/${timeEntryId}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.TIME_ENTRIES,
  },
});

export const updateTimeEntry = (timeEntryId, payload) => (dispatch) => {
  return dispatch(changeTimeEntry(timeEntryId, payload));
};

export const DELETE_TIME_ENTRY_REQUEST = "DELETE_TIME_ENTRY_REQUEST";
export const DELETE_TIME_ENTRY_SUCCESS = "DELETE_TIME_ENTRY_SUCCESS";
export const DELETE_TIME_ENTRY_FAILURE = "DELETE_TIME_ENTRY_FAILURE";

const removeTimeEntry = (timeEntryId) => ({
  [CALL_API]: {
    types: [DELETE_TIME_ENTRY_REQUEST, DELETE_TIME_ENTRY_SUCCESS, DELETE_TIME_ENTRY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/${timeEntryId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.TIME_ENTRIES,
  },
});

export const deleteTimeEntry = (timeEntryId) => (dispatch) => {
  return dispatch(removeTimeEntry(timeEntryId));
};

export const VERIFY_USER_PASS_REQUEST = "VERIFY_USER_PASS_REQUEST";
export const VERIFY_USER_PASS_SUCCESS = "VERIFY_USER_PASS_SUCCESS";
export const VERIFY_USER_PASS_FAILURE = "VERIFY_USER_PASS_FAILURE";

const checkUserPass = (payload) => ({
  [CALL_API]: {
    types: [VERIFY_USER_PASS_REQUEST, VERIFY_USER_PASS_SUCCESS, VERIFY_USER_PASS_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/verify/user/password`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.PASSWORD_VERIFY,
  },
});

export const verifyUserPassword = (payload) => (dispatch) => {
  return dispatch(checkUserPass(payload));
};

export const USERS_BY_ROLE_REQUEST = "USERS_BY_ROLE_REQUEST";
export const USERS_BY_ROLE_SUCCESS = "USERS_BY_ROLE_SUCCESS";
export const USERS_BY_ROLE_FAILURE = "USERS_BY_ROLE_FAILURE";

const getUsersByRoleId = (roleId, companyProjectId) => ({
  [CALL_API]: {
    types: [USERS_BY_ROLE_REQUEST, USERS_BY_ROLE_SUCCESS, USERS_BY_ROLE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/users/with/${roleId}/${companyProjectId}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.USER_ARRAY,
  },
});

export const getUsersWithRoleId = (roleId, companyProjectId) => (dispatch) => {
  return dispatch(getUsersByRoleId(roleId, companyProjectId));
};

export const GET_WEEKLY_TIME_REQUEST = "GET_WEEKLY_TIME_REQUEST";
export const GET_WEEKLY_TIME_SUCCESS = "GET_WEEKLY_TIME_SUCCESS";
export const GET_WEEKLY_TIME_FAILURE = "GET_WEEKLY_TIME_FAILURE";

const getWeeklyCalendarTimeEntries = (userId, from, to) => ({
  [CALL_API]: {
    types: [GET_WEEKLY_TIME_REQUEST, GET_WEEKLY_TIME_SUCCESS, GET_WEEKLY_TIME_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/calendar/week/${userId}?from=${from}&to=${to}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CALENDAR_TIME_ENTRIES,
  },
});

export const fetchWeeklyCalendarTimeEntries = (userId, from, to) => (dispatch) => {
  return dispatch(getWeeklyCalendarTimeEntries(userId, from, to));
};
