import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

export default function FreeSolo(props) {
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setDialogValue({
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    name: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    setValue({
      name: dialogValue.name,
    });
    const result = await props.saveCompany({ name: dialogValue.name });
    let newCompany = Object.values(result.response.entities.companies)[0];
    props.setOrgId(newCompany);
    props.getCompaniesList();
    setLoading(false);
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        size="small"
        value={props.orgId}
        onChange={(event, newValue) => {
          if (props.changesMade && !props.changesMade) props.setChangesMade(true);
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
            });
          } else {
            setValue(newValue);
            props.setOrgId(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        options={props.options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextField
            label={props.label}
            size="small"
            {...params}
            variant="outlined"
            required
            margin="dense"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">{props.dialogtitle}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "#EB6B6C" }}>
              <i>Warning: This will added to the database.</i>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              size="small"
              variant="outlined"
              id="name"
              value={dialogValue.name}
              onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
              label={props.dialogTextLabel}
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} type="submit" color="primary">
              {loading ? <i className="fal fa-cog fa-spin" /> : "Add"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
