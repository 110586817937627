import React, { useState, useEffect } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { Autocomplete, ToggleButton } from "@material-ui/lab";

export default function AccessItem(props) {
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  if (props.boolean) {
    return (
      <Box mb={1} width={600}>
        <Grid
          container
          style={{
            borderRadius: 10,
            background: "white",
            color: "black",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 3px 10px 3px rgba(1,22,39,.05)",
            // border: "1px solid rgba(0,0,0,.2)",
            padding: 10,
          }}
        >
          <Grid item xs={9}>
            <Box fontSize="1rem">{props.level}</Box>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ToggleButton
              disabled={props.data.isSuper}
              className={props.classes.toggle}
              style={{
                border: !selected ? "2px solid rgba(0,0,0,0.2)" : "2px solid rgba(8, 178, 178, 1)",
                background: !selected ? "rgba(255,255,255,.1)" : "rgba(8, 178, 178, 0.3)",
              }}
              value="check"
              selected={selected}
              onChange={
                props.root
                  ? () => props.updatePermissions()
                  : () => {
                      setSelected(!selected);

                      let tempData = props.userData;
                      tempData.find((user) => user.id === props.userId).adminAccess[props.section][
                        props.target
                      ] = !selected;
                      props.setData(tempData);

                      props.updatePermissions(props.accessId, {
                        [props.section]: { ...props.data[props.section], [props.target]: !selected },
                      });
                    }
              }
            >
              <i className="fas fa-check" style={{ color: !selected ? "rgba(8,178,178,0)" : "#08B2B2" }} />
            </ToggleButton>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Autocomplete
      id="combo-box-demo"
      multiple
      disableCloseOnSelect
      options={props.dropdownData || []}
      getOptionLabel={(option) => option && option.name}
      style={{
        background: "white",
        width: 600,
        padding: 10,
        borderRadius: 10,
        boxShadow: "0px 3px 10px 3px rgba(1,22,39,.05)",
      }}
      value={
        (props.data &&
          props.data.companies &&
          props.dropdownData &&
          props.dropdownData.filter((company) => props.data.companies.allowedIds.includes(company.id))) ||
        []
      }
      onChange={(event, newValue) => {
        let tempData = props.userData;
        tempData.find((user) => user.id === props.userId).adminAccess.companies.allowedIds = newValue.map((c) => c.id);
        props.setData(tempData);

        props.updatePermissions(props.accessId, {
          [props.section]: { ...props.data[props.section], allowedIds: newValue.map((c) => c.id) },
        });
      }}
      limitTags={4}
      renderInput={(params) => <TextField {...params} label="Select Organizations" />}
    />
  );
}
