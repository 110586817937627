import React from "react";
import { CircularProgress, Grid, IconButton, Box, TextField } from "@material-ui/core";

export default function ChatActions(props) {
  return (
    <Grid container style={{ borderTop: "2px solid #011627" }} alignItems="center" justify="center" fullWidth>
      <Grid item xs={9}>
        <Box p={"10px 0px 10px 15px"}>
          <TextField
            label="Type a new comment"
            variant="outlined"
            disabled={props.loading}
            multiline
            value={props.comment}
            onChange={(e) => props.setComment(e.target.value)}
            margin="none"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box p={2}>
          {props.loading ? (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton disabled={props.comment.length === 0} onClick={() => props.postComment()}>
                <i
                  style={{
                    color: props.comment.length === 0 ? "lightgray" : "#0E8C64",
                    fontSize: props.editing ? "1.3rem" : "1.5rem",
                  }}
                  className={`fal fa-${props.editing ? "check-circle" : "check-circle"}`}
                />
              </IconButton>
              {props.editing ? (
                <IconButton disabled={props.comment.length === 0} onClick={() => props.removeEdit()}>
                  <i
                    style={{ color: props.comment.length === 0 ? "lightgray" : "#EB6B6C", fontSize: "1.3rem" }}
                    className={`fal fa-times-circle`}
                  />
                </IconButton>
              ) : null}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
