import React from "react";
import { TextField, CircularProgress, IconButton, InputAdornment } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
const filter = createFilterOptions();

export default function AsyncAutoComplete(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await props.fetchData();
      setLoading(false);
      const result =
        (response.response.entities &&
          response.response.entities.lists &&
          response.response.entities.lists[props.target] &&
          Object.values(response.response.entities.lists[props.target].list)) ||
        [];
      if (active) {
        setOptions(result);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: "100%" }}
      disabled={props.disabled || false}
      multiple={props.multiple || false}
      size="small"
      open={open}
      onOpen={() => {
        setLoading(true);
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (props.creatable) {
          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
        }
        return filtered;
      }}
      value={props.value}
      // getOptionLabel={props.getOptionLabel}
      getOptionLabel={(option) => option && option.name}
      options={
        !props.value
          ? options
          : options.filter((o) =>
              props.multiple ? !props.value.find((val) => val.id === o.id) : o.id !== props.value.id
            )
      }
      loading={loading}
      freeSolo={props.creatable}
      filterSelectedOptions
      disableCloseOnSelect={props.multiple}
      fullWidth
      onChange={async (e, newVal) => {
        if (e.keyCode === 13 && newVal.length > 0) {
          return await props.createFunc(newVal);
        }

        if (props.creatable && newVal && newVal.inputValue && newVal.inputValue.length > 0) {
          props.handleChange(newVal.inputValue);
          return await props.createFunc(newVal.inputValue);
        }
        props.handleChange(newVal);
      }}
      // clearOnBlur={props.creatable}
      // clearOnEscape={false}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            size="small"
            margin="dense"
            required={props.required}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
    />
  );
}
