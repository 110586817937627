import React, { useState, useEffect } from "react";
import { Box, Popover, IconButton } from "@material-ui/core";
import Warning from "../Dialog/WarningDialog";
import { useStyles } from "./Chat.styles";
import ChatBubbles from "./ChatBubbles";
import ChatActions from "./ChatActions";

export default function Chat(props) {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let element = document.getElementById("chat");

  useEffect(() => {
    updateScroll();
  }, [props.comments, element]);

  const updateScroll = () => {
    if (element) element.scrollTop = element.scrollHeight;
  };

  const postComment = async () => {
    setLoading(true);
    let result = {};
    if (editing) {
      result = await props.editComment(commentId, comment);
      setEditing(false);
    } else {
      result = await props.addComment(comment);
    }

    setLoading(false);
    setComment("");

    return result;
  };

  const modifyComment = (type, commentId, comment, target = null) => {
    let result = {};
    switch (type) {
      case "edit":
        setEditing(true);
        setComment(comment);
        break;
      case "delete":
        setAnchorEl(target);
        break;
    }
    setCommentId(commentId);
    return result;
  };

  const removeEdit = () => {
    setEditing(false);
    setComment("");
    setCommentId("");
  };

  const deleteComment = async () => {
    setDeleting(true);
    const result = await props.deleteComment(commentId);
    setDeleting(false);
    setAnchorEl(null);
    return result;
  };

  const closeChatBox = () => props.closeChat();

  return (
    <Popover
      PaperProps={{ height: "20rem", width: "20rem" }}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={() => closeChatBox()}
    >
      <Warning
        delete
        loading={deleting}
        anchorEl={anchorEl}
        text={`Are you sure you want to delete this comment?`}
        confirm={() => deleteComment()}
        close={() => setAnchorEl(null)}
      />
      <Box className={classes.chatHeader}>
        <Box className={classes.chatHeaderText}>{props.title}</Box>
        <Box mr={1}>
          <IconButton onClick={() => closeChatBox()}>
            <i style={{ color: "white", fontSize: "1rem" }} className={`fas fa-window-minimize`} />
          </IconButton>
        </Box>
      </Box>
      <Box id="chat" style={{ scrollBehavior: "smooth" }} className={classes.container}>
        <ChatBubbles classes={classes} modifyComment={modifyComment} {...props} />
      </Box>
      <ChatActions
        classes={classes}
        postComment={postComment}
        comment={comment}
        setComment={setComment}
        loading={loading}
        editing={editing}
        removeEdit={removeEdit}
        {...props}
      />
    </Popover>
  );
}
