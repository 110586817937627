export function checkEmail(str) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  );
}

export function getYear() {
  const date = new Date();
  return date.getFullYear();
}

export const doesUserContainRoles = (roles, names = [constants.USER_ROLES.CISO_DEV]) => {
  if (!roles || roles.length === 0) {
    return false;
  }
  try {
    const userRoles = roles.map((userRole) => {
      if (userRole.role && userRole.role.name) {
        const nameArr = userRole.role.name.split(" ");
        const namesCaps = nameArr.map((e) => e.toUpperCase());
        return namesCaps.join("_");
      }
    });
    return names.some((n) => userRoles.includes(n));
  } catch (err) {
    throw err;
  }
};

export function humanizeLabels(str) {
  if (!str) return null;

  return str
    .toLowerCase()
    .split(/([ _])/g)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace(/_/g, " ")
    .replace(/\s+/g, " ");
}

export function generateSafePayload(payload) {
  let returnObject = {};
  Object.keys(payload).forEach((key) => {
    if (!!payload[key]) returnObject[key] = payload[key];
    else returnObject[key] = null;
  });
  return returnObject;
}

export const constants = {
  DEFAULT_PAGE_LIMIT: 10,
  USER_ROLES: {
    EMPLOYEE: "EMPLOYEE",
    COMPANY_ADMIN: "COMPANY_ADMIN",
    COMPANY_CONSULTANT: "COMPANY_CONSULTANT",
    CISO_ADMIN: "CISO_ADMIN",
    CISO_CONSULTANT: "CISO_CONSULTANT",
    CISO_MARKETING: "CISO_MARKETING",
    CISO_DEV: "CISO_DEV",
    CISO_FINANCE: "CISO_FINANCE",
    CISO_DATA: "CISO_DATA",
    CISO_ASSESSOR: "CISO_ASSESSOR",
    CISO_MENTOR: "CISO_MENTOR",
  },
  PROCESS_CATEGORIES: {
    TECHNICAL: "Technical",
    NON_TECHNICAL: "Non-Technical",
    CORE_PROGRAM: "Core Program",
  },
  LICENSE_TYPES: {
    EARLY_ACCESS: "Early Access",
    FREE: "Free",
    BASIC: "Basic",
    ENTERPRISE: "Enterprise",
  },
  EMAIL_TYPES: {
    SIGN_UP: "SIGN_UP",
    MIGRATION: "MIGRATION",
    PASSWORD_RESET: "PASSWORD_RESET",
  },
  EMAIL_TYPES_LIST: ["SIGN_UP", "MIGRATION", "PASSWORD_RESET"],
  EMAIL_TEMPLATES: {
    NEW_USER: "new_user",
    NEW_USER_SIGN_UP: "new_user_sign_up_template",
    INVITE_USER: "invite_user",
    RESET_PASS: "reset_pass_template",
    COMPANY_INVITE: "company_invites_another_user",
    CLIENT_VENDOR_INVITE: "client_vendors_invite",
    ENTITY_INVITE: "entities_invite",
    RISK_ASSESSMENT_INVITE: "risk_assessment_invite",
    PRE_ASSESSMENT_INVITE: "pre_assessment_invite",
    RISK_ASSESSMENT_UPDATE: "risk_assessment_update",
    PRE_ASSESSMENT_UPDATE: "pre_assessment_update",
    UPSTREAM_PRE_ASSESSMENT_COMPLETE: "upstream_pre_assessment_complete",
    UPSTREAM_RISK_ASSESSMENT_COMPLETE: "upstream_risk_assessment_complete",
    INVITE_AFTER_SCOPE: "invited_after_scope_call",
    NEWSLETTER_FEB_2020: "app_feb_newsletter",
    NEWSLETTER_MAR_2020: "app_mar_newsletter_cs_cpilist",
    NEWSLETTER_JULY_2020: "app_july_newsletter",
  },
  FOUNDATIONAL_TYPE: {
    TECHNICAL: "TECHNICAL",
    NON_TECHNICAL: "NON_TECHNICAL",
  },
  DOCUMENT_STATUES: {
    SUBMITTED: "SUBMITTED",
    RE_SUBMITTED: "RE_SUBMITTED",
    UNDER_REVIEW: "UNDER_REVIEW",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
  },
  DOCUMENT_TYPES: {
    OTHER: "other",
    PROCESSES: "processes",
    PROGRAM_CHARTER: "program_charter",
    DOCUMENT_POLICIES: "document_policies",
    BENCHMARK: "benchmark",
    NETWORK: "network",
    TOOLS: "tools",
    AVATAR_FILE: "avatar_file",
    OS_INVENTORY: "os_inventory",
    APP_INVENTORY: "app_inventory",
    SOC2: "soc2",
  },
  ORGANIZATION_OBJECTIVES: [
    { label: "Align to Best Practice", value: "Align to Best Practice" },
    { label: "Appease Clients", value: "Appease Clients" },
    { label: "Demonstrate Due Care", value: "Demonstrate Due Care" },
    {
      label: "Improve Maturity Over Time",
      value: "Improve Maturity Over Time",
    },
    {
      label: "Improve Progress",
      value: "Improve Progress",
    },
    {
      label: "Increase Security of Organization",
      value: "Increase Security of Organization",
    },
    {
      label: "Prevent Breach",
      value: "Prevent Breach",
    },
    { label: "Prevent Fines", value: "Prevent Fines" },
    {
      label: "Protect Personal Interests and Job",
      value: "Protect Personal Interests and Job",
    },
    { label: "Recover From Breach", value: "Recover From Breach" },
    { label: "Reduce Security Costs", value: "Reduce Security Costs" },
    { label: "Reduce Security Risk", value: "Reduce Security Risk" },
    {
      label: "Understand and Improve Environment",
      value: "Understand and Improve Environment",
    },
    { label: "Understand Current State", value: "Understand Current State" },
  ],
};
