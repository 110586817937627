import React from "react";
import { Box, IconButton, Grid } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tabs from "../Tabs";
import BaseButton from "../BaseButton/BaseButton";

const Frame = (props) => {
  return (
    <Box style={{ width: "100%" }} pb={3}>
      {props.tabs ? (
        <Box ml={3} mr={3}>
          <Tabs labels={props.tabs} tabValue={props.value} setTabValue={props.setValue} />
        </Box>
      ) : null}

      <Box
        ml={3}
        mr={3}
        borderRadius="10px"
        style={{
          background: "#fff",
          boxShadow: "10px 29px 83px -16px rgba(1,22,39,.3)",
          minHeight: "80vh",
          border: "2px solid #fff",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          pl={2}
          pt={1}
          pb={1}
          style={{
            background: "#fff",
            color: "#011627",
            borderRadius: "10px 10px 0 0",
            borderBottom: "2px solid #011627",
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={2}>
              <IconButton
                style={{ padding: "7px 12px" }}
                onClick={props.history ? () => props.history.goBack() : () => props.goBack()}
              >
                <i className="fas fa-chevron-left" style={{ color: "#000" }} />
              </IconButton>
            </Box>
            {props.content ? (
              <Box>
                <span style={{ color: "#0E8C64" }}>
                  <b>{props.content.name}</b>
                </span>{" "}
                / ID:
                {props.content.id.replace(/(.{15})..+/, "$1…")}
                <CopyToClipboard text={props.content.id}>
                  <IconButton style={{ padding: "4px 6px" }}>
                    <i style={{ color: "black", fontSize: "0.8rem" }} className={props.content.icon} />
                  </IconButton>
                </CopyToClipboard>{" "}
              </Box>
            ) : null}
          </Box>

          <Box mr={3}>
            {props.buttonText ? (
              <BaseButton skinnyBlue clickHandler={props.clickHandler} text={props.buttonText} />
            ) : null}
          </Box>
        </Box>
        <Box width="100%">{props.children}</Box>
      </Box>
    </Box>
  );
};

export default Frame;
