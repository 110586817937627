import React, { useState, useEffect } from "react";
import { Box, TextField, Popover, IconButton, Button } from "@material-ui/core";
import BaseButton from "../../../components/BaseButton";
import AsyncAutoComplete from "./AsyncAutoComplete";

export default function WorkElementTimeEntryItem(props) {
  const [timeData, setTimeData] = useState(0);
  const [task, setTask] = useState(null);
  const [notes, setNotes] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [newTask, setNewTask] = useState("");
  const [creatingNewTask, setCreatingNewTask] = useState(false);
  const open = Boolean(anchorEl);

  const addTimeIncrement = (increment) => {
    let t = timeData;
    t = t + increment / 60;
    if (t < 0) return;

    let currentData = props.workElements;
    let target = currentData.find((we) => we.id === props.workElement.id);
    target.hours = t;
    props.setWorkElements([...currentData]);

    setTimeData(t);
  };

  const changeTask = (task) => {
    let currentData = props.workElements;
    let target = currentData.find((we) => we.id === props.workElement.id);
    target.taskId = task.id;
    props.setWorkElements([...currentData]);
    setTask(task);
  };

  const changeNote = (note) => {
    let currentData = props.workElements;
    let target = currentData.find((we) => we.id === props.workElement.id);
    target.notes = note;
    props.setWorkElements([...currentData]);
    setNotes(note);
  };

  const addNewTask = async (task = null) => {
    const payload = {
      timeEntryTask: {
        name: task,
      },
      workElementIds: [props.workElement.id],
    };

    setCreatingNewTask(true);
    const result = await props.createTimeEntryTask(payload);
    changeTask(Object.values(result.response.entities.timeEntryTasks)[0]);
    setCreatingNewTask(false);
    setAnchorEl(null);
    setNewTask(null);
    return result;
  };

  return (
    <Box width="100%">
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{ height: "20rem", width: "20rem", padding: "1rem" }}
      >
        <Box p={3} display="flex" flexDirection="column" width="300px" alignItems="center" justifyContent="center">
          <TextField
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            label="New Task"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <br />
          <Button onClick={() => addNewTask()} style={{ width: "100%", color: "black", fontWeight: "bold" }}>
            {creatingNewTask ? <i className="fal fa-cog fa-spin" /> : "Update"}
          </Button>
        </Box>
      </Popover>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          boxShadow: "0px 11px 15px -7px rgba(0,0,0,.3)",
          borderRadius: 10,
          fontWeight: "bold",
          fontSize: "3rem",
        }}
      >
        <Box display="flex" flexDirection="column" width={"100%"} p={2}>
          <Box display="flex" alignItems="center">
            <AsyncAutoComplete
              fetchData={async () => await props.getWorkElementTasks(props.workElement.id)}
              creatable
              value={task}
              target="workElementTasks"
              label="Task"
              add={(e) => setAnchorEl(e.currentTarget)}
              createFunc={addNewTask}
              required
              handleChange={(value) => changeTask(value)}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
            {timeData < 8 && (
              <Box display="flex">
                <Box ml={0.5} mr={0.5} mb={1}>
                  <BaseButton
                    skinnyGreen
                    text="8 Hours"
                    clickHandler={() => {
                      let currentData = props.workElements;
                      let target = currentData.find((we) => we.id === props.workElement.id);
                      target.hours = 8;
                      props.setWorkElements([...currentData]);
                      setTimeData(8);
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box mb={2} style={{ fontSize: "1.2rem", color: "black" }}>
              {Math.floor(timeData)}h{" "}
              {timeData % 1 === 0 ? "00" : timeData % 1 === 0.25 ? "15" : timeData % 1 === 0.75 ? "45" : "30"}m
            </Box>
            <Box display="flex">
              <Box ml={0.5} mr={0.5}>
                <BaseButton skinnyBlue text="+ 15 mins" clickHandler={() => addTimeIncrement(15)} />
              </Box>
              <Box ml={0.5} mr={0.5} mb={0.5}>
                <BaseButton skinnyBlue text="+ 30 mins" clickHandler={() => addTimeIncrement(30)} />
              </Box>
              <Box ml={0.5} mr={0.5} mb={0.5}>
                <BaseButton skinnyBlue text="+ 60 mins" clickHandler={() => addTimeIncrement(60)} />
              </Box>
              <Box ml={0.5} mr={0.5} mb={0.5}>
                <BaseButton skinnyBlue text="+ 90 mins" clickHandler={() => addTimeIncrement(90)} />
              </Box>
              <Box ml={0.5} mr={0.5}>
                <BaseButton
                  skinnyPink
                  disabled={timeData === 0}
                  text="- 15 mins"
                  clickHandler={() => addTimeIncrement(-15)}
                />
              </Box>
              <Box ml={0.5} mr={0.5} mb={0.5}>
                <BaseButton
                  skinnyPink
                  disabled={timeData === 0}
                  text="- 30 mins"
                  clickHandler={() => addTimeIncrement(-30)}
                />
              </Box>
              <Box ml={0.5} mr={0.5} mb={0.5}>
                <BaseButton
                  skinnyPink
                  disabled={timeData === 0}
                  text="- 60 mins"
                  clickHandler={() => addTimeIncrement(-60)}
                />
              </Box>
              <Box ml={0.5} mr={0.5} mb={0.5}>
                <BaseButton
                  skinnyPink
                  disabled={timeData === 0}
                  text="- 90 mins"
                  clickHandler={() => addTimeIncrement(-90)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
