export const colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  GRAY: "#EDEDED",
  GREEN: "#0E8C64",
  SAVBLUE: "#3A5199",
  RED: "#EB6B6C",
  ORANGE: "#eba024",
  CSBLUE: "#2373D8",
  CSRED: "#ff6666",
  YELLOW: "#FFCD37",
  OFFWHITE: "#FFFFFA",
  DARKGRAY: "#696969",
  DARKBLUE: "#011627",
  SECONDBLUE: "#335C81",
};
