import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import Demographics from "./views/Demographics";
import Drivers from "./views/Drivers";
import BorderBox from "./components/BorderBox";
import { defaultDemographics, defaultDemographicsErrors } from "./utils/utils";
import {
  generatePayload,
  generateDemographicsPayload,
  populateDemographicsAnswers,
  errorChecker,
} from "./utils/helpers";
import ExpansionPanel from "../../components/ExpansionPanel";
import BaseButton from "../../components/BaseButton/BaseButton";
import NavigationBlocker from "../../components/NavigationBlocker/NavigationBlocker";
import Snackbar from "../../components/Snackbar/Snackbar";
import { constants } from "../../utils/common";
import Warning from "../../components/Dialog/WarningDialog";

export default function CompanyDemographics(props) {
  const [demographics, setDemographics] = useState(defaultDemographics);
  const [demographicsErrors, setDemographicsErrors] = useState(defaultDemographicsErrors);
  const [expanded, setExpanded] = useState("1");
  const [currentCompany, setCurrentCompany] = useState(null);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "error", message: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [updating, setUpdating] = useState(false);

  const [navigationBlocked, setNavigationBlocked] = useState(false);
  const [frameworkSuccess, setFrameworkSuccess] = useState(false);

  useEffect(() => {
    loadDemographicsData();
  }, []);

  const fetchApiData = async () => {
    const result = await props.loadCompany(props.companyId);
    const statesResult = await props.loadStatesList();
    const countriesResult = await props.loadCountriesList();
    const industriesResult = await props.loadIndustriesList();
    const dataTypes = await props.loadDataTypesList();
    const orgImpacts = await props.loadOrgImpactsList();

    const company = result.response.entities.companies[props.companyId];
    const dataTypesList = dataTypes.response.entities.lists.dataTypes.list;
    const orgImpactsList = orgImpacts.response.entities.lists.orgImpacts.list;
    const statesList = statesResult.response.entities.lists.states.list;
    const countriesList = countriesResult.response.entities.lists.countries.list;
    const industriesList = industriesResult.response.entities.lists.industries.list;

    return { company, dataTypesList, orgImpactsList, statesList, countriesList, industriesList };
  };

  const loadDemographicsData = async () => {
    const { company, dataTypesList, orgImpactsList, statesList, countriesList, industriesList } = await fetchApiData();
    if (company.demographics.length > 0) {
      setUpdating(true);
      const args = {
        demographics: company.demographics[0].demographic,
        states: statesList,
        countries: countriesList,
        industries: industriesList,
        dataTypes: dataTypesList,
        orgImpacts: orgImpactsList,
      };
      const populatedDemographics = populateDemographicsAnswers(args);
      setDemographics({ ...populatedDemographics });
    } else {
      let newData = defaultDemographics;
      newData.dataTypes = dataTypesList.map((dataType) => ({ ...dataType, checked: false, autoChecked: false }));
      newData.orgImpacts = orgImpactsList.map((orgImpact) => ({ ...orgImpact, checked: false, autoChecked: false }));
      setDemographics({ ...newData });
    }
    setCurrentCompany({ ...company });
    setLoading(false);
  };

  const updateDemographicsState = (key, value, subKey = null) => {
    const demographicsPayload = generateDemographicsPayload(demographics, key, value, subKey);
    if (key === "orgImpacts") setDemographicsErrors({ ...demographicsErrors, dataTypes: false, orgImpacts: false });
    else setDemographicsErrors({ ...demographicsErrors, [key]: false });
    if (frameworkSuccess) setFrameworkSuccess(false);
    setDemographics({ ...demographicsPayload });
    setNavigationBlocked(true);
  };

  const submitDemographics = () => {
    setAnchorEl(null);
    if (expanded === "1") {
      setExpanded("2");
    } else {
      postDemographics();
      setExpanded(null);
    }
  };

  const postDemographics = async () => {
    try {
      const errors = errorChecker(demographics);
      const errorFound = Object.values(errors).some((error) => error);
      setDemographicsErrors({ ...errors });
      if (!errorFound) {
        setSaving(true);
        setSnackbar({
          isOpen: true,
          message: "Updating Compliance Dashboard...",
          character: "bot",
          variant: "info",
        });
        let args = {
          demographics,
          states: props.states,
          countries: props.countries,
          industries: props.industries,
          dataTypes: props.dataTypes,
          orgImpacts: props.orgImpacts,
        };
        let payload = generatePayload({ ...args });
        if (payload.hasOwnProperty("id")) delete payload.id;
        const result = await props.postCompanyFrameworkDesign({
          companyId: props.companyId,
          targetBestPractice: "",
          ...payload,
        });
        if (result.type.includes("SUCCESS")) setFrameworkSuccess(true);
        setUpdating(true);
        await refreshDemographicsState(result, args);
        setSnackbar({
          isOpen: true,
          message: <div>Done!</div>,
          character: "bot",
          variant: "success",
        });
        return result;
      }
      return setSnackbar({ ...snackbar, isOpen: true, message: "Please fill out every element correctly." });
    } catch (err) {
      setSnackbar({ ...snackbar, isOpen: true, message: "Something went wrong, please refresh and try again." });
    }
  };

  const refreshDemographicsState = async (result, args) => {
    const demographicsPayload = Object.values(result.response.entities.plans)[0];
    args.demographics = demographicsPayload;
    let populatedDemographics = populateDemographicsAnswers(args);
    delete populateDemographicsAnswers.id;

    setDemographics({ ...populatedDemographics });
    await props.loadCompany(props.companyId);
    setSaving(false);
    setNavigationBlocked(false);
  };

  const closeSnackbar = () => setSnackbar({ ...snackbar, isOpen: false });

  const handleChange = (panel) => {
    if (expanded === panel) {
      return setExpanded(null);
    }
    return setExpanded(panel);
  };

  return (
    <div style={{ marginRight: 30, marginLeft: 30 }}>
      <BorderBox showBorder={props.showBorder}>
        <Snackbar
          variant={snackbar.variant}
          isOpen={snackbar.isOpen}
          message={snackbar.message}
          handleClose={() => closeSnackbar()}
          character={snackbar.character || null}
        />
        <NavigationBlocker navigationBlocked={navigationBlocked} />
        <Grid container style={{ width: "100%" }}>
          <Grid item xs={12} style={{ width: "100%" }}>
            <ExpansionPanel
              title={`Provide simple details about ${currentCompany ? currentCompany.name : "your organization"}.`}
              loading={loading}
              succeeded={frameworkSuccess}
              error={
                demographicsErrors.primaryIndustryId ||
                demographicsErrors.jobTitle ||
                demographicsErrors.objectives ||
                demographicsErrors.countryIds ||
                demographicsErrors.stateIds ||
                demographicsErrors.jobTitle
              }
              expanded={expanded === "1"}
              handleChange={() => handleChange("1")}
            >
              <Demographics
                objectives={constants.ORGANIZATION_OBJECTIVES}
                countries={props.countries}
                states={props.states}
                industries={props.industries}
                demographics={demographics}
                demographicsErrors={demographicsErrors}
                updateDemographicsState={updateDemographicsState}
              />
            </ExpansionPanel>
            <ExpansionPanel
              title={`Tell us about the potential impact if ${
                currentCompany ? currentCompany.name : "your organization"
              }'s data was compromised.`}
              loading={loading}
              error={demographicsErrors.orgImpacts || demographicsErrors.dataTypes}
              expanded={expanded === "2"}
              succeeded={frameworkSuccess}
              handleChange={() => handleChange("2")}
            >
              <Drivers
                demographics={demographics}
                demographicsErrors={demographicsErrors}
                updateDemographicsState={updateDemographicsState}
                company={currentCompany}
              />
            </ExpansionPanel>
          </Grid>
        </Grid>
        {expanded !== "1" ? (
          <Warning
            anchorEl={anchorEl}
            text={`Changing these settings will impact the recommended frameworks in 
                  the Compliance Dashboard. All custom and required carts will remain unchanged.`}
            confirm={() => submitDemographics()}
            close={() => setAnchorEl(null)}
          />
        ) : null}

        <Box pt={2} width="100%" display="flex" alignItems="center" justifyContent="center">
          {!loading && (
            <BaseButton
              white
              clickHandler={
                expanded !== "1"
                  ? (event) => (updating ? setAnchorEl(event.currentTarget) : submitDemographics())
                  : () => submitDemographics()
              }
              icon={saving ? <i className="fal fa-circle-notch fa-spin" /> : null}
              text={expanded === "1" ? "Next" : saving ? "Saving..." : "Save"}
            />
          )}
        </Box>
      </BorderBox>
    </div>
  );
}
