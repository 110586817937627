import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const POST_PROCESS_REQUEST = "POST_PROCESS_REQUEST";
export const POST_PROCESS_SUCCESS = "POST_PROCESS_SUCCESS";
export const POST_PROCESS_FAILURE = "POST_PROCESS_FAILURE";

const postProcess = (payload) => ({
  [CALL_API]: {
    types: [POST_PROCESS_REQUEST, POST_PROCESS_SUCCESS, POST_PROCESS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/processes/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROCESS,
  },
});

export const saveProcess = (payload) => (dispatch) => {
  return dispatch(postProcess(payload));
};

export const UPDATE_PROCESS_REQUEST = "UPDATE_PROCESS_REQUEST";
export const UPDATE_PROCESS_SUCCESS = "UPDATE_PROCESS_SUCCESS";
export const UPDATE_PROCESS_FAILURE = "UPDATE_PROCESS_FAILURE";

const changeProcess = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PROCESS_REQUEST, UPDATE_PROCESS_SUCCESS, UPDATE_PROCESS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/processes/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROCESS,
  },
});

export const updateProcess = (id, payload) => (dispatch) => {
  return dispatch(changeProcess(id, payload));
};

export const DELETE_PROCESS_REQUEST = "DELETE_PROCESS_REQUEST";
export const DELETE_PROCESS_SUCCESS = "DELETE_PROCESS_SUCCESS";
export const DELETE_PROCESS_FAILURE = "DELETE_PROCESS_FAILURE";

const removeProcess = (id) => ({
  [CALL_API]: {
    types: [DELETE_PROCESS_REQUEST, DELETE_PROCESS_SUCCESS, DELETE_PROCESS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/processes/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PROCESS,
  },
});

export const deleteProcess = (id) => (dispatch) => {
  return dispatch(removeProcess(id));
};

export const PROCESS_COUNT_REQUEST = "PROCESS_COUNT_REQUEST";
export const PROCESS_COUNT_SUCCESS = "PROCESS_COUNT_SUCCESS";
export const PROCESS_COUNT_FAILURE = "PROCESS_COUNT_FAILURE";

const getProcessCount = () => ({
  [CALL_API]: {
    types: [PROCESS_COUNT_REQUEST, PROCESS_COUNT_SUCCESS, PROCESS_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/processes/processes/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchProcessCount = () => (dispatch) => {
  return dispatch(getProcessCount());
};

export const PROCESSES_REQUEST = "PROCESSES_REQUEST";
export const PROCESSES_SUCCESS = "PROCESSES_SUCCESS";
export const PROCESSES_FAILURE = "PROCESSES_FAILURE";

const fetchProcesses = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [PROCESSES_REQUEST, PROCESSES_SUCCESS, PROCESSES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROCESS_ARRAY,
  },
  refresh,
});

export const loadProcesses = (lastTimeStamp, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchProcesses(`${ADMIN_URL}/processes/processes/all?limit=${limit}`, refresh));
  }

  if (refresh && lastTimeStamp) {
    return dispatch(
      fetchProcesses(`${ADMIN_URL}/processes/processes/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`, refresh)
    );
  }

  if (lastTimeStamp) {
    return dispatch(
      fetchProcesses(`${ADMIN_URL}/processes/processes/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`)
    );
  }

  return dispatch(fetchProcesses(`${ADMIN_URL}/processes/processes/all?limit=${limit}`));
};
