import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    display: "flex",
    // height: "50vh",
    overflow: "auto",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  indicator: {
    background: "linear-gradient(131deg, #2373d8 0%, #08B2B2 50%, #36A3F9 100%)",
    width: 10,
    height: "80%",
    borderRadius: 50,
    marginRight: -5,
  },
  tabs: {
    background: "#fff",
    color: "#000",
    boxShadow: "0px 3px 10px 3px rgba(1,22,39,.05)",
    borderRadius: 10,
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
      textTransform: "none",
    },
  },
  tab: {
    minWidth: 250,
  },
  toggle: {
    "&:hover": {
      background: "rgba(0,0,0, 0.1) !important",
    },
  },
}));
