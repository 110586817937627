import React from "react";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

export const getColumns = (orgImpacts = []) => [
  {
    title: "Label",
    field: "label",
  },
  {
    title: "Organizational Impacts",
    field: "orgImpacts",
    render: (row) => (
      <div style={{ minWidth: "50px" }}>
        {Array.isArray(row.orgImpacts) ? row.orgImpacts.map((orgImpact) => orgImpact.orgImpact.label).join(", ") : null}
      </div>
    ),
    editComponent: (props) => <Selection multiple target="orgImpact" options={orgImpacts} {...props} />,
  },
];
