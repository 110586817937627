export const nonBillableList = [
  "PTO",
  "Operations",
  "Application Development",
  "Internal Training",
  "Company Holiday",
  "Internal Process Development",
  "CUSF",
  "Cyber Forward",
  "Company Meeting",
  "Sick",
  "Internal Security Program",
  "Sales Engineering and Support",
  "Marketing",
  "Account Management",
  "Human Resources",
  "Break",
];
