import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout, getAdminProfile, updateInternalState } from "../../store/actions";
import { fontType } from "../../utils/themes/settings";
import useStyles from "./Header.styles";

const Header = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {}, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function navToCurrentOrganization(event = null, newTab = false) {
    const url = `${window.location.protocol}//${window.location.host}/#/organizations/${props.user.companyId}`;
    handleClose();
    if (newTab && event) {
      event.stopPropagation();
      return window.open(url, "_blank");
    }
    props.history.push(`/organizations/${props.user.companyId}`);
  }

  function navToFeedbackForm() {
    const url = `https://cisoshare.sharepoint.com/:x:/r/sites/CISOSHARE24-App-ServicesIntegration/_layouts/15/Doc.aspx?action=edit&sourcedoc=%7BF7BE48E7-3C96-4295-BCE5-81465476561A%7D&cid=79db7aea-5ba0-4f47-868f-4d653bae09eb&wdLOR=c90BA57D1-D586-D346-B365-B6CC5E0436C8`;

    return window.open(url, "_blank");
  }

  function navToMyProfile(event = null, newTab = false) {
    const url = `${window.location.protocol}//${window.location.host}/#/profile/${props.user.id}`;
    handleClose();
    if (newTab && event) {
      event.stopPropagation();
      return window.open(url, "_blank");
    }
    props.history.push(`/profile/${props.user.id}`);
  }

  function navToUsersAndTeams(event = null, newTab = false) {
    const url = `${window.location.protocol}//${window.location.host}/#/access`;
    handleClose();
    if (newTab && event) {
      event.stopPropagation();
      return window.open(url, "_blank");
    }
    props.history.push(`/access`);
  }

  function navToProjectRoster(event = null, newTab = false) {
    const url = `${window.location.protocol}//${window.location.host}/#/roster/${props.user.companyId}`;
    handleClose();
    if (newTab && event) {
      event.stopPropagation();
      return window.open(url, "_blank");
    }
    props.history.push(`/roster/${props.user.companyId}`);
  }

  function navToProjectQueue(event = null, newTab = false) {
    const url = `${window.location.protocol}//${window.location.host}/#/projectqueue`;
    handleClose();
    if (newTab && event) {
      event.stopPropagation();
      return window.open(url, "_blank");
    }
    props.history.push(`/projectqueue`);
  }

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <IconButton edge="start" className={classes.menuButton} aria-label="Menu" onClick={props.toggleDrawer(true)}>
          <i className={`${fontType} ${classes.icon} fa-bars`} />
        </IconButton>
        <div style={{ cursor: "pointer" }} onClick={() => props.history.push("/dashboard")}>
          <img className={classes.mainLogo} src={require("../../../public/img/whitecisoshare-min.png")} />
        </div>

        <div>
          <IconButton
            aria-label="Account of current user"
            aria-controls="menu-appbar"
            className={classes.menuButton}
            aria-haspopup="true"
            disableRipple
            onClick={handleMenu}
          >
            <i className={`fas ${classes.icon} fa-user-circle`} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem style={{ display: "flex" }} onClick={navToMyProfile}>
              <i className={`${fontType} ${classes.icon} fa-user`} />
              <div>My Profile</div>
            </MenuItem>
            <MenuItem style={{ display: "flex" }} onClick={() => navToCurrentOrganization()} component="a">
              <i className={`${fontType} ${classes.icon} fa-building`} />
              <div>My Organization</div>{" "}
            </MenuItem>
            <MenuItem style={{ display: "flex" }} onClick={() => navToFeedbackForm()} component="a">
              <i className={`${fontType} ${classes.icon} fa-comment-lines`} />
              <div>Give Feedback</div>{" "}
            </MenuItem>
            {props.isSuper ? (
              <div>
                <MenuItem style={{ display: "flex" }} onClick={navToUsersAndTeams}>
                  <i className={`${fontType} ${classes.icon} fa-key`} />
                  <div>Permissions</div>
                  <IconButton
                    onClick={(e) => navToUsersAndTeams(e, true)}
                    style={{ marginLeft: 2, fontSize: "0.8rem", padding: 8, color: "#000" }}
                  >
                    <i className="fad fa-external-link-alt" />
                  </IconButton>
                </MenuItem>{" "}
                <MenuItem style={{ display: "flex" }} onClick={navToProjectRoster}>
                  <i className={`${fontType} ${classes.icon} fa-user-hard-hat`} />
                  <div>Project Roster</div>
                  <IconButton
                    onClick={(e) => navToProjectRoster(e, true)}
                    style={{ marginLeft: 2, fontSize: "0.8rem", padding: 8, color: "#000" }}
                  >
                    <i className="fad fa-external-link-alt" />
                  </IconButton>
                </MenuItem>
                <MenuItem style={{ display: "flex" }} onClick={navToProjectQueue}>
                  <i className={`${fontType} ${classes.icon} fa-sparkles`} />
                  <div>Project Queue</div>
                  <IconButton
                    onClick={(e) => navToProjectQueue(e, true)}
                    style={{ marginLeft: 2, fontSize: "0.8rem", padding: 8, color: "#000" }}
                  >
                    <i className="fad fa-external-link-alt" />
                  </IconButton>
                </MenuItem>
              </div>
            ) : null}
            {!props.isSuper && props.user && props.user.adminAccess && props.user.adminAccess.canViewProjectRoster ? (
              <MenuItem style={{ display: "flex" }} onClick={navToProjectRoster}>
                <i className={`${fontType} ${classes.icon} fa-user-hard-hat`} />
                <div>Project Roster</div>
                <IconButton
                  onClick={(e) => navToProjectRoster(e, true)}
                  style={{ marginLeft: 2, fontSize: "0.8rem", padding: 8, color: "#000" }}
                >
                  <i className="fad fa-external-link-alt" />
                </IconButton>
              </MenuItem>
            ) : null}

            {!props.isSuper && props.user && props.user.adminAccess && props.user.adminAccess.canViewProjectRoster ? (
              <MenuItem style={{ display: "flex" }} onClick={navToProjectQueue}>
                <i className={`${fontType} ${classes.icon} fa-sparkles`} />
                <div>Project Queue</div>
                <IconButton
                  onClick={(e) => navToProjectQueue(e, true)}
                  style={{ marginLeft: 2, fontSize: "0.8rem", padding: 8, color: "#000" }}
                >
                  <i className="fad fa-external-link-alt" />
                </IconButton>
              </MenuItem>
            ) : null}
            <MenuItem style={{ display: "flex" }} onClick={() => props.logout()}>
              <i className={`${fontType} ${classes.icon} fa-lock`} />
              <div>Logout</div>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    getAdminProfile: () => dispatch(getAdminProfile()),
    updateInternalState: (payload) => dispatch(updateInternalState(payload)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Header));
