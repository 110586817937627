import React from "react";
import { NavLink } from "react-router-dom";
import nav from "./_nav";
import { ListItem, ListItemText, ListItemIcon, List, Box, SwipeableDrawer, makeStyles } from "@material-ui/core";
import useStyles from "./Sidebar.styles";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const Sidebar = (props) => {
  const classes = useStyles();

  const allowAccess = (obj) => {
    return obj.create || obj.update || obj.delete || obj.read;
  };

  const navOptions =
    props.user && props.user.adminAccess && !props.user.adminAccess.isSuper
      ? nav.filter((tab) => {
          switch (tab.name) {
            case "Organizations":
              return (
                props.user.adminAccess.isSuper ||
                allowAccess(props.user.adminAccess.companies) ||
                props.user.adminAccess.companies.allowedIds.length > 0
              );
            case "Projects":
              return props.user.adminAccess.isSuper || allowAccess(props.user.adminAccess.projects);
            case "Roles":
              return props.user.adminAccess.isSuper || allowAccess(props.user.adminAccess.roles);
            case "Characteristics":
              return props.user.adminAccess.isSuper || allowAccess(props.user.adminAccess.characteristics);
            case "Metadata":
              return props.user.adminAccess.isSuper || allowAccess(props.user.adminAccess.metadata);
            case "Frameworks":
              return props.user.adminAccess.isSuper || allowAccess(props.user.adminAccess.frameworks);
            case "HubSpot":
              return props.user.adminAccess.isSuper || allowAccess(props.user.adminAccess.hubspot);
            default:
              return true;
          }
        })
      : nav;

  return (
    <SwipeableDrawer
      open={props.sidebarOpen}
      onClose={props.toggleDrawer(false)}
      onOpen={props.toggleDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
      >
        <List>
          {navOptions.map((item, i) => (
            <NavLink to={item.url} className={classes.navLink} key={i}>
              <ListItem button tabIndex={i} button={true} classes={{ button: classes.button }}>
                <ListItemIcon className={classes.icon}>
                  <i className={item.icon} />
                </ListItemIcon>

                <ListItemText primary={item.name} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default Sidebar;
