import makeStyles from "@material-ui/core/styles/makeStyles";
import { colors } from "../../utils/themes/colors";

const useStyles = makeStyles({
  success: {
    backgroundColor: `${colors.GREEN} !important`,
  },
  error: {
    backgroundColor: `${colors.CSRED} !important`,
  },
  info: {
    backgroundColor: `${colors.SAVBLUE} !important`,
  },
  warning: {
    backgroundColor: `#FFA000 !important`,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "20px !important",
    display: "flex",
    alignItems: "center",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  margin10: {
    margin: "20px !important",
  },
});

export default useStyles;
