import React, { useState, useEffect, useRef } from "react";
import { default as ReactMaterialTable } from "material-table";
import { Box, LinearProgress } from "@material-ui/core";
import { constants } from "../../utils/common";

const MaterialTable = (props) => {
  const { data = [] } = props;
  const [rows, setRows] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [currentRowCount, setCurrentRowCount] = useState(0);
  const [tableOptions, setTableOptions] = useState({
    ...props.options,
    debounceInterval: 1000,
    draggable: false,
    pageSize: (props.options && props.options.pageSize) || constants.DEFAULT_PAGE_LIMIT,
    addRowPosition: "first",
    rowStyle: (rowData, index) => {
      return {
        backgroundColor: index % 2 === 0 ? "rgba(35,115,216,.03)" : "transparent",
        height: 20,
        overflow: "hidden",
      };
    },
    // filtering: "true",
    headerStyle: {
      fontWeight: "bold",
      // backgroundColor: "#011627",
      // color: "#FFF",
    },
  });
  const tableRef = useRef(null);

  useEffect(() => {
    if (data.length > 0 && tableOptions.paging) {
      setRows(data);
    } else if (data.length > 0) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    if (props.backendPagination && currentRowCount === 1 && currentCount > 10) {
      window.location.reload(false);
      return;
    }
    if (props.backendPagination) refreshData();
  }, [props.refresh, props.filterString]);

  const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

  const queryData = async (refresh, newPage = 0, search, sort = null, by = null) => {
    const companyId = props.companyId || null;
    const pageLimit = tableOptions.pageSize;
    let countResult = null;
    let dataResult = null;

    if (companyId) {
      countResult = await props.fetchCount(props.companyId, search);
      dataResult = await props.paginationCall(
        newPage,
        pageLimit,
        refresh,
        companyId,
        search,
        sort,
        by,
        props.filterString
      );
    } else {
      countResult = await props.fetchCount(search);
      dataResult = await props.paginationCall(newPage, pageLimit, refresh, search, sort, by, props.filterString);
    }

    setCurrentRowCount(
      dataResult.response.entities[props.entity] ? Object.values(dataResult.response.entities[props.entity]).length : 0
    );
    setCurrentCount(countResult.response.entities.counts[props.entity].count);
    return {
      data: dataResult.response.entities[props.entity] ? Object.values(dataResult.response.entities[props.entity]) : [],
      totalCount: countResult.response.entities.counts[props.entity].count,
    };
  };

  let actions = {};
  if (props.isDeletable) {
    actions.isDeletable = props.isDeletable;
  }
  if (props.addable) {
    actions["onRowAdd"] = async (newData) => {
      await props.addFunction(newData);
      if (props.backendPagination) refreshData();
    };
  }
  if (props.editable) {
    actions["onRowUpdate"] = async (newData, oldData) => {
      await props.editFunction(newData, oldData);
      if (props.backendPagination) refreshData();
    };
  }
  if (props.deletable) {
    actions["onRowDelete"] = async (oldData) => {
      const response = await props.deleteFunction(oldData);
      if (props.backendPagination) refreshData();
    };
  }

  return !!props.rowClickFunction ? (
    <Box>
      <ReactMaterialTable
        draggable={props.draggable}
        tableRef={tableRef}
        parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
        style={props.styles}
        title={props.title}
        editable={actions}
        columns={props.columns.map((col) => {
          return {
            ...col,
            headerStyle: col.headerStyle ? { ...col.headerStyle, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" },
            rowStyle: !!props.rowClickFunction ? {} : { cursor: "none" },
          };
        })}
        data={
          props.backendPagination
            ? (query) =>
                new Promise((resolve, reject) => {
                  const sort = query.orderDirection;
                  const by = query && query.orderBy && query.orderBy.field ? query.orderBy.field : null;
                  queryData(false, query.page, query.search, sort, by).then((result) => {
                    resolve({
                      ...result,
                      page: query.page,
                    });
                  });
                })
            : rows
        }
        onChangeRowsPerPage={(number) => {
          setTableOptions({ ...tableOptions, pageSize: number });
          if (props.backendPagination) refreshData();
        }}
        components={{ OverlayLoading: () => <LinearProgress style={{ marginTop: 120 }} color="secondary" /> }}
        options={tableOptions}
        icons={props.icons}
        detailPanel={props.detailPanel}
        actions={props.customActions}
        onSelectionChange={props.handleSelectionChange}
        onRowClick={props.panelOpen ? props.onRowClick : (e, rowData) => props.rowClickFunction(rowData)}
        localization={props.localization}
      />
    </Box>
  ) : (
    <Box>
      <ReactMaterialTable
        draggable={props.draggable}
        tableRef={tableRef}
        style={props.styles}
        title={props.title}
        editable={actions}
        columns={props.columns.map((col) => {
          return {
            ...col,
            headerStyle: col.headerStyle ? { ...col.headerStyle, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" },
            rowStyle: !!props.rowClickFunction ? {} : { cursor: "none" },
          };
        })}
        data={
          props.backendPagination
            ? (query) =>
                new Promise((resolve, reject) => {
                  const sort = query.orderDirection;
                  const by = query && query.orderBy && query.orderBy.field ? query.orderBy.field : null;
                  queryData(false, query.page, query.search, sort, by).then((result) => {
                    resolve({
                      ...result,
                      page: query.page,
                    });
                  });
                })
            : rows
        }
        onChangeRowsPerPage={(number) => {
          setTableOptions({ ...tableOptions, pageSize: number });
          if (props.backendPagination) refreshData();
        }}
        components={{ OverlayLoading: () => <LinearProgress style={{ marginTop: 120 }} color="secondary" /> }}
        options={tableOptions}
        icons={props.icons}
        detailPanel={props.detailPanel}
        actions={props.customActions}
        onSelectionChange={props.handleSelectionChange}
        localization={props.localization}
      />
    </Box>
  );
};

export default MaterialTable;
