import React, { useState, useEffect } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ProjectOverview(props) {
  const classes = useStyles();

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.area}>
      <Box className={classes.titles}>Project Overview</Box>
      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={50} />
            ) : (
              <Autocomplete
                style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                size="small"
                options={["Yes", "No"]}
                value={props.purchaseOrderReq}
                getOptionLabel={(option) => option}
                onChange={(e, newValue) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setPurchaseOrderReq(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} label="Purchase Order Required" required />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={50} />
            ) : (
              <Autocomplete
                style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                size="small"
                options={["Net 30", "Net 45"]}
                value={props.billingTerms}
                getOptionLabel={(option) => option}
                onChange={(e, newValue) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setBillingTerms(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} label="Billing Terms" required />
                )}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={80} />
            ) : (
              <TextField
                value={props.currentState}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setCurrentState(e.target.value);
                }}
                size="small"
                label="Current State"
                rows={2}
                multiline
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: 10 }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={80} />
            ) : (
              <TextField
                value={props.futureState}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setFutureState(e.target.value);
                }}
                size="small"
                label="Future State"
                rows={2}
                multiline
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: 10 }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={80} />
            ) : (
              <TextField
                value={props.businessDrivers}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setBusinessDrivers(e.target.value);
                }}
                size="small"
                label="Business Drivers"
                rows={2}
                multiline
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: 10 }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={80} />
            ) : (
              <TextField
                value={props.projectDescription}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setProjectDescription(e.target.value);
                }}
                label="Project Description"
                size="small"
                rows={2}
                multiline
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: 10 }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={50} />
            ) : (
              <Autocomplete
                style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                size="small"
                options={["Yes", "No"]}
                value={props.travel}
                getOptionLabel={(option) => option}
                onChange={(e, newValue) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setTravel(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginTop: 20 }}
                    label="Travel Required"
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={80} />
            ) : (
              <TextField
                value={props.timeframe}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setTimeframe(e.target.value);
                }}
                label="Timeframe Requested by Client"
                size="small"
                rows={2}
                multiline
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginTop: 10 }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={50} />
            ) : (
              <Autocomplete
                style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                size="small"
                options={props.resources || []}
                value={props.salesRep}
                getOptionLabel={(option) => (option ? option.firstName + " " + option.lastName : "")}
                onChange={(e, newValue) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setSalesRep(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginTop: 20 }}
                    label="Sales Representative"
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={50} />
            ) : (
              <Autocomplete
                style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                size="small"
                options={props.resources || []}
                value={props.salesEngineer}
                getOptionLabel={(option) => (option ? option.firstName + " " + option.lastName : option.firstName)}
                onChange={(e, newValue) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setSalesEngineer(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginTop: 20 }}
                    label="Sales Engineer"
                    required
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
