import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { useWindowResize } from "../../utils/hooks/useWindowResize";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: 5,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
    color: "white",
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: "white",
  },
}));

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const { width, height } = useWindowResize();

  return (
    <Box className={props.className || ""} width="100%" display="flex" alignItems="center" justifyContent="center">
      <div className={classes.root}>
        {props.loading ? (
          <SkeletonLoader height={60} width={width > 100 ? width * 0.8333 : width * 0.77}>
            <rect
              x="0"
              y="0"
              rx="10"
              ry="10"
              width={parseInt(width > 100 ? width * 0.8333 : width * 0.77)}
              height="60"
            />
          </SkeletonLoader>
        ) : (
          <Accordion
            style={{ width: "100%" }}
            expanded={props.expanded}
            onChange={() => props.handleChange(props.title)}
            TransitionProps={{
              unmountOnExit: !!props.unmountOnExit,
            }}
          >
            <AccordionSummary
              expandIcon={
                props.error && props.expanded !== props.title ? (
                  <i className="fal fa-exclamation-circle" style={{ color: "#FFCD37" }} />
                ) : !props.expanded && props.succeeded ? (
                  <i className="fal fa-check-circle" style={{ color: "#fff" }} />
                ) : (
                  <i className="fal fa-plus-hexagon" style={{ color: "white" }} />
                )
              }
              style={{
                background: props.succeeded
                  ? "#0E8C64"
                  : props.expanded === props.title
                  ? "#122F54"
                  : "rgba(18,47,84,.5)",
                borderRadius: 5,
              }}
            >
              <Typography className={classes.heading}>{props.title}</Typography>
              {/* <Typography className={classes.secondaryHeading}>{props.description}</Typography> */}
            </AccordionSummary>
            <AccordionDetails style={{ background: "rgba(1,22,39,.02)" }}>{props.children}</AccordionDetails>
          </Accordion>
        )}
      </div>
    </Box>
  );
}
