import React, { useState } from "react";
import {
  Grid,
  Box,
  Checkbox,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  TextField,
  Popover,
  Tooltip,
  Badge,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import TimeEntryItem from "./TimeEntryItem";
import TimeEntryHeader from "./TimeEntryHeader";
import BaseButton from "../../../components/BaseButton/BaseButton";
import hexOpacity from "hex-opacity";

const AccordionSummaryC = withStyles({
  content: {
    overflow: "hidden",
    margin: 0,
  },
  expandIcon: {
    padding: 0,
  },
  root: {
    minHeight: 35,
  },
})(MuiAccordionSummary);

export default function WorkElementItem(props) {
  const targetTimeEntries = (props.timeEntries && props.timeEntries[props.workElement.workElementId]) || [];
  
  return (
    <Box display="flex" style={{ width: "100%" }}>
      <Accordion
        elevation={0}
        square
        style={{
          // backgroundColor:
          //   props.number % 2 === 0 ? hexOpacity.create(props.color, 0.1) : hexOpacity.create(props.color, 0.05),
          width: "100%",
          height: "100%",
        }}
      >
        <AccordionSummaryC
          expandIcon={<i className="fas fa-chevron-down" style={{ color: "black", padding: 2 }} />}
          onClick={(event) => event.stopPropagation()}
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          {!props.oldProject && (
            <Box>
              <Tooltip title={`Add time toward ${props.workElement.workElement.name}`}>
                <BaseButton
                  text="Add Time"
                  skinnyBlue
                  clickHandler={(e) => {
                    e.stopPropagation();
                    props.preFillAddTimeDialog(props.workElement.workElementId, props.workElement.companyProjectId);
                  }}
                />
              </Tooltip>
            </Box>
          )}
          <Box ml={2}>
            {props.workElement && (
              <Box>
                <b>{props.workElement.workElement.name}</b>{" "}
                {`(${targetTimeEntries.reduce((acc, val) => {
                  return acc + val.hours;
                }, 0)} hours)`}
              </Box>
            )}
          </Box>
        </AccordionSummaryC>
        <AccordionDetails
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            padding: 0,
            borderRadius: 5,
            border: "1px solid black",
          }}
        >
          {targetTimeEntries.length > 0 ? (
            <React.Fragment>
              <TimeEntryHeader oldProject={props.oldProject} />
              <Box style={{ height: 75, overflow: "auto", resize: "vertical" }}>
                {targetTimeEntries.map((item, index) => {
                  return (
                    <TimeEntryItem
                    setStartRefresh={props.setStartRefresh}
                      setCalculateTimeLoad={props.setCalculateTimeLoad}
                      loadingText={props.loadingText}
                      setLoadingText={props.setLoadingText}
                      key={item.id}
                      oldProject={props.oldProject}
                      item={item}
                      index={index}
                      {...props}
                      workElement={props.workElement}
                    />
                  );
                })}
              </Box>
            </React.Fragment>
          ) : (
            <Box
              height="50px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "rgba(235,160,36,.2)" }}
            >
              No Time Entries
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
