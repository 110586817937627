import React, { useEffect, useState, useLayoutEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  subMonths,
  isSameMonth,
  isSameDay,
  parse,
  addDays,
  require,
} from "date-fns";
import { Tooltip, IconButton, Box, Backdrop, CircularProgress } from "@material-ui/core";
import BaseButton from "../../components/BaseButton/BaseButton";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles((theme, props) => ({
  tButton: {
    borderRadius: 50,
    color: "white",
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: 1,
    textTransform: "none",
    letterSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "nowrap",
    direction: "ltr",
    WebkitFontSmoothing: "antialiased",
    textRendering: "optimizeLegibility",
    MozOsxFontSmoothing: "grayscale",
    fontFeatureSettings: "liga",
  },
  root: {
    MainColor: "#1a8fff",
    TextColor: "#777",
    TextColorLight: "#ccc",
    BorderColor: "#eee",
    BgColor: "#f9f9f9",
    NeutralColor: "#fff",
  },
  body: {
    fontSize: "1em",
    fontWeight: 300,
    lineHeight: 1.5,
    color: "#000",
    background: "#fff",
    position: "relative",
  },
  header: {
    display: "block",
    width: "100%",
    padding: "1.75em 0",
  },
  main: {
    display: "block",
    margin: "0 auto",
    marginTop: "5em",
    maxWidth: "50em",
  },
  row: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  rowMiddle: {
    alignItems: "center",
  },
  col: {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: "100%",
  },
  colStart: {
    justifyContent: "flex-end",
    textAlign: "right",
    display: "flex",
  },
  colCenter: {
    justifyContent: "center",
    textAlign: "center",
  },
  colEnd: {
    justifyContent: "flex-start",
    textAlign: "left",
    display: "flex",
  },
  calendar: {
    display: "block",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: "white",
    borderRadius: 20,
  },
  disabled: {
    color: "#C1DFF8",
  },
  calDateHeight: {
    height: 65,
    [theme.breakpoints.up("xl")]: {
      height: 130,
    },
  },
  cell: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    fontWeight: "bold",
    minHeight: 100,
    [theme.breakpoints.up("xl")]: {
      minHeight: 165,
    },
    height: "100%",
    cursor: "pointer",
    border: "2px solid #F0F4F7",
    transition: "0.5s ease-in-out",
    "&:hover": {
      backgroundColor: "#F0F4F7",
      transition: "0.1s ease-in-out",
    },
    "&:active": {
      backgroundColor: "rgba(240,244,247,.5)",
    },
  },
  selected: {
    border: "3px solid #088FE5",
    borderRadius: 5,
  },
  renderDays: {
    borderRadius: 20,
  },
  topContainer: {
    borderRadius: "20px 20px 0 0",
  },
  lineItem: {
    whiteSpace: "nowrap",
    height: 16,
    position: "relative",
    fontSize: "0.7rem",
    color: "white",
    overflow: "hidden",
    transition: "0.4s ease-in-out",
    "&:hover": {
      background: "#011627",
      transition: "0.1s ease-in-out",
    },
    "&:active": {
      background: "rgba(1,22,39,.7)",
    },
  },

  bottom: {
    color: "rgba(255,255,255,0.5)",
  },
  top: {
    color: "#fff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

const TimeCalendar = (props) => {
  const classes = useStyles();
  const [tip, setTip] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarData, setCalendarData] = useState({});
  const [boxWidth, setBoxWidth] = useState("");
  const today = new Date();
  const [weeklyHours, setWeeklyHours] = useState("");
  const [invalidTimeEntries, setInvalidTimeEntries] = useState([]);

  useEffect(() => {
    props.getWeeklyHours(props.userId).then((result) => {
      const r = result.response.entities.counts.weeklyHours.count;

      setWeeklyHours(r.length > 1 ? r[0].split(":") : "");
      setInvalidTimeEntries(r.length > 1 ? r[1] : []);
    });
  }, [props.refresh]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const size = useWindowSize();

  useEffect(() => {}, [boxWidth]);

  useEffect(() => {
    boxID();
  }, [size[0]]);

  function boxID() {
    const elmnt = document.getElementById("boxId");
    setBoxWidth(elmnt.offsetWidth);
  }

  const SpecialBox = withStyles({
    root: {
      position: "absolute",
      paddingLeft: 3,
      paddingRight: 8,
      whiteSpace: "nowrap",
      transform: "translateX(0)",
      transition: "4s",
      "&:hover": {
        transform: `translateX(calc(${boxWidth}px - 100%))`,
      },
    },
  })(Box);

  useEffect(() => {
    fetchCalendarData();
  }, [props.to, props.from, props.refresh]);

  const fetchCalendarData = async () => {
    const result = await props.getCalendarTimeEntries(
      props.userId,
      new Date(props.from).getTime(),
      new Date(props.to).getTime()
    );
    setCalendarData(result.response.entities.calendarTimeEntries.calendarTimeEntries);
    props.setChangeCalendar(false);
  };

  const dismissTip = () => {
    setTip(false);
  };

  const onDateClick = (day) => {
    props.setTimeEntered(day);
    // setSelectedDate(day);
  };

  const nextMonth = () => {
    setCalendarData({});
    const newMonth = addMonths(currentMonth, 1);
    props.setFrom(new Date(newMonth.getFullYear(), newMonth.getMonth(), 1));
    props.setTo(new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 1));
    setCurrentMonth(newMonth);
  };

  const prevMonth = () => {
    setCalendarData({});
    const newMonth = subMonths(currentMonth, 1);
    props.setFrom(new Date(newMonth.getFullYear(), newMonth.getMonth(), 1));
    props.setTo(new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 1));
    setCurrentMonth(newMonth);
  };

  function renderHeader() {
    const dateFormat = "MMMM yyyy";
    return (
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        style={{ height: 50 }}
        className={`${classes.header} ${classes.row} ${classes.flexMiddle}`}
      >
        <div className={`${classes.col} ${classes.colStart}`}>
          <div className={`${classes.icon}`}>
            <IconButton style={{ color: "white" }} onClick={() => prevMonth()}>
              <i className="fad fa-chevron-circle-left" />
            </IconButton>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colCenter}`}>
          <span style={{ color: "white", fontWeight: "bold" }}>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className={`${classes.col} ${classes.colEnd}`}>
          <div className={`${classes.icon}`}>
            <IconButton style={{ color: "white" }} onClick={() => nextMonth()}>
              <i className="fad fa-chevron-circle-right" />
            </IconButton>
          </div>
        </div>
        {/*{props.kickoffProjectFlag ? (*/}
        {/*  <Box position="absolute" style={{ right: "0px" }}>*/}
        {/*    <IconButton*/}
        {/*      style={{ color: "white" }}*/}
        {/*      onClick={() => {*/}
        {/*        props.setAddTime(true);*/}
        {/*        props.setCompanyProjectId("PROJECT");*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <i className="fal fa-plus-circle" />*/}
        {/*    </IconButton>*/}
        {/*  </Box>*/}
        {/*) : null}*/}
      </Box>
    );
  }

  function renderDays() {
    const dateFormat = "E";
    const days = [];

    let startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <Box style={{ color: "white", fontWeight: "bold" }} className={`${classes.col} ${classes.colCenter}`} key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </Box>
      );
    }

    return (
      <Box
        className={`${classes.days} ${classes.row} ${classes.renderDays}`}
        style={{ height: 50, alignItems: "center", display: "flex" }}
      >
        {days}
      </Box>
    );
  }

  function renderCells() {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];
    const data = ["Architecture Assessment (MS)", "Big Big Project", "Same Big Project"];

    let days = [];

    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <Box
            id="boxId"
            className={`${classes.col} ${classes.cell} ${
              !isSameMonth(day, monthStart) ? classes.disabled : isSameDay(day, selectedDate) ? classes.selected : ""
            }`}
            key={day}
            onClick={() => {
              props.setAddTime(true);
              onDateClick(cloneDay);
            }}
          >
            <Box display="flex" width="100%" justifyContent="space-between">
              <Box ml={1}>
                <span>{formattedDate}</span>
              </Box>
              {isSameDay(day, selectedDate) ? <Box mr={1}>Today</Box> : null}
            </Box>

            <Box mt={1} overflow="auto" className={classes.calDateHeight}>
              {isSameMonth(day, monthStart) &&
                calendarData.hasOwnProperty(formattedDate) &&
                calendarData[formattedDate].map((d, i) => {
                  return (
                    <Box key={i} mb={0.2} style={{ background: d.color }} className={classes.lineItem}>
                      <SpecialBox
                        classes={{
                          root: classes.root,
                        }}
                      >
                        {d.hours ? d.hours : 0} hours - {d.name}
                      </SpecialBox>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Box className={`${classes.row}`} key={day}>
          {days}
        </Box>
      );
      days = [];
    }
    return (
      <Box style={{ borderRadius: 20 }} className={`${classes.body}`}>
        {rows}
      </Box>
    );
  }

  return (
    <Box className={`${classes.calendar}`}>
      <Box style={{ background: props.background, position: "relative" }} className={classes.topContainer}>
        {!props.drawerOpen && (
          <Box style={{ whiteSpace: "nowrap", top: 5, left: 20, zIndex: 1, color: "white", position: "absolute" }}>
            {weeklyHours.length > 0 ? (
              <React.Fragment>
                <span style={{ fontSize: "0.8rem" }}>{weeklyHours[0]}:</span>
                <br />
                <span>{weeklyHours[1]}:</span>
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  {weeklyHours[2] && weeklyHours[2].split("/")[0]}
                </span>

                <span>/ {weeklyHours[2].split("/")[1]}</span>
                {invalidTimeEntries.length > 0 ? (
                  <Tooltip
                    title={`You have not entered a full 8 hours for the following days: ${invalidTimeEntries.join(
                      ", "
                    )}`}
                  >
                    <span>
                      <i className={"fad fa-exclamation-triangle"} style={{ color: "#EB6B6C", marginLeft: "1rem" }} />
                    </span>
                  </Tooltip>
                ) : null}
              </React.Fragment>
            ) : (
              ""
            )}
          </Box>
        )}
        {renderHeader()}
        {renderDays()}
        {!props.drawerOpen && (
          <Tooltip title="Open Projects">
            <Box position="absolute" style={{ top: 5, right: 60, zIndex: 1 }}>
              <IconButton
                onClick={async () => {
                  await props.setDrawerOpen(true);
                  await props.updateUser(props.user.id, { showTimeEntryProjects: !props.drawerOpen });
                  await props.getAdminProfile();
                }}
              >
                <i className="fad fa-tasks-alt" style={{ color: "#fff" }} />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        <Tooltip title="Switch to week view">
          <Box position="absolute" style={{ top: 5, right: 10, zIndex: 1 }}>
            <IconButton
              onClick={async () => {
                props.setChangeCalendar(true);
                await props.updateTimeEntryView("week");
                props.getAdminProfile();
                props.setChangeCalendar(false);
              }}
            >
              <i className="fad fa-calendar" style={{ color: "#fff" }} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Box
        height="65vh"
        overflow="auto"
        width="100%"
        style={{
          position: "relative",
          filter: tip ? "blur(1px) brightness(40%) grayscale(100%)" : null,
          borderRadius: "0 0 20px 20px",
        }}
      >
        <Backdrop open={props.changeCalendar} style={{ position: "absolute", zIndex: 1 }}>
          <i className="fas fa-clock fa-spin fa-3x" style={{ color: "#fff" }} />
        </Backdrop>
        {renderCells()}
      </Box>

      {tip ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          position="absolute"
          style={{
            top: "50%",
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
            borderRadius: !tip ? "0 0 20px 0" : null,
          }}
        >
          <Box mb={2}>Click on a date to start a project / task</Box>
          <BaseButton noShadow skinnyBlue text="Dismiss hint" clickHandler={() => dismissTip()} />
        </Box>
      ) : null}
    </Box>
  );
};

export default TimeCalendar;
