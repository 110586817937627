import { fontType } from "../../../src/utils/themes/settings.js";

const navItems = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: `${fontType} fa-chart-line`,
  },
  {
    name: "Organizations",
    url: "/organizations",
    icon: `${fontType} fa-building`,
  },
  // {
  //   name: "Projects",
  //   url: "/projects",
  //   icon: `${fontType} fa-wrench`,
  // },
  {
    name: "Roles",
    url: "/roles",
    icon: `${fontType} fa-user-tag`,
  },
  // {
  //   name: "Characteristics",
  //   url: "/characteristics",
  //   icon: `${fontType} fa-layer-group`,
  // },
  {
    name: "Metadata",
    url: "/metadata",
    icon: `${fontType} fa-server`,
  },
  // {
  //   name: "Frameworks",
  //   url: "/frameworks",
  //   icon: `${fontType} fa-box-open`,
  // },
  {
    name: "HubSpot",
    url: "/hubSpot",
    icon: `${fontType} fa-plug`,
  },
];

export default navItems;
