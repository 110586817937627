import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, TextField, Tooltip, Popover, Button } from "@material-ui/core";
import { useStyles } from "../styles/ProjectAndTasks.styles";
import Warning from "../../../components/Dialog/WarningDialog";
import AsyncAutoComplete from "./AsyncAutoComplete";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ToggleButton } from "@material-ui/lab";

export default function TimeEntryItem(props) {
  const classes = useStyles();
  const [deleted, setDeleted] = useState(false);
  const [updatedNote, setUpdatedNote] = useState(props.item.description);
  const [anchorEl, setAnchorEl] = useState(null);
  const [taskAnchorEl, setTaskAnchorEl] = useState(null);
  const [task, setTask] = useState(props.item.timeEntryTask);
  const [timeEntryTask, setTimeEntryTask] = useState(props.item.timeEntryTask || null);
  const [hours, setHours] = useState(props.item.hours);
  const [deleteTimeEntryAnchorEl, setDeleteTimeEntryAnchorEl] = useState(null);
  const [timeEntryId, setTimeEntryId] = useState("");
  const [internalCategory, setInternalCategory] = useState(props.item.workElement);
  const [checked, setChecked] = useState(props.item.internal);
  const [loading, setLoading] = useState(false);
  const [editTimeInd, setEditTimeInd] = useState(false);
  const [selectedDate, handleDateChange] = useState(props.item.timeEntered);
  const [isOpen, setIsOpen] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [editCategory, setEditCategory] = useState(false);

  useEffect(() => {
    updateDate();
  }, [selectedDate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeTask = (task) => {
    let currentData = props.workElements;
    let target = currentData.find((we) => we.id === props.workElement.id);
    target.taskId = task.id;
    props.setWorkElements([...currentData]);
  };

  const addNewTask = async (task = null) => {
    const payload = {
      timeEntryTask: {
        name: task,
      },
      workElementIds: [props.workElement.workElementId],
    };
    const result = await props.createTimeEntryTask(payload);
    setTimeEntryTask(Object.values(result.response.entities.timeEntryTasks)[0]);
  };

  const addNewInternalTask = async (task = null) => {
    const payload = {
      timeEntryTask: {
        name: task,
      },
      workElementIds: [internalCategory.id],
    };
    const result = await props.createTimeEntryTask(payload);
    setTimeEntryTask(Object.values(result.response.entities.timeEntryTasks)[0]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const updateNote = async () => {
    await props.updateTimeEntry(props.item.id, { description: updatedNote });
    await props.setRefresh(true);
    setAnchorEl(false);
  };

  const cancelUpdateNote = () => {
    setUpdatedNote(props.item.description);
  };

  const updateTimeEntry = async () => {
    try {
      props.setLoadingText(true);
      if (props.internal) {
        const timeEntryTaskId =
          !internalCategory ||
          !props.item.workElement ||
          (props.item.workElement && props.item.workElement.id !== internalCategory.id)
            ? null
            : (timeEntryTask && timeEntryTask.id) || null;

        await props.updateTimeEntry(props.item.id, {
          hours,
          workElementId: (internalCategory && internalCategory.id) || "",
          timeEntryTaskId,
        });
        await props.setRefresh(true);
      }
      await props.updateTimeEntry(props.item.id, { hours, timeEntryTaskId: (timeEntryTask && timeEntryTask.id) || "" });
      await props.setStartRefresh(true);
      await props.setRefresh(true);
      setEditTimeInd(false);
      setEditTask(false);
      setEditCategory(false);
      props.setLoadingText(false);
    } catch (e) {}
  };

  const updateDate = async () => {
    props.setStartRefresh(true);
    await props.updateTimeEntry(props.item.id, { timeEntered: new Date(selectedDate).getTime() });
    props.setStartRefresh(false);
    props.setRefresh(true);
  };

  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  return (
    <Grid
      key={props.index}
      alignItems="center"
      container
      className={` ${props.index % 2 === 0 ? classes.light : classes.dark}`}
    >
      <Warning
        delete
        loading={false}
        anchorEl={deleteTimeEntryAnchorEl}
        text={`Are you sure you want to delete this time entry?`}
        confirm={() => {
          props.deleteTimeEntry(props.item.id);
          setDeleted(true);
          setDeleteTimeEntryAnchorEl(null);
        }}
        close={() => {
          setDeleteTimeEntryAnchorEl(null);
          setTimeEntryId("");
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={3} display="flex" flexDirection="column" width="300px" alignItems="center" justifyContent="center">
          <TextField
            rows={4}
            multiline
            size="small"
            value={updatedNote}
            onChange={(e) => setUpdatedNote(e.target.value)}
            label="Memo"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Button
            onClick={() => updateNote()}
            style={{ width: "100%", marginTop: 10, color: "black", fontWeight: "bold" }}
          >
            Update
          </Button>
        </Box>
      </Popover>

      <Popover
        id={id}
        open={Boolean(taskAnchorEl)}
        anchorEl={taskAnchorEl}
        onClose={() => setTaskAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={3} display="flex" flexDirection="column" width="300px" alignItems="center" justifyContent="center">
          <TextField
            size="small"
            value={task}
            onChange={(e) => setTask(e.target.value)}
            label="New Task"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Button
            onClick={() => addNewTask()}
            style={{ width: "100%", marginTop: 10, color: "black", fontWeight: "bold" }}
          >
            Update
          </Button>
        </Box>
      </Popover>
      {!props.oldProject && !editTask && (
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center">
            <Tooltip title={props.item && props.item.description ? "Edit Memo" : "Add Memo"}>
              <IconButton style={{ padding: 5, fontSize: "1rem" }} disabled={deleted} onClick={handleClick}>
                <i
                  className="fad fa-sticky-note"
                  style={{
                    color: deleted ? "gray" : props.item && props.item.description ? "#eba024" : "rgba(235,160,36,.3)",
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                style={{ padding: 5, fontSize: "1rem" }}
                disabled={deleted}
                onClick={(e) => {
                  setDeleteTimeEntryAnchorEl(e.currentTarget);
                }}
              >
                <i className="fad fa-trash" style={{ color: deleted ? "gray" : "#EB6B6C" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      )}

      <React.Fragment>
        {!editTask && (
          <React.Fragment>
            <Grid item xs={2}>
              <Box display="none">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    animateYearScrolling
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    format="d MMM yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Box>

              <Tooltip title="Double click to edit">
                <Box
                  onDoubleClick={() => setIsOpen(true)}
                  className={`${deleted ? classes.disabledItem : classes.hoverItem}`}
                  style={{
                    textAlign: "center",
                    marginRight: 10,
                    textDecoration: deleted ? "line-through" : "none",
                  }}
                >
                  {(props.item && formatDate(new Date(props.item.timeEntered).toDateString())) || "No Date Entered"}
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={editTimeInd ? 3 : 2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                style={{
                  textAlign: "center",
                  marginRight: !editTimeInd && 10,
                  textDecoration: deleted ? "line-through" : "none",
                }}
              >
                {editTimeInd ? (
                  <React.Fragment>
                    <Box pr={1} pl={1} display="flex" flexDirection="column">
                      <Tooltip title="Save Time Entry">
                        <IconButton style={{ padding: 5, fontSize: "1rem" }} onClick={() => updateTimeEntry()}>
                          <i className="fad fa-check-circle" style={{ color: "#0E8C64" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton style={{ padding: 5, fontSize: "1rem" }} onClick={() => setEditTimeInd(false)}>
                          <i className="fad fa-times-circle" style={{ color: "#EB6B6C" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <TextField
                      style={{ marginRight: 10 }}
                      size="small"
                      margin="dense"
                      type="number"
                      value={hours}
                      onChange={(e) => setHours(parseFloat(e.target.value))}
                      label="Hours"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </React.Fragment>
                ) : (
                  <Tooltip title="Double click to edit">
                    <Box
                      style={{ minWidth: 50, textDecoration: deleted ? "line-through" : "none" }}
                      onDoubleClick={() => setEditTimeInd(true)}
                      className={`${deleted ? classes.disabledItem : classes.hoverItem}`}
                    >
                      {props.item.hours}
                    </Box>
                  </Tooltip>
                )}
                {props.item.hasOwnProperty("companyProjectId")
                  ? !props.oldProject &&
                    !editTimeInd && (
                      <Box position="absolute" style={{ right: 5 }}>
                        <Tooltip title={checked ? "Change to Billable" : "Change to Non-Billable"}>
                          <ToggleButton
                            selected={checked}
                            disabled={props.refresh}
                            onChange={async () => {
                              setLoading(true);
                              props.setCalculateTimeLoad(true);
                              await props.updateTimeEntry(props.item.id, { internal: !props.item.internal });
                              await props.setRefresh(true);
                              setChecked(!checked);
                              setLoading(false);
                              props.setCalculateTimeLoad(false);
                            }}
                            style={{
                              marginLeft: 4,
                              fontSize: "0.8rem",
                              padding: 2,
                              color: props.refresh ? "gray" : checked ? "#2373D8" : "#0E8C64",
                            }}
                          >
                            <i
                              className={
                                loading ? "fas fa-cog fa-spin" : checked ? "fas fa-house-user" : "fas fa-business-time"
                              }
                            />
                          </ToggleButton>
                        </Tooltip>
                      </Box>
                    )
                  : null}
              </Box>
            </Grid>
          </React.Fragment>
        )}
        {props.internal ? (
          <React.Fragment>
            <Grid item xs={editTask ? 12 : editTimeInd ? 5 : 6} style={{ paddingRight: 10 }}>
              <Box display="flex" alignItems="center" justifyContent="center" style={{ width: "100%" }}>
                {editTask ? (
                  <React.Fragment>
                    <Box pr={1} pl={1} display="flex" flexDirection="column">
                      <Tooltip title="Save Time Entry">
                        <IconButton style={{ padding: 5, fontSize: "1rem" }} onClick={() => updateTimeEntry()}>
                          <i className="fad fa-check-circle" style={{ color: "#0E8C64" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <IconButton style={{ padding: 5, fontSize: "1rem" }} onClick={() => setEditTask(false)}>
                          <i className="fad fa-times-circle" style={{ color: "#EB6B6C" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box width="100%" pr={1}>
                      <AsyncAutoComplete
                        fetchData={async () => await props.getWorkElementsList(true)}
                        value={internalCategory}
                        target="workElements"
                        label="Select Internal Category"
                        required
                        handleChange={(value) => {
                          setInternalCategory(value);
                          setTimeEntryTask(null);
                        }}
                      />
                    </Box>
                    <Box width="100%">
                      <AsyncAutoComplete
                        disabled={!internalCategory || !internalCategory.hasOwnProperty("id")}
                        fetchData={async () => {
                          const result = await props.getWorkElementTasks(internalCategory.id);
                          return result;
                        }}
                        creatable
                        createFunc={addNewInternalTask}
                        value={timeEntryTask}
                        // defaultValue={props.item.timeEntryTask}
                        target="workElementTasks"
                        label="Task"
                        handleChange={(value) => setTimeEntryTask(value)}
                      />
                    </Box>
                  </React.Fragment>
                ) : (
                  <Grid container>
                    <Grid item xs={6}>
                      <Tooltip title="Double click to edit">
                        <Box
                          style={{ width: "fit-content", textDecoration: deleted ? "line-through" : "none" }}
                          onDoubleClick={() => setEditTask(true)}
                          className={`${deleted ? classes.disabledItem : classes.hoverItem}`}
                        >
                          {props.internal && props.item.workElement ? props.item.workElement.name : "No Category"}
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip title="Double click to edit">
                        <Box
                          ml={1}
                          style={{ width: "fit-content", textDecoration: deleted ? "line-through" : "none" }}
                          onDoubleClick={() => setEditTask(true)}
                          className={`${deleted ? classes.disabledItem : classes.hoverItem}`}
                        >
                          {(props.item.timeEntryTask && props.item.timeEntryTask.name) || "No Task"}
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          </React.Fragment>
        ) : editTask ? (
          <Grid item xs={editTask ? 12 : 6} style={{ paddingRight: 10 }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box pr={1} pl={1} display="flex" flexDirection="column">
                <Tooltip title="Save Time Entry">
                  <IconButton style={{ padding: 5, fontSize: "1rem" }} onClick={() => updateTimeEntry()}>
                    <i className="fad fa-check-circle" style={{ color: "#0E8C64" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton style={{ padding: 5, fontSize: "1rem" }} onClick={() => setEditTask(false)}>
                    <i className="fad fa-times-circle" style={{ color: "#EB6B6C" }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <AsyncAutoComplete
                fetchData={async () => await props.getWorkElementTasks(props.workElement.workElementId)}
                creatable
                value={timeEntryTask}
                // defaultValue={props.item.timeEntryTask}
                createFunc={addNewTask}
                target="workElementTasks"
                required
                label="Task"
                handleChange={(value) => setTimeEntryTask(value)}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={editTask ? 12 : 6}>
            <Tooltip title="Double click to edit">
              <Box
                ml={1}
                style={{ textDecoration: deleted ? "line-through" : "none", width: "fit-content" }}
                onDoubleClick={() => setEditTask(true)}
                className={`${deleted ? classes.disabledItem : classes.hoverItem}`}
              >
                {(props.item.timeEntryTask && props.item.timeEntryTask.name) || "No Task"}
              </Box>
            </Tooltip>
          </Grid>
        )}
      </React.Fragment>
    </Grid>
  );
}
