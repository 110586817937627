import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Button,
  Select,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  MenuItem,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "../../../components/Snackbar/Snackbar";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { validateTimeEntryPayload } from "../utils/error_helpers";
import { nonBillableList } from "../utils/lists";
import AsyncAutoComplete from "./AsyncAutoComplete";
import WorkElementTimeEntryItem from "./WorkElementTimeEntryItem";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import BaseButton from "../../../components/BaseButton/BaseButton";

const AccordionSummaryC = withStyles({
  content: {
    overflow: "hidden",
  },
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  smallButton: {
    bottom: "-20px",
    minWidth: 80,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    right: 0,
    fontSize: "0.6rem",
    background: "rgba(0,0,0,0.1)",
    padding: "2px 7px",
    transition: ".2s all linear",
    cursor: "pointer",
    borderRadius: 3,
    "&:hover": {
      background: "rgba(0,0,0,0.2)",
      transition: ".2s all linear",
    },
  },
}));

export default function AddTime(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });
  const [value, setValue] = useState("internal");
  const [companyProjectWorkElements, setSetCompanyProjectWorkElements] = useState([]);
  const [internalTimeHours, setInternalTimeHours] = useState(0);
  const [internalTimeCategory, setInternalTimeCategory] = useState("");

  useEffect(() => {
    if (props.companyProjectId === "INTERNAL") {
      setType(30);
      setValue("internal");
    } else if (props.companyProjectId === "PROJECT") {
      setType(20);
      setValue("project");
    }
    setSetCompanyProjectWorkElements([]);
  }, [props.companyProjectId]);

  useEffect(() => {
    if (props.defaultWorkElementId.length > 0) autoFillWorkElement();
  }, [props.companyProjectId, props.defaultWorkElementId]);

  useEffect(() => {
    if (type === 30) {
      setSetCompanyProjectWorkElements([]);
    }
  }, [type]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  const getCompanyProjectWorkElements = async () => {
    return await props.getCompanyProjectWorkElements(props.companyProjectId);
  };

  const autoFillWorkElement = async () => {
    const result = await getCompanyProjectWorkElements();
    const data = result.response.entities.lists.companyProjectWorkElements.list;
    setSetCompanyProjectWorkElements([data.find((row) => row.id === props.defaultWorkElementId)]);
  };

  const postNewTime = async () => {
    try {
      setLoading(true);
      props.setStartRefresh(true);
      if (type === 30 && value === "internal") {
        const payload = {
          userId: props.userId,
          hours: internalTimeHours,
          timeEntered: new Date(props.timeEntered).getTime(),
          internal: true,
          workElementId: (props.internalCategory && props.internalCategory.id) || null,
          timeEntryTaskId: (props.internalTask && props.internalTask.id) || null,
        };

        if (internalTimeHours <= 0) {
          setSnackbar({ isOpen: true, variant: "error", message: "Time entries must be greater than 0" });
          return setLoading(false);
        }

        if (!payload.workElementId) {
          setSnackbar({ isOpen: true, variant: "error", message: "Internal Category is required" });
          return setLoading(false);
        }

        const result = await props.createNewTimeEntry(payload);

        setLoading(false);
        props.setRefresh(true);
        closeDialog();
        return result;
      }

      if (companyProjectWorkElements.length === 0) {
        setSnackbar({ isOpen: true, variant: "error", message: "Please select a company project and work elements" });
        return setLoading(false);
      }

      if (companyProjectWorkElements.some((workElement) => !workElement.hours || workElement.hours <= 0)) {
        setSnackbar({ isOpen: true, variant: "error", message: "Time entries must be greater than 0" });
        return setLoading(false);
      }

      if (companyProjectWorkElements.some((workElement) => !workElement.taskId)) {
        setSnackbar({ isOpen: true, variant: "error", message: "Tasks are required for every time entry" });
        return setLoading(false);
      }

      for await (const workElement of companyProjectWorkElements) {
        const payload = {
          userId: props.userId,
          companyProjectId: type === 30 ? "internal" : props.companyProjectId,
          hours: type === 30 ? internalTimeHours : workElement.hours,
          timeEntered: new Date(props.timeEntered).getTime(),
          description: workElement.notes,
          timeEntryTaskId: workElement.taskId,
          workElementId: workElement.id,
          internal: !!workElement.nonBillable,
        };

        const result = await props.createNewTimeEntry(payload);
      }
      props.setStartRefresh(false);
      setLoading(false);
      props.setRefresh(true);
      closeDialog();
    } catch (err) {}
  };

  const handleDateChange = (date) => {
    props.setTimeEntered(date);
  };

  const closeDialog = () => {
    props.setAddTime(false);
    props.setCompanyProjectId("");
    props.setDefaultWorkElementId("");
    setSetCompanyProjectWorkElements([]);
    setTimeout(() => {
      props.setTimeEntered(new Date());
      setType(10);
    }, 500);
  };

  const addTimeIncrement = (increment) => {
    let t = internalTimeHours;
    t = t + increment / 60;
    if (t < 0) return;

    setInternalTimeHours(t);
  };

  const addNewTask = async (task = null) => {
    const payload = {
      timeEntryTask: {
        name: task,
      },
      workElementIds: [props.internalCategory.id],
    };

    // setCreatingNewTask(true);
    const result = await props.createTimeEntryTask(payload);
    props.setInternalTask(Object.values(result.response.entities.timeEntryTasks)[0]);
    // setCreatingNewTask(false);
    // setAnchorEl(null);
    // setNewTask(null);
    return result;
  };

  return (
    <React.Fragment>
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Dialog
        style={{
          background: "rgba(255,255,255, .9)",
        }}
        open={props.addTime}
        onClose={() => closeDialog()}
        maxWidth="xl"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle style={{ background: "#011627", color: "white" }}>
          {type === 20 ? "NEW PROJECT / TASK" : type === 30 ? "ADD NON-BILLABLE TIME" : "ADD TIME"}
        </DialogTitle>
        <DialogContent
          style={{
            width: 800,
            height: "700px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
            <Box mb={2} display="flex" justifyContent="space-between">
              <FormControl className={classes.formControl} margin="dense" variant="outlined">
                <InputLabel>Select activity</InputLabel>
                <Select fullWidth labelWidth={100} value={type} onChange={handleChange} style={{ color: "#000" }}>
                  <MenuItem value={10} style={{ color: "#000", background: "rgba(58,81,153,0.1)" }}>
                    <i className="fad fa-plus-circle" style={{ color: "#3A5199", marginRight: 5 }} />
                    Add time
                  </MenuItem>
                  <MenuItem value={30} style={{ color: "#000", background: "rgba(235,160,36,.1)" }}>
                    <i className="fad fa-hands-usd" style={{ marginRight: 5, color: "#eba024" }} />
                    CISOSHARE
                  </MenuItem>
                </Select>
              </FormControl>
              {type === 20 ? (
                <Autocomplete
                  style={{ display: "flex", marginBottom: 4, alignItems: "flex-end" }}
                  size="small"
                  options={["MS", "PS"]}
                  value={props.projectType}
                  getOptionLabel={(option) => option}
                  onChange={(e, newValue) => {
                    props.setProjectType(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: 150, borderRadius: 50, marginLeft: 8 }}
                      variant="outlined"
                      label="Service Type"
                      required
                    />
                  )}
                />
              ) : null}
              <Box ml={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    InputAdornmentProps={{ position: "start" }}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="dense"
                    style={{ minWidth: 200 }}
                    value={props.timeEntered}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            {type === 20 ? (
              <Box display="flex" mb={2}>
                <Box mr={0.5} width="100%">
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={props.adminProjects}
                    getOptionLabel={(option) => option.name}
                    value={props.adminProjects.find((project) => project.companyProjectId === props.companyProjectId)}
                    filterSelectedOptions
                    clearOnEscape
                    onChange={(e, newValue) => {
                      props.setCompanyProjectId(newValue && newValue.hasOwnProperty("id") ? newValue.id : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "100%", borderRadius: 50 }}
                        variant="outlined"
                        required
                        label="Select a New Project / Task"
                      />
                    )}
                  />
                </Box>
                <Box
                  ml={0.5}
                  width="100%"
                  position="relative"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ color: "rgba(0,0,0,0.3)" }}
                >
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={props.companies}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    clearOnEscape
                    onChange={(e, newValue) => {
                      props.setCompanyId(newValue && newValue.hasOwnProperty("id") ? newValue.id : "");
                    }}
                    value={props.companies.find((company) => company.id === props.companyId)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "100%", borderRadius: 50 }}
                        variant="outlined"
                        required
                        label="Select an existing client"
                      />
                    )}
                  />

                  <Box
                    position="absolute"
                    onClick={(e) => handleClick(e)}
                    className={classes.smallButton}
                    style={{ l: "#000" }}
                  >
                    Create New Client
                  </Box>
                </Box>
              </Box>
            ) : type === 30 ? (
              <Box mb={2}>
                <Box width="100%">
                  <Box mr={0.5} width="100%">
                    <AsyncAutoComplete
                      fetchData={async () => await props.getWorkElementsList(true)}
                      value={props.internalCategory}
                      target="workElements"
                      label="Select Internal Category"
                      required
                      handleChange={(value) => props.setInternalCategory(value)}
                    />
                  </Box>
                  <Box mr={0.5} width="100%">
                    <AsyncAutoComplete
                      disabled={!props.internalCategory || !props.internalCategory.hasOwnProperty("id")}
                      fetchData={async () => await props.getWorkElementTasks(props.internalCategory.id)}
                      creatable
                      value={props.internalTask}
                      target="workElementTasks"
                      label="Task"
                      add={(e) => setAnchorEl(e.currentTarget)}
                      createFunc={addNewTask}
                      handleChange={(value) => props.setInternalTask(value)}
                    />
                  </Box>
                  {value === "internal" ? (
                    <Box display="flex">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width="100%"
                        height="150px"
                        style={{
                          boxShadow: "0px 11px 15px -7px rgba(0,0,0,.3)",
                          borderRadius: 10,
                          fontWeight: "bold",
                          fontSize: "3rem",
                        }}
                      >
                        {internalTimeHours < 8 && (
                          <Box display="flex">
                            <Box ml={0.5} mr={0.5}>
                              <BaseButton skinnyGreen text="8 Hours" clickHandler={() => setInternalTimeHours(8)} />
                            </Box>
                          </Box>
                        )}
                        <span style={{ paddingBottom: 15 }}>
                          {Math.floor(internalTimeHours)}h{" "}
                          {internalTimeHours % 1 === 0
                            ? "00"
                            : internalTimeHours % 1 === 0.25
                            ? "15"
                            : internalTimeHours % 1 === 0.75
                            ? "45"
                            : "30"}
                          m
                        </span>
                        <Box display="flex" style={{ bottom: 10, left: 10 }}>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton skinnyBlue text="+ 15 mins" clickHandler={() => addTimeIncrement(15)} />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton skinnyBlue text="+ 30 mins" clickHandler={() => addTimeIncrement(30)} />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton skinnyBlue text="+ 60 mins" clickHandler={() => addTimeIncrement(60)} />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton skinnyBlue text="+ 90 mins" clickHandler={() => addTimeIncrement(90)} />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton
                              skinnyPink
                              disabled={internalTimeHours === 0}
                              text="- 15 mins"
                              clickHandler={() => addTimeIncrement(-15)}
                            />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton
                              skinnyPink
                              disabled={internalTimeHours === 0}
                              text="- 30 mins"
                              clickHandler={() => addTimeIncrement(-30)}
                            />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton
                              skinnyPink
                              disabled={internalTimeHours === 0}
                              text="- 60 mins"
                              clickHandler={() => addTimeIncrement(-60)}
                            />
                          </Box>
                          <Box ml={0.5} mr={0.5}>
                            <BaseButton
                              skinnyPink
                              disabled={internalTimeHours === 0}
                              text="- 90 mins"
                              clickHandler={() => addTimeIncrement(-90)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box ml={0.5} width="100%">
                      <Autocomplete
                        fullWidth
                        size="small"
                        options={props.projects.filter((project) => project.name.trim().toLowerCase() !== "internal")}
                        getOptionLabel={(option) => option.name + " | " + `${option.companyName}`}
                        value={props.projects.find(
                          (project) =>
                            project.name.trim().toLowerCase() !== "internal" &&
                            project.companyProjectId === props.companyProjectId
                        )}
                        filterSelectedOptions
                        clearOnEscape
                        onChange={(e, newValue) => {
                          props.setCompanyProjectId(
                            newValue && newValue.hasOwnProperty("companyProjectId") ? newValue.companyProjectId : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%", borderRadius: 50 }}
                            variant="outlined"
                            required
                            label="Select Project"
                          />
                        )}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column">
                <Box mb={2}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={props.projects.filter(
                      (project) => project.name.trim().toLowerCase() !== "internal" && project.archived === false
                    )}
                    getOptionLabel={(option) => option.name + " | " + `${option.companyName}`}
                    value={props.projects.find(
                      (project) =>
                        project.name.trim().toLowerCase() !== "internal" &&
                        project.companyProjectId === props.companyProjectId
                    )}
                    filterSelectedOptions
                    clearOnEscape
                    onChange={(e, newValue) => {
                      if (!newValue || !newValue.hasOwnProperty("companyProjectId")) {
                        props.setDefaultWorkElementId("");
                        setSetCompanyProjectWorkElements([]);
                      }
                      props.setCompanyProjectId(
                        newValue && newValue.hasOwnProperty("companyProjectId") ? newValue.companyProjectId : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        required
                        style={{ width: "100%", borderRadius: 50 }}
                        variant="outlined"
                        label="Project"
                      />
                    )}
                  />
                </Box>
                <AsyncAutoComplete
                  disabled={!props.companyProjectId || props.companyProjectId.length === 0}
                  fetchData={getCompanyProjectWorkElements}
                  value={companyProjectWorkElements}
                  target="companyProjectWorkElements"
                  label="Work Elements"
                  multiple
                  required
                  handleChange={(value) => setSetCompanyProjectWorkElements(value)}
                />
              </Box>
            )}
            <br />
            {type === 10
              ? companyProjectWorkElements.map((workElement, index) => {
                  return (
                    <Accordion
                      key={index}
                      elevation={0}
                      defaultExpanded={index === 0}
                      square
                      style={{ backgroundColor: "#011627", color: "white", marginBottom: 5 }}
                    >
                      <AccordionSummaryC
                        expandIcon={<i className="fas fa-chevron-down" style={{ color: "white" }} />}
                        onClick={(event) => event.stopPropagation()}
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <Box display="flex" alignItems="center">
                          <Box mr={4} fontWeight="bold" fontSize="1rem" style={{ textTransform: "uppercase" }}>
                            {workElement.name}
                          </Box>
                          <Checkbox
                            style={{ color: "#FFCD37", padding: 5 }}
                            onClick={(event) => {
                              event.stopPropagation();
                              let tempWorkElements = companyProjectWorkElements;
                              let targetWorkElement = tempWorkElements.find((we) => we.id === workElement.id);

                              if (!!targetWorkElement) {
                                tempWorkElements.find((we) => we.id === workElement.id).nonBillable =
                                  event.target.checked;
                              }

                              setSetCompanyProjectWorkElements([...tempWorkElements]);
                            }}
                            checked={!!workElement.nonBillable}
                            // onChange={}
                          />
                          <Box fontSize="0.9rem" style={{ color: "#FFCD37" }} pt={"2px"}>
                            Non-Billable
                          </Box>
                        </Box>
                      </AccordionSummaryC>
                      <AccordionDetails style={{ backgroundColor: "white", display: "flex", flexDirection: "column" }}>
                        <WorkElementTimeEntryItem
                          workElements={companyProjectWorkElements}
                          setWorkElements={setSetCompanyProjectWorkElements}
                          workElement={workElement}
                          getWorkElementTasks={props.getWorkElementTasks}
                          createTimeEntryTask={props.createTimeEntryTask}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : null}
          </Box>
        </DialogContent>
        <DialogActions style={{ background: "#011627", color: "white" }}>
          <Button onClick={() => closeDialog()} style={{ color: "white", fontWeight: "bold" }}>
            Cancel
          </Button>
          <Button onClick={() => postNewTime()} style={{ color: "white", fontWeight: "bold" }}>
            {loading ? <i className="fas fa-cog fa-spin" /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
