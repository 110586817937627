import React, { useEffect } from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadLaws,
  fetchLawCount,
  loadRequirementsList,
  loadStatesList,
  loadDataTypesList,
  loadIndustriesList,
  saveLaw,
  updateLawById,
  deleteLawById,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { getColumns } from "../utils/lawColumns";

const Laws = (props) => {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await props.loadRequirementsList();
    await props.loadStatesList();
    await props.loadDataTypesList();
    await props.loadIndustriesList();
  };

  const saveLaw = async (rowData) => {
    try {
      const payload = {
        ...rowData,
        stateIds: rowData.states ? [rowData.states] : [],
        dataTypeIds: rowData.dataTypes ? [rowData.dataTypes] : [],
        industryIds: rowData.industries ? [rowData.industries] : [],
      };
      const response = await props.saveLaw({ ...payload });
      return response;
    } catch (err) {}
  };

  const editLaw = async (rowData, oldData) => {
    try {
      const stateIds =
        rowData.states && Array.isArray(rowData.states)
          ? rowData.states.map((state) => state.stateId)
          : rowData.states
          ? rowData.states.split(",")
          : [];

      const industryIds =
        rowData.industries && Array.isArray(rowData.industries)
          ? rowData.industries.map((industry) => industry.industryId)
          : rowData.industries
          ? rowData.industries.split(",")
          : [];

      const dataTypeIds =
        rowData.dataTypes && Array.isArray(rowData.dataTypes)
          ? rowData.dataTypes.map((dataType) => dataType.dataTypeId)
          : rowData.dataTypes
          ? rowData.dataTypes.split(",")
          : [];

      const payload = {
        ...rowData,
        stateIds,
        dataTypeIds,
        industryIds,
      };

      const response = await props.updateLawById(rowData.id, { ...payload });
      return response;
    } catch (err) {}
  };

  const deleteLaw = async (rowData) => props.deleteLaw(rowData.id);

  const columns = getColumns(
    props.lists && props.lists.requirements && props.lists.requirements.list,
    props.lists && props.lists.states && props.lists.states.list,
    props.lists && props.lists.dataTypes && props.lists.dataTypes.list,
    props.lists && props.lists.industries && props.lists.industries.list
  );
  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchLawCount}
            paginationCall={props.loadLaws}
            title="Frameworks"
            entity={"laws"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(newData) => saveLaw(newData)}
            editFunction={(newData, oldData) => editLaw(newData, oldData)}
            deleteFunction={(newData) => props.deleteLawById(newData.id)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    laws: state.entities.laws,
    pageInfo: state.pagination.laws,
    lists: state.entities.lists,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    saveLaw: (payload) => dispatch(saveLaw(payload)),
    updateLawById: (id, payload) => dispatch(updateLawById(id, payload)),
    deleteLawById: (id) => dispatch(deleteLawById(id)),
    loadLaws: (page, limit, refresh) => dispatch(loadLaws(page, limit, refresh)),
    fetchLawCount: () => dispatch(fetchLawCount()),
    loadRequirementsList: () => dispatch(loadRequirementsList()),
    loadStatesList: () => dispatch(loadStatesList()),
    loadDataTypesList: () => dispatch(loadDataTypesList()),
    loadIndustriesList: () => dispatch(loadIndustriesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Laws));
