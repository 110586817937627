import React, { useState } from "react";
import { Grid, Box, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  textCopy: {
    padding: 2,
    transition: "0.2s ease-in-out",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
      borderRadius: 5,
      transition: "0.2s ease-in-out",
    },
    "&:active": { background: "rgba(0,0,0,0.2)", transition: "0.2s ease-in-out" },
  },
}));

export function MaxText(props) {
  const classes = useStyles();
  const MAX_TEXT = 14;
  if (props.title) {
    return (
      <Box
        style={{
          color: props.color,
          cursor: "copy",
          whiteSpace: props.nowrap ? "nowrap" : "normal",
          overflow: "hidden",
          width: props.title.length >= MAX_TEXT ? "80%" : "fit-content",
        }}
        className={classes.textCopy}
      >
        {props.title.length >= MAX_TEXT ? (
          <Tooltip
            title={`${props.title} ${props.description ? "-" : ""} ${props.description ? props.description : ""}`}
          >
            <CopyToClipboard
              text={props.title}
              onCopy={() =>
                props.setSnackbar({
                  isOpen: true,
                  variant: "success",
                  message: "Successfully copied to your clipboard!",
                })
              }
            >
              <Box style={{ fontWeight: props.bold ? "bold" : "200" }}>
                {props.title.replace(/(.{MAX_TEXT})..+/, "$1…")}
              </Box>
            </CopyToClipboard>
          </Tooltip>
        ) : (
          <CopyToClipboard
            text={props.title}
            onCopy={() =>
              props.setSnackbar({ isOpen: true, variant: "success", message: "Successfully copied to your clipboard!" })
            }
          >
            <Tooltip title={`${props.description}`}>
              <Box style={{ fontWeight: props.bold ? "bold" : "200" }}>
                {props.title.replace(/(.{MAX_TEXT})..+/, "$1…")}
              </Box>
            </Tooltip>
          </CopyToClipboard>
        )}
      </Box>
    );
  }
  return null;
}

export function DatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        inputVariant="outlined"
        margin="dense"
        InputAdornmentProps={{ position: "start" }}
        id="date-picker-inline"
        label="Project Start"
        fullWidth
        value={props.value}
        onChange={props.onChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
