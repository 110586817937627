import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import HubSpotRouter from "../containers/HubSpot";
import Companies from "../containers/Companies";
import MetadataRouter from "../containers/Metadata";
import FrameworksRouter from "../containers/Frameworks";
import Projects from "../containers/Projects";
import RolesRouter from "../containers/Roles";
import AccessRouter from "../containers/Access";
import CompanyDetails from "../containers/CompanyDetails";
import DocumentsViewerContainer from "../views/DocumentViewer";
import connect from "react-redux/es/connect/connect";
import { getAdminProfile, logout } from "../store/actions";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import setTheme from "../utils/theme_helper";
import CharacteristicsRouter from "../containers/Characteristics";
import ProjectsAndTasks from "../views/ProjectAndTasks";
import AccessDenied from "../components/ErrorPages/AccessDenied";
import AuthComponent from "../components/AuthComponent/AuthComponent";
import ProjectRoster from "../views/Roster";
import ProjectQueue from "../containers/ProjectInitiation";
import ProjectMain from "../containers/ProjectInitiation/views/ProjectMain";
import MyProfile from "../containers/MyProfile/index";

const Full = (props) => {
  const theme = setTheme(props.theme);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  // useEffect(() => {
  //   props.getAdminProfile();
  // }, [window.location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setSidebarOpen(!sidebarOpen);
  };

  const generateAccess = (key, subKey) => {
    return (
      (props.user && props.user.adminAccess && props.user.adminAccess.isSuper) ||
      (props.user && props.user.adminAccess[key][subKey])
    );
  };

  const isPageViewable = (key, type) => {
    if (props.user && props.user.adminAccess && props.user.adminAccess.isSuper) {
      return true;
    }
    switch (type) {
      case "object":
        return (
          props.user &&
          props.user.adminAccess &&
          props.user.adminAccess[key] &&
          Object.values(props.user.adminAccess[key])
            .filter((val) => !Array.isArray(val))
            .some((val) => val)
        );
      case "boolean":
        return props.user && props.user.adminAccess && props.user.adminAccess[key];
      default:
        break;
    }
  };

  return (
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <AuthComponent />
        <div>
          <Header
            toggleDrawer={toggleDrawer}
            isSuper={props.user && props.user.adminAccess && props.user.adminAccess.isSuper}
          />
          <div style={{ paddingTop: "1rem" }}>
            <Sidebar {...props} toggleDrawer={toggleDrawer} sidebarOpen={sidebarOpen} />
            <main>
              <div>
                <Switch>
                  <Route exact path="/dashboard" name="Companies" component={ProjectsAndTasks} />
                  <Route
                    exact
                    path="/organizations"
                    name="Companies"
                    render={() =>
                      isPageViewable("companies", "object") ? (
                        <Companies
                          create={generateAccess("companies", "create")}
                          update={generateAccess("companies", "update")}
                          delete={generateAccess("companies", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/hubSpot"
                    name="HubSpot"
                    render={() =>
                      isPageViewable("hubspot", "object") ? (
                        <HubSpotRouter
                          create={generateAccess("hubspot", "create")}
                          update={generateAccess("hubspot", "update")}
                          delete={generateAccess("hubspot", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/metadata"
                    name="Metadata"
                    render={() =>
                      isPageViewable("metadata", "object") ? (
                        <MetadataRouter
                          create={generateAccess("metadata", "create")}
                          update={generateAccess("metadata", "update")}
                          delete={generateAccess("metadata", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/characteristics"
                    name="Characteristics"
                    render={() =>
                      isPageViewable("characteristics", "object") ? (
                        <CharacteristicsRouter
                          create={generateAccess("characteristics", "create")}
                          update={generateAccess("characteristics", "update")}
                          delete={generateAccess("characteristics", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/frameworks"
                    name="Frameworks"
                    render={() =>
                      isPageViewable("frameworks", "object") ? (
                        <FrameworksRouter
                          create={generateAccess("frameworks", "create")}
                          update={generateAccess("frameworks", "update")}
                          delete={generateAccess("frameworks", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/projects"
                    name="Projects"
                    render={() =>
                      isPageViewable("projects", "object") ? (
                        <Projects
                          create={generateAccess("projects", "create")}
                          update={generateAccess("projects", "update")}
                          delete={generateAccess("projects", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/roles"
                    name="Roles"
                    render={() =>
                      isPageViewable("roles", "object") ? (
                        <RolesRouter
                          create={generateAccess("roles", "create")}
                          update={generateAccess("roles", "update")}
                          delete={generateAccess("roles", "delete")}
                          history={props.history}
                        />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/access"
                    name="Access"
                    render={() =>
                      isPageViewable("isSuper", "boolean") ? <AccessRouter history={props.history} /> : <AccessDenied />
                    }
                  />
                  <Route
                    exact
                    path="/projectqueue"
                    name="ProjectQueue"
                    render={(routeProps) =>
                      isPageViewable("isSuper", "boolean") || isPageViewable("canViewProjectRoster", "boolean") ? (
                        <ProjectQueue admin {...routeProps} history={props.history} />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/projectqueue/form"
                    name="ProjectMain"
                    render={(routeProps) =>
                      isPageViewable("isSuper", "boolean") || isPageViewable("canViewProjectRoster", "boolean") ? (
                        <ProjectMain admin {...routeProps} history={props.history} />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/projectqueue/form/:projectQueueId/archives"
                    name="ProjectMain"
                    render={(routeProps) =>
                      isPageViewable("isSuper", "boolean") || isPageViewable("canViewProjectRoster", "boolean") ? (
                        <ProjectMain admin {...routeProps} archives history={props.history} />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/projectqueue/form/:projectQueueId"
                    name="ProjectMain"
                    render={(routeProps) =>
                      isPageViewable("isSuper", "boolean") || isPageViewable("canViewProjectRoster", "boolean") ? (
                        <ProjectMain admin {...routeProps} history={props.history} />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/roster/:companyId"
                    name="ProjectRoster"
                    render={(routeProps) =>
                      isPageViewable("isSuper", "boolean") || isPageViewable("canViewProjectRoster", "boolean") ? (
                        <ProjectRoster admin {...routeProps} history={props.history} />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Route
                    path="/organizations/:id"
                    name="CompanyDetails"
                    render={(routeProps) => {
                      const allowedIds =
                        (props.user &&
                          props.user.adminAccess &&
                          props.user.adminAccess.companies &&
                          props.user.adminAccess.companies.allowedIds) ||
                        [];
                      const isSuper = props.user && props.user.adminAccess && props.user.adminAccess.isSuper;

                      if (isSuper || allowedIds.includes(routeProps.match.params.id)) {
                        return <CompanyDetails {...routeProps} history={props.history} />;
                      }
                      return <AccessDenied />;
                    }}
                  />
                  <Route
                    exact
                    path="/profile/:userId"
                    name="MyProfile"
                    render={(routeProps) => <MyProfile admin {...routeProps} history={props.history} />}
                  />
                  <Route
                    path="/documents/admin/:documentId"
                    name="DocumentViewer"
                    render={(routeProps) =>
                      props.user &&
                      props.user.adminAccess &&
                      props.user.adminAccess.companies &&
                      props.user.adminAccess.companies.update ? (
                        <DocumentsViewerContainer {...routeProps} history={props.history} />
                      ) : (
                        <AccessDenied />
                      )
                    }
                  />
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </MuiThemeProvider>
    </React.StrictMode>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    theme: state.user && state.user.internalStore ? state.user.internalStore.theme : "light",
  };
};

const dispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    getAdminProfile: () => dispatch(getAdminProfile()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Full));
