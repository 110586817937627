import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, Box, Checkbox, LinearProgress, InputBase } from "@material-ui/core";
import FrameTool from "./FrameTool";
import { useStyles } from "../styles/CustomTable.styles";

export default function ProjectRosterHeader(props) {
  const { selected, setSelected } = props;
  const classes = useStyles();

  const updateSort = (column) => {
    if (column === props.sort.column) {
      switch (props.sort.direction) {
        case "asc":
          props.setSort({ column, direction: "desc" });
          break;
        case "desc":
          props.setSort({ column, direction: "" });
          break;
        case "":
          props.setSort({ column, direction: "asc" });
          break;
      }
    } else {
      props.setSort({ column, direction: "asc" });
    }
  };

  return (
    <Grid
      container
      className={classes.scrollBoxLower}
      style={{
        color: "white",
        backgroundColor: "#011627",
        fontSize: "1rem",
        fontFamily: "Lato",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
      alignItems="center"
    >
      <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
        <Checkbox
          style={{ color: "white" }}
          checked={selected.length > 0 && props.projectRoster.length === selected.length}
          indeterminate={selected.length > 0 && props.projectRoster.length !== selected.length}
          onChange={(event) => {
            if (!event.target.checked) {
              setSelected([]);
            } else {
              setSelected([...props.projectRoster.map((project) => project.id)]);
            }
          }}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Grid>
      {/* <Grid item xs={1}>
        <Box
          style={{ cursor: "pointer", borderBottom: "1px solid white", marginRight: 15 }}
          onClick={() => updateSort("type")}
        >
          Type{" "}
          {props.sort.column === "type" ? (
            props.sort.direction === "asc" ? (
              <i className="fal fa-arrow-up" />
            ) : props.sort.direction === "desc" ? (
              <i className="fal fa-arrow-down" />
            ) : null
          ) : null}
        </Box>
      </Grid> */}
      {/* <Grid item xs={1}>
        <Box
          style={{ cursor: "pointer", borderBottom: "1px solid white", marginRight: 15 }}
          onClick={() => updateSort("project")}
        >
          Project/Task{" "}
          {props.sort.column === "project" ? (
            props.sort.direction === "asc" ? (
              <i className="fal fa-arrow-up" />
            ) : props.sort.direction === "desc" ? (
              <i className="fal fa-arrow-down" />
            ) : null
          ) : null}
        </Box>
      </Grid> */}
      {/* <Grid item xs={1}>
        <Box
          style={{ cursor: "pointer", borderBottom: "1px solid white", marginRight: 15 }}
          onClick={() => updateSort("client")}
        >
          Client{" "}
          {props.sort.column === "client" ? (
            props.sort.direction === "asc" ? (
              <i className="fal fa-arrow-up" />
            ) : props.sort.direction === "desc" ? (
              <i className="fal fa-arrow-down" />
            ) : null
          ) : null}
        </Box>
      </Grid> */}
      <Grid item xs={3}>
        <Box style={{ cursor: "not-allowed", borderBottom: "1px solid white", marginRight: 15 }}>Project Details</Box>
      </Grid>
      <Grid item xs={3}>
        <Box style={{ cursor: "not-allowed", borderBottom: "1px solid white", marginRight: 15 }}>Work Elements</Box>
      </Grid>
      {/* <Grid item xs={2}>
        <Box style={{ cursor: "not-allowed", borderBottom: "1px solid white", marginRight: 15 }}></Box>
      </Grid> */}
      <Grid item xs={5}>
        <Box style={{ cursor: "not-allowed", borderBottom: "1px solid white", marginRight: 15 }}>
          Allocation / Role / Resource
        </Box>
      </Grid>
      {/* <Grid item xs={1}>
        <Box style={{ cursor: "not-allowed", borderBottom: "1px solid white", marginRight: 15 }}>Time Allocation</Box>
      </Grid> */}
      {/* <Grid item xs={1}>
        <Box
          style={{ cursor: "pointer", borderBottom: "1px solid white", marginRight: 15 }}
          onClick={() => updateSort("status")}
        >
          Status{" "}
          {props.sort.column === "status" ? (
            props.sort.direction === "asc" ? (
              <i className="fal fa-arrow-up" />
            ) : props.sort.direction === "desc" ? (
              <i className="fal fa-arrow-down" />
            ) : null
          ) : null}
        </Box>
      </Grid> */}
    </Grid>
  );
}
