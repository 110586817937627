import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip, IconButton, TextField, FormControlLabel, CircularProgress } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";

export default function Form(props) {
  const classes = useStyles();
  const [deletingId, setDeletingId] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);

  const addNewResource = () => {
    try {
      if (!props.changesMade) props.setChangesMade(true);
      props.setProjectRoles([...props.projectRoles, { roleId: "", allocation: 0 }]);
    } catch (err) {}
  };

  const removeRole = (id, index) => {
    try {
      if (!props.changesMade) props.setChangesMade(true);
      setDeletingId(id);
      setDeletingIndex(index);
      setTimeout(() => {
        props.setProjectRoles([]);
        let tempProjectRoles = props.projectRoles;
        tempProjectRoles.splice(index, 1);
        props.setProjectRoles(tempProjectRoles);
        setDeletingId(null);
        setDeletingIndex(null);
      }, 100);
    } catch (err) {}
  };

  const updateResource = (index, key, value) => {
    let tempProjectRoles = props.projectRoles;
    tempProjectRoles[index][key] = value;
    props.setProjectRoles([...tempProjectRoles]);
    return tempProjectRoles;
  };

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.area}>
      <Box className={classes.titles}>Roles</Box>
      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {props.projectRoles.map((roleId, index) => {
              return props.loading ? (
                <Skeleton variant="text" animation="wave" height={50} />
              ) : (
                <Box display="flex" alignItems="flex-end" width="100%" mt={2}>
                  <Box mr={2}>
                    {deletingId === roleId.roleId && deletingIndex === index ? (
                      <IconButton disabled={props.projectRoles.length <= 1} style={{ padding: 3 }}>
                        <i
                          style={{
                            color: "rgba(235,107,108,0.3)",
                            fontSize: "1.3rem",
                          }}
                          className={`fal fa-cog fa-spin`}
                        />
                      </IconButton>
                    ) : (
                      <Tooltip title={props.projectRoles.length <= 1 ? "Must have a role" : "Remove"}>
                        <IconButton
                          disabled={props.projectRoles.length <= 1}
                          onClick={() => removeRole(roleId, index)}
                          style={{ padding: 3 }}
                        >
                          <i
                            style={{
                              color: props.projectRoles.length <= 1 ? "rgba(235,107,108,0.2)" : "#EB6B6C",
                              fontSize: "1.3rem",
                            }}
                            className={`fal fa-minus-circle`}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>

                  <Autocomplete
                    size="small"
                    fullWidth
                    options={props.roles}
                    value={props.roles.find((role) => role.id === roleId.roleId)}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => {
                      if (!props.changesMade) props.setChangesMade(true);
                      updateResource(index, "roleId", newValue.id);
                    }}
                    renderInput={(params) => <TextField {...params} label="Role" />}
                  />

                  <TextField
                    style={{ marginLeft: "1rem" }}
                    type={"number"}
                    value={roleId.allocation}
                    onChange={(e) => updateResource(index, "allocation", parseInt(e.target.value))}
                    label={"Allocation"}
                  />
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Box>

      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Tooltip title="Add Role">
          <IconButton onClick={() => addNewResource()} style={{ padding: 3 }}>
            <i style={{ color: "#0E8C64", fontSize: "1.3rem" }} className={`fal fa-plus-circle`} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
