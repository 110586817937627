import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { constants } from "../../../utils/common";
import { getAllProjects, getProjectsCount, saveProject, updateProject, deleteProject } from "../../../store/actions";

const columns = [
  { title: "Name", field: "name" },
  { title: "Description", field: "description" },
  { title: "Type", field: "type" },
  {
    title: "Milestones",
    field: "milestones",
    render: (row) => (
      <div>
        <ol>
          {row.milestones.map((milestone) => (
            <li>{milestone.name}</li>
          ))}
        </ol>
      </div>
    ),
    editable: "never",
  },
];

function Projects(props) {
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });

  const createProject = async (row) => {
    try {
      const result = await props.saveProject({ name: row.name, description: row.description, type: row.type });
      if (!result.type.includes("SUCCESS")) throw new Error();
      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to create ${row.name}.` });
    }
  };

  const editProject = async (row) => {
    try {
      const result = await props.updateProject(row.id, {
        name: row.name,
        description: row.description,
        type: row.type,
      });
      if (!result.type.includes("SUCCESS")) throw new Error();
      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to update ${row.name}.` });
    }
  };

  const deleteProject = async (row) => {
    try {
      const result = await props.deleteProject(row.id);
      if (!result.type.includes("SUCCESS")) throw new Error();
      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to delete ${row.name}.` });
    }
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getProjectsCount}
            paginationCall={props.getAllProjects}
            title="Projects"
            entity="projects"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(newData) => createProject(newData)}
            editFunction={(newData, oldData) => editProject(newData)}
            deleteFunction={(newData) => deleteProject(newData)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const dispatchToProps = (dispatch) => {
  return {
    getAllProjects: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getAllProjects(page, limit, refresh, searchTerm, sort, by)),
    getProjectsCount: () => dispatch(getProjectsCount()),
    saveProject: (data) => dispatch(saveProject(data)),
    updateProject: (id, data) => dispatch(updateProject(id, data)),
    deleteProject: (id) => dispatch(deleteProject(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Projects));
