import React from "react";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

export const getColumns = (dataTypes = []) => [
  {
    title: "Label",
    field: "label",
  },
  {
    title: "Data Types",
    field: "dataTypes",
    render: (row) => (
      <div style={{ minWidth: "50px" }}>
        {Array.isArray(row.dataTypes) ? row.dataTypes.map((dataType) => dataType.dataType.label).join(", ") : null}
      </div>
    ),
    editComponent: (props) => <Selection multiple target="dataType" options={dataTypes} {...props} />,
  },
];
