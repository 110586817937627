import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, IconButton, Box } from "@material-ui/core";
import Iframe from "react-iframe";
import Chat from "../../components/Chat/Chat";
import Frame from "../../components/Frame/Frame";
import Warning from "../../components/Dialog/WarningDialog";
import StatusButtons from "./components/StatusButtons";
import {
  loadDocumentById,
  updateDocument,
  saveComment,
  deleteComment,
  updateComment,
  deleteDocument,
  loadCompany,
} from "../../store/actions";
import { documentsSelector, userIdSelector, userSelector, documentEntitiesSelector } from "./utils/selector";
import ErrorBoundary from "../../components/ErrorPages/ErrorBoundary";
import { constants, humanizeLabels } from "../../utils/common";

const DocumentViewer = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentsAnchorEl, setCommentsAnchorEl] = useState(null);
  const [document, setDocument] = useState({});
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    fetchDocumentById();
  }, [props.documentId]);

  const fetchDocumentById = async () => {
    const document = await props.loadDocumentById(props.documentId);
    const targetDocument = document.response.entities.documents[props.documentId];
    setDocument(targetDocument);
    setStatus(targetDocument.status);
    setComments(targetDocument.comments);
    const uri = encodeURI(targetDocument.url);
    setUrl(`https://docs.google.com/gview?url=${uri}`);
    setLoading(false);
  };

  const openCommentsPopover = (event) => setCommentsAnchorEl(event.currentTarget);

  const addComment = async (comment) => {
    const result = await props.saveComment({
      name: comment,
      userId: props.user.id,
      documentId: document.id,
    });
    const resultComment = Object.values(result.response.entities.comments)[0];
    const newComment = {
      ...resultComment,
      comment: resultComment.name,
      direction: "right",
      userName: `${props.user.firstName} ${props.user.lastName}`,
    };
    setComments([...comments, newComment]);
    return result;
  };

  const editComment = async (commentId, comment) => {
    const result = await props.updateComment(commentId, { name: comment });
    let currentComments = comments;
    currentComments.find((c) => c.id === commentId).comment = comment;
    setComments([...currentComments]);
    return result;
  };

  const deleteComment = async (commentId) => {
    const result = await props.deleteComment(commentId);
    let currentComments = comments;
    currentComments.find((c) => c.id === commentId).deleted = true;
    setComments([...currentComments]);
    return result;
  };

  const changeDocumentStatus = async (status) => {
    await props.updateDocument(document.id, { status });
    return await fetchDocumentById(document.id);
  };

  return (
    <Frame
      margin
      header
      history={props.history}
      title={document ? document.name : "Document"}
      subTitle={
        <Box>
          {document && document.type
            ? document.type === constants.DOCUMENT_TYPES.SOC2
              ? "SOC 2 Type II"
              : humanizeLabels(document.type)
            : ""}{" "}
          - <strong style={{ marginLeft: "0.2rem" }}>{document ? humanizeLabels(document.status) : ""} </strong>
        </Box>
      }
    >
      <div style={{ width: "100%" }}>
        <Warning
          anchorEl={anchorEl}
          delete
          loading={deleting}
          text={`Deleting this document will remove it permanently and will remove all 
          comments associated with the document. Are you sure you wish to continue?`}
          confirm={async () => {
            setDeleting(true);
            await props.deleteDocument(document.id);
            await props.loadCompany(document.companyId);
            setDeleting(false);
            props.history.goBack();
          }}
          close={() => setAnchorEl(null)}
        />

        <Chat
          title="Documentation Chat"
          open={Boolean(commentsAnchorEl)}
          anchorEl={commentsAnchorEl}
          closeChat={() => setCommentsAnchorEl(null)}
          comments={comments}
          addComment={addComment}
          editComment={editComment}
          deleteComment={deleteComment}
        />

        <Grid container style={{ height: "100%" }} justify="center" alignItems="flex-start">
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton onClick={openCommentsPopover}>
                <i style={{ cursor: "pointer", color: "black" }} className="fal fa-comments" />
              </IconButton>
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <i style={{ cursor: "pointer", color: "black" }} className="fal fa-trash" />
              </IconButton>
              <StatusButtons
                changeDocumentStatus={changeDocumentStatus}
                status={status}
                admin={window.location.href.includes("admin")}
                {...props}
              />
            </Box>
          </Grid>

          <Grid item style={{ height: "100%" }} xs={12} md={8} lg={9}>
            <div
              style={{
                margin: "10px",
                backgroundColor: "white",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ height: "70vh", width: "97%", padding: "10px" }}>
                <Iframe
                  position="none"
                  id="document"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  url={!loading ? `${url}&embedded=true` : ""}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Frame>
  );
};

const DocumentsViewerContainer = (props) => (
  <ErrorBoundary history={props.history}>
    <DocumentViewer {...props} />
  </ErrorBoundary>
);

const mapStateToProps = (state, ownProps) => {
  return {
    documents: documentsSelector(state),
    userId: userIdSelector(state),
    user: userSelector(state),
    documentEntities: documentEntitiesSelector(state),
    documentId: ownProps.match.params.documentId,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    updateDocument: (id, payload) => dispatch(updateDocument(id, payload)),
    loadDocumentById: (id) => dispatch(loadDocumentById(id)),
    saveComment: (payload) => dispatch(saveComment(payload)),
    deleteDocument: (documentId) => dispatch(deleteDocument(documentId)),
    deleteComment: (commentId) => dispatch(deleteComment(commentId)),
    updateComment: (commentId, payload) => dispatch(updateComment(commentId, payload)),
    loadCompany: (companyId) => dispatch(loadCompany(companyId)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(DocumentsViewerContainer));
