import React from "react";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

export const getColumns = (
  laws = [],
  policies = [],
  classes = [],
  processes = [],
  components = [],
  categories = [],
  controls = [],
  projects = []
) => {
  return [
    {
      title: "Characteristic",
      field: "name",
      render: (row) => <div style={{ minWidth: "160px" }}>{row.name}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Rank",
      field: "rank",
      render: (row) => <div style={{ minWidth: "20px" }}>{row.rank ? row.rank : null}</div>,
      editComponent: (props) => <MultiLineEdit number {...props} />,
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Domains",
      field: "policies",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>{row.policies && row.policies.policy ? row.policies.policy.name : null}</div>
      ),
      editComponent: (props) => <Selection target="policy" options={policies} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Categories",
      field: "categories",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {row.categories && row.categories.category ? row.categories.category.name : null}
        </div>
      ),
      editComponent: (props) => <Selection target="category" options={categories} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    // {
    //   title: "Components",
    //   field: "components",
    //   render: (row) => (
    //     <div style={{ minWidth: "50px" }}>
    //       {row.components && row.components.component ? row.components.component.name : null}
    //     </div>
    //   ),
    //   editComponent: (props) => <Selection target="component" options={components} {...props} />,
    //   headerStyle: { fontWeight: "bold" },
    //   sorting: false,
    // },
    {
      title: "Processes",
      field: "processes",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {row.processes && row.processes.process ? row.processes.process.name : null}
        </div>
      ),
      editComponent: (props) => <Selection target="process" options={processes} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Frameworks",
      field: "laws",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {row.laws && Array.isArray(row.laws)
            ? row.laws
                .map((law) => law.law && law.law.name)
                .filter(Boolean)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => {
        return (
          <div style={{ minWidth: "80px" }}>
            <Selection multiple target="law" options={laws} {...props} />
          </div>
        );
      },
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Controls",
      field: "controls",
      render: (row) => {
        return (
          <div style={{ minWidth: "50px" }}>
            {row.controls && Array.isArray(row.controls)
              ? row.controls.map((control) => control.control.name).join(", ")
              : null}
          </div>
        );
      },
      editComponent: (props) => {
        return (
          <div style={{ minWidth: "80px" }}>
            <Selection multiple target="control" options={controls} {...props} />
          </div>
        );
      },
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Class",
      field: "classes",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>{row.classes && row.classes.class ? row.classes.class.name : null}</div>
      ),
      editComponent: (props) => <Selection target="class" options={classes} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Project",
      field: "projectId",
      render: (row) => <div style={{ minWidth: "50px" }}>{row.project && row.project ? row.project.name : null}</div>,
      editComponent: (props) => <Selection target="project" options={projects} {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    // {
    //   title: "Origin",
    //   field: "origin",
    //   render: (row) => <div style={{ minWidth: "80px" }}>{row.origin ? row.origin : null}</div>,
    //   editComponent: (props) => <MultiLineEdit {...props} />,
    //   headerStyle: { fontWeight: "bold" },
    //   sorting: false,
    // },
  ];
};

/**
 * {
      title: "Sharon",
      field: "sharon",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.sharon ? row.sharon : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Arnold",
      field: "arnold",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.arnold ? row.arnold : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Frankie",
      field: "frankie",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.frankie ? row.frankie : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Irene",
      field: "irene",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.irene ? row.irene : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Carl",
      field: "carl",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.carl ? row.carl : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Pauline",
      field: "pauline",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.pauline ? row.pauline : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Phred",
      field: "phred",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.phred ? row.phred : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Padma",
      field: "padma",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.padma ? row.padma : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Bobby",
      field: "bobby",
      render: (row) => <div style={{ minWidth: "80px" }}>{row.bobby ? row.bobby : null}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
 */
