import React from "react";
import PropTypes from "prop-types";

const VariantIcon = ({ variant = "info", classes }) => {
  switch (variant) {
    case "info":
      return <i className={`fal fa-info-circle`} />;
    case "warning":
      return <i className={`fal fa-exclamation-circle`} />;
    case "error":
      return <i className={`fal fa-times-circle`} />;
    case "success":
      return <i className={`fal fa-check-circle`} />;
    default:
      return <i className={`fal fa-info-circle`} />;
  }
};

VariantIcon.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default VariantIcon;
