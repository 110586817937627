import React, { useEffect, useState } from "react";
import { Grid, Box, IconButton, Tooltip } from "@material-ui/core";
import Toolbar from "../components/Toolbar";
import Table from "../components/Table";
import { useStyles } from "../styles/ProjectAndTasks.styles";
import Logo from "../../../../public/img/progressent.png";
import { getWeeklyHours } from "../../../store/actions";

export default function ProjectsAndTasks(props) {
  const classes = useStyles();
  const [currentFilters, setCurrentFilters] = useState([]);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");

  const updateFilters = (type, item) => {
    let filters = currentFilters;
    if (type === "delete") {
      filters = filters.filter((filter) => filter !== item);
    }
    if (type === "add") {
      filters.push(item);
    }
    setCurrentFilters([...filters]);
  };

  const filteredResults = props.projects.filter((row) => {
    return (
      row.name.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      search.trim().toLowerCase().includes(row.name.trim().toLowerCase()) ||
      row.companyName.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      search.trim().toLowerCase().includes(row.companyName.trim().toLowerCase())
    );
  });

  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent="center" flexDirection="column">
      <Box
        style={{
          background: props.background,
          height: 50,
          color: "white",
          fontWeight: "bold",
        }}
        className={classes.mainBox}
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <img
          src={Logo}
          style={{
            width: "300px",
            marginLeft: 20,
          }}
        />
        <Tooltip title="Close Projects">
          <IconButton
            style={{ marginRight: 20 }}
            onClick={async () => {
              await props.setDrawerOpen(false);
              await props.updateUser(props.user.id, { showTimeEntryProjects: !props.drawerOpen });
              await props.getAdminProfile();
            }}
          >
            <i className="fas fa-times" style={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Toolbar
        search={search}
        setSearch={setSearch}
        updateFilters={updateFilters}
        managedServices={currentFilters.includes("MS")}
        proServices={currentFilters.includes("PS")}
        archivedCompleted={currentFilters.includes("COMPLETED")}
        archived={archived}
        setArchived={setArchived}
        refresh={props.refresh}
        getWeeklyHours={props.getWeeklyHours}
        userId={props.userId}
        weeklyHours={props.weeklyHours}
      />
      <Table
        currentFilters={currentFilters}
        archived={archived}
        setStartRefresh={props.setStartRefresh}
        setCurrentFilters={setCurrentFilters}
        updateUserCompanyProject={props.updateUserCompanyProject}
        updateTimeEntry={props.updateTimeEntry}
        setRefresh={props.setRefresh}
        deleteTimeEntry={props.deleteTimeEntry}
        companyProjectId={props.compansyProjectId}
        setCompanyProjectId={props.setCompanyProjectId}
        setAddTime={props.setAddTime}
        projects={filteredResults}
        refresh={props.refresh}
        getWorkElementTasks={props.getWorkElementTasks}
        createTimeEntryTask={props.createTimeEntryTask}
        preFillAddTimeDialog={props.preFillAddTimeDialog}
        getWorkElementsList={props.getWorkElementsList}
      />
    </Box>
  );
}
