import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function VerifyPassword(props) {
  const [password, setPassword] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const verifyUserPass = async () => {
    const result = await props.verifyUserPassword({ password });
    const { passwordCorrect } = result.response.entities.passwordVerify.passwordVerify;
    if (passwordCorrect) {
      window.open(props.url, "_blank");
      setPassword("");
      return props.handleClose();
    }
    setIncorrectPassword(true);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Password Verification</DialogTitle>
      <DialogContent>
        <DialogContentText>To view the SOW, please enter your password</DialogContentText>
        <TextField
          error={incorrectPassword}
          autoFocus
          margin="dense"
          id="name"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            if (incorrectPassword) {
              setIncorrectPassword(false);
            }
            setPassword(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => verifyUserPass()} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
