import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "25rem",
    height: "30rem",
    overflow: "auto",
    padding: 10,
    resize: "both",
    background: "radial-gradient(rgba(255,255,255,1) 30%,  rgba(240,240,240,1) 80%,  rgba(220,220,220,1) 100%)",
  },
  bubbleContainer: {
    width: "100%",
    display: "flex",
    marginBottm: "auto",
    marginTop: "auto",
  },
  bubble: {
    // border: "0.5px solid black",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.2) 15px 18px 9px -9px",
    margin: "5px",
    padding: "10px",
    color: "white",
    display: "inline-block",
  },
  light: {
    // backgroundColor: "#2373D8",
    background: "radial-gradient(rgba(35,115,216,1) 30%,  rgba(35,115,216,0.9) 80%,  rgba(35,115,216,0.8) 100%)",
  },
  dark: {
    // backgroundColor: "#011627",
    background: "radial-gradient(rgba(14,140,100,1) 30%,  rgba(14,140,100,0.9) 80%,  rgba(14,140,100,0.8) 100%)",
  },
  left: {
    justifyContent: "flex-start",
  },
  right: {
    justifyContent: "flex-end",
  },
  comment: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "250px",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  chatHeader: {
    backgroundColor: "#011627",
    fontWeight: "bold",
    borderRadius: "12px 12px 0 0",
    color: "white",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  chatHeaderText: {
    padding: "10px 10px 10px 22px",
  },
}));
