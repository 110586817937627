import React from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadDataTypes,
  fetchDataTypesCount,
  saveDataType,
  updateDataTypeById,
  deleteDataTypeById,
  loadOrgImpactsList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { getColumns } from "../utils/dataTypeColumns";

const DataTypes = (props) => {
  const columns = getColumns(props.orgImpacts);

  React.useEffect(() => {
    props.loadOrgImpactsList();
  }, []);

  const postDataType = async (rowData) => {
    try {
      const payload = {
        ...rowData,
        orgImpactIds: rowData.orgImpacts ? rowData.orgImpacts : [],
      };
      const response = await props.saveDataType({ ...payload });
      return response;
    } catch (err) {}
  };

  const editDataType = async (newData, oldData) => {
    try {
      let payload = { ...newData };
      const orgImpactIds =
        newData.orgImpacts && Array.isArray(newData.orgImpacts)
          ? newData.orgImpacts.map((orgImpacts) => orgImpacts.orgImpact)
          : newData.orgImpacts
          ? newData.orgImpacts.split(",")
          : [];
      payload = { ...payload, orgImpactIds };
      const response = await props.updateDataTypeById(newData.id, { ...payload });
      return response;
    } catch (err) {}
  };

  const deleteDataType = async (rowData) => {
    try {
      const response = await props.deleteDataTypeById(rowData.id);
      return response;
    } catch (err) {}
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchDataTypesCount}
            paginationCall={props.loadDataTypes}
            title="Data Types"
            entity={"dataTypes"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => postDataType(rowData)}
            editFunction={(newData, oldData) => editDataType(newData, oldData)}
            deleteFunction={(rowData) => deleteDataType(rowData)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataTypes: state.entities.dataTypes,
    pageInfo: state.pagination.dataTypes,
    orgImpacts: (state.entities.lists && state.entities.lists.orgImpacts && state.entities.lists.orgImpacts.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadDataTypes: (page, limit, refresh) => dispatch(loadDataTypes(page, limit, refresh)),
    fetchDataTypesCount: () => dispatch(fetchDataTypesCount()),
    saveDataType: (payload) => dispatch(saveDataType(payload)),
    updateDataTypeById: (id, payload) => dispatch(updateDataTypeById(id, payload)),
    deleteDataTypeById: (id) => dispatch(deleteDataTypeById(id)),
    loadOrgImpactsList: () => dispatch(loadOrgImpactsList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(DataTypes));
