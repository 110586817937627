import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hyperlink: {
    color: "grey",
    textDecoration: "none",
    fontSize: 10,
    fontWeight: "bold",
    "&:hover": {
      color: "blue",
    },
  },
}));

const DialogFooter = () => {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent="center">
      <Box display="flex" alignSelf="center" justifyContent="center" m={1}>
        <a href="https://www.cisoshare.com/" target="_blank" className={classes.hyperlink}>
          &copy; 2021 CISOSHARE
        </a>
      </Box>
      <Box display="flex" alignSelf="center" justifyContent="center" m={1}>
        <a href="https://www.cisoshare.com/privacy-policy" target="_blank" className={classes.hyperlink}>
          Privacy Policy
        </a>
      </Box>
      <Box display="flex" alignSelf="center" justifyContent="center" m={1}>
        <a href="https://www.cisoshare.com/terms-of-use" target="_blank" className={classes.hyperlink}>
          Terms of Use
        </a>
      </Box>
    </Box>
  );
};

export default DialogFooter;
