const getTargetList = (target, state) =>
  (state.entities.lists && state.entities.lists[target] && state.entities.lists[target].list) || [];

export const countriesListSelector = (state) => getTargetList("countries", state);
export const statesListSelector = (state) => getTargetList("states", state);
export const industriesListSelector = (state) => getTargetList("industries", state);
export const dataTypesListSelector = (state) => getTargetList("dataTypes", state);
export const orgImpactsListSelector = (state) => getTargetList("orgImpacts", state);

export const demographicsTakenFlag = (state) =>
  (state.user &&
    state.user.companyId &&
    state.entities &&
    state.entities.companies &&
    state.entities.companies[state.user.companyId] &&
    state.entities.companies[state.user.companyId].demographics &&
    state.entities.companies[state.user.companyId].demographics.length > 0) ||
  false;
