import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box, Drawer } from "@material-ui/core";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import {
  getTimeEntries,
  getCalendarTimeEntries,
  createNewTimeEntry,
  updateTimeEntry,
  deleteTimeEntry,
  getProjectsList,
  getCompaniesList,
  updateUserCompanyProject,
  kickoffCompanyProjects,
  loadCompany,
  saveCompany,
  createRequest,
  getCompanyProjectWorkElements,
  getWorkElementTasks,
  createTimeEntryTask,
  getWeeklyHours,
  getAdminProfile,
  updateUser,
  fetchWeeklyCalendarTimeEntries,
  getWorkElementsList,
  getTimeEntryById,
} from "../../store/actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TimeCalendar from "../../components/Calendar/TimeCalendar";
import ProjectsAndTasks from "../../views/ProjectAndTasks/views/ProjectsAndTasks";
import AddTime from "../../views/ProjectAndTasks/components/AddTime";
import WeekView from "../../components/Calendar/WeekView";
import EditWeek from "../../views/ProjectAndTasks/components/EditWeek";
import clsx from "clsx";

const drawerWidth = 700;

const useStyles = makeStyles((theme) => ({
  scrollBox: {
    width: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    "@media (min-width: 600px)": {
      justifyContent: "center",
    },
  },
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    backgroundColor: "rgba(0,0,0,0.0)",
    border: "none",
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fff",
    border: "none",
    borderRadius: 20,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    marginLeft: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const [addTime, setAddTime] = useState(false);
  const [timeEntered, setTimeEntered] = useState(new Date());
  const [companyProjectId, setCompanyProjectId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [projects, setProjects] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [internalCategory, setInternalCategory] = useState({});
  const [internalTask, setInternalTask] = useState({});
  const [from, setFrom] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [to, setTo] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1));
  const [projectType, setProjectType] = useState("");
  const [defaultWorkElementId, setDefaultWorkElementId] = useState("");
  const [defaultCompanyProjectId, setDefaultCompanyProjectId] = useState("");
  const [calView, setCalView] = useState(props.user.timeEntryView);
  const [timeEntryId, setTimeEntryId] = useState("");
  const [changeCalendar, setChangeCalendar] = useState(true);
  const [editTime, setEditTime] = useState(false);
  const [startRefresh, setStartRefresh] = useState(false);
  const [newTime, setNewTime] = useState("");
  const [color, setColor] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(props.user.showTimeEntryProjects);
  const [width, setWidth] = useState("");

  const createWidth = async (params) => {
    let box = document.querySelector("div");
    let width = box.offsetWidth - 60;
    setWidth(width);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const size = useWindowSize();

  useEffect(() => {
    createWidth();
  }, [size[0]]);

  useEffect(() => {
    createWidth();
  }, [drawerOpen]);

  useEffect(() => {
    props.getProjectsList();
    props.getCompaniesList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await props.getTimeEntries(props.userId);
      setProjects([...Object.values(result.response.entities.timeEntriesByProject)]);
    };
    fetchData();
  }, [drawerOpen]);

  useEffect(() => {
    refreshData();
    props.getProjectsList();
    props.getCompaniesList();
    props.getAdminProfile();
  }, [refresh]);

  const refreshData = async () => {
    if (refresh) {
      const result = await props.getTimeEntries(props.userId);
      await props.getWeeklyHours(props.userId);
      setProjects([...Object.values(result.response.entities.timeEntriesByProject)]);
      setRefresh(false);
    }
  };

  const preFillAddTimeDialog = (workElementId, companyProjectId) => {
    setCompanyProjectId(companyProjectId);
    setDefaultWorkElementId(workElementId);
    setAddTime(true);
  };

  const updateTimeEntryView = async (view) => {
    setCalView(view);
    await props.updateUser(props.user.id, { timeEntryView: view });
  };

  return (
    <Box
      mr={2}
      ml={2}
      display="flex"
      alignItems="flex-end"
      position="relative"
      style={{ borderRadius: 10, flexDirection: "column" }}
    >
      <Drawer
        className={classes.drawer}
        style={{ backgroundColor: "transparent" }}
        PaperProps={{ style: { position: "absolute" } }}
        BackdropProps={{ style: { position: "absolute" } }}
        ModalProps={{
          container: document.getElementById("drawer-container"),
          style: { position: "absolute" },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <ProjectsAndTasks
          user={props.user}
          getAdminProfile={props.getAdminProfile}
          updateUser={props.updateUser}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          updateTimeEntry={props.updateTimeEntry}
          setStartRefresh={setStartRefresh}
          updateTimeEntryView={updateTimeEntryView}
          refresh={refresh}
          setRefresh={setRefresh}
          companyProjectId={companyProjectId}
          setCompanyProjectId={setCompanyProjectId}
          setAddTime={setAddTime}
          deleteTimeEntry={props.deleteTimeEntry}
          projects={props.projects}
          updateUserCompanyProject={props.updateUserCompanyProject}
          preFillAddTimeDialog={preFillAddTimeDialog}
          getWeeklyHours={props.getWeeklyHours}
          userId={props.userId}
          getWorkElementTasks={props.getWorkElementTasks}
          createTimeEntryTask={props.createTimeEntryTask}
          weeklyHours={props.weeklyHours}
          getWorkElementsList={props.getWorkElementsList}
          background="linear-gradient(131deg, #626E55 0%, #33433F 50%, #021627 100%)"
        />
      </Drawer>

      <EditWeek
        newTime={newTime}
        setStartRefresh={setStartRefresh}
        setChangeCalendar={setChangeCalendar}
        editTime={editTime}
        setEditTime={setEditTime}
        timeEntryId={timeEntryId}
        internalCategory={internalCategory}
        setInternalCategory={setInternalCategory}
        internalTask={internalTask}
        setInternalTask={setInternalTask}
        getWorkElementsList={props.getWorkElementsList}
        getWorkElementTasks={props.getWorkElementTasks}
        timeEntered={timeEntered}
        setTimeEntered={setTimeEntered}
        updateTimeEntry={props.updateTimeEntry}
        projects={props.projects}
        refresh={refresh}
        fetchWeeklyCalendarTimeEntries={props.fetchWeeklyCalendarTimeEntries}
        userId={props.userId}
        setRefresh={setRefresh}
        timeEntries={props.calendarTimeEntries}
        getTimeEntryById={props.getTimeEntryById}
        deleteTimeEntry={props.deleteTimeEntry}
        getWeeklyHours={getWeeklyHours}
        color={color}
        setTimeEntryId={setTimeEntryId}
      />
      <AddTime
        setStartRefresh={setStartRefresh}
        setChangeCalendar={setChangeCalendar}
        userCompanyId={props.companyId}
        createRequest={props.createRequest}
        adminProjects={props.adminProjects}
        saveCompany={props.saveCompany}
        internalCategory={internalCategory}
        setInternalCategory={setInternalCategory}
        internalTask={internalTask}
        setInternalTask={setInternalTask}
        companyId={companyId}
        setCompanyId={setCompanyId}
        loadCompany={props.loadCompany}
        createCompanyProject={props.kickoffCompanyProjects}
        updateUserCompanyProject={updateUserCompanyProject}
        companies={props.companies}
        projects={projects}
        projectType={projectType}
        setProjectType={setProjectType}
        setProjects={setProjects}
        addTime={addTime}
        userId={props.userId}
        companyProjectId={companyProjectId}
        setCompanyProjectId={setCompanyProjectId}
        setAddTime={setAddTime}
        timeEntered={timeEntered}
        setTimeEntered={setTimeEntered}
        createNewTimeEntry={props.createNewTimeEntry}
        getTimeEntries={props.getTimeEntries}
        setRefresh={setRefresh}
        kickoffProjectFlag={props.kickoffProjectFlag}
        getCompanyProjectWorkElements={props.getCompanyProjectWorkElements}
        getWorkElementTasks={props.getWorkElementTasks}
        createTimeEntryTask={props.createTimeEntryTask}
        defaultWorkElementId={defaultWorkElementId}
        setDefaultWorkElementId={setDefaultWorkElementId}
        defaultCompanyProjectId={defaultCompanyProjectId}
        getWorkElementsList={props.getWorkElementsList}
        timeEntryId={timeEntryId}
      />
      <AuthComponent />

      <main
        style={{ position: "relative", width: !drawerOpen ? "100%" : width - drawerWidth }}
        id="drawer-container"
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        {calView === "week" ? (
          <WeekView
            getWeeklyHours={props.getWeeklyHours}
            updateUser={props.updateUser}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            setNewTime={setNewTime}
            startRefresh={startRefresh}
            setEditTime={setEditTime}
            changeCalendar={changeCalendar}
            setChangeCalendar={setChangeCalendar}
            user={props.user}
            updateTimeEntryView={updateTimeEntryView}
            calView={calView}
            setCalView={setCalView}
            projects={props.projects}
            timeEntered={timeEntered}
            companyProjectId={companyProjectId}
            setCompanyProjectId={setCompanyProjectId}
            setTimeEntered={setTimeEntered}
            setAddTime={setAddTime}
            timeEntries={props.calendarTimeEntries}
            getCalendarTimeEntries={props.getCalendarTimeEntries}
            kickoffProjectFlag={props.kickoffProjectFlag}
            userId={props.userId}
            setColor={setColor}
            fetchWeeklyCalendarTimeEntries={props.fetchWeeklyCalendarTimeEntries}
            timeEntryId={timeEntryId}
            setTimeEntryId={setTimeEntryId}
            refresh={refresh}
            setRefresh={setRefresh}
            getAdminProfile={props.getAdminProfile}
            background="linear-gradient(131deg, #021627 0%, #33433F 50%, #626E55 100%)"
          />
        ) : (
          <TimeCalendar
            getWeeklyHours={props.getWeeklyHours}
            updateUser={props.updateUser}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            changeCalendar={changeCalendar}
            setChangeCalendar={setChangeCalendar}
            user={props.user}
            calView={calView}
            setCalView={setCalView}
            updateTimeEntryView={updateTimeEntryView}
            projects={props.projects}
            timeEntered={timeEntered}
            companyProjectId={companyProjectId}
            setCompanyProjectId={setCompanyProjectId}
            setTimeEntered={setTimeEntered}
            setAddTime={setAddTime}
            timeEntries={props.calendarTimeEntries}
            getCalendarTimeEntries={props.getCalendarTimeEntries}
            kickoffProjectFlag={props.kickoffProjectFlag}
            userId={props.userId}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            refresh={refresh}
            setRefresh={setRefresh}
            getAdminProfile={props.getAdminProfile}
            background="linear-gradient(131deg, #021627 0%, #33433F 50%, #626E55 100%)"
          />
        )}
      </main>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userId: state.user && state.user.id,
    companyId: state.user && state.user.companyId,
    companies:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.companies &&
        state.entities.lists.companies.list) ||
      [],
    adminProjects:
      (state.entities && state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list) ||
      [],
    projects: (state.entities.timeEntriesByProject && Object.values(state.entities.timeEntriesByProject)) || [],
    calendarTimeEntries:
      (state.entities.calendarTimeEntries && Object.values(state.entities.calendarTimeEntries)) || {},
    kickoffProjectFlag:
      state.user &&
      state.user.adminAccess &&
      (state.user.adminAccess.isSuper || (state.user.adminAccess.companies && state.user.adminAccess.companies.create)),
    weeklyHours:
      (state.entities &&
        state.entities.counts &&
        state.entities.counts.weeklyHours &&
        state.entities.counts.weeklyHours.count) ||
      [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    createRequest: (payload) => dispatch(createRequest(payload)),
    getTimeEntries: (id) => dispatch(getTimeEntries(id)),
    saveCompany: (data) => dispatch(saveCompany(data)),
    getCompaniesList: () => dispatch(getCompaniesList()),
    loadCompany: (companyId) => dispatch(loadCompany(companyId)),
    getProjectsList: () => dispatch(getProjectsList()),
    getCalendarTimeEntries: (id, from, to) => dispatch(getCalendarTimeEntries(id, from, to)),
    fetchWeeklyCalendarTimeEntries: (id, from, to) => dispatch(fetchWeeklyCalendarTimeEntries(id, from, to)),
    createNewTimeEntry: (payload) => dispatch(createNewTimeEntry(payload)),
    kickoffCompanyProjects: (data) => dispatch(kickoffCompanyProjects(data)),
    updateTimeEntry: (timeEntryId, payload) => dispatch(updateTimeEntry(timeEntryId, payload)),
    deleteTimeEntry: (timeEntryId) => dispatch(deleteTimeEntry(timeEntryId)),
    updateUserCompanyProject: (id, payload) => dispatch(updateUserCompanyProject(id, payload)),
    getCompanyProjectWorkElements: (id) => dispatch(getCompanyProjectWorkElements(id)),
    getWorkElementTasks: (id) => dispatch(getWorkElementTasks(id)),
    createTimeEntryTask: (data) => dispatch(createTimeEntryTask(data)),
    getWeeklyHours: (userId) => dispatch(getWeeklyHours(userId)),
    getAdminProfile: () => dispatch(getAdminProfile()),
    updateUser: (id, data) => dispatch(updateUser(id, data)),
    getWorkElementsList: (internal) => dispatch(getWorkElementsList(internal)),
    getTimeEntryById: (timeEntryId) => dispatch(getTimeEntryById(timeEntryId)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Dashboard));
