import React from "react";
import { Grid, Box } from "@material-ui/core";
import { useStyles } from "../styles/CustomTable.styles";

export default function ProjectRequestsHeader(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.scrollBoxLower}
      style={{
        color: "white",
        backgroundColor: "#011627",
        fontSize: "1rem",
        fontFamily: "Lato",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={2}>
        <Box style={{ cursor: "pointer" }}>Approval</Box>
      </Grid>
      <Grid item xs={3}>
        <Box style={{ cursor: "pointer" }}>Resource</Box>
      </Grid>
      <Grid item xs={3}>
        <Box style={{ cursor: "pointer" }}>Client</Box>
      </Grid>
      <Grid item xs={3}>
        <Box style={{ cursor: "not-allowed" }}>Project</Box>
      </Grid>
    </Grid>
  );
}
