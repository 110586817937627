import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent";
import MaterialTable from "../../../components/MaterialTable";
import { loadCompanies, fetchCompanyCount, saveUser, updateUser, deleteUser } from "../../../store/actions";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Type",
    field: "type",
  },
  {
    title: "Status",
    field: "status",
  },
];

function Documents(props) {
  const navDocumentDetails = (row) => props.history.push(`/documents/admin/${row.id}`);

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            title=""
            data={(props.data && [...props.data]) || []}
            columns={columns}
            options={{ search: true, paging: true }}
            rowClickFunction={(row) => navDocumentDetails(row)}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.entities.companies,
    pageInfo: state.pagination.companies,
    theme: state.user && state.user.internalStore ? state.user.internalStore.theme : "light",
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadCompanies: (lastTimeStamp, limit, refresh) => dispatch(loadCompanies(lastTimeStamp, limit, refresh)),
    fetchCompanyCount: () => dispatch(fetchCompanyCount()),
    saveUser: (template, payload) => dispatch(saveUser(template, payload)),
    updateUser: (id, payload) => dispatch(updateUser(id, payload)),
    deleteUser: (id, payload) => dispatch(deleteUser(id, payload)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Documents));
