/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";

export const GET_ALL_ARCHIVED_PROJECT_QUEUE_REQUEST = "GET_ALL_ARCHIVED_PROJECT_QUEUE_REQUEST";
export const GET_ALL_ARCHIVED_PROJECT_QUEUE_SUCCESS = "GET_ALL_ARCHIVED_PROJECT_QUEUE_SUCCESS";
export const GET_ALL_ARCHIVED_PROJECT_QUEUE_FAILURE = "GET_ALL_ARCHIVED_PROJECT_QUEUE_FAILURE";

const fetchAllArchivedProjectQueueItems = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [
      GET_ALL_ARCHIVED_PROJECT_QUEUE_REQUEST,
      GET_ALL_ARCHIVED_PROJECT_QUEUE_SUCCESS,
      GET_ALL_ARCHIVED_PROJECT_QUEUE_FAILURE,
    ],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.ARCHIVED_PROJECT_QUEUE_ARRAY,
  },
  refresh,
});

export const getAllArchivedProjectQueueItems = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllArchivedProjectQueueItems(
        `${BASE_URL}/initiation/all/archived?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllArchivedProjectQueueItems(
        `${BASE_URL}/initiation/all/archived?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllArchivedProjectQueueItems(
        `${BASE_URL}/initiation/all/archived?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllArchivedProjectQueueItems(
      `${BASE_URL}/initiation/all/archived?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`
    )
  );
};

export const GET_ARCHIVED_COUNT_PROJECT_QUEUE_REQUEST = "GET_ARCHIVED_COUNT_PROJECT_QUEUE_REQUEST";
export const GET_ARCHIVED_COUNT_PROJECT_QUEUE_SUCCESS = "GET_ARCHIVED_COUNT_PROJECT_QUEUE_SUCCESS";
export const GET_ARCHIVED_COUNT_PROJECT_QUEUE_FAILURE = "GET_ARCHIVED_COUNT_PROJECT_QUEUE_FAILURE";

const fetchArchivedProjectQueueCount = (search) => ({
  [CALL_API]: {
    types: [
      GET_ARCHIVED_COUNT_PROJECT_QUEUE_REQUEST,
      GET_ARCHIVED_COUNT_PROJECT_QUEUE_SUCCESS,
      GET_ARCHIVED_COUNT_PROJECT_QUEUE_FAILURE,
    ],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/count/archived?search=${search}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getArchivedProjectQueueCount = (search) => (dispatch) => {
  return dispatch(fetchArchivedProjectQueueCount(search));
};

export const GET_ALL_PROJECT_QUEUE_REQUEST = "GET_ALL_PROJECT_QUEUE_REQUEST";
export const GET_ALL_PROJECT_QUEUE_SUCCESS = "GET_ALL_PROJECT_QUEUE_SUCCESS";
export const GET_ALL_PROJECT_QUEUE_FAILURE = "GET_ALL_PROJECT_QUEUE_FAILURE";

const fetchAllProjectQueueItems = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_PROJECT_QUEUE_REQUEST, GET_ALL_PROJECT_QUEUE_SUCCESS, GET_ALL_PROJECT_QUEUE_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROJECT_QUEUE_ARRAY,
  },
  refresh,
});

export const getAllProjectQueueItems = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllProjectQueueItems(
        `${BASE_URL}/initiation/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllProjectQueueItems(
        `${BASE_URL}/initiation/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllProjectQueueItems(
        `${BASE_URL}/initiation/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllProjectQueueItems(`${BASE_URL}/initiation/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_PROJECT_QUEUE_REQUEST = "GET_COUNT_PROJECT_QUEUE_REQUEST";
export const GET_COUNT_PROJECT_QUEUE_SUCCESS = "GET_COUNT_PROJECT_QUEUE_SUCCESS";
export const GET_COUNT_PROJECT_QUEUE_FAILURE = "GET_COUNT_PROJECT_QUEUE_FAILURE";

const fetchProjectQueueCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_PROJECT_QUEUE_REQUEST, GET_COUNT_PROJECT_QUEUE_SUCCESS, GET_COUNT_PROJECT_QUEUE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/count?search=${search}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getProjectQueueCount = (search) => (dispatch) => {
  return dispatch(fetchProjectQueueCount(search));
};

export const ADD_PROJECT_TO_QUEUE_REQUEST = "ADD_PROJECT_TO_QUEUE_REQUEST";
export const ADD_PROJECT_TO_QUEUE_SUCCESS = "ADD_PROJECT_TO_QUEUE_SUCCESS";
export const ADD_PROJECT_TO_QUEUE_FAILURE = "ADD_PROJECT_TO_QUEUE_FAILURE";

const createProjectToQueue = (payload) => ({
  [CALL_API]: {
    types: [ADD_PROJECT_TO_QUEUE_REQUEST, ADD_PROJECT_TO_QUEUE_SUCCESS, ADD_PROJECT_TO_QUEUE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROJECT_QUEUE,
  },
});

export const addProjectToQueue = (payload) => (dispatch) => {
  return dispatch(createProjectToQueue(payload));
};

export const GET_QUEUE_ITEM_REQUEST = "GET_QUEUE_ITEM_REQUEST";
export const GET_QUEUE_ITEM_SUCCESS = "GET_QUEUE_ITEM_SUCCESS";
export const GET_QUEUE_ITEM_FAILURE = "GET_QUEUE_ITEM_FAILURE";

const fetchProjectQueueItem = (id) => ({
  [CALL_API]: {
    types: [GET_QUEUE_ITEM_REQUEST, GET_QUEUE_ITEM_SUCCESS, GET_QUEUE_ITEM_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/${id}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROJECT_QUEUE,
  },
});

export const getProjectQueueItemById = (id) => (dispatch) => {
  return dispatch(fetchProjectQueueItem(id));
};

export const UPDATE_PROJECT_BY_ID_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_BY_ID_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_BY_ID_FAILURE = "UPDATE_PROJECT_FAILURE";

const changeProjectQueueItem = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PROJECT_BY_ID_REQUEST, UPDATE_PROJECT_BY_ID_SUCCESS, UPDATE_PROJECT_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.PROJECT_QUEUE,
  },
});

export const updateProjectQueueItem = (id, payload) => (dispatch) => {
  return dispatch(changeProjectQueueItem(id, payload));
};

export const DELETE_PROJECT_FROM_QUEUE_REQUEST = "UPDATE_PROJECT_REQUEST";
export const DELETE_PROJECT_FROM_QUEUE_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FROM_QUEUE_FAILURE = "UPDATE_PROJECT_FAILURE";

const deleteProjectQueueItem = (id) => ({
  [CALL_API]: {
    types: [DELETE_PROJECT_FROM_QUEUE_REQUEST, DELETE_PROJECT_FROM_QUEUE_SUCCESS, DELETE_PROJECT_FROM_QUEUE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PROJECT_QUEUE,
  },
});

export const removeProjectQueueItem = (id) => (dispatch) => {
  return dispatch(deleteProjectQueueItem(id));
};

export const START_PROJECT_FROM_QUEUE_REQUEST = "UPDATE_PROJECT_REQUEST";
export const START_PROJECT_FROM_QUEUE_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const START_PROJECT_FROM_QUEUE_FAILURE = "UPDATE_PROJECT_FAILURE";

const startProjectQueueItem = (id) => ({
  [CALL_API]: {
    types: [START_PROJECT_FROM_QUEUE_REQUEST, START_PROJECT_FROM_QUEUE_SUCCESS, START_PROJECT_FROM_QUEUE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/kickoff/${id}`,
      method: "PUT",
      auth: true,
    },
    schema: Schemas.PROJECT_QUEUE,
  },
});

export const projectKickoff = (id) => (dispatch) => {
  return dispatch(startProjectQueueItem(id));
};

export const GET_PROJECT_QUEUE_LIST_REQUEST = "GET_PROJECT_QUEUE_LIST_REQUEST";
export const GET_PROJECT_QUEUE_LIST_SUCCESS = "GET_PROJECT_QUEUE_LIST_SUCCESS";
export const GET_PROJECT_QUEUE_LIST_FAILURE = "GET_PROJECT_QUEUE_LIST_FAILURE";

const fetchProjectQueueList = () => ({
  [CALL_API]: {
    types: [GET_PROJECT_QUEUE_LIST_REQUEST, GET_PROJECT_QUEUE_LIST_SUCCESS, GET_PROJECT_QUEUE_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/initiation/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getProjectQueueList = () => (dispatch) => {
  return dispatch(fetchProjectQueueList());
};
