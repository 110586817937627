import React from "react";
import Characteristics from "./views/Characteristics";
import Classes from "./views/Classes";
import Components from "./views/Components";
import Categories from "./views/Categories";
import Controls from "./views/Controls";
import Frame from "../../components/Frame/Frame";

const CharacteristicsRouter = (props) => {
  const [value, setValue] = React.useState(0);
  const tabs = ["Characteristics", "Classes", "Components", "Categories", "Controls"];

  const tabsView = () => {
    switch (value) {
      case 0:
        return <Characteristics {...props} />;
      case 1:
        return <Classes {...props} />;
      case 2:
        return <Components {...props} />;
      case 3:
        return <Categories {...props} />;
      case 4:
        return <Controls {...props} />;
    }
  };

  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
};

export default CharacteristicsRouter;
