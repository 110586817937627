import React, { useState } from "react";
import { Box, Popover, IconButton, CircularProgress } from "@material-ui/core";
import BaseButton from "../BaseButton/BaseButton";
import useStyles from "./Dialog.styles";

const Warning = (props) => {
  const classes = useStyles();

  return (
    <Popover
      elevation={props.elevation}
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={() => props.close()}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          border: `2px solid ${props.delete ? "#ff6666" : props.normal ? "#011627" : "#eba024"}`,
          borderRadius: 15,
          maxWidth: props.fitContent ? "" : "230px",
          backgroundImage: props.normal ? 'url("./img/sidepanel.png")' : "#011627",
          backgroundSize: props.normal ? "cover" : "",
          backgroundColor: props.delete
            ? "rgba(255,102,102,0.8)"
            : props.normal
            ? "rgba(1,22,39,.8)"
            : "rgba(235,160,36,.9)",
        }}
      >
        <i
          className={`fas ${props.locked ? "fa-lock-alt" : "fa-exclamation-triangle"} fa-2x`}
          style={{ color: "white", marginTop: 10 }}
          onClick={() => props.confirm()}
        />

        <Box fontSize="0.9rem" p="10px 20px" textAlign="center" color="white" fontFamily="Lato" mt={1}>
          {props.text}
        </Box>
        {props.img ? (
          <Box
            width="400px"
            borderRadius={15}
            m={"15px"}
            // style={{
            //   backgroundImage:
            //     "radial-gradient(ellipse farthest-corner at 45px 45px, rgba(50, 50, 50, 0.8) 0%, rgba(80, 80, 80, 0.2) )",
            // }}
          >
            <img
              src={props.img}
              style={{
                width: "400px",
                borderRadius: 15,
              }}
            />
          </Box>
        ) : null}

        {props.buttons ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box m={1}>
              <BaseButton buttonForDarkG text={props.cancelText} clickHandler={() => props.close()} />
            </Box>
            <Box m={1}>
              <BaseButton
                buttonForDarkY
                icon={props.loading ? <i className="fal fa-circle-notch fa-spin" /> : null}
                text={props.loading ? props.loadingButtonText : props.buttonText}
                clickHandler={() => props.confirm()}
              />
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton>
              <i className="fas fa-ban" style={{ color: "white" }} onClick={() => props.close()} />
            </IconButton>
            {props.loading ? (
              <IconButton disabled>
                <i className="fal fa-circle-notch fa-spin" style={{ color: "white" }} />
              </IconButton>
            ) : (
              <IconButton>
                <i className="fas fa-check-circle" style={{ color: "white" }} onClick={() => props.confirm()} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default Warning;
