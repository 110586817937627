import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { constants } from "../../../utils/common";

function StatusButtons(props) {
  if (props.admin) {
    return (
      <React.Fragment>
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.APPROVED
              ? "This document has been approved"
              : "Approve this document"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.APPROVED)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.APPROVED ? "#0E8C64" : "black",
              }}
              className={`${props.status === constants.DOCUMENT_STATUES.APPROVED ? "fas" : "fal"} fa-check-circle`}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.REJECTED
              ? "This document has been rejected"
              : "Reject this document"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.REJECTED)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.REJECTED ? "#EB6B6C" : "black",
              }}
              className={`${props.status === constants.DOCUMENT_STATUES.REJECTED ? "fas" : "fal"} fa-times-circle`}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.UNDER_REVIEW
              ? "This document is under review"
              : "Start the review process for this document"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.UNDER_REVIEW)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.UNDER_REVIEW ? "#eba024" : "black",
              }}
              className={`${
                props.status === constants.DOCUMENT_STATUES.UNDER_REVIEW ? "fas" : "fal"
              } fa-clipboard-list-check`}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.SUBMITTED
              ? "This document is Submitted"
              : "Change the status to 'submitted'"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.SUBMITTED)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.SUBMITTED ? "#3A5199" : "black",
              }}
              className={`${props.status === constants.DOCUMENT_STATUES.SUBMITTED ? "fas" : "fal"} fa-clock`}
            />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

  switch (props.status) {
    case constants.DOCUMENT_STATUES.APPROVED:
      return (
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.APPROVED
              ? "This document has been approved"
              : "Approve this document"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.APPROVED)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.APPROVED ? "#0E8C64" : "black",
              }}
              className={`${props.status === constants.DOCUMENT_STATUES.APPROVED ? "fas" : "fal"} fa-check-circle`}
            />
          </IconButton>
        </Tooltip>
      );
    case constants.DOCUMENT_STATUES.REJECTED:
      return (
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.REJECTED
              ? "This document has been rejected"
              : "Reject this document"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.REJECTED)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.REJECTED ? "#EB6B6C" : "black",
              }}
              className={`${props.status === constants.DOCUMENT_STATUES.REJECTED ? "fas" : "fal"} fa-times-circle`}
            />
          </IconButton>
        </Tooltip>
      );
    case constants.DOCUMENT_STATUES.UNDER_REVIEW:
      return (
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.UNDER_REVIEW
              ? "This document is under review"
              : "Start the review process for this document"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.UNDER_REVIEW)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.UNDER_REVIEW ? "#eba024" : "black",
              }}
              className={`${
                props.status === constants.DOCUMENT_STATUES.UNDER_REVIEW ? "fas" : "fal"
              } fa-clipboard-list-check`}
            />
          </IconButton>
        </Tooltip>
      );
    case constants.DOCUMENT_STATUES.SUBMITTED:
      return (
        <Tooltip
          title={
            props.status === constants.DOCUMENT_STATUES.SUBMITTED
              ? "This document is Submitted"
              : "Change the status to 'submitted'"
          }
        >
          <IconButton onClick={() => props.changeDocumentStatus(constants.DOCUMENT_STATUES.SUBMITTED)}>
            <i
              style={{
                cursor: "pointer",
                color: props.status === constants.DOCUMENT_STATUES.SUBMITTED ? "#3A5199" : "black",
              }}
              className={`${props.status === constants.DOCUMENT_STATUES.SUBMITTED ? "fas" : "fal"} fa-clock`}
            />
          </IconButton>
        </Tooltip>
      );
    default:
      return <div></div>;
  }
}

export default StatusButtons;
