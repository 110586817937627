export const validationErrors = (payload) => {
  try {
    if (
      !payload.hasOwnProperty("companyId") ||
      (payload.hasOwnProperty("companyId") && (!payload.companyId || payload.companyId.length === 0))
    ) {
      return true;
    }
    if (
      !payload.hasOwnProperty("projectName") ||
      (payload.hasOwnProperty("projectName") && payload.projectName.length === 0)
    ) {
      return true;
    }
    if (!payload.hasOwnProperty("type") || (payload.hasOwnProperty("type") && payload.type.length === 0)) {
      return true;
    }
    if (
      !payload.background.hasOwnProperty("purchaseOrderRequired") ||
      (payload.background.hasOwnProperty("purchaseOrderRequired") && payload.background.purchaseOrderRequired === null)
    ) {
      return true;
    }
    if (
      !payload.background.hasOwnProperty("travelRequired") ||
      (payload.background.hasOwnProperty("travelRequired") && payload.background.travelRequired === null)
    ) {
      return true;
    }
    if (
      !payload.background.hasOwnProperty("salesRepId") ||
      (payload.background.hasOwnProperty("salesRepId") && payload.background.salesRepId.length === 0)
    ) {
      return true;
    }
    if (
      !payload.background.hasOwnProperty("salesEngineerId") ||
      (payload.background.hasOwnProperty("salesEngineerId") && payload.background.salesEngineerId.length === 0)
    ) {
      return true;
    }
    if (
      !payload.hasOwnProperty("roles") ||
      (payload.hasOwnProperty("roles") &&
        (payload.roles.length === 0 || payload.roles.some((role) => role.roleId.length === 0 || role.allocation <= 0)))
    ) {
      return true;
    }

    if (
      !payload.hasOwnProperty("workElements") ||
      (payload.hasOwnProperty("workElements") &&
        (Object.values(payload.workElements).length === 0 ||
          Object.values(payload.workElements).some(
            (we) => !we.startDate || we.duration <= 0 || !we.durationUnit || we.durationUnit.length === 0
          )))
    ) {
      return true;
    }

    return false;
  } catch (err) {
    return true;
  }
};
