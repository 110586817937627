import React from "react";
import { Box, Checkbox, Input, InputLabel, MenuItem, FormControl, Select, Chip, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    height: 20,
  },
}));

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      minWidth: "fit-content",
    },
  },
};

export default function MuiSelect(props) {
  const classes = useStyles();

  return (
    <Box m={2}>
      <Box fontSize="1rem" fontWeight="bold">
        {props.question}
      </Box>
      <FormControl error={props.error} className={classes.formControl}>
        <InputLabel>{props.inputLabel}</InputLabel>
        <Select
          multiple={props.multiple}
          fullWidth
          style={{ height: 30, color: "#2373D8" }}
          value={props.value ? props.value : ""}
          onChange={(e) => props.handleChange(props.target, e.target.value)}
          input={<Input />}
          renderValue={(selected) => (
            <div>
              {props.multiple
                ? selected.map((value, index) => (
                    <Chip
                      key={index}
                      label={value}
                      style={{ height: 20, background: "#2373D8", color: "white", margin: 2 }}
                    />
                  ))
                : props.value}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {props.options.map((name, index) => (
            <MenuItem style={{ padding: 0 }} key={index} value={name.label}>
              <Checkbox
                size="small"
                checked={
                  props.value && props.multiple
                    ? props.value.indexOf(name.label) > -1
                    : props.value
                    ? props.value === name.label
                    : false
                }
              />
              <ListItemText primary={name.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
