import React from "react";
import { Grid, Box, Checkbox } from "@material-ui/core";
import { useStyles } from "../styles/ProjectAndTasks.styles";

export default function TimeEntryHeader(props) {
  const classes = useStyles();
  return (
    <Grid container style={{ fontWeight: "bold", marginBottom: 5 }}>
      {!props.oldProject && (
        <Grid item xs={2}>
          <Box
            style={{
              cursor: "normal",
              borderBottom: "1px dashed #2373D8",
              marginRight: 10,
              textAlign: "center",
            }}
          >
            Actions
          </Box>
        </Grid>
      )}
      <Grid item xs={2}>
        <Box
          style={{
            cursor: "normal",
            borderBottom: "1px dashed #2373D8",
            marginRight: 10,
            textAlign: "center",
          }}
        >
          Date
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box
          style={{
            cursor: "normal",
            borderBottom: "1px dashed #2373D8",
            marginRight: 10,
            textAlign: "center",
          }}
        >
          Hours
        </Box>
      </Grid>
      {props.internal ? (
        <React.Fragment>
          <Grid item xs={3}>
            <Box style={{ cursor: "normal", borderBottom: "1px dashed #2373D8", marginRight: 10, textAlign: "center" }}>
              Category
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box style={{ cursor: "normal", borderBottom: "1px dashed #2373D8", marginRight: 10, textAlign: "center" }}>
              Task
            </Box>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item xs={!props.oldProject ? 6 : 8}>
          <Box style={{ cursor: "normal", borderBottom: "1px dashed #2373D8", marginRight: 10 }}>
            {props.internal ? "Category" : "Tasks"}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
