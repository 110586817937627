import makeStyles from "@material-ui/core/styles/makeStyles";
import { colors } from "../../utils/themes/colors";

const useStyles = makeStyles((theme) => ({
  base: {
    display: "flex",
    alignItems: "center",
    padding: "5px 20px",
    justifyContent: "center",
    borderRadius: 50,
    transition: ".2s all linear",
    cursor: "pointer",
    fontSize: "1rem",
  },
  skinnyBlue: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0px 10px",
    backgroundColor: "#2373D8",
    "&:hover": {
      backgroundColor: "white",
      color: "#2373D8",
    },
  },
  skinnyPink: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0px 10px",
    backgroundColor: colors.RED,
    "&:hover": {
      backgroundColor: "white",
      color: colors.RED,
    },
  },
  skinnyRed: {
    color: "white",
    marginTop: "5px",
    // height: "20px",
    width: "120px",
    fontWeight: "bold",
    padding: "0px !important",
    backgroundColor: colors.RED,
    "&:hover": {
      backgroundColor: "white",
      color: colors.RED,
    },
  },
  skinnyGreen: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0px 10px",
    border: "1px solid #0E8C64",
    backgroundColor: "#0E8C64",
    "&:hover": {
      backgroundColor: "white",
      color: "#0E8C64",
    },
  },
  green: {
    color: "white",
    fontWeight: "bold",
    padding: "5px 20px",
    backgroundColor: "#0E8C64",
    "&:hover": {
      backgroundColor: "white",
      color: "#0E8C64",
    },
  },
  whitePlan: {
    color: "#122F54",
    padding: "5px 20px",
    marginRight: "1rem",
    fontWeight: "bold",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#122F54",
      color: "white",
    },
  },
  buttonForDarkY: {
    color: "white",
    fontWeight: "bold",
    padding: "3px 15px",
    border: "1px solid transparent",
    boxShadow: "1px 1px 2px 0px rgb(255, 205, 55) !important",
    backgroundColor: "transparent",
    "&:hover": {
      border: "1px solid rgb(255, 205, 55)",
    },
  },
  buttonForDarkG: {
    color: "white",
    fontWeight: "bold",
    padding: "3px 15px",
    border: "1px solid transparent",
    boxShadow: "1px 1px 2px 0px #0E8C64 !important",
    backgroundColor: "transparent",
    "&:hover": {
      border: "1px solid #0E8C64",
    },
  },
  whiteButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: "1px solid #122F54",
    color: "#122F54",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#122F54",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "rgba(18,47,84, 0.1)",
      borderColor: "rgba(18,47,84, 0.1)",
      color: "rgba(18,47,84, 0.8)",
    },
  },
  blueButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: "1px solid #122F54",
    color: "white",
    backgroundColor: "#122F54",
    "&:hover": {
      backgroundColor: "white",
      color: "#122F54",
    },
    "&:disabled": {
      backgroundColor: "rgba(18,47,84, 0.1)",
      borderColor: "rgba(18,47,84, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  lightBlueButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: "1px solid #2373D8",
    color: "white",
    backgroundColor: "#2373D8",
    "&:hover": {
      backgroundColor: "white",
      color: "#2373D8",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  redButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: `1px solid ${colors.RED}`,
    color: colors.RED,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: colors.RED,
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  width100: {
    width: "100%",
  },
  hover: {
    transition: "opacity .25s ease-in-out",
    opacity: "0.2",
    color: "white",
    height: "20px",
    width: "120px",
    fontWeight: "bold",
    padding: "0px !important",
    backgroundColor: "#002547",
    "&:hover": {
      opacity: "1",
      backgroundColor: "#002547",
    },
  },
  reverse: {
    transition: "opacity .25s ease-in-out",
    opacity: "1",
    color: "white",
    height: "20px",
    width: "120px",
    fontWeight: "bold",
    padding: "0px !important",
    backgroundColor: "#002547",
    "&:hover": {
      color: "#002547",
      border: "1px solid #002547",
      backgroundColor: "white",
    },
  },
}));

export default useStyles;
