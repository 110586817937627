import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Card, CardContent } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import {
  loadDataTypes,
  fetchDataTypesCount,
  saveDataType,
  loadFrameworksList,
  loadPoliciesList,
  getCharacteristics,
  fetchCharacteristicsCount,
  createCharacteristic,
  updateCharacteristic,
  deleteCharacteristic,
  getClassesList,
  loadProcessesList,
  getComponentsList,
  getCategoriesList,
  loadLawsList,
  getControlsList,
  getProjectsList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import { getColumns } from "../utils/columns";

function Characteristics(props) {
  useEffect(() => {
    props.loadLawsList();
    props.loadPoliciesList();
    props.loadProcessesList();
    props.getClassesList();
    props.getComponentsList();
    props.getCategoriesList();
    props.getControlsList();
    props.getProjectsList();
  }, []);

  const postCharacteristic = async (rowData) => {
    try {
      const payload = {
        ...rowData,
        policyIds: rowData.policies ? [rowData.policies] : [],
        classIds: rowData.classes ? [rowData.classes] : [],
        lawIds: rowData.laws ? rowData.laws.split(",") : [],
        controlIds: rowData.controls ? rowData.controls.split(",") : [],
        processIds: rowData.processes ? [rowData.processes] : [],
        componentIds: rowData.components ? [rowData.components] : [],
        categoryIds: rowData.categories ? [rowData.categories] : [],
      };
      const response = await props.createCharacteristic({ ...payload });
      return response;
    } catch (err) {}
  };

  const updateCharacteristic = async (rowData, oldData) => {
    try {
      const lawIds =
        rowData.laws && Array.isArray(rowData.laws)
          ? rowData.laws.map((law) => law.lawId)
          : rowData.laws
          ? rowData.laws.split(",")
          : [];

      const controlIds =
        rowData.controls && Array.isArray(rowData.controls)
          ? rowData.controls.map((control) => control.controlId)
          : rowData.controls
          ? rowData.controls.split(",")
          : [];

      const policyIds =
        rowData.policies && rowData.policies.policyId
          ? [rowData.policies.policyId]
          : rowData.policies
          ? [rowData.policies]
          : [];

      const processIds =
        rowData.processes && rowData.processes.processId
          ? [rowData.processes.processId]
          : rowData.processes
          ? [rowData.processes]
          : [];

      const classIds =
        rowData.classes && rowData.classes.classId
          ? [rowData.classes.classId]
          : rowData.classes
          ? [rowData.classes]
          : [];

      const componentIds =
        rowData.components && rowData.components.componentId
          ? [rowData.components.componentId]
          : rowData.components
          ? [rowData.components]
          : [];

      const categoryIds =
        rowData.categories && rowData.categories.categoryId
          ? [rowData.categories.categoryId]
          : rowData.categories
          ? [rowData.categories]
          : [];

      const payload = {
        ...rowData,
        policyIds,
        lawIds,
        classIds,
        processIds,
        componentIds,
        categoryIds,
        controlIds,
      };

      const response = await props.updateCharacteristic(rowData.id, { ...payload });
      return response;
    } catch (err) {}
  };

  const deleteCharacteristic = async (rowData) => {
    const response = await props.deleteCharacteristic(rowData.id);
    return response;
  };

  const columns = getColumns(
    props.laws,
    props.policies,
    props.classes,
    props.processes,
    props.components,
    props.categories,
    props.controls,
    props.projects
  );

  return (
    <Grid container spacing={2}>
      <AuthComponent />
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MaterialTable
              backendPagination
              totalCount={0}
              fetchCount={props.fetchCharacteristicsCount}
              paginationCall={props.getCharacteristics}
              title="Characteristics"
              entity="characteristics"
              columns={columns}
              addable={props.create}
              addFunction={(rowData) => postCharacteristic(rowData)}
              editable={props.update}
              editFunction={(newData, oldData) => updateCharacteristic(newData, oldData)}
              deletable={props.delete}
              deleteFunction={(rowData) => deleteCharacteristic(rowData)}
              options={{
                search: true,
                paging: true,
                pageSize: constants.DEFAULT_PAGE_LIMIT,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    policies:
      state.entities && state.entities.lists && state.entities.lists.policies && state.entities.lists.policies.list,
    frameworks:
      state.entities && state.entities.lists && state.entities.lists.frameworks && state.entities.lists.frameworks.list,
    laws: state.entities && state.entities.lists && state.entities.lists.laws && state.entities.lists.laws.list,
    classes:
      state.entities && state.entities.lists && state.entities.lists.classes && state.entities.lists.classes.list,
    processes:
      state.entities && state.entities.lists && state.entities.lists.processes && state.entities.lists.processes.list,
    components:
      state.entities && state.entities.lists && state.entities.lists.components && state.entities.lists.components.list,
    categories:
      state.entities && state.entities.lists && state.entities.lists.categories && state.entities.lists.categories.list,
    controls:
      state.entities && state.entities.lists && state.entities.lists.controls && state.entities.lists.controls.list,
    projects:
      state.entities && state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list,
    characteristics: state.entities.characteristics,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadDataTypes: (page, limit, refresh) => dispatch(loadDataTypes(page, limit, refresh)),
    fetchDataTypesCount: () => dispatch(fetchDataTypesCount()),
    saveDataType: (payload) => dispatch(saveDataType(payload)),
    loadFrameworksList: () => dispatch(loadFrameworksList()),
    loadLawsList: () => dispatch(loadLawsList()),
    loadPoliciesList: () => dispatch(loadPoliciesList()),
    loadProcessesList: () => dispatch(loadProcessesList()),
    getCategoriesList: () => dispatch(getCategoriesList()),
    getProjectsList: () => dispatch(getProjectsList()),
    getComponentsList: () => dispatch(getComponentsList()),
    getControlsList: () => dispatch(getControlsList()),
    getClassesList: () => dispatch(getClassesList()),
    getCharacteristics: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getCharacteristics(page, limit, refresh, searchTerm, sort, by)),
    fetchCharacteristicsCount: (searchTerm) => dispatch(fetchCharacteristicsCount(searchTerm)),
    createCharacteristic: (payload) => dispatch(createCharacteristic(payload)),
    updateCharacteristic: (id, payload) => dispatch(updateCharacteristic(id, payload)),
    deleteCharacteristic: (id) => dispatch(deleteCharacteristic(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Characteristics));
