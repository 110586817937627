import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    boxShadow: "2px 2px 10px 1px #c2cfd6 !important",
    marginTop: "1rem",
    marginLeft: "1rem",
  },
  rootProgress: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    marginLeft: 30,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: { fontSize: 14 },
  pos: { marginBottom: 12 },
  lightBlueButton: {
    padding: "5px 10px",
    textTransform: "capitalize",
    border: "1px solid #2373D8",
    color: "white",
    backgroundColor: "#2373D8",
    transition: "0.4s",
    "&:hover": {
      backgroundColor: "white",
      color: "#2373D8",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  orangeButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: `1px solid ${"#eba024"}`,
    marginRight: "0.5rem",
    color: "#eba024",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#eba024",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  redButton: {
    padding: "5px 20px",
    textTransform: "capitalize",
    border: `1px solid ${"#EB6B6C"}`,
    color: "#EB6B6C",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#EB6B6C",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "rgba(35,115,216, 0.1)",
      borderColor: "rgba(35,115,216, 0.1)",
      color: "rgba(255,255,255, 0.8)",
    },
  },
  select: {
    "&.Mui-selected": {
      outline: "none",
    },
  },
});

export default useStyles;
