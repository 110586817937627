import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import useStyles from "./BaseButton.styles";
import clsx from "clsx";

const BaseButton = (props) => {
  const classes = useStyles();

  let classNames = [classes.base];
  if (props.buttonClass) classNames.push(props.buttonClass);
  else if (props.white) classNames.push(classes.whiteButton);
  else if (props.whitePlan) classNames.push(classes.whitePlan);
  else if (props.skinnyPink) classNames.push(classes.skinnyPink);
  else if (props.lightBlue) classNames.push(classes.lightBlueButton);
  else if (props.red) classNames.push(classes.redButton);
  else if (props.blue) classNames.push(classes.blueButton);
  else if (props.skinnyBlue) classNames.push(classes.skinnyBlue);
  else if (props.skinnyGreen) classNames.push(classes.skinnyGreen);
  else if (props.buttonForDarkY) classNames.push(classes.buttonForDarkY);
  else if (props.buttonForDarkG) classNames.push(classes.buttonForDarkG);
  else if (props.skinnyRed) classNames.push(classes.skinnyRed);
  else if (props.green) classNames.push(classes.green);
  else if (props.hover && !props.reverse) classNames.push(classes.hover);
  else if (props.hover && props.reverse) classNames.push(classes.reverse);
  else classNames.push(classes.blueButton);

  if (props.fullWidth) classNames.push(classes.width100);

  let icon = props.icon ? <Box mr="10px">{props.icon}</Box> : null;
  let text = props.text;
  if (props.loading) {
    icon = <Box mr="10px">{props.loading.icon}</Box>;
    text = props.loading.text;
  }

  const tempIdGenerator = () =>
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  return (
    <Button
      style={{ boxShadow: props.noShadow ? "" : "2px 2px 10px 1px #c2cfd6 !important" }}
      id={props.id ? props.id : tempIdGenerator()}
      disabled={props.disabled ? props.disabled : false}
      onClick={props.clickHandler ? props.clickHandler : null}
      className={clsx(classNames)}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box>{icon}</Box>
        <Box fontSize={props.textSize ? props.textSize : ""}>{text}</Box>
      </Box>
    </Button>
  );
};

export default BaseButton;
