import React from "react";
import { Grid, Box, Checkbox } from "@material-ui/core";
import { useStyles } from "../styles/CustomTable.styles";

export default function Header(props) {
  const { selected, setSelected } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.scrollBoxLower}
      style={{
        color: "white",
        backgroundColor: "#011627",
        fontSize: "1rem",
        fontFamily: "Lato",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
      alignItems="center"
    >
      <Grid item xs={2}>
        <Box
          style={{
            cursor: "normal",
            borderRight: "2px solid white",
            marginRight: 10,
            paddingLeft: 10,
          }}
        >
          Client{" "}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box style={{ cursor: "normal", borderRight: "2px solid white", marginRight: 10 }}>Project Name</Box>
      </Grid>
      <Grid item xs={2}>
        <Box style={{ cursor: "normal", borderRight: "2px solid white", marginRight: 10 }}>Sales Rep</Box>
      </Grid>
      <Grid item xs={2}>
        <Box style={{ cursor: "normal", borderRight: "2px solid white", marginRight: 10 }}>Sales Engineer</Box>
      </Grid>
      <Grid item xs={2}>
        <Box style={{ cursor: "normal", borderRight: "2px solid white", marginRight: 10 }}>Engagement / Dates</Box>
      </Grid>
      <Grid item xs={1}>
        <Box style={{ cursor: "normal" }}>SOW</Box>
      </Grid>
    </Grid>
  );
}
