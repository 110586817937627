import React from "react";
import AccessItem from "./AccessItem";

export default function AccessContainer(props) {
  const { classes, user, data, setData } = props;

  return (
    <React.Fragment>
      <AccessItem
        classes={classes}
        selected={!!user.adminAccess.isSuper ? true : user.adminAccess[props.section].create}
        boolean
        level="Create"
        target="create"
        section={props.section}
        userId={user.id}
        data={user.adminAccess}
        accessId={user.adminAccess.id}
        updatePermissions={props.updatePermissions}
        userData={data}
        setData={setData}
      />
      <AccessItem
        classes={classes}
        selected={!!user.adminAccess.isSuper ? true : user.adminAccess[props.section].read}
        boolean
        level="Read"
        target="read"
        section={props.section}
        userId={user.id}
        data={user.adminAccess}
        accessId={user.adminAccess.id}
        updatePermissions={props.updatePermissions}
        userData={data}
        setData={setData}
      />
      <AccessItem
        classes={classes}
        selected={false}
        selected={!!user.adminAccess.isSuper ? true : user.adminAccess[props.section].update}
        boolean
        level="Update"
        target="update"
        section={props.section}
        userId={user.id}
        data={user.adminAccess}
        accessId={user.adminAccess.id}
        updatePermissions={props.updatePermissions}
        userData={data}
        setData={setData}
      />
      <AccessItem
        classes={classes}
        selected={!!user.adminAccess.isSuper ? true : user.adminAccess[props.section].delete}
        boolean
        level="Delete"
        target="delete"
        section={props.section}
        userId={user.id}
        data={user.adminAccess}
        accessId={user.adminAccess.id}
        updatePermissions={props.updatePermissions}
        userData={data}
        setData={setData}
      />
      {props.section === "companies" && !user.adminAccess.isSuper ? (
        <AccessItem
          classes={classes}
          selected={false}
          dropdownData={props.companies}
          section={props.section}
          userId={user.id}
          data={user.adminAccess}
          accessId={user.adminAccess.id}
          updatePermissions={props.updatePermissions}
          userData={data}
          setData={setData}
        />
      ) : null}
    </React.Fragment>
  );
}
