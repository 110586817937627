import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { constants } from "../../../utils/common";
import {
  getAllMilestones,
  getMilestonesCount,
  saveMilestone,
  updateMilestone,
  deleteMilestone,
  getProjectsList,
} from "../../../store/actions";

const columns = (projects = {}) => [
  { title: "Name", field: "name" },
  { title: "Description", field: "description" },
  { title: "Step", field: "step", type: "numeric" },
  { title: "Sales", field: "isSales", type: "boolean" },
  { title: "Project", field: "projectId", lookup: projects },
];

function Milestones(props) {
  const [projects, setProjects] = useState({});
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await props.getProjectsList();
      if (!result.type.includes("SUCCESS")) throw new Error();

      let projectLookupData = {};
      const data = result.response.entities.lists.projects.list;
      data.forEach((project) => {
        projectLookupData[project.id.toString()] = project.name;
      });

      return setProjects(projectLookupData);
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to fetch projects.` });
    }
  };

  const createMilestone = async (row) => {
    try {
      const result = await props.saveMilestone({
        name: row.name,
        description: row.description,
        step: row.step,
        projectId: row.projectId,
        isSales: row.isSales,
      });
      if (!result.type.includes("SUCCESS")) throw new Error();
      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to create ${row.name}.` });
    }
  };

  const editMilestone = async (row) => {
    try {
      const result = await props.updateMilestone(row.id, {
        name: row.name,
        description: row.description,
        step: row.step,
        projectId: row.projectId,
        isSales: row.isSales,
      });
      if (!result.type.includes("SUCCESS")) throw new Error();
      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to update ${row.name}.` });
    }
  };

  const deleteMilestone = async (row) => {
    try {
      const result = await props.deleteMilestone(row.id);
      if (!result.type.includes("SUCCESS")) throw new Error();
      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to delete ${row.name}.` });
    }
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getMilestonesCount}
            paginationCall={props.getAllMilestones}
            title="Milestones"
            entity="milestones"
            columns={columns(projects)}
            showActions
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(newData) => createMilestone(newData)}
            editFunction={(newData, oldData) => editMilestone(newData)}
            deleteFunction={(newData) => deleteMilestone(newData)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const dispatchToProps = (dispatch) => {
  return {
    getAllMilestones: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getAllMilestones(page, limit, refresh, searchTerm, sort, by)),
    getMilestonesCount: () => dispatch(getMilestonesCount()),
    saveMilestone: (data) => dispatch(saveMilestone(data)),
    updateMilestone: (id, data) => dispatch(updateMilestone(id, data)),
    deleteMilestone: (id) => dispatch(deleteMilestone(id)),
    getProjectsList: () => dispatch(getProjectsList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Milestones));
