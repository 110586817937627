import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

const updateRoleById = (roleId, payload) => ({
  [CALL_API]: {
    types: [UPDATE_ROLE_REQUEST, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/roles/${roleId}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.ROLE,
  },
});

export const changeRoleById = (roleId, payload) => (dispatch) => {
  return dispatch(updateRoleById(roleId, payload));
};

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

const deleteRoleById = (roleId) => ({
  [CALL_API]: {
    types: [DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/roles/${roleId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.ROLE,
  },
});

export const removeRoleById = (roleId) => (dispatch) => {
  return dispatch(deleteRoleById(roleId));
};

export const SAVE_ROLES_REQUEST = "SAVE_ROLES_REQUEST";
export const SAVE_ROLES_SUCCESS = "SAVE_ROLES_SUCCESS";
export const SAVE_ROLES_FAILURE = "SAVE_ROLES_FAILURE";

const postRole = (payload) => ({
  [CALL_API]: {
    types: [SAVE_ROLES_REQUEST, SAVE_ROLES_SUCCESS, SAVE_ROLES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/roles/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.ROLE,
  },
});

export const saveRole = (payload) => (dispatch) => {
  return dispatch(postRole(payload));
};

export const ROLES_LIST_REQUEST = "ROLES_LIST_REQUEST";
export const ROLES_LIST_SUCCESS = "ROLES_LIST_SUCCESS";
export const ROLES_LIST_FAILURE = "ROLES_LIST_FAILURE";

const fetchRolesList = () => ({
  [CALL_API]: {
    types: [ROLES_LIST_REQUEST, ROLES_LIST_SUCCESS, ROLES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/roles/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadRolesList = () => (dispatch) => {
  return dispatch(fetchRolesList());
};

export const ROLES_COUNT_REQUEST = "HUBSPOT_LIST_COUNT_REQUEST";
export const ROLES_COUNT_SUCCESS = "HUBSPOT_LIST_COUNT_SUCCESS";
export const ROLES_COUNT_FAILURE = "HUBSPOT_LIST_COUNT_FAILURE";

const fetchRolesListCount = () => ({
  [CALL_API]: {
    types: [ROLES_COUNT_REQUEST, ROLES_COUNT_SUCCESS, ROLES_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/roles/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchRolesCount = () => (dispatch) => {
  return dispatch(fetchRolesListCount());
};

export const ROLES_REQUEST = "ROLES_REQUEST";
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_FAILURE = "ROLES_FAILURE";

const fetchRoles = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [ROLES_REQUEST, ROLES_SUCCESS, ROLES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.ROLE_ARRAY,
  },
  refresh,
});

export const loadRoles = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchRoles(`${ADMIN_URL}/roles/all?limit=${limit}`, refresh));
  }
  if (refresh && page) {
    return dispatch(fetchRoles(`${ADMIN_URL}/roles/all?limit=${limit}&page=${page}`, refresh));
  }
  if (page) {
    return dispatch(fetchRoles(`${ADMIN_URL}/roles/all?limit=${limit}&page=${page}`));
  }
  return dispatch(fetchRoles(`${ADMIN_URL}/roles/all?limit=${limit}`));
};
