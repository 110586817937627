/**
 *
 */
import * as ActionTypes from "../actions/index";
import merge from "lodash/merge";
import paginate from "./paginate";
import { combineReducers } from "redux";

export const entities = (
  state = {
    users: {},
    companies: {},
    states: {},
    industries: {},
    programs: {},
    processes: {},
    countries: {},
    quizzes: {},
    frameworks: {},
    laws: {},
    requests: {},
    questions: {},
    reporting: {},
    sentEmails: {},
    processRequirements: {},
    programPolicies: {},
    frameworkLaws: {},
    lawDataTypes: {},
    policyDataTypes: {},
    policies: {},
    lawRequirements: {},
    policyRequirements: {},
    reviewedDocuments: {},
    dataTypeCountries: {},
    dataTypeIndustries: {},
    dataTypeOrgImpacts: {},
    dataTypeStates: {},
    payments: {},
    baselines: {},
    implementationPlans: {},
    roadMapLites: {},
    managedServiceDetails: {},
    documents: {},
    emailTemplates: {},
    dataTypes: {},
    hubSpotLists: {},
    scopes: {},
    requirements: {},
    orgImpacts: {},
    roles: {},
    contracts: {},
    proposals: {},
    userRoles: {},
    foundationalElements: {},
    foundationalElementRoles: {},
    foundationalElementPolicies: {},
    scopeDetailsUI: {},
    deliverables: {},
    lists: {},
    counts: {},
    cities: {},
    vendors: {},
    vendorSafeguards: {},
    safeguards: {},
    architectures: {},
    processHealths: {},
    scopeProcesses: {},
    riskAssessments: {},
    clientVendors: {},
    entities: {},
  },
  action
) => {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  return state;
};

export const errorMessage = (state = null, action) => {
  const { type, error } = action;

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return error;
  }

  return state;
};

export const pagination = combineReducers({
  users: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.USERS_REQUEST, ActionTypes.USERS_SUCCESS, ActionTypes.USERS_FAILURE],
  }),
  companies: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.COMPANIES_REQUEST, ActionTypes.COMPANIES_SUCCESS, ActionTypes.COMPANIES_FAILURE],
  }),
  quizzes: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.QUIZZES_REQUEST, ActionTypes.QUIZZES_SUCCESS, ActionTypes.QUIZZES_FAILURE],
  }),
  dataTypes: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.DATATYPES_REQUEST, ActionTypes.DATATYPES_SUCCESS, ActionTypes.DATATYPES_FAILURE],
  }),
  industries: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.INDUSTRIES_REQUEST, ActionTypes.INDUSTRIES_SUCCESS, ActionTypes.INDUSTRIES_FAILURE],
  }),
  states: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.STATES_REQUEST, ActionTypes.STATES_SUCCESS, ActionTypes.STATES_FAILURE],
  }),
  countries: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.COUNTRIES_REQUEST, ActionTypes.COUNTRIES_SUCCESS, ActionTypes.COUNTRIES_FAILURE],
  }),
  programs: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.PROGRAMS_REQUEST, ActionTypes.PROGRAMS_SUCCESS, ActionTypes.PROGRAMS_FAILURE],
  }),
  processes: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.PROCESSES_REQUEST, ActionTypes.PROCESSES_SUCCESS, ActionTypes.PROCESSES_FAILURE],
  }),
  requirements: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.REQUIREMENTS_REQUEST, ActionTypes.REQUIREMENTS_SUCCESS, ActionTypes.REQUIREMENTS_FAILURE],
  }),
  laws: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.LAWS_REQUEST, ActionTypes.LAWS_SUCCESS, ActionTypes.LAWS_FAILURE],
  }),
  frameworks: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.FRAMEWORKS_REQUEST, ActionTypes.FRAMEWORKS_SUCCESS, ActionTypes.FRAMEWORKS_FAILURE],
  }),
  policies: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.POLICIES_REQUEST, ActionTypes.POLICIES_SUCCESS, ActionTypes.POLICIES_FAILURE],
  }),
  reviewedDocuments: paginate({
    mapActionToKey: (action) => action.page,
    types: [
      ActionTypes.REVIEWED_DOCUMENTS_REQUEST,
      ActionTypes.REVIEWED_DOCUMENTS_SUCCESS,
      ActionTypes.REVIEWED_DOCUMENTS_FAILURE,
    ],
  }),
  payments: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.PAYMENTS_REQUEST, ActionTypes.PAYMENTS_SUCCESS, ActionTypes.PAYMENTS_FAILURE],
  }),
  baselines: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.BASELINES_REQUEST, ActionTypes.BASELINES_SUCCESS, ActionTypes.BASELINES_FAILURE],
  }),
  documents: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.DOCUMENTS_REQUEST, ActionTypes.DOCUMENTS_SUCCESS, ActionTypes.DOCUMENTS_FAILURE],
  }),
  orgImpacts: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.ORG_IMPACTS_REQUEST, ActionTypes.ORG_IMPACTS_SUCCESS, ActionTypes.ORG_IMPACTS_FAILURE],
  }),
  sentEmails: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.SENT_EMAIL_REQUEST, ActionTypes.SENT_EMAIL_SUCCESS, ActionTypes.SENT_EMAIL_FAILURE],
  }),
  requests: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.REQUESTS_REQUEST, ActionTypes.REQUESTS_SUCCESS, ActionTypes.REQUESTS_FAILURE],
  }),
  hubSpotLists: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.HUBSPOT_LISTS_REQUEST, ActionTypes.HUBSPOT_LISTS_SUCCESS, ActionTypes.HUBSPOT_LISTS_FAILURE],
  }),
  roles: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.ROLES_REQUEST, ActionTypes.ROLES_SUCCESS, ActionTypes.ROLES_FAILURE],
  }),
  foundationalElements: paginate({
    mapActionToKey: (action) => action.page,
    types: [
      ActionTypes.FOUNDATIONAL_ELEMENTS_REQUEST,
      ActionTypes.FOUNDATIONAL_ELEMENTS_SUCCESS,
      ActionTypes.FOUNDATIONAL_ELEMENTS_FAILURE,
    ],
  }),
  contracts: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.CONTRACTS_REQUEST, ActionTypes.CONTRACTS_SUCCESS, ActionTypes.CONTRACTS_FAILURE],
  }),
  proposals: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.PROPOSALS_REQUEST, ActionTypes.PROPOSALS_SUCCESS, ActionTypes.PROPOSALS_FAILURE],
  }),
  deliverables: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.DELIVERABLES_REQUEST, ActionTypes.DELIVERABLES_SUCCESS, ActionTypes.DELIVERABLES_FAILURE],
  }),
  cities: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.CITIES_REQUEST, ActionTypes.CITIES_SUCCESS, ActionTypes.CITIES_FAILURE],
  }),
  vendors: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.VENDORS_REQUEST, ActionTypes.VENDORS_SUCCESS, ActionTypes.VENDORS_FAILURE],
  }),
  safeguards: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.SAFEGUARD_REQUEST, ActionTypes.SAFEGUARD_SUCCESS, ActionTypes.SAFEGUARD_FAILURE],
  }),
  clientVendors: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.CLIENT_VENDORS_REQUEST, ActionTypes.CLIENT_VENDORS_SUCCESS, ActionTypes.CLIENT_VENDORS_FAILURE],
  }),
  entities: paginate({
    mapActionToKey: (action) => action.page,
    types: [ActionTypes.ENTITIES_REQUEST, ActionTypes.ENTITIES_SUCCESS, ActionTypes.ENTITIES_FAILURE],
  }),
  riskAssessments: paginate({
    mapActionToKey: (action) => action.page,
    types: [
      ActionTypes.RISK_ASSESSMENTS_REQUEST,
      ActionTypes.RISK_ASSESSMENTS_SUCCESS,
      ActionTypes.RISK_ASSESSMENTS_FAILURE,
    ],
  }),
});
