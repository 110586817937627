import { lightTheme } from "./themes/light";

export default function setTheme(mode) {
  switch (mode) {
    case "light":
      return lightTheme;
    default:
      return lightTheme;
  }
}
