import React, { useState, useEffect } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import InputMask from "react-input-mask";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ClientInfo(props) {
  const classes = useStyles();

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.area}>
      <Box className={classes.titles}>Client Information</Box>
      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <TextField
                value={props.orgLoc}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setOrgLoc(e.target.value);
                }}
                size="small"
                label="Organization Location"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={100} />
            ) : (
              <TextField
                value={props.orgBack}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setOrgBack(e.target.value);
                }}
                label="Organization Background"
                size="small"
                rows={2}
                multiline
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <TextField
                value={props.contactName}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setContactName(e.target.value);
                }}
                size="small"
                label="Contact Name"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <TextField
                value={props.contactTitle}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setContactTitle(e.target.value);
                }}
                size="small"
                label="Contact Title"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <TextField
                value={props.contactEmail}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setContactEmail(e.target.value);
                }}
                size="small"
                label="Contact Email"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" height={50} />
            ) : (
              <InputMask
                mask="999-999-9999"
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.setContactPhone(e.target.value);
                }}
                value={props.contactPhone}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    size="small"
                    label="Contact Phone"
                    fullWidth
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </InputMask>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
