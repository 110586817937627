import React from "react";
import { Prompt } from "react-router-dom";

const NavigationBlocker = (props) => {
  return (
    <Prompt
      when={props.navigationBlocked}
      message="Any unsaved changes will be lost. Are you sure you want to leave this page?"
    />
  );
};

export default NavigationBlocker;
