import React, { useEffect, useState } from "react";
import { Grid, TextField, Select as MuiSelect, MenuItem, Button } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeTemplateById, fetchEmailTemplates, insertEmailTemplate } from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import Dialog from "../../../components/Dialog/Dialog";

const columns = [
  {
    title: "File Name",
    field: "fileName",
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "For Students",
    field: "isStudent",
    type: "boolean",
  },
];

const EmailTemplates = (props) => {
  const [addTemplateDialog, setAddTemplateDialog] = useState(false);
  const [templateDialogType, setTemplateDialogType] = useState("POST");
  const [refresh, setRefresh] = useState(false);
  const [template, setTemplate] = useState({
    id: "",
    fileName: "",
    type: "",
    subject: "",
    currentYear: "",
    currentCompany: "",
    mailingAddress: "",
    updateProfileLink: "",
    unSubscribeFromEmails: "",
  });

  useEffect(() => {
    props.fetchEmailTemplates();
  }, []);

  const refreshTable = () => {
    setRefresh(true);
    setTimeout(() => setRefresh(false), 500);
  };

  const addNewTemplate = () => {
    setTemplate({
      id: "",
      fileName: "",
      type: "",
      subject: "",
      currentYear: "",
      currentCompany: "",
      mailingAddress: "",
      updateProfileLink: "",
      unSubscribeFromEmails: "",
    });
    setTemplateDialogType("POST");
    setAddTemplateDialog(true);
  };

  const updateEmailTemplate = async (row) => {
    const result = await props.changeTemplateById(row.id, { ...row });
    refreshTable();
    return result;
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Dialog
        open={addTemplateDialog}
        onClose={() => setAddTemplateDialog(false)}
        toggle={() => setAddTemplateDialog(!addTemplateDialog)}
        dialogTitle={<p> Create Email Template </p>}
        dialogContent={
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="standard-fileName"
                label="fileName"
                value={template.fileName}
                onChange={(e) =>
                  setTemplate({
                    ...template,
                    fileName: e.target.value,
                  })
                }
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <MuiSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={template.type}
                onChange={(e) => setTemplate({ ...template, type: e.target.value })}
              >
                {constants.EMAIL_TYPES_LIST.map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </MuiSelect>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-currentYear"
                label="currentYear"
                value={template.currentYear}
                onChange={(e) => setTemplate({ ...template, currentYear: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-subject"
                label="subject"
                value={template.subject}
                onChange={(e) => setTemplate({ ...template, subject: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-mailingAddress"
                label="mailingAddress"
                value={template.mailingAddress}
                onChange={(e) => setTemplate({ ...template, mailingAddress: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-updateProfileLink"
                label="updateProfileLink"
                value={template.updateProfileLink}
                onChange={(e) => setTemplate({ ...template, updateProfileLink: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-unSubscribeFromEmails"
                label="unSubscribeFromEmails"
                value={template.unSubscribeFromEmails}
                onChange={(e) => setTemplate({ ...template, unSubscribeFromEmails: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>
        }
        dialogActions={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={() => {
                  if (templateDialogType === "POST") {
                    props.insertEmailTemplate(template);
                  } else {
                    props.changeTemplateById(template.id, template);
                  }
                  refreshTable();
                  return setAddTemplateDialog(false);
                }}
              >
                {templateDialogType === "POST" ? "ADD" : "UPDATE"} <i className="fa fa-check fa-2x" />
              </Button>
              <Button color="red" onClick={() => setAddTemplateDialog(!addTemplateDialog)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable
            data={props.emailTemplates}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
              pageSizeOptions: [5, 10, 20, 50],
            }}
            title="Email Templates"
            columns={columns}
            showActions={true}
            customActions={
              props.create
                ? [
                    {
                      tooltip: "Create Template",
                      icon: () => <i className="fal fa-plus" />,
                      isFreeAction: true,
                      onClick: () => addNewTemplate(),
                    },
                  ]
                : null
            }
            editable={props.update}
            editFunction={(newData, oldData) => updateEmailTemplate(newData)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    emailTemplates: Object.values(state.entities.emailTemplates),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    fetchEmailTemplates: () => dispatch(fetchEmailTemplates()),
    changeTemplateById: (id, payload) => dispatch(changeTemplateById(id, payload)),
    insertEmailTemplate: (data) => dispatch(insertEmailTemplate(data)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(EmailTemplates));
