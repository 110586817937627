import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser, getAdminProfile, updateUserPassword } from "../../store/actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProfileSettings from "./views/ProfileSettings";
import Frame from "../../components/Frame/Frame";

const useStyles = makeStyles((theme) => ({}));

function MyProfile(props) {
  //   const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [randomColor, setRandomColor] = useState("");
  const tabs = ["Profile Settings"];

  useEffect(() => {
    getRandomRolor();
  }, []);

  function getRandomRolor() {
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return setRandomColor(color);
  }

  const tabsView = () => {
    switch (value) {
      case 0:
        return <ProfileSettings randomColor={randomColor} {...props} />;
    }
  };

  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.user && state.user.id,
    user: state.user,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    updateUser: (id, payload) => dispatch(updateUser(id, payload)),
    getAdminProfile: () => dispatch(getAdminProfile()),
    updateUserPassword: (id, payload) => dispatch(updateUserPassword(id, payload)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(MyProfile));
