/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const SAVE_PARTNER_REQUEST = "SAVE_PARTNER_REQUEST";
export const SAVE_PARTNER_SUCCESS = "SAVE_PARTNER_SUCCESS";
export const SAVE_PARTNER_FAILURE = "SAVE_PARTNER_FAILURE";

const createPartner = (payload) => ({
  [CALL_API]: {
    types: [SAVE_PARTNER_REQUEST, SAVE_PARTNER_SUCCESS, SAVE_PARTNER_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/partners`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PARTNERS_ARRAY,
  },
});

export const savePartner = (payload) => (dispatch) => {
  return dispatch(createPartner(payload));
};

export const UPDATE_PARTNER_REQUEST = "UPDATE_PARTNER_REQUEST";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";

const changePartner = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PARTNER_REQUEST, UPDATE_PARTNER_SUCCESS, UPDATE_PARTNER_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/partners/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.PARTNERS,
  },
});

export const updatePartner = (id, payload) => (dispatch) => {
  return dispatch(changePartner(id, payload));
};

export const DELETE_PARTNER_REQUEST = "DELETE_PARTNER_REQUEST";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_FAILURE = "DELETE_PARTNER_FAILURE";

const removePartner = (id) => ({
  [CALL_API]: {
    types: [DELETE_PARTNER_REQUEST, DELETE_PARTNER_SUCCESS, DELETE_PARTNER_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/partners/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PARTNERS,
  },
});

export const deletePartner = (id) => (dispatch) => {
  return dispatch(removePartner(id));
};

export const GET_ALL_PARTNERS_REQUEST = "GET_ALL_PARTNERS_REQUEST";
export const GET_ALL_PARTNERS_SUCCESS = "GET_ALL_PARTNERS_SUCCESS";
export const GET_ALL_PARTNERS_FAILURE = "GET_ALL_PARTNERS_FAILURE";

const fetchAllPartners = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_PARTNERS_REQUEST, GET_ALL_PARTNERS_SUCCESS, GET_ALL_PARTNERS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PARTNERS_ARRAY,
  },
  refresh,
});

export const getAllPartners = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllPartners(`${ADMIN_URL}/partners/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllPartners(
        `${ADMIN_URL}/partners/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllPartners(
        `${ADMIN_URL}/partners/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllPartners(`${ADMIN_URL}/partners/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_PARTNERS_REQUEST = "GET_COUNT_PARTNERS_REQUEST";
export const GET_COUNT_PARTNERS_SUCCESS = "GET_COUNT_PARTNERS_SUCCESS";
export const GET_COUNT_PARTNERS_FAILURE = "GET_COUNT_PARTNERS_FAILURE";

const fetchPartnersCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_PARTNERS_REQUEST, GET_COUNT_PARTNERS_SUCCESS, GET_COUNT_PARTNERS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/partners/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getPartnersCount = (search) => (dispatch) => {
  return dispatch(fetchPartnersCount(search));
};

export const PARTNER_LIST_REQUEST = "PARTNER_LIST_REQUEST";
export const PARTNER_LIST_SUCCESS = "PARTNER_LIST_SUCCESS";
export const PARTNER_LIST_FAILURE = "PARTNER_LIST_FAILURE";

const fetchPartnerList = () => ({
  [CALL_API]: {
    types: [PARTNER_LIST_REQUEST, PARTNER_LIST_SUCCESS, PARTNER_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/partners/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getPartnerList = () => (dispatch) => {
  return dispatch(fetchPartnerList());
};
