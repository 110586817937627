import makeStyles from "@material-ui/core/styles/makeStyles";
import { colors } from "../../utils/themes/colors";

const useStyles = makeStyles({
  paddingLeft20: {
    paddingLeft: "20px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  exitButton: {
    fontSize: "1.5rem",
    paddingTop: "6px",
  },
  dialog: {
    borderRadius: "12px",
    backgroundColor: "white",
    "@media (min-width: 800px)": {
      minWidth: "600px",
    },
  },
  introDialog: {
    borderRadius: "12px",
    "@media (min-width: 800px)": {
      minWidth: "1100px",
    },
  },
  fixedDialog: {
    borderRadius: "12px",
    backgroundColor: "white",
    "@media (min-width: 800px)": {
      minWidth: "600px",
    },
    height: "32rem",
  },
  contentProps: {
    // paddingLeft: "25px",
    // paddingRight: "25px",
    color: "black",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  header: {
    backgroundColor: colors.MENUBLUE,
    color: "white",
    fontSize: "1.5rem",
    fontFamily: "Lato",
    height: "30px",
    borderRadius: "10px 10px 0 0",
    padding: "25px",
    marginBottom: "2rem",
  },
  smallHeader: {
    backgroundColor: colors.MENUBLUE,
    height: "20px",
    borderRadius: "10px 10px 0 0",
  },
  warningHeader: {
    backgroundColor: colors.RED,
    color: "white",
    fontSize: ".9rem",
    fontFamily: "Lato",
    height: "10px",
    borderRadius: "10px",
    padding: "10px 15px 10px 50px",
    marginBottom: "1rem",
  },
  iconBox: {
    background: "white",
    borderRadius: "12px",
    height: "70px",
    width: "70px",
    float: "right",
    marginTop: "20px",
    position: "relative",
    boxShadow: "rgba(139,163,198,.3) 0px 15px 15px 5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconColor: {
    color: colors.CSBLUE,
  },
});

export default useStyles;
