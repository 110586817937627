/**
 *
 */
import * as api from "../../utils/api_helper";
import * as auth from "../../utils/auth_helper";
import { constants, doesUserContainRoles } from "../../utils/common";

export const LOGIN = "LOGIN";
export function login(email, password) {
  return (dispatch) => {
    dispatch(isLoggingIn(true));
    return api.authenticateAdmin(email, password).then((response) => {
      if (response.error) {
        return response;
      } else {
        dispatch({ type: LOGIN });
        dispatch(getAdminProfile());
        return Promise.resolve(true);
      }
    });
  };
}

export const LOGOUT = "LOGOUT";
export function logout() {
  return (dispatch) => {
    auth.logout();
    dispatch({
      type: LOGOUT,
    });
  };
}

export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE";
export function getAdminProfile() {
  return (dispatch) =>
    api
      .getAdminProfile()
      .then((response) => response.json())
      .then((user) => {
        const validAdminUser = user && user.result && user.result.email.split("@")[1] === "cisoshare.com";
        if (validAdminUser) {
          dispatch({
            type: GET_ADMIN_PROFILE,
            payload: user.result,
          });
        } else {
          return Promise.reject("Not Authorized to access here.");
        }
      });
}

export const VERIFY_ADMIN_PROFILE = "VERIFY_ADMIN_PROFILE";
export function verifyAdminProfile(body) {
  return (dispatch) =>
    api
      .verifyAdminProfile(body)
      .then((response) => response.json())
      .then((user) => {
        dispatch({
          type: VERIFY_ADMIN_PROFILE,
          payload: user.result,
        });
        return user;
      });
}

export const IS_LOGGED_IN = "IS_LOGGED_IN";
export function isLoggedIn() {
  return (dispatch) => {
    return auth
      .isLoggedIn()
      .then((loggedIn) => {
        const message = {
          type: loggedIn ? IS_LOGGED_IN : LOGOUT,
          isLoggedIn: loggedIn ? loggedIn : false,
        };

        dispatch(message);
      })
      .catch((err) => {
        dispatch({
          type: LOGOUT,
        });
      });
  };
}

export const IS_LOGGING_IN = "IS_LOGGING_IN";
export function isLoggingIn(loggingIn) {
  return {
    type: IS_LOGGING_IN,
    payload: {
      isLoggingIn: loggingIn,
    },
  };
}

export const UPDATE_INTERNAL_STORE = "UPDATE_INTERNAL_STORE";
export function updateInternalState(value) {
  return (dispatch) => {
    if (value === "darkTheme") {
      dispatch({
        type: UPDATE_INTERNAL_STORE,
        payload: {
          theme: "dark",
        },
      });
    }
    if (value === "lightTheme") {
      dispatch({
        type: UPDATE_INTERNAL_STORE,
        payload: {
          theme: "light",
        },
      });
    }
  };
}
