import React, { useState, useEffect } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Button, Grid } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddNewProjectItem from "./AddNewProjectItem";
import { DEFAULT_NEW_RESOURCE } from "../utils/helpers";
import { DatePicker } from "../utils/helpers";

function AutoComplete(props) {
  return (
    <Box>
      <Autocomplete
        disabled={props.skip}
        size="small"
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        onChange={(e, newValue) => props.updateResource(props.objKey, props.projectId, props.id, newValue)}
        renderInput={(params) => (
          <TextField {...params} style={{ width: 200, borderRadius: 50 }} variant="outlined" label={props.label} />
        )}
      />
    </Box>
  );
}
export default function AddNewProject(props) {
  const [company, setCompany] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const addNewResource = (projectId) => {
    let currentProjectData = projectData;
    currentProjectData[projectId].resources = [
      ...currentProjectData[projectId].resources,
      { id: Math.random().toString(36).substring(7), projectId, ...DEFAULT_NEW_RESOURCE },
    ];
    setProjectData({ ...currentProjectData });
  };

  const updateResource = async (key, projectId, id, value) => {
    let currentProjectData = projectData;
    currentProjectData[projectId].resources.find((cr) => cr.id === id)[key] = value;
    return setProjectData({ ...currentProjectData });
  };

  const updateProject = async (projectId, key, value) => {
    let currentProjectData = projectData;
    currentProjectData[projectId][key] = value;
    return setProjectData({ ...currentProjectData });
  };

  const postNewProjects = async () => {
    let payload = {};
    if (!(company && company.id) && companyName.length === 0) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Fill out organization information" });
    }
    if (projects.length === 0) {
      return setSnackbar({ isOpen: true, variant: "error", message: "At least enter one project" });
    }

    if (Object.values(projectData).some((project) => project.type.length === 0)) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Enter the type of project for every project" });
    }

    if (Object.values(projectData).some((project) => project.resources.length === 0)) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Add resources to every project" });
    }

    const resources = Object.values(projectData)
      .map((project) => project.resources)
      .flat();

    if (
      resources.some(
        (resource) =>
          !resource.skip &&
          ((resource.user && !resource.user.hasOwnProperty("id")) ||
            (resource.role && !resource.role.hasOwnProperty("id")) ||
            resource.allocation === 0)
      )
    ) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Fully fill out each role" });
    }
    setLoading(true);
    if (company && company.id) {
      payload.companyId = company.id;
    } else if (companyName) {
      const result = await props.saveCompany({ name: companyName, clientType: "MS" });
      payload.companyId = Object.keys(result.response.entities.companies)[0];
    }

    for await (const projectId of Object.keys(projectData)) {
      const project = projectData[projectId];
      payload.type = project.type;
      payload.status = "Not Started";
      payload.tags = project.tags;
      payload.description = project.description;

      const assignments = project.resources
        .filter((r) => !r.skip)
        .map((resource) => {
          return { userId: resource.user.id, roleId: resource.role.id, allocation: resource.allocation };
        });
      const subPayload = { ...payload, projectId, assignments };
      await props.kickoffCompanyProjects(subPayload);
    }
    setLoading(false);
    props.setAddNewProject(false);
    setTags([]);
    setProjects([]);
    setCompany(null);
    setProjectData({});
    setCompanyName("");
    props.fetchData();
  };

  const handleDateStart = (date) => {
    setStartDate(date);
  };

  const handleDateEnd = (date) => {
    setEndDate(date);
  };

  return (
    <Box>
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Dialog
        style={{
          background: "rgba(255,255,255, .9)",
        }}
        open={props.addNewProject}
        onClose={() => {
          setTags([]);
          setProjects([]);
          setCompany(null);
          setProjectData({});
          setCompanyName("");
          props.setAddNewProject(false);
        }}
        maxWidth="xl"
      >
        <DialogTitle style={{ background: "#011627", color: "white" }}>NEW PROJECT / TASK</DialogTitle>
        <DialogContent
          style={{ height: "700px", overflow: "auto", display: "flex", flexDirection: "column", marginTop: 20 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ width: "100%" }}
            justifyContent="center"
            alignItems="center"
          >
            <Box mb={3} display="flex" alignItems="center" justifyContent="center">
              <Autocomplete
                size="small"
                options={props.companies}
                getOptionLabel={(option) => option.name}
                value={company}
                onChange={(e, newVal) => {
                  setCompany(newVal);
                  setCompanyName("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: 300, borderRadius: 50 }}
                    variant="outlined"
                    label="Pick Existing Organization"
                  />
                )}
              />
              <Box mr={5} ml={5}>
                OR
              </Box>
              <TextField
                size="small"
                style={{ width: 300, borderRadius: 50 }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="New Organization"
                type="string"
                value={companyName}
                onChange={(e, newVal) => {
                  setCompany(null);
                  setCompanyName(e.target.value);
                }}
              />
            </Box>
          </Box>
          <i className="fas fa-chevron-down fa-2x" style={{ color: "#2373D8", alignSelf: "center" }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{ width: 700 }}
            mt={3}
            mb={3}
          >
            <Box mb={2} width="100%">
              <TextField
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                size="small"
                label="Project Name"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Grid container spacing={2} style={{ marginBottom: 16 }}>
              <Grid item xs={12} sm={6}>
                <DatePicker value={startDate} onChange={handleDateStart} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker value={endDate} onChange={handleDateEnd} />
              </Grid>
            </Grid>

            <Autocomplete
              fullWidth
              size="small"
              options={props.workElements}
              getOptionLabel={(option) => option.name}
              value={projects}
              filterSelectedOptions
              disableCloseOnSelect
              clearOnEscape
              limitTags={4}
              multiple
              onChange={(e, newVal) => {
                let currentProjectData = projectData;
                if (
                  Object.keys(currentProjectData).length > 0 &&
                  newVal.length < Object.keys(currentProjectData).length
                ) {
                  let filteredProjectData = {};
                  Object.keys(currentProjectData).forEach((key) => {
                    if (!!newVal.find((val) => val.id === key)) {
                      filteredProjectData[key] = currentProjectData[key];
                    }
                  });
                  setProjectData({ ...filteredProjectData });
                  return setProjects(newVal);
                }

                const newItem = newVal[newVal.length - 1];

                currentProjectData = {
                  ...currentProjectData,
                  [newItem.id]: { name: newItem.name, type: "", tags: [], description: "", resources: [] },
                };

                setProjectData({ ...currentProjectData });
                setProjects(newVal);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%", borderRadius: 50 }}
                  variant="outlined"
                  label="Add Work Elements"
                />
              )}
            />
          </Box>
          <i className="fas fa-chevron-down fa-2x" style={{ color: "#2373D8", alignSelf: "center" }} />
          <Box mt={4}>
            {Object.keys(projectData).map((key, index) => {
              const project = projectData[key];
              return (
                <AddNewProjectItem
                  key={key}
                  projectId={key}
                  index={index}
                  tags={tags}
                  project={project}
                  addNewResource={addNewResource}
                  updateResource={updateResource}
                  updateProject={updateProject}
                  AutoComplete={AutoComplete}
                  {...props}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions style={{ background: "#011627", color: "white" }}>
          <Button onClick={() => props.setAddNewProject(false)} style={{ color: "white", fontWeight: "bold" }}>
            Cancel
          </Button>
          <Button onClick={() => postNewProjects()} style={{ color: "white", fontWeight: "bold" }}>
            {loading ? <i class="fas fa-cog fa-spin" /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
