/**
 *
 */
import { normalize, schema } from "normalizr";
import { host } from "../../config/api_config";
import request from "../../utils/request_helper";

// Extracts the next page URL from Github API response.
const getNextPageUrl = (response, json) => {
  const resultLength = json.result.length;
  if (!resultLength) {
    return null;
  }

  if (json.nextPageUrl) {
    return json.nextPageUrl !== "done" ? json.nextPageUrl : null;
  }

  const urlParts = response.url.split("?");
  const urlParamsPart = urlParts[1];

  if (!urlParamsPart) {
    // assume we already got page 1
    return urlParts[0] + "?page=2";
  }

  const urlParams = urlParamsPart.split("&");
  const urlParamsMap = {};
  urlParams.forEach((urlParamSet) => {
    const paramSet = urlParamSet.split("=");
    urlParamsMap[paramSet[0]] = paramSet[1];
  });

  // If page is not found default to page 2, otherwise increment page
  urlParamsMap.page = urlParamsMap.page ? parseInt(urlParamsMap.page, 10) + 1 : 2;
  urlParamsMap.size = urlParamsMap.size ? parseInt(urlParamsMap.size, 10) : 10;

  const paramsToAttach = Object.keys(urlParamsMap)
    .map((key) => {
      return [`${key}=${urlParamsMap[key]}`];
    })
    .join("&");

  return urlParts[0] + `?${paramsToAttach}`;

  // const link = response.headers.get('link');
  // if (!link) {
  //   return null;
  // }
  //
  // const nextLink = link.split(',').find(s => s.indexOf('rel="next"') > -1);
  // if (!nextLink) {
  //   return null;
  // }
  //
  // return nextLink.split(';')[0].slice(1, -1)
};

// const API_ROOT = 'https://api.github.com/';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (reqInfo, schema) => {
  // const fullUrl = (endpoint.indexOf(host) === -1) ? host + endpoint : endpoint;

  return request(reqInfo).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      const nextPageUrl = getNextPageUrl(response, json);
      const totalCount = json.count;

      return Object.assign({}, normalize(json.result, schema), {
        nextPageUrl,
        totalCount,
      });
    })
  );
};

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

const userSchema = new schema.Entity(
  "users",
  {},
  {
    idAttribute: (user) => user.id,
  }
);

const countSchema = new schema.Entity(
  "counts",
  {},
  {
    idAttribute: (count) => count.id,
  }
);

const companySchema = new schema.Entity(
  "companies",
  {},
  {
    idAttribute: (company) => company.id,
  }
);

const stateSchema = new schema.Entity(
  "states",
  {},
  {
    idAttribute: (state) => state.id,
  }
);

const countrySchema = new schema.Entity(
  "countries",
  {},
  {
    idAttribute: (country) => country.id,
  }
);

const documentSchema = new schema.Entity(
  "documents",
  {},
  {
    idAttribute: (document) => document.id,
  }
);

const industrySchema = new schema.Entity(
  "industries",
  {},
  {
    idAttribute: (industry) => industry.id,
  }
);

const quizSchema = new schema.Entity(
  "quizzes",
  {},
  {
    idAttribute: (quiz) => quiz.id,
  }
);

const programSchema = new schema.Entity(
  "programs",
  {},
  {
    idAttribute: (program) => program.id,
  }
);

const processSchema = new schema.Entity(
  "processes",
  {},
  {
    idAttribute: (process) => process.id,
  }
);

const requirementSchema = new schema.Entity(
  "requirements",
  {},
  {
    idAttribute: (requirement) => requirement.id,
  }
);

const reviewedDocumentsSchema = new schema.Entity(
  "reviewedDocuments",
  {},
  {
    idAttribute: (reviewedDocument) => reviewedDocument.id,
  }
);

const commentsSchema = new schema.Entity(
  "comments",
  {},
  {
    idAttribute: (comment) => comment.id,
  }
);

const lawsSchema = new schema.Entity(
  "laws",
  {},
  {
    idAttribute: (law) => law.id,
  }
);

const dataTypeSchema = new schema.Entity(
  "dataTypes",
  {},
  {
    idAttribute: (dataType) => dataType.id,
  }
);

const listSchema = new schema.Entity(
  "lists",
  {},
  {
    idAttribute: (list) => list.id,
  }
);

const reqProgramsSchema = new schema.Entity(
  "requirementPrograms",
  {},
  {
    idAttribute: (reqProgram) => reqProgram.id,
  }
);

const lawReqsSchema = new schema.Entity(
  "lawRequirements",
  {},
  {
    idAttribute: (lawReq) => lawReq.id,
  }
);

const lawDataTypesSchema = new schema.Entity(
  "lawDataTypes",
  {},
  {
    idAttribute: (lawDataType) => lawDataType.id,
  }
);

const policiesSchema = new schema.Entity(
  "policies",
  {},
  {
    idAttribute: (policy) => policy.id,
  }
);

const policydataTypesSchema = new schema.Entity(
  "policydataTypes",
  {},
  {
    idAttribute: (policydataType) => policydataType.id,
  }
);

const processRequirementsSchema = new schema.Entity(
  "processRequirements",
  {},
  {
    idAttribute: (processRequirement) => processRequirement.id,
  }
);

const programPoliciesSchema = new schema.Entity(
  "programPolicies",
  {},
  {
    idAttribute: (programPolicy) => programPolicy.id,
  }
);

const frameworksSchema = new schema.Entity(
  "frameworks",
  {},
  {
    idAttribute: (framework) => framework.id,
  }
);

const frameworkLawsSchema = new schema.Entity(
  "frameworkLaws",
  {},
  {
    idAttribute: (frameworkLaw) => frameworkLaw.id,
  }
);

const frameworkPoliciesSchema = new schema.Entity(
  "frameworkPolicies",
  {},
  {
    idAttribute: (frameworkPolicy) => frameworkPolicy.id,
  }
);

const frameworkDataTypesSchema = new schema.Entity(
  "frameworkDataTypes",
  {},
  {
    idAttribute: (frameworkDataType) => frameworkDataType.id,
  }
);

const questionsSchema = new schema.Entity(
  "questions",
  {},
  {
    idAttribute: (question) => question.id,
  }
);

const dataTypeIndustriesSchema = new schema.Entity(
  "dataTypeIndustries",
  {},
  {
    idAttribute: (dataTypeIndustry) => dataTypeIndustry.id,
  }
);

const dataTypeCountriesSchema = new schema.Entity(
  "dataTypeCountries",
  {},
  {
    idAttribute: (dataTypeCountry) => dataTypeCountry.id,
  }
);

const dataTypeStatesSchema = new schema.Entity(
  "dataTypeStates",
  {},
  {
    idAttribute: (dataTypeState) => dataTypeState.id,
  }
);

const dataTypeOrgImpactsSchema = new schema.Entity(
  "dataTypeOrgImpacts",
  {},
  {
    idAttribute: (dataTypeOrgImpact) => dataTypeOrgImpact.id,
  }
);

const paymentsSchema = new schema.Entity(
  "payments",
  {},
  {
    idAttribute: (payment) => payment.id,
  }
);

const baselineSchema = new schema.Entity(
  "baselines",
  {},
  {
    idAttribute: (baseline) => baseline.id,
  }
);

const orgImpactsSchema = new schema.Entity(
  "orgImpacts",
  {},
  {
    idAttribute: (orgImpact) => orgImpact.id,
  }
);

const reportingSchema = new schema.Entity(
  "reporting",
  {},
  {
    idAttribute: (report) => report.id,
  }
);

const sentEmailsSchema = new schema.Entity(
  "sentEmails",
  {},
  {
    idAttribute: (sentEmail) => sentEmail.id,
  }
);

const scopesSchema = new schema.Entity(
  "scopes",
  {},
  {
    idAttribute: (scope) => scope.id,
  }
);

const requestsSchema = new schema.Entity(
  "requests",
  {},
  {
    idAttribute: (request) => request.id,
  }
);

const orgImpactProcessesSchema = new schema.Entity(
  "orgImpactProcesses",
  {},
  {
    idAttribute: (orgImpactProcess) => orgImpactProcess.id,
  }
);

const plansSchema = new schema.Entity(
  "plans",
  {},
  {
    idAttribute: (plan) => plan.id,
  }
);

const hubSpotListsSchema = new schema.Entity(
  "hubSpotLists",
  {},
  {
    idAttribute: (hubSpotList) => hubSpotList.id,
  }
);

const rolesSchema = new schema.Entity(
  "roles",
  {},
  {
    idAttribute: (role) => role.id,
  }
);

const userRolesSchema = new schema.Entity(
  "userRoles",
  {},
  {
    idAttribute: (userRole) => userRole.id,
  }
);

const foundationalElementsSchema = new schema.Entity(
  "foundationalElements",
  {},
  {
    idAttribute: (foundationalElement) => foundationalElement.id,
  }
);

const foundationalElementRolesSchema = new schema.Entity(
  "foundationalElementRoles",
  {},
  {
    idAttribute: (foundationalElementRole) => foundationalElementRole.id,
  }
);

const foundationalElementPoliciesSchema = new schema.Entity(
  "foundationalElementPolicies",
  {},
  {
    idAttribute: (foundationalElementPolicy) => foundationalElementPolicy.id,
  }
);

const contractsSchema = new schema.Entity(
  "contracts",
  {},
  {
    idAttribute: (contract) => contract.id,
  }
);

const emailTemplatesSchema = new schema.Entity(
  "emailTemplates",
  {},
  {
    idAttribute: (emailTemplate) => emailTemplate.id,
  }
);

const managedServiceDetailsSchema = new schema.Entity(
  "managedServiceDetails",
  {},
  {
    idAttribute: (managedServiceDetail) => managedServiceDetail.id,
  }
);

const roadMapLitesSchema = new schema.Entity(
  "roadMapLites",
  {},
  {
    idAttribute: (roadMapLite) => roadMapLite.id,
  }
);

const implementationPlansSchema = new schema.Entity(
  "implementationPlans",
  {},
  {
    idAttribute: (implementationPlan) => implementationPlan.id,
  }
);

const deliverablesSchema = new schema.Entity(
  "deliverables",
  {},
  {
    idAttribute: (deliverable) => deliverable.id,
  }
);

const citiesSchema = new schema.Entity(
  "cities",
  {},
  {
    idAttribute: (city) => city.id,
  }
);

const vendorsSchema = new schema.Entity(
  "vendors",
  {},
  {
    idAttribute: (vendor) => vendor.id,
  }
);

const safeguardsSchema = new schema.Entity(
  "safeguards",
  {},
  {
    idAttribute: (safeguard) => safeguard.id,
  }
);

const vendorSafeguardsSchema = new schema.Entity(
  "vendorSafeguards",
  {},
  {
    idAttribute: (vendorSafeguard) => vendorSafeguard.id,
  }
);

const architecturesSchema = new schema.Entity(
  "architectures",
  {},
  {
    idAttribute: (architecture) => architecture.id,
  }
);

const proposalsSchema = new schema.Entity(
  "proposals",
  {},
  {
    idAttribute: (proposal) => proposal.id,
  }
);

const processHealthsSchema = new schema.Entity(
  "processHealths",
  {},
  {
    idAttribute: (processHealth) => processHealth.id,
  }
);

const scopeProcessesSchema = new schema.Entity(
  "scopeProcesses",
  {},
  {
    idAttribute: (scopeProcess) => scopeProcess.id,
  }
);
const scopeDetailsUI = new schema.Entity(
  "scopeDetailsUI",
  {},
  {
    idAttribute: (scope) => scope.id,
  }
);

const clientVendorsSchema = new schema.Entity(
  "clientVendors",
  {},
  {
    idAttribute: (clientVendor) => clientVendor.id,
  }
);

const entitiesSchema = new schema.Entity(
  "entities",
  {},
  {
    idAttribute: (entity) => entity.id,
  }
);

const riskAssessmentsSchema = new schema.Entity(
  "riskAssessments",
  {},
  {
    idAttribute: (riskAssessment) => riskAssessment.id,
  }
);

const characteristicsSchema = new schema.Entity(
  "characteristics",
  {},
  {
    idAttribute: (characteristic) => characteristic.id,
  }
);

const classesSchema = new schema.Entity(
  "classes",
  {},
  {
    idAttribute: (c) => c.id,
  }
);

const componentsSchema = new schema.Entity(
  "components",
  {},
  {
    idAttribute: (component) => component.id,
  }
);

const categoriesSchema = new schema.Entity(
  "categories",
  {},
  {
    idAttribute: (category) => category.id,
  }
);

const cisoUsersSchema = new schema.Entity(
  "cisoUsers",
  {},
  {
    idAttribute: (cisoUser) => cisoUser.id,
  }
);

const studentsSchema = new schema.Entity(
  "students",
  {},
  {
    idAttribute: (student) => student.id,
  }
);

const coursesSchema = new schema.Entity(
  "courses",
  {},
  {
    idAttribute: (course) => course.id,
  }
);

const responsibilitiesSchema = new schema.Entity(
  "responsibilities",
  {},
  {
    idAttribute: (responsibility) => responsibility.id,
  }
);

const tasksSchema = new schema.Entity(
  "tasks",
  {},
  {
    idAttribute: (task) => task.id,
  }
);

const partnersSchema = new schema.Entity(
  "partners",
  {},
  {
    idAttribute: (partner) => partner.id,
  }
);

const permissionsSchema = new schema.Entity(
  "permissions",
  {},
  {
    idAttribute: (permission) => permission.id,
  }
);

const controlsSchema = new schema.Entity(
  "controls",
  {},
  {
    idAttribute: (control) => control.id,
  }
);

const projectsSchema = new schema.Entity(
  "projects",
  {},
  {
    idAttribute: (project) => project.id,
  }
);

const milestonesSchema = new schema.Entity(
  "milestones",
  {},
  {
    idAttribute: (milestone) => milestone.id,
  }
);

const objectivesSchema = new schema.Entity(
  "objectives",
  {},
  {
    idAttribute: (objective) => objective.id,
  }
);

const dashboardSchema = new schema.Entity(
  "dashboard",
  {},
  {
    idAttribute: (dashboard) => dashboard.id,
  }
);

const projectRosterSchema = new schema.Entity(
  "projectRoster",
  {},
  {
    idAttribute: (projectRoster) => projectRoster.id,
  }
);

const userCompanyProjects = new schema.Entity(
  "userCompanyProjects",
  {},
  {
    idAttribute: (userCompanyProjects) => userCompanyProjects.id,
  }
);

const companyProjects = new schema.Entity(
  "companyProjects",
  {},
  {
    idAttribute: (companyProjects) => companyProjects.id,
  }
);

const timeEntries = new schema.Entity(
  "timeEntries",
  {},
  {
    idAttribute: (timeEntries) => timeEntries.id,
  }
);

const timeEntriesByProject = new schema.Entity(
  "timeEntriesByProject",
  {},
  {
    idAttribute: (timeEntriesByProject) => timeEntriesByProject.id,
  }
);

const calendarTimeEntries = new schema.Entity(
  "calendarTimeEntries",
  {},
  {
    idAttribute: (calendarTimeEntries) => "calendarTimeEntries",
  }
);

const projectQueue = new schema.Entity(
  "projectQueue",
  {},
  {
    idAttribute: (projectQueue) => projectQueue.id,
  }
);

const passwordVerify = new schema.Entity(
  "passwordVerify",
  {},
  {
    idAttribute: (passwordVerify) => "passwordVerify",
  }
);

const workElements = new schema.Entity(
  "workElements",
  {},
  {
    idAttribute: (workElement) => workElement.id,
  }
);

const deliverables = new schema.Entity(
  "deliverables",
  {},
  {
    idAttribute: (deliverable) => deliverable.id,
  }
);

const projectTypes = new schema.Entity(
  "projectTypes",
  {},
  {
    idAttribute: (projectType) => projectType.id,
  }
);

const timeEntryTasks = new schema.Entity(
  "timeEntryTasks",
  {},
  {
    idAttribute: (timeEntryTask) => timeEntryTask.id,
  }
);

export const Schemas = {
  USER: userSchema,
  COUNT: countSchema,
  COMPANY: companySchema,
  CLIENT_VENDOR: clientVendorsSchema,
  CLIENT_VENDOR_ARRAY: [clientVendorsSchema],
  ENTITY: entitiesSchema,
  ENTITY_ARRAY: [entitiesSchema],
  INDUSTRY: industrySchema,
  REPORTING: reportingSchema,
  COUNTRY: countrySchema,
  COMMENT: commentsSchema,
  QUIZ: quizSchema,
  DOCUMENT: documentSchema,
  STATE: stateSchema,
  DATATYPE: dataTypeSchema,
  REQUEST: requestsSchema,
  LAW: lawsSchema,
  ROLE: rolesSchema,
  REQ_PROGRAMS: reqProgramsSchema,
  LAW_REQS: lawReqsSchema,
  PROCESS_REQS: processRequirementsSchema,
  REVIEWED_DOCUMENT: reviewedDocumentsSchema,
  PROCESS: processSchema,
  REQUIREMENT: requirementSchema,
  FRAMEWORK_LAWS: frameworkLawsSchema,
  LIST: listSchema,
  POLICIES: policiesSchema,
  USER_ROLE: userRolesSchema,
  FRAMEWORK_POLICIES: frameworkPoliciesSchema,
  FRAMEWORK: frameworksSchema,
  POLICY_DATA_TYPE: policydataTypesSchema,
  FRAMEWORK_DATA_TYPE: frameworkDataTypesSchema,
  PROGRAM: programSchema,
  LAW_DATATYPE: lawDataTypesSchema,
  DATATYPE_INDUSTRIES: dataTypeIndustriesSchema,
  DATATYPE_COUNTRIES: dataTypeCountriesSchema,
  DATATYPE_STATES: dataTypeStatesSchema,
  DATATYPE_ORG_IMPACTS: dataTypeOrgImpactsSchema,
  ORG_IMPACT_PROCESSES: orgImpactProcessesSchema,
  BASELINE: baselineSchema,
  PAYMENTS: paymentsSchema,
  ORG_IMPACT: orgImpactsSchema,
  HUBSPOT_LIST: hubSpotListsSchema,
  SCOPE: scopesSchema,
  CONTRACT: contractsSchema,
  EMAIL_TEMPLATE: emailTemplatesSchema,
  FOUNDATIONAL_ELEMENT: foundationalElementsSchema,
  FOUNDATIONAL_ELEMENT_ROLE: foundationalElementRolesSchema,
  FOUNDATIONAL_ELEMENT_POLICY: foundationalElementPoliciesSchema,
  DELIVERABLE: deliverablesSchema,
  PROPOSAL: proposalsSchema,
  RISK_ASSESSMENT: riskAssessmentsSchema,
  RISK_ASSESSMENT_ARRAY: [riskAssessmentsSchema],
  DELIVERABLE_ARRAY: [deliverablesSchema],
  HUBSPOT_LIST_ARRAY: [hubSpotListsSchema],
  EMAIL_TEMPLATE_ARRAY: [emailTemplatesSchema],
  CONTRACT_ARRAY: [contractsSchema],
  PROPOSAL_ARRAY: [proposalsSchema],
  FOUNDATIONAL_ELEMENT_ARRAY: [foundationalElementsSchema],
  FOUNDATIONAL_ELEMENT_ROLE_ARRAY: [foundationalElementRolesSchema],
  FOUNDATIONAL_ELEMENT_POLICY_ARRAY: [foundationalElementPoliciesSchema],
  ORG_IMPACT_ARRAY: [orgImpactsSchema],
  REQUEST_ARRAY: [requestsSchema],
  SCOPE_ARRAY: [scopesSchema],
  USER_ROLE_ARRAY: [userRolesSchema],
  ROLE_ARRAY: [rolesSchema],
  DATATYPE_ORG_IMPACTS_ARRAY: [dataTypeOrgImpactsSchema],
  USER_ARRAY: [userSchema],
  POLICY_DATA_TYPE_ARRAY: [policydataTypesSchema],
  STATE_ARRAY: [stateSchema],
  COUNTRY_ARRAY: [countrySchema],
  DOCUMENT_ARRAY: [documentSchema],
  INDUSTRY_ARRAY: [industrySchema],
  BASELINE_ARRAY: [baselineSchema],
  REVIEWED_DOCUMENT_ARRAY: [reviewedDocumentsSchema],
  QUIZ_ARRAY: [quizSchema],
  PROCESS_ARRAY: [processSchema],
  FRAMEWORK_LAWS_ARRAY: [frameworkLawsSchema],
  FRAMEWORK_DATA_TYPE_ARRAY: [frameworkDataTypesSchema],
  FRAMEWORK_POLICIES_ARRAY: [frameworkPoliciesSchema],
  DATATYPE_INDUSTRIES_ARRAY: [dataTypeIndustriesSchema],
  DATATYPE_COUNTRIES_ARRAY: [dataTypeCountriesSchema],
  DATATYPE_STATES_ARRAY: [dataTypeStatesSchema],
  ORG_IMPACT_PROCESSES_ARRAY: [orgImpactProcessesSchema],
  DATATYPE_ARRAY: [dataTypeSchema],
  PROGRAM_ARRAY: [programSchema],
  REQUIREMENT_ARRAY: [requirementSchema],
  COMMENT_ARRAY: [commentsSchema],
  PAYMENTS_ARRAY: [paymentsSchema],
  FRAMEWORK_ARRAY: [frameworksSchema],
  LAW_ARRAY: [lawsSchema],
  LAW_DATATYPE_ARRAY: [lawDataTypesSchema],
  REQ_PROGRAMS_ARRAY: [reqProgramsSchema],
  LAW_REQS_ARRAY: [lawReqsSchema],
  PROCESS_REQS_ARRAY: [processRequirementsSchema],
  PROGRAM_POLICIES_ARRAY: [programPoliciesSchema],
  POLICIES_ARRAY: [policiesSchema],
  COMPANY_ARRAY: [companySchema],
  SENT_EMAILS_ARRAY: [sentEmailsSchema],
  QUESTIONS: questionsSchema,
  QUESTIONS_ARRAY: [questionsSchema],
  IMPLEMENTATION_PLAN_VISUAL: implementationPlansSchema,
  MANAGED_SERVICES_VISUAL: managedServiceDetailsSchema,
  ROAD_MAP_VISUAL: roadMapLitesSchema,
  PRORGAM_POLICY: programPoliciesSchema,
  SENT_EMAILS: sentEmailsSchema,
  PLAN_ARRAY: [plansSchema],
  IMPLEMENTATION_PLAN_ARRAY: [implementationPlansSchema],
  MANAGED_SERVICES_ARRAY: [managedServiceDetailsSchema],
  ROAD_MAP_ARRAY: [roadMapLitesSchema],
  CITY_ARRAY: [citiesSchema],
  VENDOR_ARRAY: [vendorsSchema],
  SAFEGUARD_ARRAY: [safeguardsSchema],
  VENDOR_SAFEGUARD_ARRAY: [vendorSafeguardsSchema],
  ARCHITECTURE_ARRAY: [architecturesSchema],
  PLAN: plansSchema,
  ARCHITECTURE: architecturesSchema,
  VENDOR: vendorsSchema,
  SAFEGUARD: safeguardsSchema,
  VENDOR_SAFEGUARD: vendorSafeguardsSchema,
  CITY: citiesSchema,
  PROCESS_HEALTH: processHealthsSchema,
  SCOPE_PROCESS: scopeProcessesSchema,
  SCOPE_PROCESS_ARRAY: [scopeProcessesSchema],
  SCOPE_DETAILS_UI: scopeDetailsUI,
  CHARACTERISTICS: characteristicsSchema,
  CHARACTERISTICS_ARRAY: [characteristicsSchema],
  CLASSES: classesSchema,
  CLASSES_ARRAY: [classesSchema],
  COMPONENTS: componentsSchema,
  COMPONENTS_ARRAY: [componentsSchema],
  CATEGORIES: categoriesSchema,
  CATEGORIES_ARRAY: [categoriesSchema],
  CISO_USERS: cisoUsersSchema,
  CISO_USERS_ARRAY: [cisoUsersSchema],
  STUDENTS: studentsSchema,
  STUDENTS_ARRAY: [studentsSchema],
  COURSES: coursesSchema,
  COURSES_ARRAY: [coursesSchema],
  RESPONSIBILITIES: responsibilitiesSchema,
  RESPONSIBILITIES_ARRAY: [responsibilitiesSchema],
  TASKS: tasksSchema,
  TASKS_ARRAY: [tasksSchema],
  PERMISSIONS: permissionsSchema,
  PERMISSIONS_ARRAY: [permissionsSchema],
  PARTNERS: partnersSchema,
  PARTNERS_ARRAY: [partnersSchema],
  CONTROLS: controlsSchema,
  CONTROLS_ARRAY: [controlsSchema],
  PROJECTS: projectsSchema,
  PROJECTS_ARRAY: [projectsSchema],
  MILESTONES: milestonesSchema,
  MILESTONES_ARRAY: [milestonesSchema],
  OBJECTIVES: objectivesSchema,
  OBJECTIVES_ARRAY: [objectivesSchema],
  DASHBOARD: dashboardSchema,
  PROJECT_ROSTER: projectRosterSchema,
  PROJECT_ROSTER_ARRAY: [projectRosterSchema],
  USER_COMPANY_PROJECTS: userCompanyProjects,
  USER_COMPANY_PROJECTS_ARRAY: [userCompanyProjects],
  COMPANY_PROJECTS: companyProjects,
  COMPANY_PROJECTS_ARRAY: [companyProjects],
  TIME_ENTRIES: timeEntries,
  TIME_ENTRIES_ARRAY: [timeEntries],
  TIME_ENTRIES_BY_PROJECT: timeEntriesByProject,
  TIME_ENTRIES_BY_PROJECT_ARRAY: [timeEntriesByProject],
  CALENDAR_TIME_ENTRIES: calendarTimeEntries,
  CALENDAR_TIME_ENTRIES_ARRAY: [calendarTimeEntries],
  ARCHIVED_PROJECT_QUEUE: projectQueue,
  ARCHIVED_PROJECT_QUEUE_ARRAY: [projectQueue],
  PROJECT_QUEUE: projectQueue,
  PROJECT_QUEUE_ARRAY: [projectQueue],
  PASSWORD_VERIFY: passwordVerify,
  WORK_ELEMENTS: workElements,
  WORK_ELEMENTS_ARRAY: [workElements],
  DELIVERABLES: deliverables,
  DELIVERABLES_ARRAY: [deliverables],
  PROJECT_TYPES: projectTypes,
  PROJECT_TYPES_ARRAY: [projectTypes],
  TIME_ENTRY_TASKS: timeEntryTasks,
  TIME_ENTRY_TASKS_ARRAY: [timeEntryTasks],
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { reqInfo } = callAPI;
  const { schema, types } = callAPI;

  if (typeof reqInfo.endpoint === "function") {
    reqInfo.endpoint = reqInfo.endpoint(store.getState());
  }

  if (typeof reqInfo.endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!schema) {
    throw new Error("Specify one of the exported Schemas.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return callApi(reqInfo, schema)
    .then((response) => {
      return next(
        actionWith({
          response,
          type: successType,
        })
      );
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        const customError = new Error();
        customError.name = "InternalError";
        customError.message =
          "There seems to be a problem with the connection. Could not establish a connection to the server";
        error = customError;
      }
      return next(
        actionWith({
          type: failureType,
          error: error || { message: "Opps, something went wrong.", error: {} },
        })
      );
    });
};
