import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CloneProjectItem from "./CloneProjectItem";
import { DEFAULT_NEW_RESOURCE } from "../utils/helpers";

function AutoComplete(props) {
  return (
    <Box>
      <Autocomplete
        disabled={props.skip}
        size="small"
        options={props.options}
        value={props.value}
        getOptionLabel={props.getOptionLabel}
        onChange={(e, newValue) => props.updateResource(props.objKey, props.projectId, props.id, newValue)}
        renderInput={(params) => (
          <TextField {...params} style={{ width: 200, borderRadius: 50 }} variant="outlined" label={props.label} />
        )}
      />
    </Box>
  );
}
export default function CloneProject(props) {
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });
  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(
      props.projectRoster
        .filter((pr) => props.selected.includes(pr.id))
        .map((project) => {
          return {
            ...project,
            company: "",
            newCompanyName: "",
          };
        })
    );
  }, [props.selected]);

  const addNewResource = (projectId) => {
    let currentProjectData = data;

    currentProjectData.find((row) => row.id === projectId).users = [
      ...data.find((row) => row.id === projectId).users,
      { id: Math.random().toString(36).substring(7), projectId, ...DEFAULT_NEW_RESOURCE },
    ];
    setData([...currentProjectData]);
  };

  const updateClonedProject = (id, key, value) => {
    let currentData = data;
    currentData.find((row) => row.id === id)[key] = value;
    setData([...currentData]);
  };

  const updateClonedProjectResources = (key, projectId, resourceId, value) => {
    let currentProjectData = data;
    currentProjectData.find((row) => row.id === projectId).users.find((user) => user.id === resourceId)[key] = value;
    return setData([...currentProjectData]);
  };

  const cloneSelectedProjects = async () => {
    if (data.some((project) => !project.company.hasOwnProperty("id") && project.newCompanyName.length === 0)) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Select a company for each cloned project" });
    }

    if (data.some((project) => project.type.length === 0)) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Select a project type for each cloned project" });
    }

    if (data.some((project) => project.type.length === 0)) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Select a project type for each cloned project" });
    }

    const resources = data.map((project) => project.users).flat();

    if (
      resources.some(
        (resource) =>
          !resource.skip &&
          ((resource.user && !resource.user.hasOwnProperty("id")) ||
            (resource.role && !resource.role.hasOwnProperty("id")) ||
            resource.allocation === 0)
      )
    ) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Fully fill out each role" });
    }
    setLoading(true);
    for await (const row of data) {
      let companyId = null;

      if (row.company && row.company.id) {
        companyId = row.company.id;
      } else {
        const result = await props.saveCompany({ name: row.newCompanyName, clientType: "MS" });
        companyId = Object.keys(result.response.entities.companies)[0];
      }

      const projectId = row.projectId;
      const type = row.type;
      const status = "Not Started";
      const tags = row.tags;
      const description = row.description;
      const assignments = row.users
        .filter((r) => !r.skip)
        .map((resource) => {
          return { userId: resource.user.id, roleId: resource.role.id, allocation: resource.allocation };
        });
      const payload = {
        companyId,
        projectId,
        assignments,
        type: row.type,
        status: "Not Started",
        type,
        status,
        tags,
        description,
      };
      await props.kickoffCompanyProjects(payload);
    }
    setLoading(false);

    props.setCloneProjects(false);
    props.fetchData();
    props.setSelected([]);
  };

  return (
    <Box>
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Dialog
        style={{
          background: "rgba(255,255,255, .9)",
        }}
        open={props.cloneProjects}
        onClose={() => {
          props.setCloneProjects(false);
        }}
        maxWidth="xl"
      >
        <DialogTitle style={{ background: "#011627", color: "white" }}>CLONE PROJECTS</DialogTitle>
        <DialogContent
          style={{ height: "700px", overflow: "auto", display: "flex", flexDirection: "column", marginTop: 20 }}
        >
          <Box mt={4}>
            {data.map((project, index) => {
              return (
                <CloneProjectItem
                  key={project.id}
                  index={index}
                  project={project}
                  tags={tags}
                  addNewResource={addNewResource}
                  updateClonedProject={updateClonedProject}
                  updateClonedProjectResources={updateClonedProjectResources}
                  AutoComplete={AutoComplete}
                  {...props}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions style={{ background: "#011627", color: "white" }}>
          <Button onClick={() => props.setCloneProjects(false)} style={{ color: "white", fontWeight: "bold" }}>
            Cancel
          </Button>
          <Button onClick={() => cloneSelectedProjects()} style={{ color: "white", fontWeight: "bold" }}>
            {loading ? <i class="fas fa-cog fa-spin" /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
