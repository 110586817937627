import React from "react";
import { Grid, Box, IconButton } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "License",
    field: "licences",
    render: (row) => {
      if (row && row.licenses && row.licenses.length > 0) {
        return (
          <Box>
            {row.licenses[0].id.replace(/(.{10})..+/, "$1…")}
            <CopyToClipboard text={row.licenses[0].id}>
              <IconButton style={{ padding: "4px 6px" }}>
                <i style={{ color: "black", fontSize: "0.8rem" }} className="fal fa-clipboard" />
              </IconButton>
            </CopyToClipboard>{" "}
          </Box>
        );
      } else {
        return "No License Key";
      }
    },
    editable: "never",
  },
  {
    title: "Program Score",
    field: "score",
    render: (row) => {
      if (row && row.riskAssessments.length > 0) return Math.round(5 - row.riskAssessments[0].criticality) * 110 + 300;
      return "---";
    },
    editable: "never",
  },
  {
    title: "Progress Score",
    field: "progressScore",
    render: (row) => {
      if (row && row.progressScore) {
        if (row.progressScore >= 0 && row.progressScore < 0.3) return "F";
        if (row.progressScore >= 0.3 && row.progressScore < 0.7) return "D";
        if (row.progressScore >= 0.7 && row.progressScore <= 1) return "D+";
        if (row.progressScore > 1 && row.progressScore < 1.3) return "C-";
        if (row.progressScore >= 1.3 && row.progressScore < 1.7) return "C";
        if (row.progressScore >= 1.7 && row.progressScore < 2) return "C+";
        if (row.progressScore >= 2 && row.progressScore < 2.3) return "B-";
        if (row.progressScore >= 2.3 && row.progressScore < 2.7) return "B";
        if (row.progressScore >= 2.7 && row.progressScore < 3) return "B+";
        if (row.progressScore >= 3 && row.progressScore < 3.3) return "A-";
        if (row.progressScore >= 3.3 && row.progressScore < 3.7) return "A";
        if (row.progressScore >= 3.7) return "A+";
      }
      return "---";
    },
    editable: "never",
  },
  {
    title: "Client Type",
    field: "clientType",
    lookup: {
      null: "---",
      PS: "PS",
      MS: "MS",
    },
  },
  {
    title: "Third Party Enabled",
    field: "allowAssessments",
    type: "boolean",
    editable: "onUpdate",
  },
  {
    title: "Max Vendors",
    field: "maxVendors",
    type: "number",
    editable: "onUpdate",
    render: (row) => {
      if (row && row.unlimitedVendors) {
        return (
          <Box fontSize="1.4rem" fontWeight="bold">
            &#8734;
          </Box>
        );
      }
      return row.maxVendors;
    },
  },
  {
    title: "Unlimited Vendors",
    field: "unlimitedVendors",
    type: "boolean",
    editable: "onUpdate",
  },
];
