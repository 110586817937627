import React from "react";
import { Grid, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../styles/CustomTable.styles";

export default function ResourcesHeader(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.scrollBoxLower}
      style={{
        color: "white",
        backgroundColor: "#011627",
        fontSize: "1rem",
        fontFamily: "Lato",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={2}>
        <Box p={2}>Resource</Box>
      </Grid>
      <Grid item xs={2}>
        <Box p={2}>Client</Box>
      </Grid>
      <Grid item xs={1}>
        <Box p={2}>Project Type</Box>
      </Grid>
      <Grid item xs={3}>
        <Box p={2}>Project</Box>
      </Grid>
      <Grid item xs={2}>
        <Box p={2}>Role</Box>
      </Grid>
      <Grid item xs={2}>
        <Box p={2}>Time Allocation</Box>
      </Grid>
    </Grid>
  );
}
