import React from "react";
import Laws from "./views/Laws";
import Policies from "./views/Policies";
import Processes from "./views/Processes";
import Programs from "./views/Programs";
import Requirements from "./views/Requirements";
import Frame from "../../components/Frame/Frame";

const FrameworksRouter = (props) => {
  const [value, setValue] = React.useState(0);
  const tabs = ["Frameworks", "Domains", "Processes", "Programs", "Requirements"];

  const tabsView = () => {
    switch (value) {
      case 0:
        return <Laws {...props} />;
      case 1:
        return <Policies {...props} />;
      case 2:
        return <Processes {...props} />;
      case 3:
        return <Programs {...props} />;
      case 4:
        return <Requirements {...props} />;
    }
  };

  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
};

export default FrameworksRouter;
