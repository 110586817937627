import React from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createWorkElement,
  getDeliverablesList,
  getWorkElementsCount,
  getWorkElementsList,
  loadWorkElements,
  removeWorkElement,
  updateWorkElement,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { Selection } from "../../../components/MaterialTable/Inputs";

const WorkElements = (props) => {
  const { deliverables } = props;
  const columns = [
    {
      title: "Work Element",
      field: "name",
    },
    {
      title: "Deliverables",
      field: "deliverables",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {row.deliverables && Array.isArray(row.deliverables)
            ? row.deliverables
                .map((deliverable) => deliverable.deliverable && deliverable.deliverable.name)
                .filter(Boolean)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => <Selection multiple target="deliverable" options={deliverables} {...props} />,
    },
    {
      title: "Internal",
      field: "internal",
      type: "boolean",
    },
  ];

  React.useEffect(() => {
    props.getDeliverablesList();
  }, []);

  const postWorkElement = async (rowData) => {
    try {
      const payload = {
        workElement: { name: rowData.name, internal: rowData.internal },
        deliverableIds:
          rowData.deliverables && Array.isArray(rowData.deliverables)
            ? rowData.deliverables.map((orgImpacts) => orgImpacts.deliverableId)
            : rowData.deliverables
            ? rowData.deliverables.split(",")
            : [],
        internal: rowData.internal,
      };
      return await props.createWorkElement({ ...payload });
    } catch (err) {}
  };

  const editDeliverable = async (rowData, oldData) => {
    try {
      let payload = { workElement: { name: rowData.name, internal: rowData.internal } };
      payload.deliverableIds =
        rowData.deliverables && Array.isArray(rowData.deliverables)
          ? rowData.deliverables.map((deliverable) => deliverable.deliverableId)
          : rowData.deliverables
          ? rowData.deliverables.split(",")
          : [];
      return await props.updateWorkElement(rowData.id, { ...payload });
    } catch (err) {}
  };

  const deleteWorkElement = async (rowData) => {
    try {
      return await props.removeWorkElement(rowData.id);
    } catch (err) {}
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getWorkElementsCount}
            paginationCall={props.loadWorkElements}
            title="Work Elements"
            entity="workElements"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => postWorkElement(rowData)}
            editFunction={(newData, oldData) => editDeliverable(newData, oldData)}
            deleteFunction={(rowData) => deleteWorkElement(rowData)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataTypes: state.entities.dataTypes,
    pageInfo: state.pagination.dataTypes,
    deliverables:
      (state.entities.lists && state.entities.lists.deliverables && state.entities.lists.deliverables.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadWorkElements: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(loadWorkElements(page, limit, refresh, searchTerm, sort, by)),
    getWorkElementsCount: () => dispatch(getWorkElementsCount()),
    createWorkElement: (payload) => dispatch(createWorkElement(payload)),
    updateWorkElement: (id, payload) => dispatch(updateWorkElement(id, payload)),
    removeWorkElement: (id) => dispatch(removeWorkElement(id)),
    getWorkElementsList: () => dispatch(getWorkElementsList()),
    getDeliverablesList: () => dispatch(getDeliverablesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(WorkElements));
