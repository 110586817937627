import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import useStyles from "../styles/ProjectCard.styles";
import Warning from "../../../components/Dialog/WarningDialog";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "20%",
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#2373D8",
  },
}))(LinearProgress);

const PLAN_MAX_LEN = 20;

export default function ProjectAccordion(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [resetAnchorEl, setResetAnchorEl] = useState(null);
  const [milestoneCompletion, setMilestoneCompletion] = useState(0);

  useEffect(() => {
    setMilestoneCompletion(
      Math.round((props.milestones.filter((milestone) => milestone.finished).length / props.milestones.length) * 100)
    );
  }, [props.milestones]);

  const deleteCompanyProject = async () => {
    try {
      const result = await props.deleteCompanyProject(props.projectId, props.companyId);
      if (!result.type.includes("SUCCESS")) throw new Error();
      props.setProjects((prev) => [
        ...props.companyProjects.filter((project) => project.projectId !== props.projectId),
      ]);
      props.setCompanyProjects((prev) => [
        ...props.companyProjects.filter((project) => project.projectId !== props.projectId),
      ]);
      return result;
    } catch (err) {
      return props.setSnackbar({ isOpen: true, variant: "error", message: `Failed to create the project.` });
    }
  };

  const updateCompanyProject = async (milestoneId, status) => {
    try {
      const result = await props.updateCompanyProject(props.id, milestoneId, status.length > 0 ? status : "none");
      if (!result.type.includes("SUCCESS")) throw new Error();

      let currentCompanyProjects = props.companyProjects;
      let targetCompanyProject = props.companyProjects.find((project) => project.projectId === props.projectId);
      let targetCompanyProjectMilestones = targetCompanyProject.milestones;
      let targetMilestone = targetCompanyProjectMilestones.find((milestone) => milestone.milestoneId === milestoneId);

      switch (status) {
        case "":
          targetMilestone.started = false;
          targetMilestone.finished = false;
          targetMilestone.startedAt = null;
          targetMilestone.finishedAt = null;
          break;
        case "started":
          targetMilestone.started = true;
          targetMilestone.startedAt = new Date();
          targetMilestone.finished = false;
          targetMilestone.finishedAt = null;
          break;
        case "finished":
          if (!targetMilestone.started) {
            targetMilestone.started = true;
            targetMilestone.startedAt = new Date();
          }
          targetMilestone.finished = true;
          targetMilestone.finishedAt = new Date();

          break;
      }
      const completion = Math.round(
        (targetCompanyProjectMilestones.filter((milestone) => milestone.finished).length /
          targetCompanyProjectMilestones.length) *
          100
      );
      setMilestoneCompletion(completion);
      props.setProjects([...currentCompanyProjects]);
      props.setCompanyProjects([...currentCompanyProjects]);

      return result;
    } catch (err) {
      return props.setSnackbar({ isOpen: true, variant: "error", message: `Failed to update milestone.` });
    }
  };

  const latestMilestone = props.milestones.filter((milestone) => milestone.started || milestone.finished)[
    props.milestones.filter((milestone) => milestone.started || milestone.finished).length - 1
  ];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      m={1}
      mr={2}
      ml={2}
      style={{ border: "1px solid rgba(0,0,0,.2)", borderRadius: 5 }}
    >
      <Warning
        anchorEl={anchorEl}
        text={`Cancelling this project will remove all progress. Are you sure you want to continue?`}
        confirm={() => deleteCompanyProject()}
        close={() => setAnchorEl(null)}
      />
      <Warning
        anchorEl={resetAnchorEl}
        text={`This will clear all milestone statuses, are you sure you want to continue? `}
        confirm={async () => {
          const result = await props.resetMilestones(props.id);
          let currentCompanyProjects = props.companyProjects;
          let targetCompanyProject = props.companyProjects.find((project) => project.projectId === props.projectId);
          targetCompanyProject.milestones = targetCompanyProject.milestones.map((milestone) => {
            return {
              ...milestone,
              started: false,
              finished: false,
              startedAt: null,
              finishedAt: null,
            };
          });

          props.setProjects([...currentCompanyProjects]);
          props.setCompanyProjects([...currentCompanyProjects]);
          setMilestoneCompletion(0);
          setResetAnchorEl(null);
        }}
        close={() => setResetAnchorEl(null)}
      />

      <Accordion style={{ width: "100%", display: "flex", flexDirection: "column" }} elevation={0}>
        <AccordionSummary style={{ width: "100%" }} expandIcon={<i className="fal fa-chevron-down" />}>
          <Box display="flex" alignItems="center" fontWeight="bold">
            {props.name}
          </Box>
          <Box className={classes.rootProgress}>
            <BorderLinearProgress variant="determinate" value={milestoneCompletion} />
            <Box ml={1} fontWeight="bold">
              {milestoneCompletion}%
            </Box>
            <Box ml={4} fontWeight="bold">
              {latestMilestone ? "Latest:" : null}
            </Box>
            <Box ml={1}>
              {latestMilestone ? latestMilestone.name : null}{" "}
              {latestMilestone && latestMilestone.started && !latestMilestone.finished
                ? "(Started)"
                : latestMilestone && latestMilestone.finished
                ? "(Finished)"
                : null}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ width: "100%" }}>
          <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
            <h3>Sales</h3>
            <Grid container>
              {props.milestones
                .filter((milestone) => milestone.isSales)
                .map((milestone, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box width="95%" ml="2px" fontSize="0.8rem" color="rgba(0,0,0,0.5)" whiteSpace="nowrap">
                        {milestone.name.length >= PLAN_MAX_LEN ? (
                          <Tooltip title={milestone.name}>
                            <Box>{milestone.name.replace(/(.{20})..+/, "$1…")}</Box>
                          </Tooltip>
                        ) : (
                          milestone.name.replace(/(.{20})..+/, "$1…")
                        )}
                      </Box>
                      <FormControl
                        style={{
                          width: "95%",
                          borderRadius: "4px",
                          background: milestone.finished
                            ? "#0E8C64"
                            : milestone.started
                            ? "rgba(14,140,100, 0.6)"
                            : index > 0 && !props.milestones[index - 1].finished
                            ? "rgba(0,0,0,.2)"
                            : "white",
                        }}
                        key={index}
                        disabled={index > 0 && !props.milestones[index - 1].finished}
                        className={classes.formControl}
                      >
                        <Select
                          variant="outlined"
                          margin="dense"
                          className={classes.select}
                          fullWidth
                          style={{
                            color: index > 0 && !props.milestones[index - 1].finished ? "rgba(0,0,0,.6)" : "white",
                            width: "100%",
                            borderRadius: "4px",
                          }}
                          value={milestone.finished ? "finished" : milestone.started ? "started" : ""}
                          onChange={(e) => updateCompanyProject(milestone.milestoneId, e.target.value)}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="started">Started</MenuItem>
                          <MenuItem value="finished">Finished</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                })}
            </Grid>

            <h3>Delivery</h3>
            <Grid container>
              {props.milestones
                .filter((milestone) => !milestone.isSales)
                .map((milestone, index) => {
                  const indexModifier = index + props.milestones.filter((milestone) => milestone.isSales).length;
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box width="95%" ml="2px" fontSize="0.8rem" color="rgba(0,0,0,0.5)" whiteSpace="nowrap">
                        {milestone.name.length >= PLAN_MAX_LEN ? (
                          <Tooltip title={milestone.name}>
                            <Box>{milestone.name.replace(/(.{20})..+/, "$1…")}</Box>
                          </Tooltip>
                        ) : (
                          milestone.name.replace(/(.{20})..+/, "$1…")
                        )}
                      </Box>
                      <FormControl
                        style={{
                          width: "95%",
                          borderRadius: "4px",
                          background: milestone.finished
                            ? "#0E8C64"
                            : milestone.started
                            ? "rgba(14,140,100, 0.6)"
                            : !props.milestones[indexModifier - 1].finished
                            ? "rgba(0,0,0,.2)"
                            : "white",
                        }}
                        key={indexModifier}
                        disabled={indexModifier > 0 && !props.milestones[indexModifier - 1].finished}
                        className={classes.formControl}
                      >
                        <Select
                          variant="outlined"
                          margin="dense"
                          className={classes.select}
                          fullWidth
                          style={{
                            color: index > 0 && !props.milestones[index - 1].finished ? "rgba(0,0,0,.6)" : "white",
                            borderRadius: "4px",
                          }}
                          value={milestone.finished ? "finished" : milestone.started ? "started" : ""}
                          onChange={(e) => updateCompanyProject(milestone.milestoneId, e.target.value)}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="started">Started</MenuItem>
                          <MenuItem value="finished">Finished</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box
        mr={1}
        mt={1}
        fontWeight="bold"
        style={{ width: 250, display: "flex", justifyContent: "flex-end", alignSelf: "flex-start" }}
      >
        <Button className={classes.orangeButton} size="small" onClick={(e) => setResetAnchorEl(e.currentTarget)}>
          Reset
        </Button>
        <Button className={classes.redButton} size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
          Cancel Project
        </Button>
      </Box>
    </Box>
  );
}
