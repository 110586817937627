/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "/hub";

export const SAVE_DELIVERABLE_REQUEST = "SAVE_DELIVERABLE_REQUEST";
export const SAVE_DELIVERABLE_SUCCESS = "SAVE_DELIVERABLE_SUCCESS";
export const SAVE_DELIVERABLE_FAILURE = "SAVE_DELIVERABLE_FAILURE";

const postDeliverable = (payload) => ({
  [CALL_API]: {
    types: [SAVE_DELIVERABLE_REQUEST, SAVE_DELIVERABLE_SUCCESS, SAVE_DELIVERABLE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/deliverables/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.DELIVERABLES,
  },
});

export const createDeliverable = (payload) => (dispatch) => {
  return dispatch(postDeliverable(payload));
};

export const UPDATE_DELIVERABLE_REQUEST = "UPDATE_DELIVERABLE_REQUEST";
export const UPDATE_DELIVERABLE_SUCCESS = "UPDATE_DELIVERABLE_SUCCESS";
export const UPDATE_DELIVERABLE_FAILURE = "UPDATE_DELIVERABLE_FAILURE";

const changeDeliverable = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_DELIVERABLE_REQUEST, UPDATE_DELIVERABLE_SUCCESS, UPDATE_DELIVERABLE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/deliverables/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.DELIVERABLES,
  },
});

export const updateDeliverable = (id, payload) => (dispatch) => {
  return dispatch(changeDeliverable(id, payload));
};

export const DELETE_DELIVERABLE_REQUEST = "DELETE_DELIVERABLE_REQUEST";
export const DELETE_DELIVERABLE_SUCCESS = "DELETE_DELIVERABLE_SUCCESS";
export const DELETE_DELIVERABLE_FAILURE = "DELETE_DELIVERABLE_FAILURE";

const deleteDeliverable = (id) => ({
  [CALL_API]: {
    types: [DELETE_DELIVERABLE_REQUEST, DELETE_DELIVERABLE_SUCCESS, DELETE_DELIVERABLE_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/deliverables/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.DELIVERABLES,
  },
});

export const removeDeliverable = (id) => (dispatch) => {
  return dispatch(deleteDeliverable(id));
};
export const GET_ALL_DELIVERABLES_REQUEST = "GET_ALL_DELIVERABLES_REQUEST";
export const GET_ALL_DELIVERABLES_SUCCESS = "GET_ALL_DELIVERABLES_SUCCESS";
export const GET_ALL_DELIVERABLES_FAILURE = "GET_ALL_DELIVERABLES_FAILURE";

const fetchAllDeliverables = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_DELIVERABLES_REQUEST, GET_ALL_DELIVERABLES_SUCCESS, GET_ALL_DELIVERABLES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.DELIVERABLES_ARRAY,
  },
  refresh,
});

export const loadDeliverables = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllDeliverables(
        `${BASE_URL}/deliverables/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllDeliverables(
        `${BASE_URL}/deliverables/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllDeliverables(
        `${BASE_URL}/deliverables/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllDeliverables(
      `${BASE_URL}/deliverables/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`
    )
  );
};

export const DELIVERABLE_LIST_REQUEST = "DELIVERABLE_LIST_REQUEST";
export const DELIVERABLE_LIST_SUCCESS = "DELIVERABLE_LIST_SUCCESS";
export const DELIVERABLE_LIST_FAILURE = "DELIVERABLE_LIST_FAILURE";

const fetchDeliverablesList = () => ({
  [CALL_API]: {
    types: [DELIVERABLE_LIST_REQUEST, DELIVERABLE_LIST_SUCCESS, DELIVERABLE_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/deliverables/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getDeliverablesList = () => (dispatch) => {
  return dispatch(fetchDeliverablesList());
};

export const DELIVERABLE_COUNT_REQUEST = "DELIVERABLE_COUNT_REQUEST";
export const DELIVERABLE_COUNT_SUCCESS = "DELIVERABLE_COUNT_SUCCESS";
export const DELIVERABLE_COUNT_FAILURE = "DELIVERABLE_COUNT_FAILURE";

const fetchDeliverablesCount = () => ({
  [CALL_API]: {
    types: [DELIVERABLE_COUNT_REQUEST, DELIVERABLE_COUNT_SUCCESS, DELIVERABLE_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/deliverables/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getDeliverablesCount = () => (dispatch) => {
  return dispatch(fetchDeliverablesCount());
};
