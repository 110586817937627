import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadCountries,
  fetchCountriesCount,
  postCountry,
  updateCountryById,
  deleteCountryById,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { Grid } from "@material-ui/core";

const columns = [
  {
    title: "Name",
    field: "name",
  },
];

const Countries = (props) => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    props.fetchCountriesCount().then((response) => {
      if (response.type === "COUNTRIES_COUNT_SUCCESS") {
        setTotalCount(response.response.entities.counts["countries"].count);
      }
    });
  }, []);

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            totalCount={totalCount}
            fetchCount={props.fetchCountriesCount}
            paginationCall={props.loadCountries}
            title="Countries"
            entity={"countries"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => props.postCountry({ ...rowData })}
            editFunction={(newData, oldData) => props.updateCountryById(newData.id, { ...newData })}
            deleteFunction={(rowData) => props.deleteCountryById(rowData.id)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: state.entities.countries,
    pageInfo: state.pagination.countries,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadCountries: (page, limit, refresh) => dispatch(loadCountries(page, limit, refresh)),
    fetchCountriesCount: () => dispatch(fetchCountriesCount()),
    postCountry: (data) => dispatch(postCountry(data)),
    updateCountryById: (id, data) => dispatch(updateCountryById(id, data)),
    deleteCountryById: (id, data) => dispatch(deleteCountryById(id, data)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Countries));
