import React from "react";
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, IconButton, Box } from "@material-ui/core";
import DialogFooter from "./DialogFooter";

const Dialog = (props) => (
  <MuiDialog {...props} open={props.open} onClose={() => props.onClose()} fullWidth maxWidth="md">
    <DialogTitle toggle={() => props.toggle()}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Box>{props.dialogTitle}</Box>
        <IconButton onClick={props.onClose} style={{ padding: "7px 12px" }}>
          <i className="fas fa-times" style={{ color: "#000" }} />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent style={props.small ? {} : { minHeight: "20vh" }}>{props.dialogContent}</DialogContent>
    <DialogActions>{props.dialogActions}</DialogActions>
    <DialogFooter />
  </MuiDialog>
);

export default Dialog;
