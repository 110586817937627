import React from "react";
import { default as MuiTabs } from "@material-ui/core/Tabs";
import { default as MuiTab } from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import useStyles from "./Tabs.styles";
import { Box } from "@material-ui/core";

const Badge = (props) => {
  return (
    <Box>
      <Box
        style={{
          position: "absolute",
          fontSize: "0.75rem",
          width: props.count >= 10 ? 22 : 18,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: props.count >= 10 ? 22 : 18,
          border: "1px solid #0E8C64",
          background: "#0E8C64",
          color: "white",
          borderRadius: "50%",
          fontWeight: "bold",
          top: 0,
          right: 0,
        }}
      >
        {props.count}
      </Box>
      {props.children}
    </Box>
  );
};

const Tabs = ({ fullWidth = false, labels = [], tabValue = 0, setTabValue = () => {} }) => {
  const classes = useStyles();

  return (
    <MuiTabs
      className={`${classes.styledTabsRoot}`}
      variant={fullWidth ? "fullWidth" : "standard"}
      TabIndicatorProps={{
        style: {
          background: "#2373d8",
          height: 7,
          borderRadius: 50,
        },
      }}
      value={tabValue}
      onChange={(e, val) => setTabValue(val)}
    >
      {labels.map((label, index) => {
        const labelContent = label.hasOwnProperty("count") ? <Badge count={label.count}>{label.title}</Badge> : label;
        return <MuiTab selected key={index} className={classes.styledTabRoot} label={labelContent} disableRipple />;
      })}
    </MuiTabs>
  );
};

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

export default Tabs;
