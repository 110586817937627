import React, { useEffect, useState } from "react";
import { Checkbox, Box, Grid, InputBase, Tooltip } from "@material-ui/core";
import { useStyles } from "../styles/ProjectAndTasks.styles";
import FrameTool from "../../Roster/components/FrameTool";

export default function Toolbar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [weeklyHours, setWeeklyHours] = useState("");
  const [invalidTimeEntries, setInvalidTimeEntries] = useState([]);

  useEffect(() => {
    props.getWeeklyHours(props.userId).then((result) => {
      const r = result.response.entities.counts.weeklyHours.count;

      setWeeklyHours(r.length > 1 ? r[0].split(":") : "");
      setInvalidTimeEntries(r.length > 1 ? r[1] : []);
    });
  }, [props.refresh]);

  const handleChangeCM = (event) => {
    if (!event.target.checked) {
      return props.updateFilters("delete", "COMPLETED");
    }
    return props.updateFilters("add", "COMPLETED");
  };

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      pt={"10px"}
      pb={"10px"}
      style={{ minHeight: 62, borderBottom: "1px solid #F0F4F7", background: "white" }}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
              <Box ml={2} width="100%" style={{ whiteSpace: "nowrap" }}>
                {weeklyHours.length > 0 ? (
                  <React.Fragment>
                    <span style={{ fontSize: "0.8rem" }}>{weeklyHours[0]}:</span>
                    <br />
                    <span>{weeklyHours[1]}:</span>
                    <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {weeklyHours[2] && weeklyHours[2].split("/")[0]}
                    </span>

                    <span>/ {weeklyHours[2].split("/")[1]}</span>
                    {invalidTimeEntries.length > 0 ? (
                      <Tooltip
                        title={`You have not entered a full 8 hours for the following days: ${invalidTimeEntries.join(
                          ", "
                        )}`}
                      >
                        <span>
                          <i
                            className={"fad fa-exclamation-triangle"}
                            style={{ color: "#EB6B6C", marginLeft: "1rem" }}
                          />
                        </span>
                      </Tooltip>
                    ) : null}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" ml={1}>
            <Box display="flex" alignItems="center" style={{ width: "100%" }}>
              <Tooltip title="Click here to view all projects that have been completed.">
                <Checkbox style={{ color: "#13252F" }} checked={props.archivedCompleted} onChange={handleChangeCM} />
              </Tooltip>
              <Box fontSize="0.7rem" fontWeight="bold" pt={"3px"}>
                Show Completed Projects
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifySelf: "flex-end",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: 16,
                width: "100%",
              }}
            >
              <Box ml={2} mr={1} style={{ height: 10, width: 3, borderRadius: 50, background: "#13252F" }} />
              <Box className={`${classes.search}`}>
                <div className={classes.searchIcon}>
                  <i className="fas fa-search" />
                </div>
                <InputBase
                  placeholder="Search…"
                  onChange={(e) => props.setSearch(e.target.value)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
