import React from "react";
import { Box } from "@material-ui/core";
export default function BorderBox(props) {
  return (
    <Box
      p={"2px"}
      style={
        props.showBorder
          ? {
              borderRadius: "12px",
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 15px 0px",
            }
          : {}
      }
    >
      <Box
        // pl={5}
        // pt={5}
        // pr={2}
        // pb={2}
        style={{
          borderRadius: "12px",
          background: "#fff",
          minHeight: "30vh",
          color: "#000",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
