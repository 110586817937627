/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const SAVE_PERMISSION_REQUEST = "SAVE_PERMISSION_REQUEST";
export const SAVE_PERMISSION_SUCCESS = "SAVE_PERMISSION_SUCCESS";
export const SAVE_PERMISSION_FAILURE = "SAVE_PERMISSION_FAILURE";

const createPermission = (payload) => ({
  [CALL_API]: {
    types: [SAVE_PERMISSION_REQUEST, SAVE_PERMISSION_SUCCESS, SAVE_PERMISSION_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/permissions`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PERMISSIONS_ARRAY,
  },
});

export const savePermission = (payload) => (dispatch) => {
  return dispatch(createPermission(payload));
};

export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAILURE = "UPDATE_PERMISSION_FAILURE";

const changePermission = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS, UPDATE_PERMISSION_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/permissions/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.PERMISSIONS,
  },
});

export const updatePermission = (id, payload) => (dispatch) => {
  return dispatch(changePermission(id, payload));
};

export const DELETE_PERMISSION_REQUEST = "DELETE_PERMISSION_REQUEST";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAILURE = "DELETE_PERMISSION_FAILURE";

const removePermission = (id) => ({
  [CALL_API]: {
    types: [DELETE_PERMISSION_REQUEST, DELETE_PERMISSION_SUCCESS, DELETE_PERMISSION_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/permissions/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PERMISSIONS,
  },
});

export const deletePermission = (id) => (dispatch) => {
  return dispatch(removePermission(id));
};

export const GET_ALL_PERMISSIONS_REQUEST = "GET_ALL_PERMISSIONS_REQUEST";
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS";
export const GET_ALL_PERMISSIONS_FAILURE = "GET_ALL_PERMISSIONS_FAILURE";

const fetchAllPermissions = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_PERMISSIONS_REQUEST, GET_ALL_PERMISSIONS_SUCCESS, GET_ALL_PERMISSIONS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PERMISSIONS_ARRAY,
  },
  refresh,
});

export const getAllPermissions = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllPermissions(
        `${ADMIN_URL}/permissions/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllPermissions(
        `${ADMIN_URL}/permissions/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllPermissions(
        `${ADMIN_URL}/permissions/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllPermissions(`${ADMIN_URL}/permissions/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_PERMISSIONS_REQUEST = "GET_COUNT_PERMISSIONS_REQUEST";
export const GET_COUNT_PERMISSIONS_SUCCESS = "GET_COUNT_PERMISSIONS_SUCCESS";
export const GET_COUNT_PERMISSIONS_FAILURE = "GET_COUNT_PERMISSIONS_FAILURE";

const fetchPermissionsCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_PERMISSIONS_REQUEST, GET_COUNT_PERMISSIONS_SUCCESS, GET_COUNT_PERMISSIONS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/permissions/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getPermissionsCount = (search) => (dispatch) => {
  return dispatch(fetchPermissionsCount(search));
};

export const PERMISSIONS_LIST_REQUEST = "PERMISSIONS_LIST_REQUEST";
export const PERMISSIONS_LIST_SUCCESS = "PERMISSIONS_LIST_SUCCESS";
export const PERMISSIONS_LIST_FAILURE = "PERMISSIONS_LIST_FAILURE";

const fetchPermissionList = () => ({
  [CALL_API]: {
    types: [PERMISSIONS_LIST_REQUEST, PERMISSIONS_LIST_SUCCESS, PERMISSIONS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/permissions/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getPermissionList = () => (dispatch) => {
  return dispatch(fetchPermissionList());
};
