import React from "react";
import Frame from "../../components/Frame/Frame";
import Projects from "./views/Projects";
import Milestones from "./views/Milestones";

function ProjectsRouter(props) {
  const [value, setValue] = React.useState(0);
  const tabs = ["Projects", "Milestones"];
  const tabsView = () => {
    switch (value) {
      case 0:
        return <Projects {...props} />;
      case 1:
        return <Milestones {...props} />;
    }
  };
  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
}

export default ProjectsRouter;
