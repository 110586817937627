import React from "react";
import Frame from "../../components/Frame/Frame";
import Roles from "./views/Roles";
import Responsibilities from "./views/Responsibilities";
import Tasks from "./views/Tasks";
import Permissions from "./views/Permissions";

function RolesRouter(props) {
  const [value, setValue] = React.useState(0);
  const tabs = ["Roles"];
  const tabsView = () => {
    switch (value) {
      case 0:
        return <Roles {...props} />;
      // case 1:
      //   return <Responsibilities {...props} />;
      // case 2:
      //   return <Tasks {...props} />;
      // case 3:
      //   return <Permissions {...props} />;
    }
  };
  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
}

export default RolesRouter;
