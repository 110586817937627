import React, { useState, useEffect } from "react";
import { MaxText } from "../utils/helpers";
import { Grid, Box, Checkbox, TextField, Popover, Button, Tooltip, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStyles } from "../styles/CustomTable.styles";
import AsyncAutoComplete from "./AsyncAutoComplete";
import { TreeView, TreeItem } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function ProjectRosterItem(props) {
  const { project, selected, setSelected, index, editing, data, setData } = props;
  const [boxHeight, setBoxHeight] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [newDescription, setNewDescription] = useState(project.description);
  const [newType, setNewType] = useState(project.type);
  const [newStatus, setNewStatus] = useState(project.status);
  const [anchorAl, setAnchorAl] = useState(null);
  const [newRoleId, setNewRoleId] = useState("");
  const [startDate, setStartDate] = useState(new Date(project.startDate));
  const [endDate, setEndDate] = useState(new Date(project.endDate));
  const [removeResourceAnchorEl, setRemoveResourceAnchorEl] = useState(null);
  const [removeUserProjectAssignment, setRemoveUserProjectAssignment] = useState(null);

  const open = Boolean(anchorEl);
  const addNewResource = Boolean(anchorAl);
  const removeResource = Boolean(removeResourceAnchorEl);
  const classes = useStyles();

  function boxID() {
    const elmnt = document.getElementById(project.id);
    setBoxHeight(elmnt.offsetHeight);
  }

  useEffect(() => {
    boxID();
  }, []);

  const updateResource = (objKey, projectId, resourceId, newValue) => {
    try {
      let currentData = data;
      let target = currentData.find((d) => d.id === projectId).users.find((u) => u.id === resourceId);
      target[objKey] = newValue && newValue.hasOwnProperty("id") ? newValue.id : newValue;
      setData([...currentData]);
    } catch (e) {}
  };

  const updateProject = (objKey, projectId, newValue) => {
    let currentData = data;
    let target = currentData.find((d) => d.id === projectId);
    target[objKey] = newValue.hasOwnProperty("id") ? newValue.id : newValue;
    setData([...currentData]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resourceHandleClick = (event) => {
    setAnchorAl(event.currentTarget);
  };

  const removeResourceHandleClick = (event) => {
    setRemoveResourceAnchorEl(event.currentTarget);
  };

  const removeResourceClose = () => {
    setRemoveResourceAnchorEl(null);
  };

  const resourceHandleClose = () => {
    setAnchorAl(null);
  };

  const addNewRole = async () => {
    const payload = {
      userId: "",
      roleId: newRoleId.id,
      allocation: 0,
    };
    await props.attachRoleToCompanyProject(project.id, payload);
    resourceHandleClose();
    await props.fetchData();
  };

  const removeRole = async () => {
    await props.detachUserCompanyProject(removeUserProjectAssignment.id);
    removeResourceClose();
    await props.fetchData();
  };

  const saveDescription = async () => {
    await props.changeCompanyProject(project.id, {
      description: newDescription,
      type: newType,
      status: newStatus,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
    handleClose();
    await props.fetchData();
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  return (
    <Box className={classes.scrollBoxLower}>
      <Popover open={addNewResource} anchorEl={anchorAl} onClose={resourceHandleClose}>
        <Box p={3} display="flex" flexDirection="column" width="300px" alignItems="center" justifyContent="center">
          <Autocomplete
            size="small"
            options={props.roles}
            value={newRoleId}
            disableClearable
            fullWidth
            getOptionLabel={(option) => option.name}
            onChange={(e, newValue) => setNewRoleId(newValue)}
            renderInput={(params) => (
              <TextField {...params} InputLabelProps={{ shrink: true }} label="Role" variant="outlined" />
            )}
          />
          <Button
            disabled={!newRoleId || newRoleId.length === 0}
            onClick={addNewRole}
            style={{ marginTop: 10, width: "100%", color: "black", fontWeight: "bold" }}
          >
            Add Role
          </Button>
        </Box>
      </Popover>
      <Popover open={removeResource} anchorEl={removeResourceAnchorEl} onClose={removeResourceClose}>
        <Box p={3} display="flex" flexDirection="column" width="300px" alignItems="center" justifyContent="center">
          <Autocomplete
            size="small"
            options={props.project.users}
            value={removeUserProjectAssignment}
            disableClearable
            fullWidth
            getOptionLabel={(option) =>
              `${option.role.name} (${
                option.user ? `${option.user.firstName} ${option.user.lastName}` : "No Assignment"
              })`
            }
            onChange={(e, newValue) => setRemoveUserProjectAssignment(newValue)}
            renderInput={(params) => (
              <TextField {...params} InputLabelProps={{ shrink: true }} variant="outlined" label="Role" />
            )}
          />

          <Button
            disabled={!removeUserProjectAssignment}
            onClick={removeRole}
            style={{ marginTop: 10, width: "100%", color: "black", fontWeight: "bold" }}
          >
            Remove Role
          </Button>
        </Box>
      </Popover>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={3} display="flex" flexDirection="column">
          <Autocomplete
            size="small"
            options={["MS", "PS"]}
            value={newType}
            disableClearable
            fullWidth
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => setNewType(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Service Type"
              />
            )}
          />
          <br />
          <TextField
            fullWidth
            multiline
            size="small"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            label="Description"
            type="string"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
          <br />
          <Autocomplete
            size="small"
            options={["Not Started", "In Progress", "Complete"]}
            value={project.status}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => setNewStatus(newValue)}
            renderInput={(params) => <TextField fullWidth {...params} variant="outlined" label={"Status"} />}
          />
          <br />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              InputAdornmentProps={{ position: "start" }}
              id="date-picker-inline"
              label="Start Date"
              fullWidth
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
                if (e > endDate) setEndDate(null);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              disabled={!startDate}
              InputAdornmentProps={{ position: "start" }}
              id="date-picker-inline"
              shouldDisableDate={(date) => date <= startDate}
              label="End Date"
              fullWidth
              value={endDate}
              onChange={(e) => setEndDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
          <Button onClick={() => saveDescription()} style={{ color: "black", fontWeight: "bold" }}>
            Save
          </Button>
        </Box>
      </Popover>
      <Grid
        className={classes.scrollBoxLower}
        id={project.id}
        container
        style={{
          color: "black",
          backgroundColor: index % 2 === 0 ? "rgba(0,0,0,0.05)" : "white",
          fontSize: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          fontFamily: "Lato",
        }}
      >
        <React.Fragment>
          <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={1}>
            <Tooltip title={editing ? "Press save or cancel in the toolbar" : "Select edit in the toolbar"}>
              <div>
                <Checkbox
                  style={{ color: editing ? "rgba(58,81,153,.2)" : "#3A5199", height: "fit-content" }}
                  disabled={editing}
                  checked={selected.includes(project.id)}
                  onChange={(event) =>
                    event.target.checked
                      ? setSelected([...selected, project.id])
                      : setSelected([...selected.filter((id) => id !== project.id)])
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </Tooltip>
          </Grid>

          <React.Fragment>
            <Grid item xs={3}>
              <Box
                style={{
                  border: project.type === "MS" ? "1px solid #3B8AE4" : "1px solid #423BE4",
                  borderRadius: 5,
                  paddingLeft: 10,
                  marginRight: 10,
                }}
                pt={1}
                pb={1}
                display="flex"
                flexDirection="column"
                height={boxHeight - 10}
                position="relative"
              >
                <Box position="absolute" p={1} style={{ top: 0, right: 0 }}>
                  <Tooltip title="Edit Project Details">
                    <IconButton
                      style={{ fontSize: "1rem", color: project.type === "MS" ? "#3B8AE4" : "#423BE4" }}
                      onClick={(e) => handleClick(e)}
                    >
                      <i className="fad fa-file-search" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <MaxText
                  bold
                  title={`${project.company && project.company.name}`}
                  helper="Client: "
                  description="Click here to copy the text"
                  snackbar={props.snackbar}
                  setSnackbar={props.setSnackbar}
                />
                <MaxText
                  bold
                  color={project.type === "MS" ? "#3B8AE4" : "#423BE4"}
                  title={`${project.type}`}
                  helper="Service Type: "
                  description="Click here to copy the text"
                  snackbar={props.snackbar}
                  setSnackbar={props.setSnackbar}
                />
                <MaxText
                  bold
                  title={`${project.projectName}`}
                  helper="Project Name: "
                  description="Click here to copy the text"
                  snackbar={props.snackbar}
                  setSnackbar={props.setSnackbar}
                />
                <MaxText
                  bold
                  title={`${formatDate(new Date(project.startDate).toDateString())} - ${formatDate(
                    new Date(project.endDate).toDateString()
                  )}`}
                  helper="Duration: "
                  description="Click here to copy the text"
                  snackbar={props.snackbar}
                  setSnackbar={props.setSnackbar}
                />
                <MaxText
                  bold
                  title={`${project.status}`}
                  helper="Project Status: "
                  description="Click here to copy the text"
                  snackbar={props.snackbar}
                  setSnackbar={props.setSnackbar}
                />
                <MaxText
                  bold
                  title={`${project.users && project.users.reduce((acc, user) => acc + user.allocation, 0)}`}
                  helper="Project Allocation: "
                  description="Click here to copy the text"
                  snackbar={props.snackbar}
                  setSnackbar={props.setSnackbar}
                />
              </Box>
            </Grid>
            {editing ? null : (
              <Grid item xs={3}>
                <Box
                  style={{
                    border: project.type === "MS" ? "1px solid #3B8AE4" : "1px solid #423BE4",
                    background: project.type === "MS" ? "rgba(59,138,228,.1)" : "rgba(66,59,228,.1)",
                    borderRadius: 5,
                    paddingLeft: 10,
                    marginRight: 10,
                    overflow: "auto",
                  }}
                  pt={1}
                  pb={1}
                  display="flex"
                  // justifyContent="center"
                  flexDirection="column"
                  height={boxHeight - 10}
                  position="relative"
                >
                  <TreeView
                    // className={classes.root}
                    defaultCollapseIcon={<i className="fas fa-chevron-down" />}
                    defaultExpandIcon={<i className="fas fa-chevron-right" />}
                  >
                    {project.workElements &&
                      project.workElements.map((we, index) => {
                        const data = we;
                        return (
                          <TreeItem nodeId={`${we.id}-${index}`} label={we.workElement.name} key={`${we.id}-${index}`}>
                            {data.deliverables.map((item, i) => {
                              return (
                                <Box display="flex" alignItems="baseline">
                                  <i
                                    className="fas fa-circle"
                                    style={{
                                      marginRight: 3,
                                      color: project.type === "MS" ? "rgba(59,138,228,.5)" : "rgba(66,59,228,.5)",
                                      fontSize: "0.4rem",
                                      transform: "translateY(-2px)",
                                    }}
                                  />
                                  <MaxText
                                    key={`${item.id}-${i}`}
                                    fontSize="0.8rem"
                                    title={item.deliverable.name}
                                    description="Click here to copy the text"
                                    snackbar={props.snackbar}
                                    setSnackbar={props.setSnackbar}
                                  />
                                </Box>
                              );
                            })}
                          </TreeItem>
                        );
                      })}
                  </TreeView>
                </Box>
              </Grid>
            )}
          </React.Fragment>

          <Grid item xs={editing ? 8 : 5}>
            <Box
              style={{
                border: project.type === "MS" ? "1px solid #3B8AE4" : "1px solid #423BE4",
                background: project.type === "MS" ? "rgba(59,138,228,.05)" : "rgba(66,59,228,.05)",
                borderRadius: 5,
                paddingLeft: 10,
                marginRight: 10,
                overflow: editing ? "auto" : null,
              }}
              pt={1}
              pb={1}
              display="flex"
              // justifyContent="center"
              flexDirection="column"
              height={!editing ? boxHeight - 10 : boxHeight + 30}
              position="relative"
            >
              <React.Fragment>
                <Box position="absolute" p={1} style={{ top: 0, right: 0 }}>
                  <Tooltip title="Add Resource">
                    <IconButton style={{ fontSize: "1rem", color: "#2373D8" }} onClick={(e) => resourceHandleClick(e)}>
                      <i className="fas fa-user-plus" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove Resource">
                    <IconButton
                      style={{ fontSize: "1rem", color: "#eba024" }}
                      onClick={(e) => removeResourceHandleClick(e)}
                    >
                      <i className="fas fa-user-minus" />
                    </IconButton>
                  </Tooltip>
                </Box>
                {project.users &&
                  project.users.map((user) => {
                    return (
                      <Box key={`role-${user.id}`} mr={1} display="flex" alignItems="center">
                        {editing ? (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={4} md={2}>
                              <Box key={`allocation-${user.id}`} display="flex" alignItems="center" padding={0.5}>
                                <TextField
                                  label="Allocation"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  defaultValue={user.allocation}
                                  value={user.newAllocation}
                                  onChange={(e) =>
                                    updateResource("newAllocation", project.id, user.id, parseInt(e.target.value))
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                              <AsyncAutoComplete
                                fetchData={async () =>
                                  await props.getUsersWithRoleId(user.newRoleId || user.roleId, project.id)
                                }
                                defaultValue={user.user || null}
                                projectId={project.id}
                                resourceId={user.id}
                                objKey="newResourceId"
                                label={user.role && user.role.name ? user.role.name : "No Role Selected"}
                                updateResource={updateResource}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <React.Fragment>
                            <Box
                              display="flex"
                              mt="3px"
                              mb="3px"
                              ml={2}
                              mr={1}
                              alignItems="center"
                              justifyContent="center"
                              width="50px"
                              overflow="auto"
                              style={{
                                background: "white",
                                borderRadius: 5,
                                boxShadow: "3px 3px 1px 1px rgba(1,22,39,.1)",
                              }}
                              key={`allocation-${user.id}`}
                            >
                              {user.allocation}
                            </Box>
                            <MaxText
                              nowrap
                              bold
                              title={`${user.role && user.role.name ? user.role.name : "No Role Selected"}`}
                              description="Click here to copy the text"
                              snackbar={props.snackbar}
                              setSnackbar={props.setSnackbar}
                            />
                            {": "}
                            <Box>
                              <MaxText
                                title={user && user.user ? `${user.user.firstName} ${user.user.lastName}` : "---"}
                                description="Click here to copy the text"
                                snackbar={props.snackbar}
                                setSnackbar={props.setSnackbar}
                              />
                            </Box>
                          </React.Fragment>
                        )}
                      </Box>
                    );
                  })}
              </React.Fragment>
            </Box>
          </Grid>
        </React.Fragment>
      </Grid>
    </Box>
  );
}
