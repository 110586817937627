/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "/hub";

export const SAVE_TIME_ENTRY_REQUEST = "SAVE_TIME_ENTRY_REQUEST";
export const SAVE_TIME_ENTRY_SUCCESS = "SAVE_TIME_ENTRY_SUCCESS";
export const SAVE_TIME_ENTRY_FAILURE = "SAVE_TIME_ENTRY_FAILURE";

const postTimeEntryTask = (payload) => ({
  [CALL_API]: {
    types: [SAVE_TIME_ENTRY_REQUEST, SAVE_TIME_ENTRY_SUCCESS, SAVE_TIME_ENTRY_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/entry/tasks/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.TIME_ENTRY_TASKS,
  },
});

export const createTimeEntryTask = (payload) => (dispatch) => {
  return dispatch(postTimeEntryTask(payload));
};

export const UPDATE_TIME_ENTRY_TASK_REQUEST = "UPDATE_TIME_ENTRY_TASK_REQUEST";
export const UPDATE_TIME_ENTRY_TASK_SUCCESS = "UPDATE_TIME_ENTRY_TASK_SUCCESS";
export const UPDATE_TIME_ENTRY_TASK_FAILURE = "UPDATE_TIME_ENTRY_TASK_FAILURE";

const changeTimeEntryTask = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_TIME_ENTRY_TASK_REQUEST, UPDATE_TIME_ENTRY_TASK_SUCCESS, UPDATE_TIME_ENTRY_TASK_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/entry/tasks/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.TIME_ENTRY_TASKS,
  },
});

export const updateTimeEntryTask = (id, payload) => (dispatch) => {
  return dispatch(changeTimeEntryTask(id, payload));
};

export const DELETE_TIME_ENTRY_TASK_REQUEST = "DELETE_TIME_ENTRY_TASK_REQUEST";
export const DELETE_TIME_ENTRY_TASK_SUCCESS = "DELETE_TIME_ENTRY_TASK_SUCCESS";
export const DELETE_TIME_ENTRY_TASK_FAILURE = "DELETE_TIME_ENTRY_TASK_FAILURE";

const deleteTimeEntryTask = (id) => ({
  [CALL_API]: {
    types: [DELETE_TIME_ENTRY_TASK_REQUEST, DELETE_TIME_ENTRY_TASK_SUCCESS, DELETE_TIME_ENTRY_TASK_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/entry/tasks/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.TIME_ENTRY_TASKS,
  },
});

export const removeTimeEntryTask = (id) => (dispatch) => {
  return dispatch(deleteTimeEntryTask(id));
};
export const GET_ALL_TIME_ENTRY_TASKS_REQUEST = "GET_ALL_TIME_ENTRY_TASKS_REQUEST";
export const GET_ALL_TIME_ENTRY_TASKS_SUCCESS = "GET_ALL_TIME_ENTRY_TASKS_SUCCESS";
export const GET_ALL_TIME_ENTRY_TASKS_FAILURE = "GET_ALL_TIME_ENTRY_TASKS_FAILURE";

const fetchAllTimeEntryTasks = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_TIME_ENTRY_TASKS_REQUEST, GET_ALL_TIME_ENTRY_TASKS_SUCCESS, GET_ALL_TIME_ENTRY_TASKS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.TIME_ENTRY_TASKS_ARRAY,
  },
  refresh,
});

export const loadTimeEntryTasks = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllTimeEntryTasks(
        `${BASE_URL}/time/entry/tasks/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllTimeEntryTasks(
        `${BASE_URL}/time/entry/tasks/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllTimeEntryTasks(
        `${BASE_URL}/time/entry/tasks/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllTimeEntryTasks(`${BASE_URL}/time/entry/tasks/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const TIME_ENTRY_LIST_REQUEST = "TIME_ENTRY_LIST_REQUEST";
export const TIME_ENTRY_LIST_SUCCESS = "TIME_ENTRY_LIST_SUCCESS";
export const TIME_ENTRY_LIST_FAILURE = "TIME_ENTRY_LIST_FAILURE";

const fetchTimeEntryTasksList = () => ({
  [CALL_API]: {
    types: [TIME_ENTRY_LIST_REQUEST, TIME_ENTRY_LIST_SUCCESS, TIME_ENTRY_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/entry/tasks/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getTimeEntryTasksList = () => (dispatch) => {
  return dispatch(fetchTimeEntryTasksList());
};

export const TIME_ENTRY_COUNT_REQUEST = "TIME_ENTRY_COUNT_REQUEST";
export const TIME_ENTRY_COUNT_SUCCESS = "TIME_ENTRY_COUNT_SUCCESS";
export const TIME_ENTRY_COUNT_FAILURE = "TIME_ENTRY_COUNT_FAILURE";

const fetchTimeEntryTasksCount = () => ({
  [CALL_API]: {
    types: [TIME_ENTRY_COUNT_REQUEST, TIME_ENTRY_COUNT_SUCCESS, TIME_ENTRY_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/time/entry/tasks/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getTimeEntryTasksCount = () => (dispatch) => {
  return dispatch(fetchTimeEntryTasksCount());
};
