/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const SAVE_COURSE_REQUEST = "SAVE_COURSE_REQUEST";
export const SAVE_COURSE_SUCCESS = "SAVE_COURSE_SUCCESS";
export const SAVE_COURSE_FAILURE = "SAVE_COURSE_FAILURE";

const createCourse = (payload) => ({
  [CALL_API]: {
    types: [SAVE_COURSE_REQUEST, SAVE_COURSE_SUCCESS, SAVE_COURSE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/courses`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.COURSES_ARRAY,
  },
});

export const saveCourse = (payload) => (dispatch) => {
  return dispatch(createCourse(payload));
};

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILURE = "UPDATE_COURSE_FAILURE";

const changeCourse = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_COURSE_REQUEST, UPDATE_COURSE_SUCCESS, UPDATE_COURSE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/courses/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.COURSES,
  },
});

export const updateCourse = (id, payload) => (dispatch) => {
  return dispatch(changeCourse(id, payload));
};

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";

const removeCourse = (id) => ({
  [CALL_API]: {
    types: [DELETE_COURSE_REQUEST, DELETE_COURSE_SUCCESS, DELETE_COURSE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/courses/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.COURSES,
  },
});

export const deleteCourse = (id) => (dispatch) => {
  return dispatch(removeCourse(id));
};


export const GET_ALL_COURSES_REQUEST = "GET_ALL_COURSES_REQUEST";
export const GET_ALL_COURSES_SUCCESS = "GET_ALL_COURSES_SUCCESS";
export const GET_ALL_COURSES_FAILURE = "GET_ALL_COURSES_FAILURE";

const fetchAllCourses = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_COURSES_REQUEST, GET_ALL_COURSES_SUCCESS, GET_ALL_COURSES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COURSES_ARRAY,
  },
  refresh,
});

export const getAllCourses = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllCourses(`${ADMIN_URL}/courses/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllCourses(
        `${ADMIN_URL}/courses/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllCourses(`${ADMIN_URL}/courses/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`)
    );
  }

  return dispatch(
    fetchAllCourses(`${ADMIN_URL}/courses/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_COURSES_REQUEST = "GET_COUNT_COURSES_REQUEST";
export const GET_COUNT_COURSES_SUCCESS = "GET_COUNT_COURSES_SUCCESS";
export const GET_COUNT_COURSES_FAILURE = "GET_COUNT_COURSES_FAILURE";

const fetchCoursesCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_COURSES_REQUEST, GET_COUNT_COURSES_SUCCESS, GET_COUNT_COURSES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/courses/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getCoursesCount = (search) => (dispatch) => {
  return dispatch(fetchCoursesCount(search));
};

export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_FAILURE = "COURSE_LIST_FAILURE";

const fetchCourseList = () => ({
  [CALL_API]: {
    types: [COURSE_LIST_REQUEST, COURSE_LIST_SUCCESS, COURSE_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/courses/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getCourseList = () => (dispatch) => {
  return dispatch(fetchCourseList());
};
