import React, { useState, useEffect } from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import logo from "../../../../public/img/cisoshare-min1.png";

export default function Top(props) {
  // const classes = useStyles();

  return (
    <Box width="90%">
      <Grid container>
      <Hidden smUp>
          <Grid item xs={12} sm={4} md={3}>
            <Box
              display="flex"
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
              display="flex"
              p={2}
            >
              <img src={logo} width="50%" />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} md={9}>
          <Box p={2}>
            <Box fontWeight="bold">Project Initiation and Artifacts</Box>
            <Box>This document defines the various artifacts required to deliver a Services project at CISOSHARE.</Box>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={4} md={3}>
            <Box
              display="flex"
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
              display="flex"
              p={2}
            >
              <img src={logo} width="80%" />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}
