import React from "react";
import { Box, IconButton } from "@material-ui/core";
import moment from "moment/moment";

export default function ChatBubbles(props) {
  const { classes } = props;
  return props.comments.map((comment, i = 0) => {
    return (
      <Box
        className={`${classes.bubbleContainer} ${comment.direction === "right" ? classes.right : classes.left}`}
        key={i}
        id={comment.id}
      >
        <Box key={i++} className={`${classes.bubble} ${comment.direction === "right" ? classes.light : classes.dark}`}>
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            <Box fontWeight="bold" fontSize=".8rem" mr={3}>
              {comment.userName}{" "}
              <span style={{ fontWeight: 300, fontSize: "0.65rem" }}>
                ({moment(comment.createdAt).format("MM/DD/YYYY")})
              </span>
            </Box>
            {comment.direction === "right" ? (
              <Box>
                <IconButton onClick={() => props.modifyComment("edit", comment.id, comment.comment)} size="small">
                  <i className="fal fa-edit" style={{ fontSize: "0.8rem", color: "white" }} />
                </IconButton>
                <IconButton
                  onClick={(e) => props.modifyComment("delete", comment.id, comment.comment, e.currentTarget)}
                  size="small"
                >
                  <i className="fal fa-trash" style={{ fontSize: "0.8rem", color: "white" }} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
          <Box className={classes.comment} style={{ whiteSpace: "initial", fontWeight: "300" }} mt={1}>
            {comment.deleted ? <span style={{ fontStyle: "italic" }}>Deleted</span> : comment.comment}
          </Box>
        </Box>
      </Box>
    );
  });
}
