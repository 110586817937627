import React from "react";
import BaseButton from "../BaseButton";
import { Box } from "@material-ui/core";

const Fallback = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: 'url("./img/backgroundLight.png")',
        backgroundSize: "cover",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Lato",
          fontSize: "1.6rem",
        }}
      >
        <Box mb={1}>Something Went Wrong</Box>
        {!window.location.href.includes("home") ? (
          <BaseButton clickHandler={() => props.history.push("/home")} text={"Go to Home"} />
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default Fallback;
