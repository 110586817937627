import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
const customFilter = createFilterOptions();

export default function CloneProjectItem(props) {
  const {
    project,
    index,
    key,
    addNewResource,
    updateClonedProject,
    updateClonedProjectResources,
    tags,
    AutoComplete,
  } = props;

  return (
    <Accordion
      key={project.id}
      style={{
        marginBottom: 10,
        borderRadius: 5,
        boxShadow: "2px 2px 50px 10px rgba(0,0,0, 0.2)",
        width: 700,
      }}
    >
      <AccordionSummary
        style={{
          borderRadius: 5,
          backgroundColor: index % 2 === 0 ? "#011627" : "rgba(1,22,39,.8)",
          color: "white",
        }}
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          {project.name} (
          {project.company && project.company.name
            ? project.company.name
            : project.newCompanyName
            ? project.newCompanyName
            : "No Organization Selected"}
          )
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", borderRadius: 5 }}>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }} mt={1}>
          <Box mb={3} display="flex" alignItems="center" justifyContent="center">
            <Autocomplete
              size="small"
              options={props.companies}
              getOptionLabel={(option) => option.name}
              value={project.company}
              onChange={(e, newVal) => {
                updateClonedProject(project.id, "company", newVal);
                updateClonedProject(project.id, "newCompanyName", "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: 300, borderRadius: 50 }}
                  variant="outlined"
                  label="Pick Existing Organization"
                />
              )}
            />
            <Box mr={5} ml={5}>
              OR
            </Box>
            <TextField
              size="small"
              style={{ width: 300, borderRadius: 50 }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="New Organization"
              type="string"
              value={project.newCompanyName}
              onChange={(e, newVal) => {
                updateClonedProject(project.id, "company", "");
                updateClonedProject(project.id, "newCompanyName", e.target.value);
              }}
            />
          </Box>
          <Box display="flex" width="100%" mb={2}>
            <Autocomplete
              size="small"
              options={["MS", "PS"]}
              value={project.type}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => updateClonedProject(project.id, "type", newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: 150, borderRadius: 50 }}
                  variant="outlined"
                  label="Project Type"
                />
              )}
            />
            <Box ml={1} width="100%">
              <Autocomplete
                size="small"
                options={tags}
                value={project.tags}
                getOptionLabel={(option) => option}
                filterOptions={(options, params) => {
                  const filtered = customFilter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push(params.inputValue);
                  }

                  return filtered;
                }}
                multiple
                clearOnBlur
                freeSolo
                fullWidth
                onChange={(e, newValue) => updateClonedProject(project.id, "tags", newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    style={{ borderRadius: 50 }}
                    variant="outlined"
                    label="Tags"
                  />
                )}
              />
            </Box>
          </Box>
          <Box mt={1} mb={1}>
            <TextField
              fullWidth
              multiline
              size="small"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Description"
              type="string"
              value={project.description}
              onChange={(e) => updateClonedProject(project.id, "description", e.target.value)}
            />
          </Box>
          {project.users.map((resource, i) => {
            return (
              <Box
                mt={1}
                key={`resource-${i}`}
                display="flex"
                alignItems="center"
                pt={0.2}
                pb={0.2}
                style={{
                  background: resource.skip ? "blanchedalmond" : "white",
                  transition: "0.1s ease-in-out",
                }}
              >
                {!resource.skip ? (
                  <Tooltip title="Remove Row">
                    <IconButton
                      onClick={() => updateClonedProjectResources("skip", project.id, resource.id, !resource.skip)}
                    >
                      <i style={{ color: "red", fontSize: "1.3rem" }} className={`fal fa-trash`} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Add Row Back">
                    <IconButton
                      onClick={() => updateClonedProjectResources("skip", project.id, resource.id, !resource.skip)}
                    >
                      <i style={{ color: "blue", fontSize: "1.3rem" }} className={`fal fa-undo`} />
                    </IconButton>
                  </Tooltip>
                )}
                <Box mr={1} ml={1}>
                  <AutoComplete
                    skip={resource.skip}
                    options={props.resources}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    updateResource={updateClonedProjectResources}
                    id={resource.id}
                    value={resource.user}
                    projectId={project.id}
                    objKey="user"
                    label="Resource"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        style={{
                          width: 120,
                        }}
                        variant="outlined"
                        label="Tags"
                      />
                    )}
                  />
                </Box>
                <Box mr={1} ml={1}>
                  <AutoComplete
                    options={props.roles}
                    skip={resource.skip}
                    getOptionLabel={(option) => option.name}
                    updateResource={updateClonedProjectResources}
                    id={resource.id}
                    projectId={project.id}
                    value={resource.role}
                    objKey="role"
                    label="Role"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: 120, borderRadius: 50 }}
                        variant="outlined"
                        label="Tags"
                      />
                    )}
                  />
                </Box>
                <Box ml={1}>
                  <TextField
                    label="Allocation"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    type="number"
                    disabled={resource.skip}
                    value={resource.allocation}
                    onChange={(e) =>
                      updateClonedProjectResources("allocation", project.id, resource.id, parseInt(e.target.value))
                    }
                  />
                </Box>
              </Box>
            );
          })}
          <Box style={{ alignSelf: "center" }}>
            <Tooltip title="Add New Row">
              <IconButton onClick={() => addNewResource(project.id)}>
                <i style={{ color: "black", fontSize: "1.3rem" }} className={`fal fa-plus-circle`} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
