import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { useStyles } from "../styles/CustomTable.styles";

const FrameTool = (props) => {
  const classes = useStyles();
  return (
    <Tooltip title={props.tip}>
      <IconButton
        onClick={props.onClick}
        style={{ padding: 7 }}
        className={props.light ? classes.iconHoverLight : classes.iconHoverDark}
      >
        <i
          className={`fa${props.solid ? "s" : "l"} fa-${props.icon} ${
            props.light ? classes.hoverDark : classes.hoverLight
          }`}
          style={{
            fontSize: "1.2rem",
            marginRight: 2,
            marginLeft: 2,
            color: props.color ? props.color : "black",
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default FrameTool;
