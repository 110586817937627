import React, { useEffect } from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadPrograms,
  fetchProgramCount,
  savePrograms,
  updatePrograms,
  deletePrograms,
  loadPoliciesList,
  loadProcessesList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { getColumns } from "../utils/programColumns";

const Programs = (props) => {
  useEffect(() => {
    props.loadPoliciesList();
    props.loadProcessesList();
  }, []);

  const columns = getColumns(
    props.lists && props.lists.policies && props.lists.policies.list,
    props.lists && props.lists.processes && props.lists.processes.list
  );

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchProgramCount}
            paginationCall={props.loadPrograms}
            title="Programs"
            entity="programs"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(row) => props.savePrograms(row)}
            editFunction={(row, oldData) => props.updatePrograms(row.id, row)}
            deleteFunction={(row) => props.deletePrograms(row.id)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    programs: state.entities.programs,
    pageInfo: state.pagination.programs,
    lists: state.entities.lists,
    processes: (state.entities.lists && state.entities.lists.processes && state.entities.processes.list) || [],
    policies: (state.entities.lists && state.entities.lists.policies && state.entities.policies.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadPrograms: (page, limit, refresh) => dispatch(loadPrograms(page, limit, refresh)),
    fetchProgramCount: () => dispatch(fetchProgramCount()),
    savePrograms: (payload) => dispatch(savePrograms(payload)),
    updatePrograms: (id, payload) => dispatch(updatePrograms(id, payload)),
    deletePrograms: (id) => dispatch(deletePrograms(id)),
    loadPoliciesList: () => dispatch(loadPoliciesList()),
    loadProcessesList: () => dispatch(loadProcessesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Programs));
