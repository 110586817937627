/**
 *
 */
import user from "./user_reducer";
import { entities, errorMessage, pagination } from "./api_reducer";

export default {
  user,
  entities,
  errorMessage,
  pagination
};
