import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const GET_CONTROLS_REQUEST = "GET_CONTROLS_REQUEST";
export const GET_CONTROLS_SUCCESS = "GET_CONTROLS_SUCCESS";
export const GET_CONTROLS_FAILURE = "GET_CONTROLS_FAILURE";

const fetchControls = (nextPageUrl, refresh) => ({
  [CALL_API]: {
    types: [GET_CONTROLS_REQUEST, GET_CONTROLS_SUCCESS, GET_CONTROLS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CONTROLS_ARRAY,
  },
  refresh,
});

export const getControls = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchControls(`${ADMIN_URL}/controls/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchControls(
        `${ADMIN_URL}/controls/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchControls(`${ADMIN_URL}/controls/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`)
    );
  }

  return dispatch(fetchControls(`${ADMIN_URL}/controls/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`));
};

export const GET_CONTROLS_COUNT_REQUEST = "GET_CONTROLS_COUNT_REQUEST";
export const GET_CONTROLS_COUNT_SUCCESS = "GET_CONTROLS_COUNT_SUCCESS";
export const GET_CONTROLS_COUNT_FAILURE = "GET_CONTROLS_COUNT_FAILURE";

const getControlsCount = (searchTerm) => ({
  [CALL_API]: {
    types: [GET_CONTROLS_COUNT_REQUEST, GET_CONTROLS_COUNT_SUCCESS, GET_CONTROLS_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/controls/count?search=${searchTerm}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchControlsCount = (searchTerm) => (dispatch) => {
  return dispatch(getControlsCount(searchTerm));
};

export const POST_CONTROLS_REQUEST = "POST_CONTROLS_REQUEST";
export const POST_CONTROLS_SUCCESS = "POST_CONTROLS_SUCCESS";
export const POST_CONTROLS_FAILURE = "POST_CONTROLS_FAILURE";

const postControl = (payload) => ({
  [CALL_API]: {
    types: [POST_CONTROLS_REQUEST, POST_CONTROLS_SUCCESS, POST_CONTROLS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/controls`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.CONTROLS,
  },
});

export const createControl = (payload) => (dispatch) => {
  return dispatch(postControl(payload));
};

export const UPDATE_CONTROL_REQUEST = "UPDATE_CONTROL_REQUEST";
export const UPDATE_CONTROL_SUCCESS = "UPDATE_CONTROL_SUCCESS";
export const UPDATE_CONTROL_FAILURE = "UPDATE_CONTROL_FAILURE";

const changeControl = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_CONTROL_REQUEST, UPDATE_CONTROL_SUCCESS, UPDATE_CONTROL_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/controls/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.CONTROLS,
  },
});

export const updateControl = (id, payload) => (dispatch) => {
  return dispatch(changeControl(id, payload));
};

export const DELETE_CONTROL_REQUEST = "DELETE_CONTROL_REQUEST";
export const DELETE_CONTROL_SUCCESS = "DELETE_CONTROL_SUCCESS";
export const DELETE_CONTROL_FAILURE = "DELETE_CONTROL_FAILURE";

const removeControl = (id) => ({
  [CALL_API]: {
    types: [DELETE_CONTROL_REQUEST, DELETE_CONTROL_SUCCESS, DELETE_CONTROL_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/controls/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.CONTROLS,
  },
});

export const deleteControl = (id) => (dispatch) => {
  return dispatch(removeControl(id));
};

export const CONTROLS_LIST_REQUEST = "CONTROLS_LIST_REQUEST";
export const CONTROLS_LIST_SUCCESS = "CONTROLS_LIST_SUCCESS";
export const CONTROLS_LIST_FAILURE = "CONTROLS_LIST_FAILURE";

const loadControlsList = () => ({
  [CALL_API]: {
    types: [CONTROLS_LIST_REQUEST, CONTROLS_LIST_SUCCESS, CONTROLS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/controls/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getControlsList = () => (dispatch) => {
  return dispatch(loadControlsList());
};
