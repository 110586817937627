const ENV = {
  local: {
    baseURL: "http://localhost:3000",
    secretId: "%C5s6%S5vGGQ",
    secretKey: "b$yiE^kD%GfbjZmR2AxmD8I6*w@Po2",
    timely:
      "https://api.timelyapp.com/1.1/oauth/authorize?response_type=code&redirect_uri=urn:ietf:wg:oauth:2.0:oob&client_id=DAGImnQUnxI9z8u9iE-x0R17eSrFEkwKNTuts5W1aVc",
  },
  qa: {
    baseURL: "https://cottoneyedjoe.cisoshare.com",
    secretId: "%C5s6%S5vGGQ",
    secretKey: "b$yiE^kD%GfbjZmR2AxmD8I6*w@Po2",
  },
  production: {
    baseURL: "https://sylvia.cisoshare.com",
    secretId: "%C5s6%S5vGGQ",
    secretKey: "b$yiE^kD%GfbjZmR2AxmD8I6*w@Po2",
  },
};

const chosenEnv = ENV.production;

export const host = chosenEnv.baseURL;

export const timelyCB = chosenEnv.timely;

export const client = {
  id: chosenEnv.secretId,
  secret: chosenEnv.secretKey,
};
