import React from "react";
import { connect } from "react-redux";
import { login, logout, getAdminProfile, verifyAdminProfile } from "../../store/actions";
import { constants, doesUserContainRoles } from "../../utils/common";
import { DialogContentText, Box, TextField, Button, makeStyles, InputAdornment } from "@material-ui/core";
import Dialog from "../../components/Dialog/Dialog";
import ReactCodeInput from "react-verification-code-input";
import CPI_main from "../../../public/img/CPI_main-min.png";
import DialogFooter from "../../components/Dialog/DialogFooter";
import BaseButton from "../../components/BaseButton/BaseButton";
import { timelyCB } from "../../config/api_config";

const useStyles = makeStyles(() => ({
  paperContainer: {
    backgroundImage: `url(${CPI_main})`,
    height: "100%",
    backgroundSize: "cover",
  },
  logo: { width: "100%" },
  field: { width: "100%" },
  card: { borderRadius: "8px", boxShadow: "none" },
  content: { textAlign: "center" },
}));

const Login = (props) => {
  const classes = useStyles();
  const [error, setError] = React.useState({ visible: false, description: null });
  const [dialog, setDialog] = React.useState({ type: "", isOpen: false });
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [twoFactorError, setTwoFactorError] = React.useState(false);
  const [twoFactorLoading, setTwoFactorLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleLogin = () => {
    setLoading(true);
    setError({ visible: false, description: null });
    props.login(userName, password).then((result) => {
      setLoading(false);
      if (result.error) setError({ visible: true, description: result.error_description.message });
      else setDialog({ type: "2FA", isOpen: true });
    });
  };

  const navToDashboard = (code = null) => {
    try {
      setTwoFactorLoading(true);
      props.verifyAdminProfile({ email: props.user.email, token: code }).then((res) => {
        if (res.success && !code) {
          if (!res.result.qrVerified) {
            setTwoFactorLoading(false);
            setDialog({ isOpen: false, type: "" });
            return handleLogin();
          }
        }

        if (res.result.qrVerified) {
          return props.history.push(encodeURI(timelyCB));
        }
        setTwoFactorError(true);
        return setTwoFactorLoading(false);
      });
    } catch (e) {}
  };
  const dialogHelper = () => {
    let header, content, actions;
    switch (dialog.type) {
      case "timeOut":
        header = "Logged Out";
        content = (
          <DialogContentText>
            <i className="fa fa-lock fa-3x  " style={{ display: "table" }} />
            <p>For security purposes, you have been logged out of CISOSHARE.</p>
          </DialogContentText>
        );
        actions = (
          <Button
            onClick={() =>
              setDialog({
                ...dialog,
                isOpen: false,
              })
            }
            color="primary"
          >
            OK
          </Button>
        );
        break;
      case "2FA":
        header = "2FA";
        content = (
          <React.Fragment>
            <DialogContentText>
              <Box display="flex" alignItems="center" flexDirection="column">
                <i className="fa fa-lock fa-3x" style={{ display: "table", color: "#ff6666" }} />
                <p>
                  {!props.user
                    ? "Fetching User Info..."
                    : props.user.qrVerified
                    ? `Please enter your 6 digit code from your Google Authenticator app to login.`
                    : `Please scan this QR code with your Google Authenticator app.
                  After the QR Code is successfully added, please press "Continue" on the dialog.
                  In case of failure, please refresh your page and try again or
                  contact support at app@cisoshare.com.`}
                </p>
                <div>
                  {props.user.qrVerified ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          border: twoFactorError ? "2px solid #ff6666" : "",
                          borderRadius: "8px",
                        }}
                      >
                        <ReactCodeInput
                          autoFocus={true}
                          fields={6}
                          loading={twoFactorLoading}
                          onChange={() => {
                            if (twoFactorError) {
                              setTwoFactorError(false);
                            }
                          }}
                          onComplete={(e) => {
                            navToDashboard(e);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <img src={props.user.qrcode ? props.user.qrcode : ""} alt={""} />
                  )}
                </div>
              </Box>
            </DialogContentText>
          </React.Fragment>
        );
        actions = !props.user.qrVerified ? (
          <Button onClick={() => navToDashboard()} color="primary">
            Continue
          </Button>
        ) : null;
        break;
    }
    return (
      <Dialog
        open={dialog.isOpen}
        onClose={() => setDialog({ ...dialog, isOpen: false })}
        toggle={() => setDialog({ ...dialog, isOpen: false })}
        dialogTitle={header}
        dialogContent={content}
        dialogActions={actions}
        small
      />
    );
  };

  return (
    <Box className={classes.paperContainer} display="flex" alignContent="center" justifyContent="center">
      {dialogHelper()}
      <Box
        height="400px"
        width="400px"
        style={{ background: "#fff", borderRadius: 15 }}
        p={3}
        alignSelf="center"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
        flexDirection="column"
      >
        <img className={classes.logo} src={require("../../../public/img/cisoshare-min.png")} />
        <Box display="flex" alignContent="center" flexDirection="column" width="80%" alignSelf="center">
          <TextField
            label="Email"
            className={classes.field}
            size="small"
            value={userName}
            error={error.visible}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className={`fas fa-user-circle`} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => setUserName(event.target.value)}
            margin="dense"
            fullWidth
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Password"
            size="small"
            type="password"
            error={error.visible}
            helperText={error.description}
            value={password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className={`fas fa-lock`} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => setPassword(event.target.value)}
            margin="dense"
          />
        </Box>
        <Box display="flex" alignContent="center" flexDirection="column" width="50%" alignSelf="center">
          <BaseButton
            disabled={userName.split("@")[1] !== "cisoshare.com"}
            white
            icon={loading ? <i className="fal fa-circle-notch fa-spin" /> : null}
            clickHandler={() => handleLogin()}
            text={loading ? "Logging In" : "Login"}
          />
        </Box>

        <DialogFooter />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    error: state.error,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(login(username, password)),
    logout: () => dispatch(logout()),
    getAdminProfile: () => dispatch(getAdminProfile()),
    verifyAdminProfile: (body) => dispatch(verifyAdminProfile(body)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(Login);
