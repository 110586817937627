/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const SAVE_TASK_REQUEST = "SAVE_TASK_REQUEST";
export const SAVE_TASK_SUCCESS = "SAVE_TASK_SUCCESS";
export const SAVE_TASK_FAILURE = "SAVE_TASK_FAILURE";

const createTask = (payload) => ({
  [CALL_API]: {
    types: [SAVE_TASK_REQUEST, SAVE_TASK_SUCCESS, SAVE_TASK_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/tasks`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.TASKS_ARRAY,
  },
});

export const saveTask = (payload) => (dispatch) => {
  return dispatch(createTask(payload));
};

export const UPDATE_TASK_REQUEST = "UPDATE_TASK_REQUEST";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";

const changeTask = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_TASK_REQUEST, UPDATE_TASK_SUCCESS, UPDATE_TASK_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/tasks/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.TASKS,
  },
});

export const updateTask = (id, payload) => (dispatch) => {
  return dispatch(changeTask(id, payload));
};

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE";

const removeTask = (id) => ({
  [CALL_API]: {
    types: [DELETE_TASK_REQUEST, DELETE_TASK_SUCCESS, DELETE_TASK_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/tasks/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.TASKS,
  },
});

export const deleteTask = (id) => (dispatch) => {
  return dispatch(removeTask(id));
};

export const GET_ALL_TASKS_REQUEST = "GET_ALL_TASKS_REQUEST";
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
export const GET_ALL_TASKS_FAILURE = "GET_ALL_TASKS_FAILURE";

const fetchAllTasks = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_TASKS_REQUEST, GET_ALL_TASKS_SUCCESS, GET_ALL_TASKS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.TASKS_ARRAY,
  },
  refresh,
});

export const getAllTasks = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllTasks(`${ADMIN_URL}/tasks/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllTasks(
        `${ADMIN_URL}/tasks/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllTasks(`${ADMIN_URL}/tasks/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`)
    );
  }

  return dispatch(fetchAllTasks(`${ADMIN_URL}/tasks/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`));
};

export const GET_COUNT_TASKS_REQUEST = "GET_COUNT_TASKS_REQUEST";
export const GET_COUNT_TASKS_SUCCESS = "GET_COUNT_TASKS_SUCCESS";
export const GET_COUNT_TASKS_FAILURE = "GET_COUNT_TASKS_FAILURE";

const fetchTasksCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_TASKS_REQUEST, GET_COUNT_TASKS_SUCCESS, GET_COUNT_TASKS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/tasks/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getTasksCount = (search) => (dispatch) => {
  return dispatch(fetchTasksCount(search));
};

export const TASK_LIST_REQUEST = "TASK_LIST_REQUEST";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";
export const TASK_LIST_FAILURE = "TASK_LIST_FAILURE";

const fetchTasksList = () => ({
  [CALL_API]: {
    types: [TASK_LIST_REQUEST, TASK_LIST_SUCCESS, TASK_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/tasks/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getTasksList = () => (dispatch) => {
  return dispatch(fetchTasksList());
};
