import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

export default function Pagination(props) {
  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setLimit(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  return (
    <TablePagination
      style={{
        alignSelf: "flex-end",
        borderTop: "2px solid #011627",
        fontSize: "1rem",
        width: "100%",
        fontFamily: "Lato",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
      }}
      component="div"
      count={props.count}
      page={props.page}
      onChangePage={handleChangePage}
      rowsPerPage={props.limit}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
