import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box, Select, MenuItem, Tooltip, IconButton, InputBase } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ProjectAccordion from "../components/ProjectAccordion";
import {
  createCompanyProject,
  getProjectsList,
  deleteCompanyProject,
  updateCompanyProject,
  resetMilestones,
} from "../../../store/actions";
import { useStyles } from "../styles/SearchBar.styles";

function Projects(props) {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", variant: "info" });
  const [value, setValue] = useState("");
  const [companyProjects, setCompanyProjects] = useState(props.companyProjects);

  useEffect(() => {
    props.loadCompany(props.companyId);
    props.getProjectsList();
  }, []);

  useEffect(() => {
    setCompanyProjects(props.companyProjects);
  }, [props.companyProjects]);

  const filterProjects = (term) => {
    if (!term || term.length === 0) {
      return setCompanyProjects([...props.companyProjects]);
    }

    const filteredProjects = companyProjects.filter((project) =>
      project.name.toLowerCase().includes(term.toLowerCase())
    );
    return setCompanyProjects([...filteredProjects]);
  };

  const createCompanyProject = async () => {
    try {
      const result = await props.createCompanyProject(value, props.companyId);
      if (!result.type.includes("SUCCESS")) throw new Error();
      await props.loadCompany(props.companyId);
      props.setProjects([{ ...Object.values(result.response.entities.projects)[0] }, ...companyProjects]);
      setCompanyProjects([{ ...Object.values(result.response.entities.projects)[0] }, ...companyProjects]);
      setValue("");

      return result;
    } catch (err) {
      return setSnackbar({ isOpen: true, variant: "error", message: `Failed to create the project.` });
    }
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Grid container justify="flex-start">
        <Grid item xs={12}>
          <Box
            mt={2}
            ml={2}
            width="100%"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
          >
            <Box display="flex" alignItems="flex-end" justifyContent="space-between" width="99%">
              <Box>
                <Box ml={1} mb={1} fontWeight="bold">
                  Add a Project
                </Box>
                <Box width="100%" display="flex" alignItems="center" justifyContent="flex-start">
                  <Select
                    variant="outlined"
                    margin="dense"
                    style={{ width: "300px" }}
                    value={value}
                    disabled={
                      props.projects.filter(
                        (project) => !companyProjects.map((cp) => cp.projectId).includes(project.id)
                      ).length === 0
                    }
                    onChange={(e) => setValue(e.target.value)}
                  >
                    {props.projects
                      .filter((project) => !companyProjects.map((cp) => cp.projectId).includes(project.id))
                      .map((project) => {
                        return <MenuItem value={project.id}>{project.name}</MenuItem>;
                      })}
                  </Select>
                  <Tooltip title={"Create Project"}>
                    <IconButton
                      disabled={value === null || value.length === 0}
                      onClick={() => createCompanyProject()}
                      style={{ color: value === null || value.length === 0 ? "rgba(0,0,0,0.2)" : "#0E8C64" }}
                    >
                      <i className="fad fa-plus-circle" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Box mr={2} mb={0.5} className={`${classes.search} selector2`}>
                <div className={classes.searchIcon}>
                  <i className="fas fa-search" />
                </div>
                <InputBase
                  placeholder="Search…"
                  onChange={(e) => filterProjects(e.target.value)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {companyProjects.map((project) => {
              return (
                <Grid item xs={12}>
                  <ProjectAccordion
                    key={project.name}
                    id={project.id}
                    name={project.name}
                    projectId={project.projectId}
                    milestones={project.milestones}
                    companyId={props.companyId}
                    deleteCompanyProject={props.deleteCompanyProject}
                    updateCompanyProject={props.updateCompanyProject}
                    resetMilestones={props.resetMilestones}
                    setSnackbar={setSnackbar}
                    companyProjects={companyProjects}
                    setCompanyProjects={setCompanyProjects}
                    setProjects={props.setProjects}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    projects: (state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    createCompanyProject: (projectId, companyId) => dispatch(createCompanyProject(projectId, companyId)),
    deleteCompanyProject: (projectId, companyId) => dispatch(deleteCompanyProject(projectId, companyId)),
    updateCompanyProject: (companyProjectId, milestoneId, status) =>
      dispatch(updateCompanyProject(companyProjectId, milestoneId, status)),
    getProjectsList: () => dispatch(getProjectsList()),
    resetMilestones: (companyProjectId) => dispatch(resetMilestones(companyProjectId)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Projects));
