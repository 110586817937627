import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function AsyncAutoComplete(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await props.fetchData();
      setLoading(false);
      const result =
        (response.response.entities &&
          response.response.entities.users &&
          Object.values(response.response.entities.users)) ||
        [];
      if (active) {
        setOptions(result);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 300 }}
      size="small"
      open={open}
      onOpen={() => {
        setLoading(true);
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      defaultValue={props.defaultValue}
      renderOption={(option) =>
        `${option.firstName} ${option.lastName} (${option.weeklyAllocation.toFixed(2)} hrs/week)`
      }
      getOptionLabel={(option) => option && `${option.firstName} ${option.lastName}`}
      options={options}
      loading={loading}
      onChange={(e, newVal) => {
        props.updateResource(props.objKey, props.projectId, props.resourceId, newVal);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
