import React, { useEffect } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllTasks,
  getTasksCount,
  saveTask,
  updateTask,
  deleteTask,
  loadProcessesList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import { Grid, Box } from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";

const Tasks = (props) => {
  const columns = [
    { title: "Name", field: "name" },
    {
      title: "Process",
      field: "processId",
      lookup: props.processes || {},
    },
    {
      title: "Level of Effort",
      field: "loe",
      type: "numeric",
    },
    {
      title: "Variable",
      field: "variable",
      type: "numeric",
    },
    {
      title: "Frequency",
      field: "frequency",
    },
    {
      title: "NICE Aligned",
      field: "nistNiceAligned",
      type: "boolean",
    },
  ];

  useEffect(() => {
    props.loadProcessesList();
  }, []);

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s", marginTop: "0.5rem" }}>
      <AuthComponent />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getTasksCount}
            paginationCall={props.getAllTasks}
            title="Tasks"
            entity="tasks"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => props.saveTask({ ...rowData })}
            editFunction={(newData, oldData) => props.updateTask(newData.id, { ...newData })}
            deleteFunction={(rowData) => props.deleteTask(rowData.id)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tasks: state.entities.tasks,
    pageInfo: state.pagination.tasks,
    processes:
      state.entities.lists && state.entities.lists.processes && state.entities.lists.processes.list
        ? state.entities.lists.processes.list.reduce((acc, process) => {
            let { id } = process;
            return { ...acc, [id]: process.name };
          }, {})
        : {},
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getAllTasks: (page, limit, refresh) => dispatch(getAllTasks(page, limit, refresh)),
    getTasksCount: () => dispatch(getTasksCount()),
    saveTask: (data) => dispatch(saveTask(data)),
    updateTask: (id, data) => dispatch(updateTask(id, data)),
    deleteTask: (id) => dispatch(deleteTask(id)),
    loadProcessesList: () => dispatch(loadProcessesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Tasks));
