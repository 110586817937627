import React, { useEffect } from "react";
import { Grid, Box, Tooltip, FormControlLabel, Switch } from "@material-ui/core";
import { useStyles } from "./styles/Permissions.styles";
import { dataSet } from "./utils/data";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Frame from "../../components/Frame/Frame";
import {
  getCisoshareUsers,
  getCompaniesList,
  updatePermissions,
  getAdminProfile,
  loadRolesList,
  addRolesToUser,
} from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabPanel, a11yProps, Accordion, AccordionSummary, AccordionDetails, AccordionHeader } from "./utils/helpers";
import AccessContainer from "./components/AccessContainer";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import UserInformation from "./components/UserInformation";

const Access = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [expanded, setExpanded] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(true);

  useEffect(() => {
    props.loadRolesList();
    props.getCisoshareUsers().then((result) => {
      setData(Object.values(result.response.entities.cisoUsers));
      setLoading(false);
      setRefresh(false);
    });
    props.getCompaniesList();
  }, []);

  const useComponentWillUnmount = (onUnmountHandler) => {
    useEffect(
      () => () => {
        onUnmountHandler();
      },
      []
    );
  };

  function handleClickCheckbox(e) {
    e.stopPropagation();
  }

  useComponentWillUnmount(async () => await props.getAdminProfile());

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const allowAccess = (obj) => {
    return obj.create || obj.update || obj.delete || obj.read;
  };

  const generateAccess = (access) => {
    let accessList = [];
    if (access) {
      if (allowAccess(access.companies)) accessList.push("Organizations");
      if (allowAccess(access.projects)) accessList.push("Projects");
      if (allowAccess(access.roles)) accessList.push("Roles");
      if (allowAccess(access.characteristics)) accessList.push("Characteristics");
      if (allowAccess(access.metadata)) accessList.push("Metadata");
      if (allowAccess(access.frameworks)) accessList.push("Frameworks");
      if (allowAccess(access.hubspot)) accessList.push("HubSpot");
    }

    if (accessList.length === 0) return "None";
    return accessList.join(" | ");
  };

  const generateRoles = (roles) => {
    let rolesList = new Set();

    roles.forEach((role) => {
      if (role.role) {
        rolesList.add(role.role.name);
      }
    });

    const rolesArray = Array.from(rolesList);

    if (rolesArray.length === 0) return "None";
    return (
      <ul>
        {rolesArray.map((role, index) => {
          return <li key={index}>{role}</li>;
        })}
      </ul>
    );
  };

  return (
    <Frame goBack={() => props.history.goBack()}>
      <AuthComponent />

      <Box style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
        <Accordion style={{ background: "#011627", color: "white", cursor: "not-allowed", border: "none" }}>
          <AccordionHeader style={{ background: "inherit", color: "inherit", cursor: "default" }}>
            <Grid container>
              {Object.keys(dataSet.TITLES).map((key) => {
                return (
                  <Grid item xs={dataSet.TITLES[key].size}>
                    <Box>{dataSet.TITLES[key].name}</Box>
                  </Grid>
                );
              })}
            </Grid>
          </AccordionHeader>
        </Accordion>

        {loading
          ? new Array(8).fill("").map((value, index) => {
              return (
                <Box key={index}>
                  <Box mt={0.5} />
                  <Skeleton variant="text" animation="wave" variant="rect" height={60} />
                  <Box mt={0.5} />
                </Box>
              );
            })
          : data.map((user, index) => {
              return (
                <Accordion
                  index={index}
                  style={{
                    transition: "0.5s ease-in-out",
                    // color: expanded === `panel${user.id}` ? "white" : "black",
                    padding: expanded === `panel${user.id}` ? 10 : 0,
                    background:
                      user.adminAccess && user.adminAccess.isSuper
                        ? "rgba(235,107,108,.05)"
                        : expanded === `panel${user.id}`
                        ? "rgba(0,0,0,.1)"
                        : "rgba(35,115,216,.05)",
                  }}
                  expanded={expanded === `panel${user.id}`}
                  onChange={handleChange(`panel${user.id}`)}
                >
                  <AccordionSummary
                    style={{ cursor: "pointer", border: "none" }}
                    aria-controls={`panel${user.id}d-content`}
                    id={`panel${user.id}d-header`}
                  >
                    <Grid container>
                      <Grid item xs={2} className={classes.gridItem}>
                        <Box>
                          {user.firstName} {user.lastName}
                        </Box>
                      </Grid>
                      <Grid item xs={4} className={classes.gridItem}>
                        <Box>
                          {user.adminAccess && user.adminAccess.isSuper ? (
                            <Box>
                              <i className="fad fa-ban" style={{ marginRight: 3, color: "#ff6666" }} /> Locked Settings
                              -<span style={{ fontWeight: "bold", marginLeft: 3 }}>Full Access</span>
                            </Box>
                          ) : (
                            generateAccess(user.adminAccess)
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={4} className={classes.gridItem}>
                        <Box>{generateRoles(user.roles)}</Box>
                      </Grid>
                      <Grid item xs={1} className={classes.gridItem}>
                        <Box ml={4}>
                          <FormControlLabel
                            onClick={(e) => handleClickCheckbox(e)}
                            control={
                              <Switch
                                checked={user.adminAccess && user.adminAccess.isSuper}
                                onChange={() => {
                                  let tempData = data;
                                  const isSuper = tempData.find((u) => u.id === user.id).adminAccess.isSuper;
                                  tempData.find((u) => u.id === user.id).adminAccess.isSuper = !isSuper;
                                  setData([...tempData]);
                                  props.updatePermissions(user.adminAccess.id, { isSuper: !isSuper });
                                  if (!isSuper && `panel${user.id}` === expanded) setExpanded("");
                                }}
                                name="checkedA"
                              />
                            }
                            label="Admin"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      border: "none",
                    }}
                  >
                    <Box className={classes.root}>
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChangeTabs}
                        className={classes.tabs}
                        classes={{
                          indicator: classes.indicator,
                          wrapper: classes.wrapper,
                        }}
                      >
                        {Object.keys(dataSet.MENU_TABS).map((key, index) => {
                          return <Tab className={classes.tab} label={dataSet.MENU_TABS[key]} {...a11yProps(index)} />;
                        })}
                      </Tabs>

                      <TabPanel value={value} index={0} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <UserInformation
                          classes={classes}
                          rolesList={props.rolesList}
                          user={user}
                          section={""}
                          data={data}
                          setData={setData}
                          updatePermissions={props.updatePermissions}
                          addRolesToUser={props.addRolesToUser}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          getCisoshareUsers={props.getCisoshareUsers}
                        />
                      </TabPanel>

                      <TabPanel value={value} index={1} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="companies"
                          updatePermissions={props.updatePermissions}
                          companies={props.companies}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={2} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="projects"
                          updatePermissions={props.updatePermissions}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={3} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="roles"
                          updatePermissions={props.updatePermissions}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={4} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="characteristics"
                          updatePermissions={props.updatePermissions}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={5} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="metadata"
                          updatePermissions={props.updatePermissions}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={6} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="frameworks"
                          updatePermissions={props.updatePermissions}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={7} style={{ width: 400 }}>
                        <Box ml={0.5} mb={1} style={{ fontWeight: "900" }}>
                          Set Permissions:
                        </Box>
                        <AccessContainer
                          classes={classes}
                          user={user}
                          data={data}
                          setData={setData}
                          section="hubspot"
                          updatePermissions={props.updatePermissions}
                        />
                      </TabPanel>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
      </Box>
    </Frame>
  );
};

const mapStateToProps = (state) => {
  return {
    cisoshareUsers: (state.entities.cisoUsers && Object.values(state.entities.cisoUsers)) || [],
    companies: (state.entities.lists && state.entities.lists.companies && state.entities.lists.companies.list) || [],
    rolesList: (state.entities.lists && state.entities.lists.roles && state.entities.lists.roles.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getCisoshareUsers: () => dispatch(getCisoshareUsers()),
    getCompaniesList: () => dispatch(getCompaniesList()),
    updatePermissions: (id, data) => dispatch(updatePermissions(id, data)),
    getAdminProfile: () => dispatch(getAdminProfile()),
    loadRolesList: () => dispatch(loadRolesList()),
    addRolesToUser: (userId, data) => dispatch(addRolesToUser(userId, data)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Access));
