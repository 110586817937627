import { host } from "../config/api_config";
import * as auth from "./auth_helper";
import axios from "axios";

export default async function request(config) {
  const endpoint = config.endpoint.indexOf(host) === -1 ? host + config.endpoint : config.endpoint;
  const options = { method: config.method, headers: {} };

  if (config.auth) {
    try {
      const token = await auth.getToken();
      options.headers.Authorization = `Bearer ${token.access_token}`;
    } catch (error) {
      throw error;
    }
  }

  // body
  if (config.body) {
    options.headers["Content-Type"] = config.contentType || "application/json";
    options.body = config.body instanceof Object ? JSON.stringify(config.body) : config.body;
  }

  options.headers.Accept = "application/json";
  options.credentials = "include";

  return fetch(endpoint, options);
}

export async function uploadSOW(url, data) {
  const token = await auth.getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(`${host}/${url}`, data, config).catch((err) => err);
}
