// Base Imports
import React, { useState, useEffect } from "react";
import { Box, LinearProgress } from "@material-ui/core";

// Dialog Components
import AddNewResource from "../components/AddNewResource";
import AddNewProject from "../components/AddNewProject";
import CloneProject from "../components/CloneProject";

// Header Components
import TableFrame from "../components/TableFrame";
import ProjectRosterHeader from "../components/ProjectRosterHeader";
import ResourcesHeader from "../components/ResourcesHeader";

// Table Components
import ProjectRosterItem from "../components/ProjectRosterItem";
import ProjectResourceItem from "../components/ProjectResourceItem";
import Pagination from "../components/Pagination";

// Utilities
import Alert from "../components/Alert";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { useStyles } from "../styles/CustomTable.styles";
import { useDebounce } from "../utils/useDebounce";
import { getProjectReport } from "../../../store/actions";

export default function ProjectRoster(props) {
  const classes = useStyles();
  const [viewType, setViewType] = useState("Account");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState({ column: "", direction: "" });
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [addNewResource, setAddNewResource] = useState(false);
  const [addNewProject, setAddNewProject] = useState(false);
  const [cloneProjects, setCloneProjects] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [alert, setAlert] = useState({ open: false, type: "" });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });
  const debouncedSearch = useDebounce(search, 750);

  useEffect(() => {
    fetchData();
    fetchResourceData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [viewType, page, limit, sort]);

  useEffect(() => {
    setPage(0);
    setCount(0);
    setSort({ column: "", direction: "" });
  }, [viewType]);

  useEffect(() => {
    fetchData();
  }, [debouncedSearch]);

  useEffect(() => {
    if (search.length === 0) fetchData();
  }, [search]);

  useEffect(() => {
    if (addNewResource || addNewProject || cloneProjects) {
      fetchResourceData();
    }
  }, [addNewResource, addNewProject, cloneProjects]);

  const fetchData = async () => {
    setLoading(true);
    const companyId = props.admin ? "all" : props.companyId;
    let result = {};
    if (viewType === "Account") {
      result = await props.getProjectRosterByCompanyId(
        companyId,
        search,
        page,
        limit,
        false,
        sort.column,
        sort.direction
      );
    } else if (viewType === "Archives") {
      result = await props.getProjectRosterByCompanyId(
        companyId,
        search,
        page,
        limit,
        true,
        sort.column,
        sort.direction
      );
    } else {
      result = await props.getProjectRosterResourceByCompanyId(companyId, search, page, limit, false);
    }
    const resultObj = Object.values(Object.values(result.response.entities)[0])[0];
    setData((resultObj && resultObj.data) || []);
    setCount((resultObj && resultObj.count) || 0);
    setLoading(false);
    return result;
  };

  const fetchResourceData = async () => {
    props.getCisoshareUsers();
    props.getProjectsList();
    props.loadRolesList();
    props.getCompaniesList();
    props.getActiveCompaniesList();
    props.getWorkElementsList();
    props.getDeliverablesList();
  };

  const updateSelectedProjects = async () => {
    try {
      const currentData = data;
      let companyProjectIds = new Set();

      const payload = currentData
        .filter((row) => selected.includes(row.id))
        .map((row) => {
          const projectPayload = row.users
            .map((user) => {
              companyProjectIds.add(user.companyProjectId);

              let updatedResource = {
                userCompanyProjectId: user.id,
                data: {},
              };
              let createUpdate = false;

              if (user.hasOwnProperty("newRoleId")) {
                createUpdate = true;
                updatedResource.data.roleId = user.newRoleId;
              }
              if (user.hasOwnProperty("newResourceId")) {
                createUpdate = true;
                updatedResource.data.userId = user.newResourceId;
              }
              if (user.hasOwnProperty("newAllocation")) {
                createUpdate = true;
                updatedResource.data.allocation = parseInt(user.newAllocation);
              }
              if (user.hasOwnProperty("delete") && user.delete) {
                createUpdate = true;
                updatedResource.data.delete = true;
              }

              if (createUpdate) {
                return updatedResource;
              }
              return null;
            })
            .filter(Boolean);
          return projectPayload;
        });

      const dataToUpdate = payload.flat();

      for await (const row of dataToUpdate) {
        if (row.data.hasOwnProperty("delete") && row.data.delete) {
          await props.detachUserCompanyProject(row.userCompanyProjectId);
        } else {
          const payload = { ...row.data };
          await props.updateUserCompanyProject(row.userCompanyProjectId, { ...payload });
        }
      }

      const target = data.filter((row) => Array.from(companyProjectIds).includes(row.id));

      for await (const row of target) {
        await props.changeCompanyProject(row.id, {
          status: row.status,
          tags: row.tags,
          description: row.description,
          type: row.type,
        });
      }
      setEditMode(false);
      await fetchData();
      setSelected([]);
    } catch (err) {}
  };

  const removeCompanyProjects = async () => {
    for await (const row of selected) {
      const targetRow = data.find((r) => r.id === row);
      const companyId = targetRow.companyId;
      const projectId = targetRow.projectId;
      await props.deleteCompanyProject(projectId, companyId);
    }
    setAlert({ open: false, type: "" });
    setSelected([]);
    await fetchData();
  };

  const archiveCompanyProjects = async (archived) => {
    for await (const row of selected) {
      await props.changeCompanyProject(row, { archived });
    }
    setAlert({ open: false, type: "" });
    setSelected([]);
    await fetchData();
  };

  return (
    <Box>
      {/* Dialogs */}
      <AddNewResource
        open={addNewResource}
        setOpen={setAddNewResource}
        resources={props.resources}
        projects={props.projects}
        roles={props.roles}
        companies={props.activeCompanies}
        projectTypes={[]}
        attachUserToCompanyProject={props.attachUserToCompanyProject}
        getCompanyProjects={props.getCompanyProjects}
        fetchData={fetchData}
      />
      <AddNewProject
        addNewProject={addNewProject}
        setAddNewProject={setAddNewProject}
        companies={props.companies}
        resources={props.resources}
        projects={props.projects}
        workElements={props.workElements}
        deliverables={props.deliverables}
        roles={props.roles}
        saveCompany={props.saveCompany}
        kickoffCompanyProjects={props.kickoffCompanyProjects}
        fetchData={fetchData}
      />
      <CloneProject
        cloneProjects={cloneProjects}
        setCloneProjects={setCloneProjects}
        selected={selected}
        setSelected={setSelected}
        fetchData={fetchData}
        projectRoster={data}
        companies={props.companies}
        resources={props.resources}
        projects={props.projects}
        roles={props.roles}
        saveCompany={props.saveCompany}
        kickoffCompanyProjects={props.kickoffCompanyProjects}
      />
      <Alert
        open={alert.open}
        type={alert.type}
        setAlert={setAlert}
        selected={selected}
        companyProjects={props.companyProjects}
        confirmAction={
          alert.type === "Delete"
            ? () => removeCompanyProjects()
            : alert.type === "Archive"
            ? () => archiveCompanyProjects(true)
            : alert.type === "Un-Archive"
            ? () => archiveCompanyProjects(false)
            : () => {}
        }
      />

      {/* Table Components */}
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <TableFrame
        classes={classes}
        selected={selected}
        setSelected={setSelected}
        setAddNewResource={setAddNewResource}
        setAddNewProject={setAddNewProject}
        setSearch={setSearch}
        viewType={viewType}
        setViewType={setViewType}
        data={data}
        setData={setData}
        editMode={editMode}
        setEditMode={setEditMode}
        updateSelectedProjects={updateSelectedProjects}
        removeCompanyProjects={removeCompanyProjects}
        setAlert={setAlert}
        fetchData={fetchData}
        changeCompanyProject={props.changeCompanyProject}
        setCloneProjects={setCloneProjects}
        getProjectReport={props.getProjectReport}
      />
      {(viewType === "Account" || viewType === "Archives") && data ? (
        <Box className={classes.scrollBox}>
          <ProjectRosterHeader
            selected={selected}
            setSelected={setSelected}
            projectRoster={data}
            sort={sort}
            setSort={setSort}
          />
          <Box className={classes.scrollBoxY}>
            {loading ? <LinearProgress /> : null}
            {data.map((project, index) => {
              return (
                <ProjectRosterItem
                  key={project.id}
                  attachRoleToCompanyProject={props.attachRoleToCompanyProject}
                  detachUserCompanyProject={props.detachUserCompanyProject}
                  project={project}
                  index={index}
                  selected={selected}
                  setSelected={setSelected}
                  editing={editMode && selected.includes(project.id)}
                  setEditMode={setEditMode}
                  data={data}
                  setData={setData}
                  companies={props.companies}
                  resources={props.resources}
                  projects={props.projects}
                  roles={props.roles}
                  snackbar={snackbar}
                  setSnackbar={setSnackbar}
                  fetchData={fetchData}
                  changeCompanyProject={props.changeCompanyProject}
                  viewType={viewType}
                  getUsersWithRoleId={props.getUsersWithRoleId}
                />
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box className={classes.scrollBox}>
          <ResourcesHeader />
          {loading ? <LinearProgress /> : null}
          {data.map((resource, index) => {
            return (
              <ProjectResourceItem resource={resource} index={index} snackbar={snackbar} setSnackbar={setSnackbar} />
            );
          })}
        </Box>
      )}
      <Pagination count={count} limit={limit} setLimit={setLimit} page={page} setPage={setPage} />
    </Box>
  );
}
