import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import AccessItem from "./AccessItem";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function UserInformation(props) {
  const [roles, setRoles] = useState([]);
  const [canViewProjectRoster, setCanViewProjectRoster] = useState(props.user.adminAccess.canViewProjectRoster);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRoles(props.user.roles.map((r) => r.role).filter(Boolean));
    setCanViewProjectRoster(props.user.adminAccess.canViewProjectRoster);
  }, [props.user.adminAccess]);

  const updateRootPermissions = (key, value) => {
    let tempData = props.data;
    tempData.find((row) => row.id === props.user.id).adminAccess = { ...props.user.adminAccess, [key]: value };
    props.setData([...tempData]);
    props.updatePermissions(props.user.adminAccess.id, { ...props.user.adminAccess, [key]: value });
  };

  const updateUserRoles = async (rolesList) => {
    props.setRefresh(true);
    setRoles(rolesList);
    setLoading(true);
    await props.addRolesToUser(props.user.id, { roleIds: rolesList.map((r) => r && r.id).filter(Boolean) });
    props.getCisoshareUsers().then((result) => {
      props.setData([...Object.values(result.response.entities.cisoUsers)]);
      setLoading(false);
    });
  };

  return (
    <div>
      <Autocomplete
        style={{
          background: "white",
          width: 600,
          padding: 10,
          borderRadius: 10,
          boxShadow: "0px 3px 10px 3px rgba(1,22,39,.05)",
        }}
        disableCloseOnSelect
        limitTags={3}
        multiple
        loading={loading}
        id="tags-standard"
        options={props.rolesList.filter((role) => !roles.find((r) => r.id === role.id))}
        getOptionLabel={(option) => option && option.name}
        value={roles}
        onChange={async (e, newVal) => {
          await updateUserRoles(newVal);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="standard"
            label="Roles"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <br />
      <AccessItem
        classes={props.classes}
        selected={!!props.user.adminAccess.isSuper ? true : props.user.adminAccess.canViewProjectRoster}
        boolean
        root
        level="View/Edit Project Roster & Project Queue"
        target="isProjectManager"
        section={props.section}
        userId={props.user.id}
        data={props.user.adminAccess}
        accessId={props.user.adminAccess.id}
        updatePermissions={() => updateRootPermissions("canViewProjectRoster", !canViewProjectRoster)}
      />
    </div>
  );
}
