import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadRequirements, fetchRequirementCount } from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import moment from "moment";
import { Grid } from "@material-ui/core";

const columns = [
  {
    title: "Date Created",
    field: "createdAt",
    render: (row) => moment(row.createdAt).format("MMMM Do YYYY, h:mm A"),
    editable: "never",
  },
  {
    title: "Name",
    field: "name",
    editable: "never",
  },
];

const Requirements = (props) => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    props.fetchRequirementCount().then((response) => {
      if (response.type === "REQUIREMENT_COUNT_SUCCESS") {
        setTotalCount(response.response.entities.counts["requirements"].count);
      }
    });
  }, []);

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            totalCount={totalCount}
            fetchCount={props.fetchRequirementCount}
            paginationCall={props.loadRequirements}
            title="Requirements"
            entity={"requirements"}
            columns={columns}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
            rowClickFunction={(e, row) => props.history.push(`/frameworks/requirements/${row.id}`)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    requirements: state.entities.requirements,
    pageInfo: state.pagination.requirements,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadRequirements: (page, limit, refresh) => dispatch(loadRequirements(page, limit, refresh)),
    fetchRequirementCount: () => dispatch(fetchRequirementCount()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Requirements));
