import React, { useEffect } from "react";

const AuthComponentView = (props) => {
  useEffect(() => {
    if (!props.user.isLoggedIn) return redirectToLogin();
    props.isLoggedIn();
  }, [props.user.isLoggedIn]);

  const redirectToLogin = () => props.history.push("/login");

  return <div></div>;
};

export default AuthComponentView;
