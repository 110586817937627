import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { default as MuiSnackbar } from "@material-ui/core/Snackbar";
import { default as MuiSnackbarContent } from "@material-ui/core/SnackbarContent";
import useStyles from "./Snackbar.styles";
import VariantIcon from "./VariantIcon";

const Snackbar = ({ isOpen = false, variant = "info", message = "", handleClose = () => {} }) => {
  const classes = useStyles();

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={() => handleClose()}
    >
      <MuiSnackbarContent
        className={`${classes[variant]} ${classes.margin10}`}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <div className={`${classes.icon} ${classes.iconVariant}`}>
              <VariantIcon classes={classes} variant={variant} />
            </div>
            <div>{message}</div>
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" disableRipple onClick={() => handleClose(false)}>
            <i style={{ fontSize: "1rem" }} className={`fal fa-times`} />
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  );
};

Snackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

export default Snackbar;
