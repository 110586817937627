import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import Skeleton from "@material-ui/lab/Skeleton";

export default function DropZone(props) {
  const classes = useStyles();

  const prepareFileForUpload = async (file) => {
    props.setFileName(file.name);
    props.setFile(file);
  };

  const removeFile = async () => {
    props.setFileName("");
    props.setFile(null);
    if (!!props.documentId) {
      await props.deleteDocument(props.documentId);
    }
  };

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.area}>
      <Box className={classes.titles}>
        Sanitized Scope of Work{" "}
        <Tooltip title={"This is a scope of work that has been modified to remove all financial information."}>
          <i style={{ cursor: "pointer", color: "#2373D8" }} className={"fas fa-question-circle"} />
        </Tooltip>{" "}
      </Box>
      <Box pl="10px">
        {props.loading ? (
          <Skeleton variant="text" animation="wave" height={50} width={200} />
        ) : props.fileName.length === 0 ? (
          <Box>
            <Button style={{ width: 200, marginTop: "2rem" }} variant="contained" component="label">
              Upload Sanitized SOW
              <input
                type="file"
                hidden
                accept=".pdf"
                onChange={async (e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  await prepareFileForUpload(e.target.files[0]);
                }}
              />
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" style={{ marginTop: "2rem" }}>
            <IconButton onClick={() => removeFile()} style={{ color: "black", marginRight: "1rem" }}>
              <i className="fal fa-trash" />
            </IconButton>
            {props.fileName}
          </Box>
        )}
      </Box>
    </Box>
  );
}
