import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  styledTabsRoot: {
    borderBottom: "1px solid transparent",
    marginBottom: "10px",
  },
  styledTabRoot: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "14px",
    fontFamily: "Lato",
    // textTransform: "uppercase",
    "&:hover": {
      color: "#2373D8",
      opacity: 1,
    },
    "&:selected": {
      color: "black",
      fontWeight: "bold !important",
    },
    root: {
      "&$selected": {
        fontWeight: "bold",
      },
    },
  },
  count: {
    position: "absolute",
    fontSize: "0.75rem",
    width: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 18,
    // border: "1px solid #0E8C64",
    // background: "white",
    color: "black",
    borderRadius: "50%",
    // fontWeight: "bold",
    top: 4,
    right: 0,
  },
  selected: { fontWeight: "bold !important" },
});

export default useStyles;
