import React from "react";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";

export default function DemographicsCheckbox(props) {
  return (
    <Box width="100%">
      <FormControlLabel
        control={
          <Checkbox
            type="checkbox"
            size="small"
            style={{ padding: 2, color: "#2373D8" }}
            checked={Boolean(props.checked)}
            onChange={() => props.handleChange(props.target, !props.checked, props.title)}
          />
        }
        label={<Box style={{ fontSize: "0.9rem" }}>{props.title}</Box>}
      />
    </Box>
  );
}
