/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";

export const SAVE_REQUEST_REQUEST = "SAVE_REQUEST_REQUEST";
export const SAVE_REQUEST_SUCCESS = "SAVE_REQUEST_SUCCESS";
export const SAVE_REQUEST_FAILURE = "SAVE_REQUEST_FAILURE";

const postRequest = (payload) => ({
  [CALL_API]: {
    types: [SAVE_REQUEST_REQUEST, SAVE_REQUEST_SUCCESS, SAVE_REQUEST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/requests`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.REQUEST,
  },
});

export const createRequest = (payload) => (dispatch) => {
  return dispatch(postRequest(payload));
};

export const UPDATE_REQUEST_REQUEST = "UPDATE_REQUEST_REQUEST";
export const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
export const UPDATE_REQUEST_FAILURE = "UPDATE_REQUEST_FAILURE";

const changeRequest = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_REQUEST_REQUEST, UPDATE_REQUEST_SUCCESS, UPDATE_REQUEST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/requests/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.REQUEST,
  },
});

export const updateRequest = (id, payload) => (dispatch) => {
  return dispatch(changeRequest(id, payload));
};

export const DELETE_REQUEST_REQUEST = "DELETE_REQUEST_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAILURE = "DELETE_REQUEST_FAILURE";

const deleteRequest = (id) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST_REQUEST, DELETE_REQUEST_SUCCESS, DELETE_REQUEST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/requests/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.REQUEST,
  },
});

export const removeRequest = (id) => (dispatch) => {
  return dispatch(deleteRequest(id));
};

export const REQUEST_LIST_REQUEST = "REQUEST_LIST_REQUEST";
export const REQUEST_LIST_SUCCESS = "REQUEST_LIST_SUCCESS";
export const REQUEST_LIST_FAILURE = "REQUEST_LIST_FAILURE";

const fetchRequests = (companyId, type) => ({
  [CALL_API]: {
    types: [REQUEST_LIST_REQUEST, REQUEST_LIST_SUCCESS, REQUEST_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/requests/${companyId}/${type}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.REQUEST_ARRAY,
  },
});

export const getRequests = (companyId, type) => (dispatch) => {
  return dispatch(fetchRequests(companyId, type));
};

export const REQUEST_COUNT_REQUEST = "REQUEST_COUNT_REQUEST";
export const REQUEST_COUNT_SUCCESS = "REQUEST_COUNT_SUCCESS";
export const REQUEST_COUNT_FAILURE = "REQUEST_COUNT_FAILURE";

const fetchRequestsCount = (companyId, type) => ({
  [CALL_API]: {
    types: [REQUEST_COUNT_REQUEST, REQUEST_COUNT_SUCCESS, REQUEST_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/requests/${companyId}/${type}/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getRequestsCount = (companyId, type) => (dispatch) => {
  return dispatch(fetchRequestsCount(companyId, type));
};
