import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Grid, Hidden, TextField } from "@material-ui/core";
import Top from "../components/MainTop";
import Form from "../../ProjectInitiationForm";
import {
  getProjectsList,
  getCisoshareUsers,
  getCompaniesList,
  loadRolesList,
  saveCompany,
  addProjectToQueue,
  getProjectQueueItemById,
  updateProjectQueueItem,
  deleteDocument,
  removeProjectQueueItem,
  projectKickoff,
  getDeliverablesList,
  loadDeliverables,
  getWorkElementsList,
  getProjectQueueList,
  getProjectTypesList,
} from "../../../store/actions";

function ProjectForm(props) {
  // const classes = useStyles();

  useEffect(() => {
    props.getProjectsList();
    props.getCisoshareUsers();
    props.getCompaniesList();
    props.loadRolesList();
    props.getDeliverablesList();
    props.loadDeliverables();
    props.getWorkElementsList();
    props.getProjectTypesList();
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      height="88vh"
      width="100%"
      flexDirection="column"
      style={{ background: "rgba(51,92,129,0.05)" }}
    >
      <Top />
      <Form {...props} />
    </Box>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.user && state.user.id,
    deliverablesList:
      (state.entities.lists && state.entities.lists.deliverables && state.entities.lists.deliverables.list) || [],
    workElementsList:
      (state.entities.lists && state.entities.lists.workElements && state.entities.lists.workElements.list) || [],
    projectTypesList:
      (state.entities.lists && state.entities.lists.projectTypes && state.entities.lists.projectTypes.list) || [],
    adminProjects:
      (state.entities && state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list) ||
      [],
    projectQueueId: ownProps.match.params.projectQueueId,
    projectQueue: (state.entities && state.entities.projectQueue && Object.values(state.entities.projectQueue)) || [],
    resources: (state.entities && state.entities.cisoUsers && Object.values(state.entities.cisoUsers)) || [],
    projectQueueList:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.projectQueue &&
        state.entities.lists.projectQueue.list) ||
      [],
    companies:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.companies &&
        state.entities.lists.companies.list) ||
      [],
    roles:
      (state.entities && state.entities.lists && state.entities.lists.roles && state.entities.lists.roles.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadDeliverables: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(loadDeliverables(page, limit, refresh, searchTerm, sort, by)),
    getProjectsList: () => dispatch(getProjectsList()),
    getCisoshareUsers: () => dispatch(getCisoshareUsers()),
    getCompaniesList: () => dispatch(getCompaniesList()),
    loadRolesList: () => dispatch(loadRolesList()),
    saveCompany: (data) => dispatch(saveCompany(data)),
    addProjectToQueue: (payload) => dispatch(addProjectToQueue(payload)),
    getProjectQueueItemById: (id) => dispatch(getProjectQueueItemById(id)),
    updateProjectQueueItem: (id, payload) => dispatch(updateProjectQueueItem(id, payload)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    removeProjectQueueItem: (id) => dispatch(removeProjectQueueItem(id)),
    projectKickoff: (id) => dispatch(projectKickoff(id)),
    getDeliverablesList: () => dispatch(getDeliverablesList()),
    getWorkElementsList: () => dispatch(getWorkElementsList()),
    getProjectQueueList: () => dispatch(getProjectQueueList()),
    getProjectTypesList: () => dispatch(getProjectTypesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(ProjectForm));
