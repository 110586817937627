import React, { useState, useEffect } from "react";
import ProjectRoster from "./views/ProjectRoster";
import ProcessRoster from "./views/ProcessRoster";
import ProjectRequests from "./views/ProjectRequests";
import Frame from "../../components/Frame/Frame";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getProjectRosterByCompanyId,
  getActiveCompaniesList,
  getCompaniesList,
  loadRolesList,
  getProjectsList,
  getProjectRosterResourceByCompanyId,
  attachUserToCompanyProject,
  updateUserCompanyProject,
  detachUserCompanyProject,
  getCompanyProjects,
  getCisoshareUsers,
  deleteCompanyProject,
  changeCompanyProject,
  saveCompany,
  kickoffCompanyProjects,
  updateRequest,
  removeRequest,
  getRequests,
  getRequestsCount,
  getWorkElementsList,
  getDeliverablesList,
  getUsersWithRoleId,
  attachRoleToCompanyProject,
  getProjectReport,
} from "../../store/actions";

function RosterRouter(props) {
  const [value, setValue] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const tabs = ["Project Roster"];

  useEffect(() => {
    props.getRequestsCount("all", "PROJECT");
  }, []);

  const tabsView = () => {
    switch (value) {
      case 0:
        return <ProjectRoster {...props} />;
      case 1:
        return <ProjectRequests {...props} setRequestCount={setRequestCount} />;
    }
  };

  return (
    <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
      {tabsView()}
    </Frame>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    companyId: ownProps.match.params.companyId,
    projectRoster:
      (state.entities &&
        state.entities.projectRoster &&
        state.entities.projectRoster &&
        Object.values(state.entities.projectRoster)) ||
      [],
    projectRosterCount: (state.entities && state.entities.projectRoster && state.entities.projectRoster.count) || 0,

    resources: (state.entities && state.entities.cisoUsers && Object.values(state.entities.cisoUsers)) || [],
    workElements:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.workElements &&
        state.entities.lists.workElements.list) ||
      [],
    deliverables:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.deliverables &&
        state.entities.lists.deliverables.list) ||
      [],
    projects:
      (state.entities && state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list) ||
      [],
    roles:
      (state.entities && state.entities.lists && state.entities.lists.roles && state.entities.lists.roles.list) || [],
    companies:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.companies &&
        state.entities.lists.companies.list) ||
      [],
    activeCompanies:
      (state.entities &&
        state.entities.lists &&
        state.entities.lists.activeCompanies &&
        state.entities.lists.activeCompanies.list) ||
      [],
    requestCount:
      (state.entities &&
        state.entities.counts &&
        state.entities.counts.requests &&
        state.entities.counts.requests.count) ||
      0,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    // Lists
    getCisoshareUsers: () => dispatch(getCisoshareUsers()),
    getProjectsList: () => dispatch(getProjectsList()),
    loadRolesList: () => dispatch(loadRolesList()),
    getActiveCompaniesList: () => dispatch(getActiveCompaniesList()),
    getCompaniesList: () => dispatch(getCompaniesList()),
    getWorkElementsList: () => dispatch(getWorkElementsList()),
    getDeliverablesList: () => dispatch(getDeliverablesList()),
    getProjectReport: (startDate, endDate) => dispatch(getProjectReport(startDate, endDate)),

    // Populate Data
    getProjectRosterByCompanyId: (companyId, search, page, limit, archive, column, direction) =>
      dispatch(getProjectRosterByCompanyId(companyId, search, page, limit, archive, column, direction)),
    getProjectRosterResourceByCompanyId: (companyId, search, page, limit) =>
      dispatch(getProjectRosterResourceByCompanyId(companyId, search, page, limit)),
    getCompanyProjects: (companyId) => dispatch(getCompanyProjects(companyId)),
    getRequests: (companyId, type) => dispatch(getRequests(companyId, type)),
    getRequestsCount: (companyId, type) => dispatch(getRequestsCount(companyId, type)),
    getUsersWithRoleId: (roleId, companyProjectId) => dispatch(getUsersWithRoleId(roleId, companyProjectId)),

    // Manipulate Data
    attachUserToCompanyProject: (id, payload) => dispatch(attachUserToCompanyProject(id, payload)),
    updateUserCompanyProject: (id, payload) => dispatch(updateUserCompanyProject(id, payload)),
    detachUserCompanyProject: (id) => dispatch(detachUserCompanyProject(id)),
    deleteCompanyProject: (projectId, companyId) => dispatch(deleteCompanyProject(projectId, companyId)),
    changeCompanyProject: (companyProjectId, payload) => dispatch(changeCompanyProject(companyProjectId, payload)),
    saveCompany: (data) => dispatch(saveCompany(data)),
    kickoffCompanyProjects: (data) => dispatch(kickoffCompanyProjects(data)),
    updateRequest: (id, payload) => dispatch(updateRequest(id, payload)),
    removeRequest: (id) => dispatch(removeRequest(id)),
    attachRoleToCompanyProject: (id, data) => dispatch(attachRoleToCompanyProject(id, data)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(RosterRouter));
