import React from "react";
import HubSpotLists from "./views/HubSpotLists";
import EmailTemplates from "./views/EmailTemplates";
import Frame from "../../components/Frame/Frame";

const HubSpotRouter = (props) => {
  const [value, setValue] = React.useState(0);
  const tabs = ["Templates", "Lists"];
  const tabsView = () => {
    switch (value) {
      case 0:
        return <EmailTemplates {...props} />;
      case 1:
        return <HubSpotLists {...props} />;
    }
  };

  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
};

export default HubSpotRouter;
