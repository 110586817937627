import React from "react";
import { Grid, Box } from "@material-ui/core";
import MuiSelect from "../components/MuiSelect";
import DemographicsCheckbox from "../components/DemographicsCheckbox";

export default function Demographics(props) {
  return (
    <Box width="100%" flexDirection="column" display="flex" alignItems="center" justifyContent="center">
      <Grid container alignItems="center">
        <Grid item sm={12} md={6}>
          <MuiSelect
            multiple
            error={props.demographicsErrors.objectives}
            question={"List your top three objectives in order of priority:"}
            value={props.demographics.objectives || []}
            options={props.objectives}
            inputLabel={!props.demographicsErrors.objectives ? "Objectives" : "Three objectives are required"}
            target="objectives"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <MuiSelect
            question="Choose the regions in which you operate:"
            value={props.demographics.countryIds || ""}
            options={props.countries || []}
            error={props.demographicsErrors.countryIds}
            inputLabel={!props.demographicsErrors.countryIds ? "Countries" : "A country is required"}
            target="countryIds"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <MuiSelect
            multiple
            question="Within that country, select the regions in which you operate:"
            value={props.demographics.stateIds || []}
            options={props.states || []}
            error={props.demographicsErrors.stateIds}
            inputLabel={!props.demographicsErrors.stateIds ? "Regions" : "A state/region is required"}
            target="stateIds"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <MuiSelect
            question="Choose your primary industry:"
            value={props.demographics.primaryIndustryId || ""}
            options={props.industries || []}
            inputLabel={!props.demographicsErrors.primaryIndustryId ? "Industries" : "A primary industry is required"}
            error={props.demographicsErrors.primaryIndustryId}
            target="primaryIndustryId"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <MuiSelect
            multiple
            question="Select any applicable additional industries:"
            value={props.demographics.industryIds || []}
            options={
              props.industries.filter((industry) => industry.label !== props.demographics.primaryIndustryId) || []
            }
            inputLabel="Industries"
            target="industryIds"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
      </Grid>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        fontSize="1rem"
        ml="2rem"
        mb={2}
        justifyContent="flex-start"
        style={{ fontWeight: "bold" }}
      >
        Common Organizational Characteristics:
      </Box>
      <Grid style={{ marginLeft: "2rem" }} container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <DemographicsCheckbox
            title="Publicly Traded"
            checked={props.demographics.publiclyTraded}
            target="publiclyTraded"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DemographicsCheckbox
            title="Global Organization"
            checked={props.demographics.multiCountry}
            target="multiCountry"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DemographicsCheckbox
            title="Align to Best Practice"
            checked={props.demographics.bestPractice}
            target="bestPractice"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DemographicsCheckbox
            title="Process Credit Cards"
            checked={props.demographics.creditCard}
            target="creditCard"
            handleChange={props.updateDemographicsState}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
