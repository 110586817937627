import request from "./request_helper";
import * as auth from "./auth_helper";

export const authenticateAdmin = (username, pass) => {
  const userBody = {
    grant_type: "password",
    scope: "read",
    username: username,
    password: pass,
  };

  return auth.login(userBody);
};

export const getAdminProfile = () => {
  return request({
    endpoint: "/users/me",
    method: "GET",
    auth: true,
  });
};

export const verifyAdminProfile = (body) => {
  return request({
    endpoint: "/unsafe/2fa/verify",
    method: "POST",
    auth: false,
    body,
  });
};
