import React from "react";
import { MultiLineEdit } from "../../../components/MaterialTable/Inputs";

export const getColumns = () => {
  return [
    {
      title: "Process",
      field: "name",
      render: (row) => <div style={{ minWidth: "160px" }}>{row.name}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Description",
      field: "description",
      render: (row) => <div style={{ minWidth: "160px" }}>{row.description}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
    },
  ];
};
