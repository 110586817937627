import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Button, Grid, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AsyncAutoComplete from "./AsyncAutoComplete";
import Snackbar from "../../../components/Snackbar";
import Warning from "../../../components/Dialog/WarningDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  smallButton: {
    bottom: "-20px",
    minWidth: 80,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    right: 0,
    fontSize: "0.6rem",
    background: "rgba(0,0,0,0.1)",
    padding: "2px 7px",
    transition: ".2s all linear",
    cursor: "pointer",
    borderRadius: 3,
    "&:hover": {
      background: "rgba(0,0,0,0.2)",
      transition: ".2s all linear",
    },
  },
}));

export default function EditWeek(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeEntryTask, setTimeEntryTask] = useState(null);
  const [workElement, setWorkElement] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [hours, setHours] = useState(0.0);
  const [timeEntered, setTimeEntered] = useState(new Date());
  const [deleting, setDeleting] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", variant: "info" });

  useEffect(() => {
    if (props.timeEntryId) {
      getTimeEntryById();
    }
  }, [props.timeEntryId]);

  const getTimeEntryById = async () => {
    const result = await props.getTimeEntryById(props.timeEntryId);
    const timeEntry = result.response.entities.timeEntries[props.timeEntryId];
    if (timeEntry.internal) {
      populateTimeEntry(timeEntry);
      setProjectName("Internal");
      return timeEntry;
    }
    populateTimeEntry(timeEntry);
    setProjectName((timeEntry.project && timeEntry.project.projectName) || "- - -");
    return timeEntry;
  };

  const populateTimeEntry = (timeEntry) => {
    setCompanyName((timeEntry.project && timeEntry.project.company && timeEntry.project.company.name) || "CISOSHARE");
    setHours(timeEntry.hours);
    setTimeEntryTask(timeEntry.timeEntryTask);
    setWorkElement(timeEntry.workElement);
    setHours(timeEntry.hours);
    setTimeEntered(new Date(timeEntry.timeEntered));
  };

  const closeDialog = () => {
    props.setEditTime(false);
    props.setTimeEntryId("");
    setTimeout(() => {
      setTimeEntryTask(null);
      setWorkElement(null);
      setCompanyName(null);
      setProjectName(null);
      setHours(null);
      setTimeEntered(null);
    }, 300);
  };

  const addNewInternalTask = async (task = null) => {
    const payload = {
      timeEntryTask: {
        name: task,
      },
      workElementIds: [workElement.id],
    };
    const result = await props.createTimeEntryTask(payload);
    setTimeEntryTask(Object.values(result.response.entities.timeEntryTasks)[0]);
  };

  const handleDateChange = (date) => {
    setTimeEntered(date);
  };

  const update = async () => {
    props.setStartRefresh(true);

    if (hours <= 0) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Some time is required" });
    }
    if (projectName !== "Internal" && (!timeEntryTask || !timeEntryTask.hasOwnProperty("id"))) {
      return setSnackbar({ isOpen: true, variant: "error", message: "A task is required" });
    }
    if (!workElement || !workElement.hasOwnProperty("id")) {
      return setSnackbar({ isOpen: true, variant: "error", message: "A work element is required" });
    }
    await props.updateTimeEntry(props.timeEntryId, {
      hours: hours,
      timeEntered: new Date(timeEntered).getTime(),
      timeEntryTaskId: (timeEntryTask && timeEntryTask.id) || null,
      workElement: (workElement && workElement.id) || null,
    });
    props.setEditTime(false);
    props.setRefresh(true);
  };

  const deleteTimeEntryById = async () => {
    await props.deleteTimeEntry(props.timeEntryId);
    props.setStartRefresh(true);
    props.setStartRefresh(false);
    props.setEditTime(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Warning
        delete
        loading={deleting}
        anchorEl={anchorEl}
        text={`Are you sure you want to delete time entry?`}
        confirm={() => deleteTimeEntryById()}
        close={() => setAnchorEl(null)}
      />
      <Dialog
        style={{
          background: "rgba(255,255,255, .9)",
        }}
        open={props.editTime}
        onClose={() => closeDialog()}
        maxWidth="xl"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          style={{
            background: "#fff",
            padding: "10px 25px",
            color: "#000",
            boxShadow: `2px -9px 4px 10px ${props.color}`,
          }}
        >
          <Box display="flex" alignItems="center">
            <Box style={{ width: 15, height: 15, backgroundColor: props.color, marginRight: 5 }} />{" "}
            <span style={{ fontWeight: "bold", fontSize: "0.9rem", marginRight: 5 }}>{companyName}</span> |
            <span style={{ fontWeight: "lighter", fontSize: "0.7rem", color: "rgba(0,0,0,.7)", marginLeft: 5 }}>
              {projectName}
            </span>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            width: 500,
            // height: 300,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ marginRight: 10 }}
                size="small"
                margin="dense"
                type="number"
                value={hours}
                onChange={(e) => setHours(parseFloat(e.target.value))}
                label="Hours"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: "start" }}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="dense"
                  style={{ minWidth: 200 }}
                  value={props.timeEntered}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Box mr={0.5} width="100%">
                <AsyncAutoComplete
                  fetchData={async () => await props.getWorkElementsList(projectName.toLowerCase() === "internal")}
                  value={workElement}
                  target="workElements"
                  label="Select Internal Category"
                  required
                  handleChange={(value) => {
                    setWorkElement(value);
                    setTimeEntryTask(null);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mr={0.5} width="100%">
                <AsyncAutoComplete
                  disabled={!workElement || !workElement.hasOwnProperty("id")}
                  fetchData={async () => {
                    const result = await props.getWorkElementTasks(workElement.id);
                    return result;
                  }}
                  creatable
                  createFunc={addNewInternalTask}
                  value={timeEntryTask}
                  target="workElementTasks"
                  label="Task"
                  handleChange={(value) => setTimeEntryTask(value)}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ background: "#fff", color: "white", padding: "5px 16px" }}>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            style={{
              borderRadius: 50,
              height: 25,
              color: "white",
              fontWeight: "bold",
              backgroundColor: `#ff6666`,
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => closeDialog()}
            style={{
              borderRadius: 50,
              height: 25,
              color: "black",
              fontWeight: "bold",
              border: `1px solid ${props.color}`,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => update()}
            style={{
              borderRadius: 50,
              height: 25,
              color: "black",
              fontWeight: "bold",
              border: `1px solid ${props.color}`,
            }}
          >
            {loading ? <i className="fas fa-cog fa-spin" /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
