import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "#011627",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  icon: {
    marginRight: 5,
  },
  menuButton: {
    color: "white",
  },
  headerImg: {},

  mainLogo: {
    height: "2.3rem",
  },
}));

export default useStyles;
