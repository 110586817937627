import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Users from "./views/Users";
import Projects from "./views/Projects";
import Documents from "./views/Documents";
import Details from "./views/Details";
import Frame from "../../components/Frame/Frame";
import Snackbar from "../../components/Snackbar/Snackbar";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import { loadCompany } from "../../store/actions";
import DemographicsContainer from "../../views/Demographics/DemographicsContainer";

function CompanyDetails(props) {
  const [value, setValue] = useState(0);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await props.loadCompany(props.targetCompanyId);
    const company = result.response.entities.companies[props.targetCompanyId];
    setProjects(company.projects);
  };

  const tabsView = () => {
    switch (value) {
      case 0:
        return (
          <Details
            license={
              props.company && props.company.licenses && props.company.licenses.length > 0 && props.company.licenses[0]
            }
            dealId={(props.company && props.company.hubSpotDealId) || ""}
            companyId={props.targetCompanyId}
            setSnackbar={setSnackbar}
          />
        );
      case 1:
        return (
          <Users
            data={props.company && props.company.employees}
            companyId={props.targetCompanyId}
            loadCompany={props.loadCompany}
          />
        );
      // case 2:
      //   return <DemographicsContainer companyId={props.targetCompanyId} />;
      // case 3:
      //   return (
      //     <Documents
      //       data={props.company && props.company.documents}
      //       companyId={props.targetCompanyId}
      //       loadCompany={props.loadCompany}
      //     />
      //   );
    }
  };

  const tabs = ["Details", "Users"];

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Frame
        tabs={tabs}
        value={value}
        setValue={setValue}
        content={{
          id: props.company && props.company.id ? props.company.id : "No Id",
          name: props.company && props.company.name ? props.company.name : "No Name",
          icon: "fal fa-copy",
        }}
        goBack={() => props.history.goBack()}
        buttonText={value === 5 ? "Submit" : null}
        clickHandler={value === 5 ? () => props.makeFrameworkCall() : null} // to do with ryan
      >
        {tabsView()}
      </Frame>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    targetCompanyId: ownProps.match.params.id,
    company: state.entities.companies[ownProps.match.params.id],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadCompany: (companyId) => dispatch(loadCompany(companyId)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(CompanyDetails));
