import React from "react";
import BaseButton from "../BaseButton/BaseButton";
import { Box } from "@material-ui/core";

export default function AccessDenied() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: 'url("./img/backgroundLight.png")',
        backgroundSize: "cover",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Lato",
          fontSize: "1.6rem",
        }}
      >
        <Box mb={1}>You don't have access to this page</Box>
        {/* <BaseButton clickHandler={() => {}} text="Request Access" /> */}
      </Box>
    </div>
  );
}
