/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const SAVE_DATATYPE_REQUEST = "SAVE_DATATYPE_REQUEST";
export const SAVE_DATATYPE_SUCCESS = "SAVE_DATATYPE_SUCCESS";
export const SAVE_DATATYPE_FAILURE = "SAVE_DATATYPE_FAILURE";

const postDataType = (data) => ({
  [CALL_API]: {
    types: [SAVE_DATATYPE_REQUEST, SAVE_DATATYPE_SUCCESS, SAVE_DATATYPE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/datatypes/`,
      method: "POST",
      auth: true,
      body: data,
    },
    schema: Schemas.DATATYPE,
  },
});

export const saveDataType = (data) => (dispatch) => {
  return dispatch(postDataType(data));
};

export const SAVE_INDUSTRY_REQUEST = "SAVE_INDUSTRY_REQUEST";
export const SAVE_INDUSTRY_SUCCESS = "SAVE_INDUSTRY_SUCCESS";
export const SAVE_INDUSTRY_FAILURE = "SAVE_INDUSTRY_FAILURE";

const postIndustry = (data) => ({
  [CALL_API]: {
    types: [SAVE_INDUSTRY_REQUEST, SAVE_INDUSTRY_SUCCESS, SAVE_INDUSTRY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/industries`,
      method: "POST",
      auth: true,
      body: data,
    },
    schema: Schemas.INDUSTRY,
  },
});

export const saveIndustry = (data) => (dispatch) => {
  return dispatch(postIndustry(data));
};

export const SAVE_STATES_ARRAY_REQUEST = "SAVE_STATES_ARRAY_REQUEST";
export const SAVE_STATES_ARRAY_SUCCESS = "SAVE_STATES_ARRAY_SUCCESS";
export const SAVE_STATES_ARRAY_FAILURE = "SAVE_STATES_ARRAY_FAILURE";

const saveState = (data) => ({
  [CALL_API]: {
    types: [SAVE_STATES_ARRAY_REQUEST, SAVE_STATES_ARRAY_SUCCESS, SAVE_STATES_ARRAY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/states`,
      method: "POST",
      auth: true,
      body: data,
    },
    schema: Schemas.STATE,
  },
});

export const postState = (data) => (dispatch) => {
  return dispatch(saveState(data));
};

export const SAVE_COUNTRIES_ARRAY_REQUEST = "SAVE_COUNTRIES_ARRAY_REQUEST";
export const SAVE_COUNTRIES_ARRAY_SUCCESS = "SAVE_COUNTRIES_ARRAY_SUCCESS";
export const SAVE_COUNTRIES_ARRAY_FAILURE = "SAVE_COUNTRIES_ARRAY_FAILURE";

const insertCountry = (data) => ({
  [CALL_API]: {
    types: [SAVE_COUNTRIES_ARRAY_REQUEST, SAVE_COUNTRIES_ARRAY_SUCCESS, SAVE_COUNTRIES_ARRAY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/countries`,
      method: "POST",
      auth: true,
      body: data,
    },
    schema: Schemas.COUNTRY,
  },
});

export const postCountry = (data) => (dispatch) => {
  return dispatch(insertCountry(data));
};

export const SAVE_ORG_IMPACT_REQUEST = "SAVE_ORG_IMPACT_REQUEST";
export const SAVE_ORG_IMPACT_SUCCESS = "SAVE_ORG_IMPACT_SUCCESS";
export const SAVE_ORG_IMPACT_FAILURE = "SAVE_ORG_IMPACT_FAILURE";

const postOrgImpact = (data) => ({
  [CALL_API]: {
    types: [SAVE_ORG_IMPACT_REQUEST, SAVE_ORG_IMPACT_SUCCESS, SAVE_ORG_IMPACT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/orgImpacts`,
      method: "POST",
      auth: true,
      body: data,
    },
    schema: Schemas.ORG_IMPACT,
  },
});

export const saveOrgImpact = (data) => (dispatch) => {
  return dispatch(postOrgImpact(data));
};

export const SAVE_LAW_REQUEST = "SAVE_LAW_REQUEST";
export const SAVE_LAW_SUCCESS = "SAVE_LAW_SUCCESS";
export const SAVE_LAW_FAILURE = "SAVE_LAW_FAILURE";

const postLaw = (payload) => ({
  [CALL_API]: {
    types: [SAVE_LAW_REQUEST, SAVE_LAW_SUCCESS, SAVE_LAW_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/laws`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.LAW,
  },
});

export const saveLaw = (payload) => (dispatch) => {
  return dispatch(postLaw(payload));
};

export const SAVE_FRAMEWORK_REQUEST = "SAVE_FRAMEWORK_REQUEST";
export const SAVE_FRAMEWORK_SUCCESS = "SAVE_FRAMEWORK_SUCCESS";
export const SAVE_FRAMEWORK_FAILURE = "SAVE_FRAMEWORK_FAILURE";

const postFramework = (payload) => ({
  [CALL_API]: {
    types: [SAVE_FRAMEWORK_REQUEST, SAVE_FRAMEWORK_SUCCESS, SAVE_FRAMEWORK_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/frameworks`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.FRAMEWORK,
  },
});

export const saveFramework = (payload) => (dispatch) => {
  return dispatch(postFramework(payload));
};

export const SAVE_POLICY_REQUEST = "SAVE_POLICY_REQUEST";
export const SAVE_POLICY_SUCCESS = "SAVE_POLICY_SUCCESS";
export const SAVE_POLICY_FAILURE = "SAVE_POLICY_FAILURE";

const postPolicy = (payload) => ({
  [CALL_API]: {
    types: [SAVE_POLICY_REQUEST, SAVE_POLICY_SUCCESS, SAVE_POLICY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/policies`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.POLICIES,
  },
});

export const savePolicy = (payload) => (dispatch) => {
  return dispatch(postPolicy(payload));
};

export const SAVE_HUBSPOT_LIST_REQUEST = "SAVE_HUBSPOT_LIST_REQUEST";
export const SAVE_HUBSPOT_LIST_SUCCESS = "SAVE_HUBSPOT_LIST_SUCCESS";
export const SAVE_HUBSPOT_LIST_FAILURE = "SAVE_HUBSPOT_LIST_FAILURE";

const postHubSpotList = (payload) => ({
  [CALL_API]: {
    types: [SAVE_HUBSPOT_LIST_REQUEST, SAVE_HUBSPOT_LIST_SUCCESS, SAVE_HUBSPOT_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/hubspot/list`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.HUBSPOT_LIST,
  },
});

export const saveHubSpotList = (payload) => (dispatch) => {
  return dispatch(postHubSpotList(payload));
};

export const DATATYPES_LIST_REQUEST = "DATATYPES_LIST_REQUEST";
export const DATATYPES_LIST_SUCCESS = "DATATYPES_LIST_SUCCESS";
export const DATATYPES_LIST_FAILURE = "DATATYPES_LIST_FAILURE";

const fetchDataTypesList = () => ({
  [CALL_API]: {
    types: [DATATYPES_LIST_REQUEST, DATATYPES_LIST_SUCCESS, DATATYPES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/metadata/datatypes/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadDataTypesList = () => (dispatch) => {
  return dispatch(fetchDataTypesList());
};

export const UPDATE_DATATYPE_BY_ID_REQUEST = "UPDATE_DATATYPE_BY_ID_REQUEST";
export const UPDATE_DATATYPE_BY_ID_SUCCESS = "UPDATE_DATATYPE_BY_ID_SUCCESS";
export const UPDATE_DATATYPE_BY_ID_FAILURE = "UPDATE_DATATYPE_BY_ID_FAILURE";

const updateDataTypesById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_DATATYPE_BY_ID_REQUEST, UPDATE_DATATYPE_BY_ID_SUCCESS, UPDATE_DATATYPE_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/dataTypes/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.DATATYPE,
  },
});

export const updateDataTypeById = (id, payload) => (dispatch) => {
  return dispatch(updateDataTypesById(id, payload));
};

export const POLICIES_LIST_REQUEST = "POLICIES_LIST_REQUEST";
export const POLICIES_LIST_SUCCESS = "POLICIES_LIST_SUCCESS";
export const POLICIES_LIST_FAILURE = "POLICIES_LIST_FAILURE";

const fetchPoliciesList = () => ({
  [CALL_API]: {
    types: [POLICIES_LIST_REQUEST, POLICIES_LIST_SUCCESS, POLICIES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/frameworks/policies/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadPoliciesList = () => (dispatch) => {
  return dispatch(fetchPoliciesList());
};

export const PROCESSES_LIST_REQUEST = "PROCESSES_LIST_REQUEST";
export const PROCESSES_LIST_SUCCESS = "PROCESSES_LIST_SUCCESS";
export const PROCESSES_LIST_FAILURE = "PROCESSES_LIST_FAILURE";

const fetchProcessesList = () => ({
  [CALL_API]: {
    types: [PROCESSES_LIST_REQUEST, PROCESSES_LIST_SUCCESS, PROCESSES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/frameworks/processes/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadProcessesList = () => (dispatch) => {
  return dispatch(fetchProcessesList());
};

// export const POLICIES_BY_ID_REQUEST = "POLICIES_BY_ID_REQUEST";
// export const POLICIES_BY_ID_SUCCESS = "POLICIES_BY_ID_SUCCESS";
// export const POLICIES_BY_ID_FAILURE = "POLICIES_BY_ID_FAILURE";

// const fetchPolicyById = (id) => ({
//   [CALL_API]: {
//     types: [POLICIES_BY_ID_REQUEST, POLICIES_BY_ID_SUCCESS, POLICIES_BY_ID_FAILURE],
//     reqInfo: {
//       endpoint: `${ADMIN_URL}/policies/${id}`,
//       method: "GET",
//       auth: true,
//     },
//     schema: Schemas.POLICIES,
//   },
// });

// export const loadPolicyById = (id) => (dispatch) => {
//   return dispatch(fetchPolicyById(id));
// };

export const UPDATE_POLICIES_BY_ID_REQUEST = "UPDATE_POLICIES_BY_ID_REQUEST";
export const UPDATE_POLICIES_BY_ID_SUCCESS = "UPDATE_POLICIES_BY_ID_SUCCESS";
export const UPDATE_POLICIES_BY_ID_FAILURE = "UPDATE_POLICIES_BY_ID_FAILURE";

const putPolicyById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_POLICIES_BY_ID_REQUEST, UPDATE_POLICIES_BY_ID_SUCCESS, UPDATE_POLICIES_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/policies/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.POLICIES,
  },
});

export const updatePolicyById = (id, payload) => (dispatch) => {
  return dispatch(putPolicyById(id, payload));
};

export const INDUSTRIES_LIST_REQUEST = "INDUSTRIES_LIST_REQUEST";
export const INDUSTRIES_LIST_SUCCESS = "INDUSTRIES_LIST_SUCCESS";
export const INDUSTRIES_LIST_FAILURE = "INDUSTRIES_LIST_FAILURE";

const fetchIndustriesList = () => ({
  [CALL_API]: {
    types: [INDUSTRIES_LIST_REQUEST, INDUSTRIES_LIST_SUCCESS, INDUSTRIES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/unsafe/industries/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadIndustriesList = () => (dispatch) => {
  return dispatch(fetchIndustriesList());
};

export const UPDATE_INDUSTRY_BY_ID_REQUEST = "UPDATE_INDUSTRY_BY_ID_REQUEST";
export const UPDATE_INDUSTRY_BY_ID_SUCCESS = "UPDATE_INDUSTRY_BY_ID_SUCCESS";
export const UPDATE_INDUSTRY_BY_ID_FAILURE = "UPDATE_INDUSTRY_BY_ID_FAILURE";

const updateIndustriesById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_INDUSTRY_BY_ID_REQUEST, UPDATE_INDUSTRY_BY_ID_SUCCESS, UPDATE_INDUSTRY_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/industries/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.INDUSTRY,
  },
});

export const updateIndustryById = (id, payload) => (dispatch) => {
  return dispatch(updateIndustriesById(id, payload));
};

export const STATES_LIST_REQUEST = "STATES_LIST_REQUEST";
export const STATES_LIST_SUCCESS = "STATES_LIST_SUCCESS";
export const STATES_LIST_FAILURE = "STATES_LIST_FAILURE";

const fetchStatesList = () => ({
  [CALL_API]: {
    types: [STATES_LIST_REQUEST, STATES_LIST_SUCCESS, STATES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/unsafe/states/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadStatesList = () => (dispatch) => {
  return dispatch(fetchStatesList());
};

export const UPDATE_STATE_BY_ID_REQUEST = "UPDATE_STATE_BY_ID_REQUEST";
export const UPDATE_STATE_BY_ID_SUCCESS = "UPDATE_STATE_BY_ID_SUCCESS";
export const UPDATE_STATE_BY_ID_FAILURE = "UPDATE_STATE_BY_ID_FAILURE";

const updateStatesById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_STATE_BY_ID_REQUEST, UPDATE_STATE_BY_ID_SUCCESS, UPDATE_STATE_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/states/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.STATE,
  },
});

export const updateStateById = (id, payload) => (dispatch) => {
  return dispatch(updateStatesById(id, payload));
};

export const UPDATE_ORG_IMPACT_BY_ID_REQUEST = "UPDATE_ORG_IMPACT_BY_ID_REQUEST";
export const UPDATE_ORG_IMPACT_BY_ID_SUCCESS = "UPDATE_ORG_IMPACT_BY_ID_SUCCESS";
export const UPDATE_ORG_IMPACT_BY_ID_FAILURE = "UPDATE_ORG_IMPACT_BY_ID_FAILURE";

const changeOrgImpactById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_ORG_IMPACT_BY_ID_REQUEST, UPDATE_ORG_IMPACT_BY_ID_SUCCESS, UPDATE_ORG_IMPACT_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/orgImpacts/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.ORG_IMPACT,
  },
});

export const updateOrgImpactById = (id, payload) => (dispatch) => {
  return dispatch(changeOrgImpactById(id, payload));
};

export const COUNTRIES_LIST_REQUEST = "COUNTRIES_LIST_REQUEST";
export const COUNTRIES_LIST_SUCCESS = "COUNTRIES_LIST_SUCCESS";
export const COUNTRIES_LIST_FAILURE = "COUNTRIES_LIST_FAILURE";

const fetchCountriesList = () => ({
  [CALL_API]: {
    types: [COUNTRIES_LIST_REQUEST, COUNTRIES_LIST_SUCCESS, COUNTRIES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/unsafe/countries/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadCountriesList = () => (dispatch) => {
  return dispatch(fetchCountriesList());
};

export const ORG_IMPACTS_LIST_REQUEST = "ORG_IMPACTS_LIST_REQUEST";
export const ORG_IMPACTS_LIST_SUCCESS = "ORG_IMPACTS_LIST_SUCCESS";
export const ORG_IMPACTS_LIST_FAILURE = "ORG_IMPACTS_LIST_FAILURE";

const fetchOrgImpactsList = () => ({
  [CALL_API]: {
    types: [ORG_IMPACTS_LIST_REQUEST, ORG_IMPACTS_LIST_SUCCESS, ORG_IMPACTS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/metadata/orgImpacts/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadOrgImpactsList = () => (dispatch) => {
  return dispatch(fetchOrgImpactsList());
};

export const UPDATE_COUNTRY_BY_ID_REQUEST = "UPDATE_COUNTRY_BY_ID_REQUEST";
export const UPDATE_COUNTRY_BY_ID_SUCCESS = "UPDATE_COUNTRY_BY_ID_SUCCESS";
export const UPDATE_COUNTRY_BY_ID_FAILURE = "UPDATE_COUNTRY_BY_ID_FAILURE";

const changeCountryById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_COUNTRY_BY_ID_REQUEST, UPDATE_COUNTRY_BY_ID_SUCCESS, UPDATE_COUNTRY_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/countries/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.COUNTRY,
  },
});

export const updateCountryById = (id, payload) => (dispatch) => {
  return dispatch(changeCountryById(id, payload));
};

export const LAWS_LIST_REQUEST = "LAWS_LIST_REQUEST";
export const LAWS_LIST_SUCCESS = "LAWS_LIST_SUCCESS";
export const LAWS_LIST_FAILURE = "LAWS_LIST_FAILURE";

const fetchLawsList = () => ({
  [CALL_API]: {
    types: [LAWS_LIST_REQUEST, LAWS_LIST_SUCCESS, LAWS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/frameworks/laws/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadLawsList = () => (dispatch) => {
  return dispatch(fetchLawsList());
};

export const UPDATE_LAWS_BY_ID_REQUEST = "UPDATE_LAWS_BY_ID_REQUEST";
export const UPDATE_LAWS_BY_ID_SUCCESS = "UPDATE_LAWS_BY_ID_SUCCESS";
export const UPDATE_LAWS_BY_ID_FAILURE = "UPDATE_LAWS_BY_ID_FAILURE";

const putLawById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_LAWS_BY_ID_REQUEST, UPDATE_LAWS_BY_ID_SUCCESS, UPDATE_LAWS_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/laws/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.LAW,
  },
});

export const updateLawById = (id, payload) => (dispatch) => {
  return dispatch(putLawById(id, payload));
};

export const REQUIREMENTS_LIST_REQUEST = "REQUIREMENTS_LIST_REQUEST";
export const REQUIREMENTS_LIST_SUCCESS = "REQUIREMENTS_LIST_SUCCESS";
export const REQUIREMENTS_LIST_FAILURE = "REQUIREMENTS_LIST_FAILURE";

const fetchRequirementsList = () => ({
  [CALL_API]: {
    types: [REQUIREMENTS_LIST_REQUEST, REQUIREMENTS_LIST_SUCCESS, REQUIREMENTS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/frameworks/requirements/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const loadRequirementsList = () => (dispatch) => {
  return dispatch(fetchRequirementsList());
};

export const UPDATE_FRAMEWORKS_BY_ID_REQUEST = "UPDATE_FRAMEWORKS_BY_ID_REQUEST";
export const UPDATE_FRAMEWORKS_BY_ID_SUCCESS = "UPDATE_FRAMEWORKS_BY_ID_SUCCESS";
export const UPDATE_FRAMEWORKS_BY_ID_FAILURE = "UPDATE_FRAMEWORKS_BY_ID_FAILURE";

const putFrameworkById = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_FRAMEWORKS_BY_ID_REQUEST, UPDATE_FRAMEWORKS_BY_ID_SUCCESS, UPDATE_FRAMEWORKS_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/frameworks/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.FRAMEWORK,
  },
});

export const updateFrameworkById = (id, payload) => (dispatch) => {
  return dispatch(putFrameworkById(id, payload));
};

export const HUBSPOT_LIST_COUNT_REQUEST = "HUBSPOT_LIST_COUNT_REQUEST";
export const HUBSPOT_LIST_COUNT_SUCCESS = "HUBSPOT_LIST_COUNT_SUCCESS";
export const HUBSPOT_LIST_COUNT_FAILURE = "HUBSPOT_LIST_COUNT_FAILURE";

const getHubSpotListCount = () => ({
  [CALL_API]: {
    types: [HUBSPOT_LIST_COUNT_REQUEST, HUBSPOT_LIST_COUNT_SUCCESS, HUBSPOT_LIST_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/hubspot/lists/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchHubSpotListCount = () => (dispatch) => {
  return dispatch(getHubSpotListCount());
};

export const FRAMEWORK_COUNT_REQUEST = "FRAMEWORK_COUNT_REQUEST";
export const FRAMEWORK_COUNT_SUCCESS = "FRAMEWORK_COUNT_SUCCESS";
export const FRAMEWORK_COUNT_FAILURE = "FRAMEWORK_COUNT_FAILURE";

const getFrameworkCount = () => ({
  [CALL_API]: {
    types: [FRAMEWORK_COUNT_REQUEST, FRAMEWORK_COUNT_SUCCESS, FRAMEWORK_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/frameworks/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchFrameworkCount = () => (dispatch) => {
  return dispatch(getFrameworkCount());
};

export const REQUIREMENT_COUNT_REQUEST = "REQUIREMENT_COUNT_REQUEST";
export const REQUIREMENT_COUNT_SUCCESS = "REQUIREMENT_COUNT_SUCCESS";
export const REQUIREMENT_COUNT_FAILURE = "REQUIREMENT_COUNT_FAILURE";

const getRequirementCount = () => ({
  [CALL_API]: {
    types: [REQUIREMENT_COUNT_REQUEST, REQUIREMENT_COUNT_SUCCESS, REQUIREMENT_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/requirements/requirements/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchRequirementCount = () => (dispatch) => {
  return dispatch(getRequirementCount());
};

export const POLICIES_COUNT_REQUEST = "POLICIES_COUNT_REQUEST";
export const POLICIES_COUNT_SUCCESS = "POLICIES_COUNT_SUCCESS";
export const POLICIES_COUNT_FAILURE = "POLICIES_COUNT_FAILURE";

const getPoliciesCount = () => ({
  [CALL_API]: {
    types: [POLICIES_COUNT_REQUEST, POLICIES_COUNT_SUCCESS, POLICIES_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/policies/policies/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchPoliciesCount = () => (dispatch) => {
  return dispatch(getPoliciesCount());
};

export const LAW_COUNT_REQUEST = "LAW_COUNT_REQUEST";
export const LAW_COUNT_SUCCESS = "LAW_COUNT_SUCCESS";
export const LAW_COUNT_FAILURE = "LAW_COUNT_FAILURE";

const getLawCount = () => ({
  [CALL_API]: {
    types: [LAW_COUNT_REQUEST, LAW_COUNT_SUCCESS, LAW_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/laws/laws/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchLawCount = () => (dispatch) => {
  return dispatch(getLawCount());
};

export const DATATYPE_COUNT_REQUEST = "DATATYPE_COUNT_REQUEST";
export const DATATYPE_COUNT_SUCCESS = "DATATYPE_COUNT_SUCCESS";
export const DATATYPE_COUNT_FAILURE = "DATATYPE_COUNT_FAILURE";

const getDataTypesCount = () => ({
  [CALL_API]: {
    types: [DATATYPE_COUNT_REQUEST, DATATYPE_COUNT_SUCCESS, DATATYPE_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/dataTypes/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchDataTypesCount = () => (dispatch) => {
  return dispatch(getDataTypesCount());
};

export const INDUSTRY_COUNT_REQUEST = "INDUSTRY_COUNT_REQUEST";
export const INDUSTRY_COUNT_SUCCESS = "INDUSTRY_COUNT_SUCCESS";
export const INDUSTRY_COUNT_FAILURE = "INDUSTRY_COUNT_FAILURE";

const getIndustriesCount = () => ({
  [CALL_API]: {
    types: [INDUSTRY_COUNT_REQUEST, INDUSTRY_COUNT_SUCCESS, INDUSTRY_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/industries/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchIndustriesCount = () => (dispatch) => {
  return dispatch(getIndustriesCount());
};

export const STATE_COUNT_REQUEST = "STATE_COUNT_REQUEST";
export const STATE_COUNT_SUCCESS = "STATE_COUNT_SUCCESS";
export const STATE_COUNT_FAILURE = "STATE_COUNT_FAILURE";

const getStatesCount = () => ({
  [CALL_API]: {
    types: [STATE_COUNT_REQUEST, STATE_COUNT_SUCCESS, STATE_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/states/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchStatesCount = () => (dispatch) => {
  return dispatch(getStatesCount());
};

export const ORG_IMPACT_COUNT_REQUEST = "ORG_IMPACT_COUNT_REQUEST";
export const ORG_IMPACT_COUNT_SUCCESS = "ORG_IMPACT_COUNT_SUCCESS";
export const ORG_IMPACT_COUNT_FAILURE = "ORG_IMPACT_COUNT_FAILURE";

const getOrgImpactsCount = () => ({
  [CALL_API]: {
    types: [ORG_IMPACT_COUNT_REQUEST, ORG_IMPACT_COUNT_SUCCESS, ORG_IMPACT_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/orgImpacts/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchOrgImpactsCount = () => (dispatch) => {
  return dispatch(getOrgImpactsCount());
};

export const COUNTRIES_COUNT_REQUEST = "COUNTRIES_COUNT_REQUEST";
export const COUNTRIES_COUNT_SUCCESS = "COUNTRIES_COUNT_SUCCESS";
export const COUNTRIES_COUNT_FAILURE = "COUNTRIES_COUNT_FAILURE";

const getCountriesCount = () => ({
  [CALL_API]: {
    types: [COUNTRIES_COUNT_REQUEST, COUNTRIES_COUNT_SUCCESS, COUNTRIES_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/countries/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchCountriesCount = () => (dispatch) => {
  return dispatch(getCountriesCount());
};

export const COMPANIES_REQUEST = "COMPANIES_REQUEST";
export const COMPANIES_SUCCESS = "COMPANIES_SUCCESS";
export const COMPANIES_FAILURE = "COMPANIES_FAILURE";

export const HUBSPOT_LISTS_REQUEST = "HUBSPOT_LISTS_REQUEST";
export const HUBSPOT_LISTS_SUCCESS = "HUBSPOT_LISTS_SUCCESS";
export const HUBSPOT_LISTS_FAILURE = "HUBSPOT_LISTS_FAILURE";

const fetchHubSpotLists = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [HUBSPOT_LISTS_REQUEST, HUBSPOT_LISTS_SUCCESS, HUBSPOT_LISTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.HUBSPOT_LIST_ARRAY,
  },
  refresh,
});

export const loadHubSpotLists = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchHubSpotLists(`${ADMIN_URL}/hubspot/lists/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchHubSpotLists(`${ADMIN_URL}/hubspot/lists/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchHubSpotLists(`${ADMIN_URL}/hubspot/lists/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchHubSpotLists(`${ADMIN_URL}/hubspot/lists/all?limit=${limit}`));
};

export const DATATYPES_REQUEST = "DATATYPES_REQUEST";
export const DATATYPES_SUCCESS = "DATATYPES_SUCCESS";
export const DATATYPES_FAILURE = "DATATYPES_FAILURE";

const fetchDataTypes = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [DATATYPES_REQUEST, DATATYPES_SUCCESS, DATATYPES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.DATATYPE_ARRAY,
  },
  refresh,
});

export const loadDataTypes = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchDataTypes(`${ADMIN_URL}/dataTypes/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchDataTypes(`${ADMIN_URL}/dataTypes/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchDataTypes(`${ADMIN_URL}/dataTypes/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchDataTypes(`${ADMIN_URL}/dataTypes/all?limit=${limit}`));
};

export const INDUSTRIES_REQUEST = "INDUSTRIES_REQUEST";
export const INDUSTRIES_SUCCESS = "INDUSTRIES_SUCCESS";
export const INDUSTRIES_FAILURE = "INDUSTRIES_FAILURE";

const fetchIndustries = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [INDUSTRIES_REQUEST, INDUSTRIES_SUCCESS, INDUSTRIES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.INDUSTRY_ARRAY,
  },
  refresh,
});

export const loadIndustries = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchIndustries(`${ADMIN_URL}/industries/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchIndustries(`${ADMIN_URL}/industries/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchIndustries(`${ADMIN_URL}/industries/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchIndustries(`${ADMIN_URL}/industries/all?limit=${limit}`));
};

export const STATES_REQUEST = "STATES_REQUEST";
export const STATES_SUCCESS = "STATES_SUCCESS";
export const STATES_FAILURE = "STATES_FAILURE";

const fetchStates = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [STATES_REQUEST, STATES_SUCCESS, STATES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.STATE_ARRAY,
  },
  refresh,
});

export const loadStates = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchStates(`${ADMIN_URL}/states/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchStates(`${ADMIN_URL}/states/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchStates(`${ADMIN_URL}/states/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchStates(`${ADMIN_URL}/states/all?limit=${limit}`));
};

export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";
export const COUNTRIES_FAILURE = "COUNTRIES_FAILURE";

const fetchCountries = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [COUNTRIES_REQUEST, COUNTRIES_SUCCESS, COUNTRIES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNTRY_ARRAY,
  },
  refresh,
});

export const loadCountries = (lastTimeStamp, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchCountries(`${ADMIN_URL}/countries/all?limit=${limit}`, refresh));
  }

  if (refresh && lastTimeStamp) {
    return dispatch(
      fetchCountries(`${ADMIN_URL}/countries/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`, refresh)
    );
  }

  if (lastTimeStamp) {
    return dispatch(fetchCountries(`${ADMIN_URL}/countries/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`));
  }

  return dispatch(fetchCountries(`${ADMIN_URL}/countries/all?limit=${limit}`));
};

export const REQUIREMENTS_REQUEST = "REQUIREMENTS_REQUEST";
export const REQUIREMENTS_SUCCESS = "REQUIREMENTS_SUCCESS";
export const REQUIREMENTS_FAILURE = "REQUIREMENTS_FAILURE";

const fetchRequirements = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [REQUIREMENTS_REQUEST, REQUIREMENTS_SUCCESS, REQUIREMENTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.REQUIREMENT_ARRAY,
  },
  refresh,
});

export const loadRequirements = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchRequirements(`${ADMIN_URL}/requirements/requirements/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(
      fetchRequirements(`${ADMIN_URL}/requirements/requirements/all?limit=${limit}&page=${page}`, refresh)
    );
  }

  if (page) {
    return dispatch(fetchRequirements(`${ADMIN_URL}/requirements/requirements/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchRequirements(`${ADMIN_URL}/requirements/requirements/all?limit=${limit}`));
};

export const LAWS_REQUEST = "LAWS_REQUEST";
export const LAWS_SUCCESS = "LAWS_SUCCESS";
export const LAWS_FAILURE = "LAWS_FAILURE";

const fetchLaws = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [LAWS_REQUEST, LAWS_SUCCESS, LAWS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LAW_ARRAY,
  },
  refresh,
});

export const loadLaws = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchLaws(`${ADMIN_URL}/laws/laws/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchLaws(`${ADMIN_URL}/laws/laws/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchLaws(`${ADMIN_URL}/laws/laws/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchLaws(`${ADMIN_URL}/laws/laws/all?limit=${limit}`));
};

export const POLICIES_REQUEST = "POLICIES_REQUEST";
export const POLICIES_SUCCESS = "POLICIES_SUCCESS";
export const POLICIES_FAILURE = "POLICIES_FAILURE";

const fetchPolicies = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [POLICIES_REQUEST, POLICIES_SUCCESS, POLICIES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.POLICIES_ARRAY,
  },
  refresh,
});

export const loadPolicies = (lastTimeStamp, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchPolicies(`${ADMIN_URL}/policies/policies/all?limit=${limit}`, refresh));
  }

  if (refresh && lastTimeStamp) {
    return dispatch(
      fetchPolicies(`${ADMIN_URL}/policies/policies/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`, refresh)
    );
  }

  if (lastTimeStamp) {
    return dispatch(fetchPolicies(`${ADMIN_URL}/policies/policies/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`));
  }

  return dispatch(fetchPolicies(`${ADMIN_URL}/policies/policies/all?limit=${limit}`));
};

export const FRAMEWORKS_REQUEST = "FRAMEWORKS_REQUEST";
export const FRAMEWORKS_SUCCESS = "FRAMEWORKS_SUCCESS";
export const FRAMEWORKS_FAILURE = "FRAMEWORKS_FAILURE";

const fetchFrameworks = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [FRAMEWORKS_REQUEST, FRAMEWORKS_SUCCESS, FRAMEWORKS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.FRAMEWORK_ARRAY,
  },
  refresh,
});

export const loadFrameworks = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchFrameworks(`${ADMIN_URL}/frameworks/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchFrameworks(`${ADMIN_URL}/frameworks/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchFrameworks(`${ADMIN_URL}/frameworks/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchFrameworks(`${ADMIN_URL}/frameworks/all?limit=${limit}`));
};

export const ORG_IMPACTS_REQUEST = "ORG_IMPACTS_REQUEST";
export const ORG_IMPACTS_SUCCESS = "ORG_IMPACTS_SUCCESS";
export const ORG_IMPACTS_FAILURE = "ORG_IMPACTS_FAILURE";

const fetchOrgImpacts = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [ORG_IMPACTS_REQUEST, ORG_IMPACTS_SUCCESS, ORG_IMPACTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.ORG_IMPACT_ARRAY,
  },
  refresh,
});

export const loadOrgImpacts = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchOrgImpacts(`${ADMIN_URL}/orgImpacts/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchOrgImpacts(`${ADMIN_URL}/orgImpacts/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchOrgImpacts(`${ADMIN_URL}/orgImpacts/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchOrgImpacts(`${ADMIN_URL}/orgImpacts/all?limit=${limit}`));
};

export const DELETE_DATATYPE_BY_ID_REQUEST = "DELETE_DATATYPE_BY_ID_REQUEST";
export const DELETE_DATATYPE_BY_ID_SUCCESS = "DELETE_DATATYPE_BY_ID_SUCCESS";
export const DELETE_DATATYPE_BY_ID_FAILURE = "DELETE_DATATYPE_BY_ID_FAILURE";

const deleteDataType = (id) => ({
  [CALL_API]: {
    types: [DELETE_DATATYPE_BY_ID_REQUEST, DELETE_DATATYPE_BY_ID_SUCCESS, DELETE_DATATYPE_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/dataTypes/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.DATATYPE,
  },
});

export const deleteDataTypeById = (id) => (dispatch) => {
  return dispatch(deleteDataType(id));
};

export const DELETE_LAW_BY_ID_REQUEST = "DELETE_LAW_BY_ID_REQUEST";
export const DELETE_LAW_BY_ID_SUCCESS = "DELETE_LAW_BY_ID_SUCCESS";
export const DELETE_LAW_BY_ID_FAILURE = "DELETE_LAW_BY_ID_FAILURE";

const deleteLaw = (id) => ({
  [CALL_API]: {
    types: [DELETE_LAW_BY_ID_REQUEST, DELETE_LAW_BY_ID_SUCCESS, DELETE_LAW_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/laws/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.LAW,
  },
});

export const deleteLawById = (id) => (dispatch) => {
  return dispatch(deleteLaw(id));
};

export const DELETE_POLICY_BY_ID_REQUEST = "DELETE_POLICY_BY_ID_REQUEST";
export const DELETE_POLICY_BY_ID_SUCCESS = "DELETE_POLICY_BY_ID_SUCCESS";
export const DELETE_POLICY_BY_ID_FAILURE = "DELETE_POLICY_BY_ID_FAILURE";

const deletePolicy = (id) => ({
  [CALL_API]: {
    types: [DELETE_POLICY_BY_ID_REQUEST, DELETE_POLICY_BY_ID_SUCCESS, DELETE_POLICY_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/policies/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.POLICIES,
  },
});

export const deletePolicyById = (id) => (dispatch) => {
  return dispatch(deletePolicy(id));
};

export const DELETE_FRAMEWORK_BY_ID_REQUEST = "DELETE_FRAMEWORK_BY_ID_REQUEST";
export const DELETE_FRAMEWORK_BY_ID_SUCCESS = "DELETE_FRAMEWORK_BY_ID_SUCCESS";
export const DELETE_FRAMEWORK_BY_ID_FAILURE = "DELETE_FRAMEWORK_BY_ID_FAILURE";

const deleteFramework = (id) => ({
  [CALL_API]: {
    types: [DELETE_FRAMEWORK_BY_ID_REQUEST, DELETE_FRAMEWORK_BY_ID_SUCCESS, DELETE_FRAMEWORK_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/frameworks/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.FRAMEWORK,
  },
});

export const deleteFrameworkById = (id) => (dispatch) => {
  return dispatch(deleteFramework(id));
};

export const DELETE_STATE_BY_ID_REQUEST = "DELETE_STATE_BY_ID_REQUEST";
export const DELETE_STATE_BY_ID_SUCCESS = "DELETE_STATE_BY_ID_SUCCESS";
export const DELETE_STATE_BY_ID_FAILURE = "DELETE_STATE_BY_ID_FAILURE";

const deleteState = (id) => ({
  [CALL_API]: {
    types: [DELETE_STATE_BY_ID_REQUEST, DELETE_STATE_BY_ID_SUCCESS, DELETE_STATE_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/states/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.STATE,
  },
});

export const deleteStateById = (id) => (dispatch) => {
  return dispatch(deleteState(id));
};

export const DELETE_ORG_IMPACT_BY_ID_REQUEST = "DELETE_ORG_IMPACT_BY_ID_REQUEST";
export const DELETE_ORG_IMPACT_BY_ID_SUCCESS = "DELETE_ORG_IMPACT_BY_ID_SUCCESS";
export const DELETE_ORG_IMPACT_BY_ID_FAILURE = "DELETE_ORG_IMPACT_BY_ID_FAILURE";

const deleteOrgImpact = (id) => ({
  [CALL_API]: {
    types: [DELETE_ORG_IMPACT_BY_ID_REQUEST, DELETE_ORG_IMPACT_BY_ID_SUCCESS, DELETE_ORG_IMPACT_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/orgImpacts/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.ORG_IMPACT,
  },
});

export const deleteOrgImpactById = (id) => (dispatch) => {
  return dispatch(deleteOrgImpact(id));
};

export const DELETE_COUNTRY_BY_ID_REQUEST = "DELETE_COUNTRY_BY_ID_REQUEST";
export const DELETE_COUNTRY_BY_ID_SUCCESS = "DELETE_COUNTRY_BY_ID_SUCCESS";
export const DELETE_COUNTRY_BY_ID_FAILURE = "DELETE_COUNTRY_BY_ID_FAILURE";

const deleteCountry = (id) => ({
  [CALL_API]: {
    types: [DELETE_COUNTRY_BY_ID_REQUEST, DELETE_COUNTRY_BY_ID_SUCCESS, DELETE_COUNTRY_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/countries/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.COUNTRY,
  },
});

export const deleteCountryById = (id) => (dispatch) => {
  return dispatch(deleteCountry(id));
};

export const DELETE_INDUSTRY_BY_ID_REQUEST = "DELETE_INDUSTRY_BY_ID_REQUEST";
export const DELETE_INDUSTRY_BY_ID_SUCCESS = "DELETE_INDUSTRY_BY_ID_SUCCESS";
export const DELETE_INDUSTRY_BY_ID_FAILURE = "DELETE_INDUSTRY_BY_ID_FAILURE";

const deleteIndustry = (id) => ({
  [CALL_API]: {
    types: [DELETE_INDUSTRY_BY_ID_REQUEST, DELETE_INDUSTRY_BY_ID_SUCCESS, DELETE_INDUSTRY_BY_ID_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/industries/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.INDUSTRY,
  },
});

export const deleteIndustryById = (id) => (dispatch) => {
  return dispatch(deleteIndustry(id));
};

export const USER_FRAMEWORK_DESIGN_REQUEST = "USER_FRAMEWORK_DESIGN_REQUEST";
export const USER_FRAMEWORK_DESIGN_SUCCESS = "USER_FRAMEWORK_DESIGN_SUCCESS";
export const USER_FRAMEWORK_DESIGN_FAILURE = "USER_FRAMEWORK_DESIGN_FAILURE";

const insertCompanyFrameworkDesign = (payload) => ({
  [CALL_API]: {
    types: [USER_FRAMEWORK_DESIGN_REQUEST, USER_FRAMEWORK_DESIGN_SUCCESS, USER_FRAMEWORK_DESIGN_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/frameworks/company/details`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.PLAN,
  },
});

export const postCompanyFrameworkDesign = (payload) => (dispatch) => {
  return dispatch(insertCompanyFrameworkDesign(payload));
};

export const VENDOR_POST_REQUEST = "VENDOR_POST_REQUEST";
export const VENDOR_POST_SUCCESS = "VENDOR_POST_SUCCESS";
export const VENDOR_POST_FAILURE = "VENDOR_POST_FAILURE";

const insertVendor = (payload) => ({
  [CALL_API]: {
    types: [VENDOR_POST_REQUEST, VENDOR_POST_SUCCESS, VENDOR_POST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/metadata/vendor`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.VENDOR,
  },
});

export const postVendor = (payload) => (dispatch) => {
  return dispatch(insertVendor(payload));
};

export const CITY_POST_REQUEST = "CITY_POST_REQUEST";
export const CITY_POST_SUCCESS = "CITY_POST_SUCCESS";
export const CITY_POST_FAILURE = "CITY_POST_FAILURE";

const insertCity = (payload) => ({
  [CALL_API]: {
    types: [CITY_POST_REQUEST, CITY_POST_SUCCESS, CITY_POST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/metadata/city`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.CITY,
  },
});

export const postCity = (payload) => (dispatch) => {
  return dispatch(insertCity(payload));
};

export const CITY_PUT_REQUEST = "CITY_PUT_REQUEST";
export const CITY_PUT_SUCCESS = "CITY_PUT_SUCCESS";
export const CITY_PUT_FAILURE = "CITY_PUT_FAILURE";

const updateCityById = (id, payload) => ({
  [CALL_API]: {
    types: [CITY_PUT_REQUEST, CITY_PUT_SUCCESS, CITY_PUT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/metadata/city/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.CITY,
  },
});

export const updateCity = (id, payload) => (dispatch) => {
  return dispatch(updateCityById(id, payload));
};

export const CITY_DELETE_REQUEST = "CITY_DELETE_REQUEST";
export const CITY_DELETE_SUCCESS = "CITY_DELETE_SUCCESS";
export const CITY_DELETE_FAILURE = "CITY_DELETE_FAILURE";

const removeCityById = (id) => ({
  [CALL_API]: {
    types: [CITY_DELETE_REQUEST, CITY_DELETE_SUCCESS, CITY_DELETE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/metadata/city/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.CITY,
  },
});

export const deleteCityById = (id) => (dispatch) => {
  return dispatch(removeCityById(id));
};

export const VENDOR_PUT_REQUEST = "VENDOR_PUT_REQUEST";
export const VENDOR_PUT_SUCCESS = "VENDOR_PUT_SUCCESS";
export const VENDOR_PUT_FAILURE = "VENDOR_PUT_FAILURE";

const updateVendorById = (id, payload) => ({
  [CALL_API]: {
    types: [VENDOR_PUT_REQUEST, VENDOR_PUT_SUCCESS, VENDOR_PUT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/metadata/vendor/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.VENDOR,
  },
});

export const updateVendor = (id, payload) => (dispatch) => {
  return dispatch(updateVendorById(id, payload));
};

export const VENDOR_DELETE_REQUEST = "VENDOR_DELETE_REQUEST";
export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAILURE = "VENDOR_DELETE_FAILURE";

const removeVendor = (id) => ({
  [CALL_API]: {
    types: [VENDOR_DELETE_REQUEST, VENDOR_DELETE_SUCCESS, VENDOR_DELETE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/metadata/vendor/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.VENDOR,
  },
});

export const deleteVendor = (id) => (dispatch) => {
  return dispatch(removeVendor(id));
};

export const DOCUMENT_REQUEST = "DOCUMENT_REQUEST";
export const DOCUMENT_SUCCESS = "DOCUMENT_SUCCESS";
export const DOCUMENT_FAILURE = "DOCUMENT_FAILURE";

const fetchDocumentById = (id) => ({
  [CALL_API]: {
    types: [DOCUMENT_REQUEST, DOCUMENT_SUCCESS, DOCUMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/documents/document/${id}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.DOCUMENT,
  },
});

export const loadDocumentById = (id) => (dispatch) => {
  return dispatch(fetchDocumentById(id));
};

export const UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_DOCUMENT_FAILURE";

const changeDocument = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_DOCUMENT_REQUEST, UPDATE_DOCUMENT_SUCCESS, UPDATE_DOCUMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/documents/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.DOCUMENT,
  },
});

export const updateDocument = (id, payload) => (dispatch) => {
  return dispatch(changeDocument(id, payload));
};

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

const removeDocument = (documentId) => ({
  [CALL_API]: {
    types: [DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/documents/${documentId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.DOCUMENT_ARRAY,
  },
});

export const deleteDocument = (payload) => (dispatch) => {
  return dispatch(removeDocument(payload));
};

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";

const changeComment = (commentId, payload) => ({
  [CALL_API]: {
    types: [UPDATE_COMMENT_REQUEST, UPDATE_COMMENT_SUCCESS, UPDATE_COMMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/documents/comment/${commentId}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMMENT,
  },
});

export const updateComment = (commentId, payload) => (dispatch) => {
  return dispatch(changeComment(commentId, payload));
};

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

const removeComment = (commentId) => ({
  [CALL_API]: {
    types: [DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILURE],
    reqInfo: {
      endpoint: `${BASE_URL}/documents/comment/${commentId}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.COMMENT,
  },
});

export const deleteComment = (commentId) => (dispatch) => {
  return dispatch(removeComment(commentId));
};

export const GET_CHARACTERISTICS_REQUEST = "GET_CHARACTERISTICS_REQUEST";
export const GET_CHARACTERISTICS_SUCCESS = "GET_CHARACTERISTICS_SUCCESS";
export const GET_CHARACTERISTICS_FAILURE = "GET_CHARACTERISTICS_FAILURE";

const fetchCharacteristics = (nextPageUrl, refresh) => ({
  [CALL_API]: {
    types: [GET_CHARACTERISTICS_REQUEST, GET_CHARACTERISTICS_SUCCESS, GET_CHARACTERISTICS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CHARACTERISTICS_ARRAY,
  },
  refresh,
});

export const getCharacteristics = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchCharacteristics(
        `${ADMIN_URL}/characteristics/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchCharacteristics(
        `${ADMIN_URL}/characteristics/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchCharacteristics(
        `${ADMIN_URL}/characteristics/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchCharacteristics(`${ADMIN_URL}/characteristics/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_CHARACTERISTICS_COUNT_REQUEST = "GET_CHARACTERISTICS_COUNT_REQUEST";
export const GET_CHARACTERISTICS_COUNT_SUCCESS = "GET_CHARACTERISTICS_COUNT_SUCCESS";
export const GET_CHARACTERISTICS_COUNT_FAILURE = "GET_CHARACTERISTICS_COUNT_FAILURE";

const getCharacteristicsCount = (searchTerm) => ({
  [CALL_API]: {
    types: [GET_CHARACTERISTICS_COUNT_REQUEST, GET_CHARACTERISTICS_COUNT_SUCCESS, GET_CHARACTERISTICS_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/characteristics/count?search=${searchTerm}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchCharacteristicsCount = (searchTerm) => (dispatch) => {
  return dispatch(getCharacteristicsCount(searchTerm));
};

export const POST_CHARACTERISTIC_REQUEST = "POST_CHARACTERISTIC_REQUEST";
export const POST_CHARACTERISTIC_SUCCESS = "POST_CHARACTERISTIC_SUCCESS";
export const POST_CHARACTERISTIC_FAILURE = "POST_CHARACTERISTIC_FAILURE";

const postCharacteristic = (payload) => ({
  [CALL_API]: {
    types: [POST_CHARACTERISTIC_REQUEST, POST_CHARACTERISTIC_SUCCESS, POST_CHARACTERISTIC_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/characteristics`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.CHARACTERISTICS,
  },
});

export const createCharacteristic = (payload) => (dispatch) => {
  return dispatch(postCharacteristic(payload));
};

export const UPDATE_CHARACTERISTIC_REQUEST = "UPDATE_CHARACTERISTIC_REQUEST";
export const UPDATE_CHARACTERISTIC_SUCCESS = "UPDATE_CHARACTERISTIC_SUCCESS";
export const UPDATE_CHARACTERISTIC_FAILURE = "UPDATE_CHARACTERISTIC_FAILURE";

const changeCharacteristic = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_CHARACTERISTIC_REQUEST, UPDATE_CHARACTERISTIC_SUCCESS, UPDATE_CHARACTERISTIC_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/characteristics/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.CHARACTERISTICS,
  },
});

export const updateCharacteristic = (id, payload) => (dispatch) => {
  return dispatch(changeCharacteristic(id, payload));
};

export const DELETE_CHARACTERISTIC_REQUEST = "DELETE_CHARACTERISTIC_REQUEST";
export const DELETE_CHARACTERISTIC_SUCCESS = "DELETE_CHARACTERISTIC_SUCCESS";
export const DELETE_CHARACTERISTIC_FAILURE = "DELETE_CHARACTERISTIC_FAILURE";

const removeCharacteristic = (id) => ({
  [CALL_API]: {
    types: [DELETE_CHARACTERISTIC_REQUEST, DELETE_CHARACTERISTIC_SUCCESS, DELETE_CHARACTERISTIC_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/characteristics/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.CHARACTERISTICS,
  },
});

export const deleteCharacteristic = (id) => (dispatch) => {
  return dispatch(removeCharacteristic(id));
};

export const GET_CLASSES_REQUEST = "GET_CLASSES_REQUEST";
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const GET_CLASSES_FAILURE = "GET_CLASSES_FAILURE";

const fetchClasses = (nextPageUrl, refresh) => ({
  [CALL_API]: {
    types: [GET_CLASSES_REQUEST, GET_CLASSES_SUCCESS, GET_CLASSES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CLASSES_ARRAY,
  },
  refresh,
});

export const getClasses = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchClasses(`${ADMIN_URL}/classes/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchClasses(
        `${ADMIN_URL}/classes/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchClasses(`${ADMIN_URL}/classes/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`)
    );
  }

  return dispatch(fetchClasses(`${ADMIN_URL}/classes/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`));
};

export const GET_CLASSES_COUNT_REQUEST = "GET_CLASSES_COUNT_REQUEST";
export const GET_CLASSES_COUNT_SUCCESS = "GET_CLASSES_COUNT_SUCCESS";
export const GET_CLASSES_COUNT_FAILURE = "GET_CLASSES_COUNT_FAILURE";

const getClassesCount = (searchTerm) => ({
  [CALL_API]: {
    types: [GET_CLASSES_COUNT_REQUEST, GET_CLASSES_COUNT_SUCCESS, GET_CLASSES_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/classes/count?search=${searchTerm}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchClassesCount = (searchTerm) => (dispatch) => {
  return dispatch(getClassesCount(searchTerm));
};

export const POST_CLASSES_REQUEST = "POST_CLASSES_REQUEST";
export const POST_CLASSES_SUCCESS = "POST_CLASSES_SUCCESS";
export const POST_CLASSES_FAILURE = "POST_CLASSES_FAILURE";

const postClass = (payload) => ({
  [CALL_API]: {
    types: [POST_CLASSES_REQUEST, POST_CLASSES_SUCCESS, POST_CLASSES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/classes`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.CLASSES,
  },
});

export const createClass = (payload) => (dispatch) => {
  return dispatch(postClass(payload));
};

export const UPDATE_CLASS_REQUEST = "UPDATE_CLASS_REQUEST";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAILURE = "UPDATE_CLASS_FAILURE";

const changeClass = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_CLASS_REQUEST, UPDATE_CLASS_SUCCESS, UPDATE_CLASS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/classes/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.CLASSES,
  },
});

export const updateClass = (id, payload) => (dispatch) => {
  return dispatch(changeClass(id, payload));
};

export const DELETE_CLASS_REQUEST = "DELETE_CLASS_REQUEST";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAILURE = "DELETE_CLASS_FAILURE";

const removeClass = (id) => ({
  [CALL_API]: {
    types: [DELETE_CLASS_REQUEST, DELETE_CLASS_SUCCESS, DELETE_CLASS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/classes/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.CLASSES,
  },
});

export const deleteClass = (id) => (dispatch) => {
  return dispatch(removeClass(id));
};

export const CLASSES_LIST_REQUEST = "CLASSES_LIST_REQUEST";
export const CLASSES_LIST_SUCCESS = "CLASSES_LIST_SUCCESS";
export const CLASSES_LIST_FAILURE = "CLASSES_LIST_FAILURE";

const loadClassesList = () => ({
  [CALL_API]: {
    types: [CLASSES_LIST_REQUEST, CLASSES_LIST_SUCCESS, CLASSES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/classes/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getClassesList = () => (dispatch) => {
  return dispatch(loadClassesList());
};

export const GET_COMPONENTS_REQUEST = "GET_COMPONENTS_REQUEST";
export const GET_COMPONENTS_SUCCESS = "GET_COMPONENTS_SUCCESS";
export const GET_COMPONENTS_FAILURE = "GET_COMPONENTS_FAILURE";

const fetchComponents = (nextPageUrl, refresh) => ({
  [CALL_API]: {
    types: [GET_COMPONENTS_REQUEST, GET_COMPONENTS_SUCCESS, GET_COMPONENTS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COMPONENTS_ARRAY,
  },
  refresh,
});

export const getComponents = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchComponents(`${ADMIN_URL}/components/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchComponents(
        `${ADMIN_URL}/components/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchComponents(
        `${ADMIN_URL}/components/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchComponents(`${ADMIN_URL}/components/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COMPONENTS_COUNT_REQUEST = "GET_COMPONENTS_COUNT_REQUEST";
export const GET_COMPONENTS_COUNT_SUCCESS = "GET_COMPONENTS_COUNT_SUCCESS";
export const GET_COMPONENTS_COUNT_FAILURE = "GET_COMPONENTS_COUNT_FAILURE";

const getComponentsCount = (searchTerm) => ({
  [CALL_API]: {
    types: [GET_COMPONENTS_COUNT_REQUEST, GET_COMPONENTS_COUNT_SUCCESS, GET_COMPONENTS_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/components/count?search=${searchTerm}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchComponentsCount = (searchTerm) => (dispatch) => {
  return dispatch(getComponentsCount(searchTerm));
};

export const POST_COMPONENTS_REQUEST = "POST_COMPONENTS_REQUEST";
export const POST_COMPONENTS_SUCCESS = "POST_COMPONENTS_SUCCESS";
export const POST_COMPONENTS_FAILURE = "POST_COMPONENTS_FAILURE";

const postComponent = (payload) => ({
  [CALL_API]: {
    types: [POST_COMPONENTS_REQUEST, POST_COMPONENTS_SUCCESS, POST_COMPONENTS_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/components`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMPONENTS,
  },
});

export const createComponent = (payload) => (dispatch) => {
  return dispatch(postComponent(payload));
};

export const UPDATE_COMPONENT_REQUEST = "UPDATE_COMPONENT_REQUEST";
export const UPDATE_COMPONENT_SUCCESS = "UPDATE_COMPONENT_SUCCESS";
export const UPDATE_COMPONENT_FAILURE = "UPDATE_COMPONENT_FAILURE";

const changeComponent = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_COMPONENT_REQUEST, UPDATE_COMPONENT_SUCCESS, UPDATE_COMPONENT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/components/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.COMPONENTS,
  },
});

export const updateComponent = (id, payload) => (dispatch) => {
  return dispatch(changeComponent(id, payload));
};

export const DELETE_COMPONENT_REQUEST = "DELETE_COMPONENT_REQUEST";
export const DELETE_COMPONENT_SUCCESS = "DELETE_COMPONENT_SUCCESS";
export const DELETE_COMPONENT_FAILURE = "DELETE_COMPONENT_FAILURE";

const removeComponent = (id) => ({
  [CALL_API]: {
    types: [DELETE_COMPONENT_REQUEST, DELETE_COMPONENT_SUCCESS, DELETE_COMPONENT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/components/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.COMPONENTS,
  },
});

export const deleteComponent = (id) => (dispatch) => {
  return dispatch(removeComponent(id));
};

export const COMPONENTS_LIST_REQUEST = "COMPONENTS_LIST_REQUEST";
export const COMPONENTS_LIST_SUCCESS = "COMPONENTS_LIST_SUCCESS";
export const COMPONENTS_LIST_FAILURE = "COMPONENTS_LIST_FAILURE";

const loadComponentsList = () => ({
  [CALL_API]: {
    types: [COMPONENTS_LIST_REQUEST, COMPONENTS_LIST_SUCCESS, COMPONENTS_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/components/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getComponentsList = () => (dispatch) => {
  return dispatch(loadComponentsList());
};

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

const fetchCategories = (nextPageUrl, refresh) => ({
  [CALL_API]: {
    types: [GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.CATEGORIES_ARRAY,
  },
  refresh,
});

export const getCategories = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchCategories(`${ADMIN_URL}/categories/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`, refresh)
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchCategories(
        `${ADMIN_URL}/categories/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchCategories(
        `${ADMIN_URL}/categories/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchCategories(`${ADMIN_URL}/categories/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_CATEGORIES_COUNT_REQUEST = "GET_CATEGORIES_COUNT_REQUEST";
export const GET_CATEGORIES_COUNT_SUCCESS = "GET_CATEGORIES_COUNT_SUCCESS";
export const GET_CATEGORIES_COUNT_FAILURE = "GET_CATEGORIES_COUNT_FAILURE";

const getCategoriesCount = (searchTerm) => ({
  [CALL_API]: {
    types: [GET_CATEGORIES_COUNT_REQUEST, GET_CATEGORIES_COUNT_SUCCESS, GET_CATEGORIES_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/categories/count?search=${searchTerm}`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchCategoriesCount = (searchTerm) => (dispatch) => {
  return dispatch(getCategoriesCount(searchTerm));
};

export const POST_CATEGORIES_REQUEST = "POST_CATEGORIES_REQUEST";
export const POST_CATEGORIES_SUCCESS = "POST_CATEGORIES_SUCCESS";
export const POST_CATEGORIES_FAILURE = "POST_CATEGORIES_FAILURE";

const postCategory = (payload) => ({
  [CALL_API]: {
    types: [POST_CATEGORIES_REQUEST, POST_CATEGORIES_SUCCESS, POST_CATEGORIES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/categories`,
      method: "POST",
      body: payload,
      auth: true,
    },
    schema: Schemas.CATEGORIES,
  },
});

export const createCategory = (payload) => (dispatch) => {
  return dispatch(postCategory(payload));
};

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

const changeCategory = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/categories/${id}`,
      method: "PUT",
      body: payload,
      auth: true,
    },
    schema: Schemas.CATEGORIES,
  },
});

export const updateCategory = (id, payload) => (dispatch) => {
  return dispatch(changeCategory(id, payload));
};

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

const removeCategory = (id) => ({
  [CALL_API]: {
    types: [DELETE_CATEGORY_REQUEST, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/categories/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.CATEGORIES,
  },
});

export const deleteCategory = (id) => (dispatch) => {
  return dispatch(removeCategory(id));
};

export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_SUCCESS = "CATEGORIES_LIST_SUCCESS";
export const CATEGORIES_LIST_FAILURE = "CATEGORIES_LIST_FAILURE";

const loadCategoriesList = () => ({
  [CALL_API]: {
    types: [CATEGORIES_LIST_REQUEST, CATEGORIES_LIST_SUCCESS, CATEGORIES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/categories/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getCategoriesList = () => (dispatch) => {
  return dispatch(loadCategoriesList());
};
