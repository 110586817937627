import React from "react";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

export const getColumns = (policies = [], processes = []) => {
  return [
    {
      title: "Program",
      field: "name",
      render: (row) => <div style={{ minWidth: "160px" }}>{row.name}</div>,
      editComponent: (props) => <MultiLineEdit {...props} />,
      headerStyle: { fontWeight: "bold" },
    },
    {
      title: "Domains",
      field: "policies",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {Array.isArray(row.policies) && row.policies.length > 0
            ? row.policies
                .map((policy) => (policy.policy && policy.policy.name) || null)
                .filter((policy) => policy !== null)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => {
        return (
          <div style={{ minWidth: "80px" }}>
            <Selection multiple target="policy" options={policies} {...props} />
          </div>
        );
      },
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
    {
      title: "Processes",
      field: "processes",
      render: (row) => (
        <div style={{ minWidth: "50px" }}>
          {Array.isArray(row.processes) && row.processes.length > 0
            ? row.processes
                .map((process) => process.name || null)
                .filter((process) => process !== null)
                .join(", ")
            : null}
        </div>
      ),
      editComponent: (props) => {
        return (
          <div style={{ minWidth: "80px" }}>
            <Selection multiple target="process" options={processes} {...props} />
          </div>
        );
      },
      headerStyle: { fontWeight: "bold" },
      sorting: false,
    },
  ];
};
