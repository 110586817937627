/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const SAVE_OBJECTIVE_REQUEST = "SAVE_OBJECTIVE_REQUEST";
export const SAVE_OBJECTIVE_SUCCESS = "SAVE_OBJECTIVE_SUCCESS";
export const SAVE_OBJECTIVE_FAILURE = "SAVE_OBJECTIVE_FAILURE";

const createObjective = (payload) => ({
  [CALL_API]: {
    types: [SAVE_OBJECTIVE_REQUEST, SAVE_OBJECTIVE_SUCCESS, SAVE_OBJECTIVE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/objectives`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.OBJECTIVES_ARRAY,
  },
});

export const saveObjective = (payload) => (dispatch) => {
  return dispatch(createObjective(payload));
};

export const GET_OBJECTIVES_LIST_REQUEST = "GET_OBJECTIVES_LIST_REQUEST";
export const GET_OBJECTIVES_LIST_SUCCESS = "GET_OBJECTIVES_LIST_SUCCESS";
export const GET_OBJECTIVES_LIST_FAILURE = "GET_OBJECTIVES_LIST_FAILURE";

const fetchObjectivesList = () => ({
  [CALL_API]: {
    types: [GET_OBJECTIVES_LIST_REQUEST, GET_OBJECTIVES_LIST_SUCCESS, GET_OBJECTIVES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/objectives/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getObjectivesList = () => (dispatch) => {
  return dispatch(fetchObjectivesList());
};

export const UPDATE_OBJECTIVE_REQUEST = "UPDATE_OBJECTIVE_REQUEST";
export const UPDATE_OBJECTIVE_SUCCESS = "UPDATE_OBJECTIVE_SUCCESS";
export const UPDATE_OBJECTIVE_FAILURE = "UPDATE_OBJECTIVE_FAILURE";

const changeObjective = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_OBJECTIVE_REQUEST, UPDATE_OBJECTIVE_SUCCESS, UPDATE_OBJECTIVE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/objectives/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.OBJECTIVES,
  },
});

export const updateObjective = (id, payload) => (dispatch) => {
  return dispatch(changeObjective(id, payload));
};

export const DELETE_OBJECTIVE_REQUEST = "DELETE_OBJECTIVE_REQUEST";
export const DELETE_OBJECTIVE_SUCCESS = "DELETE_OBJECTIVE_SUCCESS";
export const DELETE_OBJECTIVE_FAILURE = "DELETE_OBJECTIVE_FAILURE";

const removeObjective = (id) => ({
  [CALL_API]: {
    types: [DELETE_OBJECTIVE_REQUEST, DELETE_OBJECTIVE_SUCCESS, DELETE_OBJECTIVE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/objectives/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.OBJECTIVES,
  },
});

export const deleteObjective = (id) => (dispatch) => {
  return dispatch(removeObjective(id));
};

export const GET_ALL_OBJECTIVES_REQUEST = "GET_ALL_OBJECTIVES_REQUEST";
export const GET_ALL_OBJECTIVES_SUCCESS = "GET_ALL_OBJECTIVES_SUCCESS";
export const GET_ALL_OBJECTIVES_FAILURE = "GET_ALL_OBJECTIVES_FAILURE";

const fetchAllObjectives = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_OBJECTIVES_REQUEST, GET_ALL_OBJECTIVES_SUCCESS, GET_ALL_OBJECTIVES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.OBJECTIVES_ARRAY,
  },
  refresh,
});

export const getAllObjectives = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllObjectives(
        `${ADMIN_URL}/objectives/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllObjectives(
        `${ADMIN_URL}/objectives/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllObjectives(
        `${ADMIN_URL}/objectives/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllObjectives(`${ADMIN_URL}/objectives/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_OBJECTIVES_REQUEST = "GET_COUNT_OBJECTIVES_REQUEST";
export const GET_COUNT_OBJECTIVES_SUCCESS = "GET_COUNT_OBJECTIVES_SUCCESS";
export const GET_COUNT_OBJECTIVES_FAILURE = "GET_COUNT_OBJECTIVES_FAILURE";

const fetchObjectivesCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_OBJECTIVES_REQUEST, GET_COUNT_OBJECTIVES_SUCCESS, GET_COUNT_OBJECTIVES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/objectives/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getObjectivesCount = (search) => (dispatch) => {
  return dispatch(fetchObjectivesCount(search));
};
