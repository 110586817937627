import React from "react";
import AuthComponent from "../../../components/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createDeliverable,
  getDeliverablesCount,
  getDeliverablesList,
  loadDeliverables,
  removeDeliverable,
  updateDeliverable,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable";
import { Grid } from "@material-ui/core";
import { Selection } from "../../../components/MaterialTable/Inputs";

const Deliverables = (props) => {
  const columns = [
    {
      title: "Deliverable",
      field: "name",
    },
    {
      title: "Description",
      field: "description",
    },
  ];

  React.useEffect(() => {
    // props.loadOrgImpactsList();
  }, []);

  const postDeliverable = async (rowData) => {
    try {
      const payload = {
        ...rowData,
      };
      return await props.createDeliverable({ ...payload });
    } catch (err) {}
  };

  const editDeliverable = async (newData, oldData) => {
    try {
      let payload = { ...newData };
      return await props.updateDeliverable(newData.id, { ...payload });
    } catch (err) {}
  };

  const deleteDeliverable = async (rowData) => {
    try {
      return await props.removeDeliverable(rowData.id);
    } catch (err) {}
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getDeliverablesCount}
            paginationCall={props.loadDeliverables}
            title="Deliverables"
            entity="deliverables"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => postDeliverable(rowData)}
            editFunction={(newData, oldData) => editDeliverable(newData, oldData)}
            deleteFunction={(rowData) => deleteDeliverable(rowData)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataTypes: state.entities.dataTypes,
    pageInfo: state.pagination.dataTypes,
    deliverables:
      (state.entities.lists && state.entities.lists.orgImpacts && state.entities.lists.orgImpacts.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadDeliverables: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(loadDeliverables(page, limit, refresh, searchTerm, sort, by)),
    getDeliverablesCount: () => dispatch(getDeliverablesCount()),
    createDeliverable: (payload) => dispatch(createDeliverable(payload)),
    updateDeliverable: (id, payload) => dispatch(updateDeliverable(id, payload)),
    removeDeliverable: (id) => dispatch(removeDeliverable(id)),
    getDeliverablesList: () => dispatch(getDeliverablesList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Deliverables));
