/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const POST_PROGRAM_REQUEST = "POST_PROGRAM_REQUEST";
export const POST_PROGRAM_SUCCESS = "POST_PROGRAM_SUCCESS";
export const POST_PROGRAM_FAILURE = "POST_PROGRAM_FAILURE";

const postProgram = (payload) => ({
  [CALL_API]: {
    types: [POST_PROGRAM_REQUEST, POST_PROGRAM_SUCCESS, POST_PROGRAM_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/programs/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROGRAMS,
  },
});

export const saveProgram = (payload) => (dispatch) => {
  return dispatch(postProgram(payload));
};

export const UPDATE_PROGRAM_REQUEST = "UPDATE_PROGRAM_REQUEST";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_FAILURE = "UPDATE_PROGRAM_FAILURE";

const changeProgram = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_PROGRAM_REQUEST, UPDATE_PROGRAM_SUCCESS, UPDATE_PROGRAM_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/programs/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.PROGRAMS,
  },
});

export const updateProgram = (id, payload) => (dispatch) => {
  return dispatch(changeProgram(id, payload));
};

export const DELETE_PROGRAM_REQUEST = "DELETE_PROGRAM_REQUEST";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_FAILURE = "DELETE_PROGRAM_FAILURE";

const removeProgram = (id) => ({
  [CALL_API]: {
    types: [DELETE_PROGRAM_REQUEST, DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/programs/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.PROGRAM,
  },
});

export const deleteProgram = (id) => (dispatch) => {
  return dispatch(removeProgram(id));
};

export const PROGRAM_COUNT_REQUEST = "PROGRAM_COUNT_REQUEST";
export const PROGRAM_COUNT_SUCCESS = "PROGRAM_COUNT_SUCCESS";
export const PROGRAM_COUNT_FAILURE = "PROGRAM_COUNT_FAILURE";

const getProgramCount = () => ({
  [CALL_API]: {
    types: [PROGRAM_COUNT_REQUEST, PROGRAM_COUNT_SUCCESS, PROGRAM_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/programs/programs/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchProgramCount = () => (dispatch) => {
  return dispatch(getProgramCount());
};

export const PROGRAMS_REQUEST = "PROGRAMS_REQUEST";
export const PROGRAMS_SUCCESS = "PROGRAMS_SUCCESS";
export const PROGRAMS_FAILURE = "PROGRAMS_FAILURE";

const fetchPrograms = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [PROGRAMS_REQUEST, PROGRAMS_SUCCESS, PROGRAMS_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.PROGRAM_ARRAY,
  },
  refresh,
});

export const loadPrograms = (lastTimeStamp, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchPrograms(`${ADMIN_URL}/programs/programs/all?limit=${limit}`, refresh));
  }

  if (refresh && lastTimeStamp) {
    return dispatch(
      fetchPrograms(`${ADMIN_URL}/programs/programs/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`, refresh)
    );
  }

  if (lastTimeStamp) {
    return dispatch(fetchPrograms(`${ADMIN_URL}/programs/programs/all?limit=${limit}&lastTimeStamp=${lastTimeStamp}`));
  }

  return dispatch(fetchPrograms(`${ADMIN_URL}/programs/programs/all?limit=${limit}`));
};
