import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip, IconButton, TextField, FormControlLabel, CircularProgress } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import { DatePicker } from "../utils/helpers";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "#2373D8",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
    },
    "& label.MuiFormLabel-root": {
      color: "#2373D8",
    },

    // .MuiFormLabel-root

    "& label.Mui-focused": {
      color: "#2373D8",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2373D8",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2373D8",
      },
    },
  },
})(TextField);

export default function Deliverables(props) {
  const classes = useStyles();
  const [deletingId, setDeletingId] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);

  const addNewDelivery = (id) => {
    try {
      props.setProjectData({
        ...props.projectData,
        [id]: {
          ...props.projectData[id],
          deliverableIds: [...props.projectData[id].deliverableIds, ""],
        },
      });
    } catch (err) {}
  };

  const removeDelivery = (workElementId, deliverableId, index) => {
    try {
      setDeletingId(workElementId);
      setDeletingIndex(index);
      setTimeout(() => {
        let targetWorkElement = props.projectData[workElementId];
        let newWorkElementDeliverables = targetWorkElement.deliverableIds;
        targetWorkElement.deliverableIds = [];
        props.setProjectData({ ...props.projectData, [workElementId]: { ...targetWorkElement } });
        newWorkElementDeliverables.splice(index, 1);
        targetWorkElement.deliverableIds = newWorkElementDeliverables;
        props.setProjectData({ ...props.projectData, [workElementId]: { ...targetWorkElement } });
        setDeletingId(null);
        setDeletingIndex(null);
      }, 500);
    } catch (err) {}
  };

  const updateDeliverables = async (workElementId, deliverableId, index) => {
    let targetWorkElement = props.projectData[workElementId];
    let targetWorkElementDeliverables = targetWorkElement.deliverableIds;
    targetWorkElementDeliverables[index] = deliverableId;
    props.setProjectData({ ...props.projectData, [workElementId]: { ...targetWorkElement } });
  };

  const updateWorkElement = async (workElementId, key, value) => {
    let targetWorkElement = props.projectData[workElementId];
    targetWorkElement[key] = value;
    props.setProjectData({ ...props.projectData, [workElementId]: { ...targetWorkElement } });
  };

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.area}>
      <Box className={classes.titles}>Work Elements</Box>
      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        {Object.keys(props.projectData).map((key, index) => {
          return (
            <div key={index}>
              <Box ml={"10px"} mt={2}>
                <Box fontWeight="bold" style={{ color: "#2373D8", textTransform: "uppercase" }}>
                  {props.workElementsList &&
                    props.workElementsList.find((we) => we.id === key) &&
                    props.workElementsList.find((we) => we.id === key).name}
                </Box>
                <Box mb={1} mt={1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      {props.loading ? (
                        <Skeleton variant="text" animation="wave" height={50} />
                      ) : (
                        <DatePicker
                          label="Element Start Date"
                          shouldDisableDate={(date) => date < props.projectStartDate - 1}
                          value={props.projectData[key].startDate}
                          marginType="dense"
                          onChange={(date) => updateWorkElement(key, "startDate", date)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} style={{ position: "relative" }}>
                      {props.loading ? null : (
                        <Box
                          fontWeight="bold"
                          fontSize="0.6rem"
                          style={{
                            textTransform: "uppercase",
                            position: "absolute",
                            bottom: -5,
                            left: 8,
                            whiteSpace: "nowrap",
                            color: "#2373D8",
                          }}
                        >
                          Duration of Work Element
                        </Box>
                      )}
                      {props.loading ? (
                        <Skeleton variant="text" animation="wave" height={50} />
                      ) : (
                        <CssTextField
                          value={props.projectData[key].duration}
                          onChange={(e) => {
                            updateWorkElement(key, "duration", e.target.value);
                          }}
                          size="small"
                          fullWidth
                          label="Unit"
                          margin="dense"
                          type="number"
                          inputProps={{ min: 0 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                      {props.loading ? (
                        <Skeleton variant="text" animation="wave" height={50} />
                      ) : (
                        <Autocomplete
                          size="small"
                          fullWidth
                          options={["Days", "Weeks", "Months", "Quarters", "Years"]}
                          value={props.projectData[key].durationUnit || ""}
                          getOptionLabel={(option) => option}
                          onChange={(e, newValue) => {
                            updateWorkElement(key, "durationUnit", newValue);
                          }}
                          renderInput={(params) => (
                            <CssTextField
                              {...params}
                              margin="dense"
                              InputLabelProps={{ shrink: true }}
                              label="Interval"
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10} md={8}>
                    {props.projectData[key].deliverableIds.map((id, index) => {
                      return props.loading ? (
                        <Skeleton variant="text" animation="wave" height={50} />
                      ) : (
                        <Box display="flex" alignItems="flex-end" width="100%" mt={2}>
                          <Box mr={2}>
                            {deletingId === key && deletingIndex === index ? (
                              <IconButton
                                disabled={props.projectData[key].deliverableIds.length <= 1}
                                style={{ padding: 3 }}
                              >
                                <i
                                  style={{
                                    color: "rgba(235,107,108,0.3)",
                                    fontSize: "1.3rem",
                                  }}
                                  className={`fal fa-cog fa-spin`}
                                />
                              </IconButton>
                            ) : (
                              <Tooltip
                                title={
                                  props.projectData[key].deliverableIds.length <= 1
                                    ? "Must have a deliverable"
                                    : "Remove"
                                }
                              >
                                <IconButton
                                  disabled={props.projectData[key].deliverableIds.length <= 1}
                                  onClick={() => removeDelivery(key, id, index)}
                                  style={{ padding: 3 }}
                                >
                                  <i
                                    style={{
                                      color:
                                        props.projectData[key].deliverableIds.length <= 1
                                          ? "rgba(235,107,108,0.2)"
                                          : "#EB6B6C",
                                      fontSize: "1.3rem",
                                    }}
                                    className={`fal fa-minus-circle`}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>

                          <Autocomplete
                            size="small"
                            fullWidth
                            options={props.deliverablesList || []}
                            value={props.deliverablesList.find((d) => d.id === id)}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, newValue) => {
                              // if (!props.changesMade) props.setChangesMade(true);
                              updateDeliverables(key, newValue.id, index);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} InputLabelProps={{ shrink: true }} label="Deliverable" />
                            )}
                          />
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
                <Tooltip title="Add Deliverable">
                  <IconButton onClick={() => addNewDelivery(key)} style={{ padding: 3 }}>
                    <i style={{ color: "#0E8C64", fontSize: "1.3rem" }} className={`fal fa-plus-circle`} />
                  </IconButton>
                </Tooltip>
              </Box>
            </div>
          );
        })}
      </Box>
    </Box>
  );
}
