export const validateTimeEntryPayload = (payload, type, value) => {
  if (!payload.hasOwnProperty("hours") || (payload.hasOwnProperty("hours") && payload.hours <= 0)) {
    return true;
  }

  if (
    !payload.hasOwnProperty("userId") ||
    (payload.hasOwnProperty("userId") && (!payload.userId || payload.userId.length === 0))
  ) {
    return true;
  }

  if (type === 30 && value === "internal" && payload.hasOwnProperty("companyProjectId")) {
    return true;
  }

  if (
    (((type === 30 && value === "project") || type !== 30) && !payload.hasOwnProperty("companyProjectId")) ||
    (((type === 30 && value === "project") || type !== 30) &&
      payload.hasOwnProperty("companyProjectId") &&
      (!payload.companyProjectId ||
        payload.companyProjectId.length === 0 ||
        payload.companyProjectId === "CISOSHARE" ||
        payload.companyProjectId === "PROJECT"))
  ) {
    return true;
  }

  return false;
};

export const validateRequestPayload = (payload) => {
  if (payload.hasOwnProperty("companyId") && payload.companyId.length === 0) {
    return true;
  }
  if (payload.hasOwnProperty("projectId") && payload.projectId.length === 0) {
    return true;
  }
  if ((payload.hasOwnProperty("type") && payload.type.length === null) || payload.type.length === 0) {
    return true;
  }
  return false;
};
