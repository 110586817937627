import React, { useState } from "react";
import { Grid, Box, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { prop } from "ramda";

export const DEFAULT_NEW_RESOURCE = {
  user: "",
  role: "",
  company: "",
  companyProject: "",
  companyProjectOptions: [],
  allocation: 0,
  skip: false,
};
export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      backgroundColor: "white",
      borderRadius: 10,
      marginBottom: 10,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionHeader = withStyles({
  root: {
    backgroundColor: "white",
    color: "black",
    borderBottom: "2px solid rgba(0, 0, 0)",
    borderLeft: "none",
    marginBottom: -1,
    fontWeight: "bold",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  textCopy: {
    padding: 2,
    cursor: "copy",
    transition: "0.2s ease-in-out",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
      borderRadius: 5,
      transition: "0.2s ease-in-out",
    },
    "&:active": { background: "rgba(0,0,0,0.2)", transition: "0.2s ease-in-out" },
  },
}));

export function DatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        inputVariant="outlined"
        margin="dense"
        InputAdornmentProps={{ position: "start" }}
        id="date-picker-inline"
        label="Project Start"
        fullWidth
        value={props.value}
        onChange={props.onChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export function MaxText(props) {
  const classes = useStyles();
  const MAX_TEXT = 20;
  if (props.title) {
    return (
      <Box
        style={{
          color: props.color,
          display: "flex",
          alignItems: "center",
          whiteSpace: props.nowrap ? "nowrap" : "normal",
          overflow: "hidden",
          width: "fit-content",
        }}
      >
        {props.title.length >= MAX_TEXT ? (
          <Tooltip
            title={`${props.title} ${props.description ? "-" : ""} ${props.description ? props.description : ""}`}
          >
            <React.Fragment>
              {props.helper}
              <CopyToClipboard
                text={props.title}
                onCopy={() =>
                  props.setSnackbar({
                    isOpen: true,
                    variant: "success",
                    message: "Successfully copied to your clipboard!",
                  })
                }
              >
                <Box
                  className={classes.textCopy}
                  ml={props.helper ? 0.5 : 0}
                  style={{ fontWeight: props.bold ? "bold" : "200", fontSize: props.fontSize }}
                >
                  {props.title.replace(/(.{MAX_TEXT})..+/, "$1…")}
                </Box>
              </CopyToClipboard>
            </React.Fragment>
          </Tooltip>
        ) : (
          <React.Fragment>
            {props.helper}
            <CopyToClipboard
              text={props.title}
              onCopy={() =>
                props.setSnackbar({
                  isOpen: true,
                  variant: "success",
                  message: "Successfully copied to your clipboard!",
                })
              }
            >
              <Tooltip title={`${props.description}`}>
                <Box
                  className={classes.textCopy}
                  ml={props.helper ? 0.5 : 0}
                  style={{ fontWeight: props.bold ? "bold" : "200", fontSize: props.fontSize }}
                >
                  {props.title.replace(/(.{MAX_TEXT})..+/, "$1…")}
                </Box>
              </Tooltip>
            </CopyToClipboard>
          </React.Fragment>
        )}
      </Box>
    );
  }
  return null;
}
