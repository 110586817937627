import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isLoggedIn, logout } from "../../store/actions";
import AuthComponentView from "./AuthComponentView";

const mapStateToProps = (state) => {
  return { user: state.user };
};

const dispatchToProps = (dispatch) => {
  return {
    isLoggedIn: () => dispatch(isLoggedIn()),
    logout: () => dispatch(logout()),
  };
};

const AuthComponent = (props) => <AuthComponentView {...props} />;

export default connect(mapStateToProps, dispatchToProps)(withRouter(AuthComponent));
