import React, { useEffect } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllResponsibilities,
  getResponsibilitiesCount,
  saveResponsibility,
  updateResponsibility,
  deleteResponsibility,
  getTasksList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import { Grid, Box } from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { generateColumns } from "../utils/responsibilitiesColumns";

const Responsibilities = (props) => {
  const columns = generateColumns(props.tasks);

  useEffect(() => {
    props.getTasksList();
  }, []);
  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s", marginTop: "0.5rem" }}>
      <AuthComponent />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getResponsibilitiesCount}
            paginationCall={props.getAllResponsibilities}
            title="Responsibilities"
            entity="responsibilities"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => props.saveResponsibility({ ...rowData })}
            editFunction={(newData, oldData) =>
              props.updateResponsibility(newData.id, {
                ...newData,
                tasks: newData.tasks.map((val) => {
                  if (val.hasOwnProperty("id")) return val.id;
                  else return val;
                }),
              })
            }
            deleteFunction={(rowData) => props.deleteResponsibility(rowData.id)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.entities.roles,
    pageInfo: state.pagination.roles,
    tasks:
      state.entities.lists && state.entities.lists.tasks && state.entities.lists.tasks.list
        ? state.entities.lists.tasks.list.reduce((acc, task) => {
            let { id } = task;
            return { ...acc, [id]: task.name };
          }, {})
        : {},
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getAllResponsibilities: (page, limit, refresh) => dispatch(getAllResponsibilities(page, limit, refresh)),
    getResponsibilitiesCount: () => dispatch(getResponsibilitiesCount()),
    saveResponsibility: (data) => dispatch(saveResponsibility(data)),
    updateResponsibility: (id, data) => dispatch(updateResponsibility(id, data)),
    deleteResponsibility: (id) => dispatch(deleteResponsibility(id)),
    getTasksList: () => dispatch(getTasksList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Responsibilities));
