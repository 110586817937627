import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  icon: {
    fontSize: 20,
    color: "black",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    color: "black",
    "&:hover": {
      backgroundColor: "rgba(1,22,39,.7)",
    },
    "&:hover div, &:hover a": {
      color: "white",
    },
  },
  navLink: {
    color: "black",
    textDecoration: "none",
    "&:link, &:visited, &:hover, &:active": {
      textDecoration: "none",
    },
    "&.active div[role='button']": {
      backgroundColor: "rgba(1,22,39,1.0)",
      color: "white",
    },
    "&.active i": {
      color: "white",
    },
  },
});

export default useStyles;
