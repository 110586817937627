import { constants } from "../../../utils/common";

export const defaultDemographics = {
  publiclyTraded: false,
  multiCountry: false,
  creditCard: false,
  bestPractice: false,
  dataTypes: [],
  orgImpacts: [],
  primaryIndustryId: null,
  objectives: null,
  targetBestPractice: null,
  countryIds: null,
  stateIds: null,
  industryIds: null,
};

export const defaultDemographicsErrors = {
  dataTypes: false,
  orgImpacts: false,
  primaryIndustryId: false,
  objectives: false,
  countryIds: false,
  stateIds: false,
};
