import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";

export const generateColumns = (tasks) => {
  return [
    { title: "Name", field: "name" },
    {
      title: "Tasks",
      field: "tasks",
      lookup: tasks,
      render: (rowData) => <ul>{rowData.tasks && rowData.tasks.map((task) => <li>{task.name}</li>)}</ul>,
      editComponent: (props) => (
        <FormControl style={{ width: "100%" }}>
          <InputLabel shrink>Tasks</InputLabel>
          <Select
            id="Prefix"
            value={
              (props.value &&
                props.value.map((val) => {
                  if (val.hasOwnProperty("id")) return val.id;
                  else return val;
                })) ||
              []
            }
            renderValue={(selected) =>
              Object.keys(tasks)
                .map((task) => (selected.includes(task) ? tasks[task] : null))
                .filter(Boolean)
                .join(", ") || ""
            }
            onChange={(e) => props.onChange(e.target.value)}
            multiple
          >
            {Object.keys(tasks).map((task) => {
              return (
                <MenuItem key={task} value={task}>
                  <Checkbox
                    checked={props.value && (props.value.includes(task) || props.value.some((val) => val.id === task))}
                  />
                  <ListItemText primary={tasks[task]} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ),
    },
  ];
};
