import React, { useEffect } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadRoles,
  fetchRolesCount,
  saveRole,
  changeRoleById,
  removeRoleById,
  getResponsibilityList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import { Grid, Box } from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { generateColumns } from "../utils/rolesColumns";

const Roles = (props) => {
  const columns = generateColumns(props.responsibilities);
  useEffect(() => {
    props.getResponsibilityList();
  }, []);

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s", marginTop: "0.5rem" }}>
      <AuthComponent />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchRolesCount}
            paginationCall={props.loadRoles}
            title="Roles"
            entity="roles"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) =>
              props.saveRole({
                name: rowData.name || "",
                description: rowData.description || "",
                icon: rowData.icon || "",
                responsibilities: rowData.responsibilities || [],
              })
            }
            editFunction={(newData, oldData) =>
              props.changeRoleById(newData.id, {
                name: newData.name || "",
                description: newData.description || "",
                icon: newData.icon || "",
                responsibilities: newData.responsibilities.map((val) => {
                  if (val.hasOwnProperty("id")) return val.id;
                  else return val;
                }),
              })
            }
            deleteFunction={(rowData) => props.removeRoleById(rowData.id)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.entities.roles,
    pageInfo: state.pagination.roles,
    responsibilities:
      state.entities.lists && state.entities.lists.responsibilities && state.entities.lists.responsibilities.list
        ? state.entities.lists.responsibilities.list.reduce((acc, responsibility) => {
            let { id } = responsibility;
            return { ...acc, [id]: responsibility.name };
          }, {})
        : {},
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadRoles: (page, limit, refresh) => dispatch(loadRoles(page, limit, refresh)),
    fetchRolesCount: () => dispatch(fetchRolesCount()),
    saveRole: (data) => dispatch(saveRole(data)),
    changeRoleById: (id, data) => dispatch(changeRoleById(id, data)),
    removeRoleById: (id) => dispatch(removeRoleById(id)),
    getResponsibilityList: () => dispatch(getResponsibilityList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Roles));
