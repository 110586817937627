import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent";
import MaterialTable from "../../../components/MaterialTable";
import Snackbar from "../../../components/Snackbar";
import {
  loadCompanies,
  fetchCompanyCount,
  saveUser,
  updateUser,
  deleteUser,
  changeUserPassword,
} from "../../../store/actions";
import { constants, generateSafePayload } from "../../../utils/common";

const columns = [
  { title: "First Name", field: "firstName" },
  { title: "Last Name", field: "lastName" },
  { title: "Email", field: "email" },
  {
    title: "Account Confirmed",
    field: "isEmailConfirmed",
    type: "boolean",
    render: (row) => <div>{row.isEmailConfirmed ? "Yes" : "No"}</div>,
  },
];

const USER_INTERACTION_TYPES = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

function Users(props) {
  const [refresh, setRefresh] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", variant: "info" });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers([...props.data]);
  }, [props.data]);

  const generatePayload = (row, type) => {
    switch (type) {
      case USER_INTERACTION_TYPES.CREATE:
        return generateSafePayload({
          ...row,
          password: "Ciso!2345",
          role: constants.USER_ROLES.EMPLOYEE,
          companyId: props.companyId,
        });
      case USER_INTERACTION_TYPES.UPDATE:
        return generateSafePayload({ ...row, companyId: props.companyId });
      default:
        break;
    }
  };

  const createUser = async (row) => {
    try {
      const payload = generatePayload(row, USER_INTERACTION_TYPES.CREATE);
      const result = await props.saveUser(constants.EMAIL_TEMPLATES.INVITE_USER, payload);
      setRefresh(true);
      if (result.type.includes("SUCCESS")) await props.loadCompany(props.companyId);
      setRefresh(false);
      return result;
    } catch (err) {}
  };

  const changeUser = async (row) => {
    try {
      const payload = generatePayload(row, USER_INTERACTION_TYPES.UPDATE);
      const result = await props.updateUser(row.id, payload);
      if (result.type.includes("SUCCESS")) await props.loadCompany(props.companyId);
      setRefresh(true);
      return result;
    } catch (err) {}
  };

  const removeUser = async (row) => {
    try {
      const result = await props.deleteUser(row.id);
      if (result.type.includes("SUCCESS")) await props.loadCompany(props.companyId);
      return result;
    } catch (err) {}
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            title=""
            data={users}
            columns={columns}
            addable
            editable
            deletable
            refresh={refresh}
            addFunction={(newData) => createUser(newData)}
            editFunction={(newData) => changeUser(newData)}
            deleteFunction={(newData) => removeUser(newData)}
            options={{ search: true, paging: true }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companies: state.entities.companies,
    pageInfo: state.pagination.companies,
    theme: state.user && state.user.internalStore ? state.user.internalStore.theme : "light",
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadCompanies: (lastTimeStamp, limit, refresh) => dispatch(loadCompanies(lastTimeStamp, limit, refresh)),
    fetchCompanyCount: () => dispatch(fetchCompanyCount()),
    changeUserPassword: (userId, payload) => dispatch(changeUserPassword(userId, payload)),
    saveUser: (template, payload) => dispatch(saveUser(template, payload)),
    updateUser: (id, payload) => dispatch(updateUser(id, payload)),
    deleteUser: (id, payload) => dispatch(deleteUser(id, payload)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Users));
