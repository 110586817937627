import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { DEFAULT_NEW_RESOURCE } from "../utils/helpers";

function AutoComplete(props) {
  return (
    <Box mr={1} ml={1}>
      <Autocomplete
        disabled={props.skip}
        size="small"
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        onChange={(e, newValue) => props.updateResource(props.objKey, props.id, newValue)}
        renderInput={(params) => (
          <TextField {...params} style={{ width: 200, borderRadius: 50 }} variant="outlined" label={props.label} />
        )}
      />
    </Box>
  );
}

export default function AddNewResource(props) {
  const [loading, setLoading] = useState(false);
  const [newResources, setNewResources] = useState([
    { id: Math.random().toString(36).substring(7), ...DEFAULT_NEW_RESOURCE },
  ]);
  const [snackbar, setSnackbar] = useState({ isOpen: false, variant: "info", message: "" });

  const handleClose = () => {
    props.setOpen(false);
    setNewResources([{ id: Math.random().toString(36).substring(7), ...DEFAULT_NEW_RESOURCE }]);
  };

  const updateResource = async (key, id, value) => {
    if (key === "company") {
      const result = await props.getCompanyProjects(value.id);
      const companyProjects =
        (Object.values(result.response.entities).length > 0 &&
          Object.values(result.response.entities.companyProjects)) ||
        [];
      let currentResources = newResources;
      currentResources.find((cr) => cr.id === id)[key] = value;
      currentResources.find((cr) => cr.id === id).companyProjectOptions = companyProjects;
      return setNewResources([...currentResources]);
    }
    let currentResources = newResources;
    currentResources.find((cr) => cr.id === id)[key] = value;
    return setNewResources([...currentResources]);
  };

  const addNewResource = () => {
    try {
      setNewResources([...newResources, { id: Math.random().toString(36).substring(7), ...DEFAULT_NEW_RESOURCE }]);
    } catch (err) {}
  };

  const postNewResources = async () => {
    setLoading(true);
    const filteredNewResources = newResources.filter((nr) => !nr.skip);
    const isError = !filteredNewResources.every(
      (resource) => resource.user && resource.user.id && resource.role && resource.role.id && resource.allocation > 0
    );
    if (isError) {
      return setSnackbar({ isOpen: true, variant: "error", message: "Complete all fields before submitting" });
    }
    for await (const resource of filteredNewResources) {
      const payload = {
        userId: resource.user.id,
        roleId: resource.role.id,
        allocation: resource.allocation,
      };
      const result = await props.attachUserToCompanyProject(resource.companyProject.id, payload);
    }
    setLoading(false);
    handleClose();
    props.fetchData();
  };

  return (
    <React.Fragment>
      <Snackbar {...snackbar} handleClose={() => setSnackbar({ ...snackbar, isOpen: false })} />
      <Dialog
        style={{
          background: "rgba(255,255,255, .9)",
        }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle style={{ background: "#011627", color: "white" }}>NEW ROLE ENTRY</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" mb={2}>
            {newResources.map((resource, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                  p={1}
                  style={{ backgroundColor: resource.skip ? "lightgrey" : "white" }}
                >
                  {!resource.skip ? (
                    <Tooltip title="Remove Row">
                      <IconButton onClick={() => updateResource("skip", resource.id, !resource.skip)}>
                        <i style={{ color: "red", fontSize: "1.3rem" }} className={`fal fa-trash`} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add Row Back">
                      <IconButton onClick={() => updateResource("skip", resource.id, !resource.skip)}>
                        <i style={{ color: "blue", fontSize: "1.3rem" }} className={`fal fa-undo`} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <AutoComplete
                    options={props.companies}
                    skip={resource.skip}
                    getOptionLabel={(option) => option.name}
                    updateResource={updateResource}
                    id={resource.id}
                    objKey="company"
                    label="Organization"
                  />
                  <AutoComplete
                    skip={resource.skip}
                    options={resource.companyProjectOptions}
                    getOptionLabel={(option) => option.name}
                    updateResource={updateResource}
                    id={resource.id}
                    objKey="companyProject"
                    label="Project/Task"
                  />
                  <AutoComplete
                    skip={resource.skip}
                    options={props.resources}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    updateResource={updateResource}
                    id={resource.id}
                    objKey="user"
                    label="Resource"
                  />
                  <AutoComplete
                    options={props.roles}
                    skip={resource.skip}
                    getOptionLabel={(option) => option.name}
                    updateResource={updateResource}
                    id={resource.id}
                    objKey="role"
                    label="Role"
                  />
                  <Box mr={1} ml={1}>
                    <TextField
                      label="Allocation"
                      type="number"
                      size="small"
                      variant="outlined"
                      style={{ width: 100 }}
                      disabled={resource.skip}
                      value={resource.allocation}
                      onChange={(e) => updateResource("allocation", resource.id, parseInt(e.target.value))}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box ml={"6px"}>
            <Tooltip title="Add New Row">
              <IconButton onClick={() => addNewResource()}>
                <i style={{ color: "black", fontSize: "1.3rem" }} className={`fal fa-plus-circle`} />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions style={{ background: "#011627", color: "white" }}>
          <Button onClick={handleClose} style={{ color: "white", fontWeight: "bold" }}>
            Cancel
          </Button>
          <Button onClick={postNewResources} style={{ color: "white", fontWeight: "bold" }}>
            {loading ? <i class="fas fa-cog fa-spin" /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
