import React from "react";
import { TextField, MenuItem, Select } from "@material-ui/core";
import { default as ReactSelection } from "react-select";
// import { FixedSizeList as List } from "react-window";

export function MultiLineEdit(props) {
  return (
    <TextField
      id={`correctedComments-${props.rowData.questionOrder}`}
      value={props.value}
      fullWidth={true}
      multiline={true}
      type={props.number ? "number" : "string"}
      onChange={(e) =>
        !props.number ? props.onChange(e.target.value) : props.onChange(e.target.value.replace(/\D/g, ""))
      }
    />
  );
}

export function Selection(props) {
  const convertMultiple = (value) => {
    if (props.target === null) {
      return value && value.length > 0 && Array.isArray(value)
        ? value.map((val) => val)
        : value && !Array.isArray(value)
        ? value.split(",")
        : [];
    }
    return value && value.length > 0 && Array.isArray(value) && value.some((val) => val.hasOwnProperty(props.target))
      ? value.map((val) => val[props.target].id)
      : !Array.isArray(value) && value
      ? value.split(",")
      : [];
  };

  const handleMultiple = (value) => value && value.join(",");

  const convertSingle = (value) => {
    return value && value.hasOwnProperty(props.target)
      ? value[props.target].id
      : value && value.hasOwnProperty("id") && !value.hasOwnProperty(props.target)
      ? value.id
      : value;
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={props.multiple ? convertMultiple(props.value) : convertSingle(props.value)}
      multiple={props.multiple}
      onChange={(e) =>
        props.multiple ? props.onChange(handleMultiple(e.target.value)) : props.onChange(e.target.value)
      }
    >
      {!props.multiple && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}
      {props.options &&
        props.options.map((option, index) => (
          <MenuItem key={index} style={{ whiteSpace: "normal" }} value={option.value || option.id}>
            {option.label || option.name}
          </MenuItem>
        ))}
    </Select>
  );
}
