import React from "react";
import { Grid } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getClasses, fetchClassesCount, createClass, updateClass, deleteClass } from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import moment from "moment";
import { prop } from "ramda";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Date Created",
    field: "createdAt",
    render: (row) => (row && row.createdAt ? moment(row.createdAt).format("MMMM Do YYYY, h:mm A") : null),
    editable: "never",
    defaultSort: "desc",
  },
];

function Classes(props) {
  const postClass = async (rowData) => {
    try {
      const response = await props.createClass({ ...rowData });
      return response;
    } catch (err) {}
  };

  const changeClass = async (rowData, oldData) => {
    try {
      const payload = { ...rowData };
      const response = await props.updateClass(rowData.id, { ...payload });
      return response;
    } catch (err) {}
  };

  const removeClass = async (rowData) => {
    const response = await props.deleteClass(rowData.id);
    return response;
  };

  return (
    <Grid container spacing={2}>
      <AuthComponent />
      <Grid item xs={12}>
        <MaterialTable
          backendPagination
          totalCount={0}
          fetchCount={props.fetchClassesCount}
          paginationCall={props.getClasses}
          title="Classes"
          entity="classes"
          addable={props.create}
          addFunction={(rowData) => postClass(rowData)}
          editable={props.update}
          editFunction={(newData, oldData) => changeClass(newData, oldData)}
          deletable={props.delete}
          deleteFunction={(rowData) => removeClass(rowData)}
          columns={columns}
          options={{
            search: true,
            paging: true,
            pageSize: constants.DEFAULT_PAGE_LIMIT,
          }}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: state.entities.classes,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getClasses: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getClasses(page, limit, refresh, searchTerm, sort, by)),
    fetchClassesCount: (searchTerm) => dispatch(fetchClassesCount(searchTerm)),
    createClass: (payload) => dispatch(createClass(payload)),
    updateClass: (id, payload) => dispatch(updateClass(id, payload)),
    deleteClass: (id) => dispatch(deleteClass(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Classes));
