import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Grid,
  Box,
  Checkbox,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Button,
  Popover,
  Tooltip,
  Badge,
} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import BaseButton from "../../../components/BaseButton/BaseButton";
import { useStyles } from "../styles/ProjectAndTasks.styles";
import FrameTool from "./FrameTool";
import Warning from "../../../components/Dialog/WarningDialog";
import { SliderPicker } from "react-color-hook";
import TimeEntryHeader from "../components/TimeEntryHeader";
import TimeEntryItem from "../components/TimeEntryItem";
import WorkElementItem from "./WorkElementItem";
import hexOpacity from "hex-opacity";

const AccordionSummaryC = withStyles({
  content: {
    overflow: "hidden",
  },
})(MuiAccordionSummary);

export default function ProjectItem(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorEl, setColorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeEntries, setTimeEntries] = useState(props.project.timeEntries);
  const [tempTimeEntry, setTempTimeEntry] = useState({});
  const [boxWidth, setBoxWidth] = useState("");
  const [innerBox, setInnerBox] = useState("");
  const [calculateTimeLoad, setCalculateTimeLoad] = useState(false);
  const [deliverablesOpen, setDeliverablesOpen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [loadingText, setLoadingText] = useState(false);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const size = useWindowSize();

  useEffect(() => {}, [boxWidth, innerBox]);

  useEffect(() => {
    boxID();
    innerBoxId();
  }, [size[0]]);

  function boxID() {
    const elmnt = document.getElementById(`${props.project.id}-Outer`);
    setBoxWidth(elmnt.offsetWidth);
  }

  function innerBoxId() {
    const elmnt = document.getElementById(`${props.project.id}-Inner`);
    setInnerBox(elmnt.offsetWidth);
  }

  useEffect(() => {
    boxID();
  }, [boxWidth]);

  useEffect(() => {
    innerBoxId();
  }, [innerBox]);

  const open = Boolean(colorEl);

  const hoursDone = props.project && props.project.hoursUsed;
  const hoursAllocated = props.project && props.project.allocation;

  const overAllocated = hoursDone - hoursAllocated;
  const underAllocated = hoursAllocated - hoursDone;

  const allocationPercentage = (hoursDone / hoursAllocated) * 100;

  useEffect(() => {
    setTimeEntries(props.project.timeEntries);
  }, [props.project]);

  const clicker = (event) => {
    event.stopPropagation();
    props.setAddTime(true);
    props.setCompanyProjectId(
      props.project.companyProjectId.length === 0 ? "INTERNAL" : props.project.companyProjectId
    );
  };

  const deleteTimeEntry = async () => {
    setLoading(true);
    let tempTimeEntries = timeEntries;
    let targetTimeEntry = tempTimeEntries.find((te) => te.id === props.timeEntryId);
    targetTimeEntry.deleted = true;
    setAnchorEl(null);
    const result = await props.deleteTimeEntry(props.timeEntryId);
    setTimeEntries([...tempTimeEntries]);
    props.setTimeEntryId("");
    setLoading(false);
    return result;
  };

  const changeTimeEntry = async () => {
    try {
      setLoading(true);
      const payload = {
        hours: tempTimeEntry.hours,
        description: tempTimeEntry.description,
      };
      const result = await props.updateTimeEntry(props.timeEntryId, payload);
      props.setTimeEntryId("");
      props.setRefresh(true);
      setLoading(false);
      return result;
    } catch (err) {}
  };

  const handleColorChange = async (color, event) => {
    await props.updateUserCompanyProject(props.project.id, { color: color.hex });
    props.setRefresh(true);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setColorEl(event.currentTarget);
  };

  const handleClose = () => {
    setColorEl(null);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  const SpecialBox = withStyles({
    root: {
      position: "absolute",
      paddingLeft: 3,
      paddingRight: 8,
      whiteSpace: "nowrap",
      transform: "translateX(0)",
      transition: "0.5s",
      "&:hover": {
        transform: innerBox > boxWidth ? `translateX(calc(${boxWidth}px - 100%))` : `none`,
      },
    },
  })(Box);

  return (
    <Box display="flex" width="100%" className={classes.mainTable} style={{ background: "white" }}>
      <Popover
        open={open}
        anchorEl={colorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box alignItems="center" justifyContent="center" p={1} style={{ width: 200 }}>
          <SliderPicker color={props.project && props.project.color} onChangeComplete={handleColorChange} />
        </Box>
      </Popover>
      <Warning
        delete
        loading={loading}
        anchorEl={anchorEl}
        text={`Are you sure you want to delete this time entry?`}
        confirm={() => deleteTimeEntry()}
        close={() => {
          setAnchorEl(null);
          props.setTimeEntryId("");
        }}
      />
      <div
        className={`${classes.root}`}
        style={{
          paddingTop: openAccordion && 5,
          paddingBottom: openAccordion && 5,
          marginTop: openAccordion && 5,
          marginBottom: openAccordion && 5,
          borderRadius: 10,
          // borderBottom: openAccordion && "1px solid black",
          // borderTop: openAccordion && "1px solid black",
          backgroundColor: openAccordion && hexOpacity.create(props.project && props.project.color, 0.8),
        }}
      >
        <Accordion
          // disabled={(props.project && props.project.requested) || props.project.archived}
          onClick={(event) => {
            event.stopPropagation();
          }}
          elevation={0}
          expanded={openAccordion}
          onChange={() => setOpenAccordion(!openAccordion)}
          square
          style={{
            background:
              (props.project && props.project.requested) || props.project.archived
                ? "rgba(0,0,0,.1)"
                : `linear-gradient(131deg,${hexOpacity.create(
                    props.project && props.project.color,
                    0.1
                  )} 0%, ${hexOpacity.create(props.project && props.project.color, 0.05)} 50%, #fff 100%`,
          }}
        >
          <AccordionSummaryC
            expandIcon={<i className="fas fa-chevron-down" style={{ color: "black" }} />}
            onClick={(event) => event.stopPropagation()}
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Box display="flex" alignItems="center" ml={1}>
              <Tooltip
                title={
                  overAllocated > 0
                    ? `${(allocationPercentage - 100).toFixed(2)}% over allocated`
                    : hoursAllocated === "∞"
                    ? "No allocation"
                    : `${underAllocated} hours left for this project`
                }
              >
                <Box
                  onClick={(e) => (props.project.companyName !== "CISOSHARE" ? handleClick(e) : {})}
                  height="25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={2}
                  mt={1}
                  mb={1}
                  pr={1}
                  pl={1}
                  style={{
                    color: "white",
                    width: 80,
                    borderRadius: 50,
                    fontWeight: "200",
                    background: `linear-gradient(131deg, ${props.project && props.project.color} 40%, #021627 100%)`,
                    boxShadow: `0px 0px 7px 0px ${props.project && props.project.color}`,
                  }}
                >
                  {calculateTimeLoad ? (
                    <i className="fas fa-hourglass-start fa-spin" />
                  ) : hoursAllocated === "∞" ? (
                    <span style={{ fontWeight: "bold", marginRight: 2 }}>{hoursDone}</span>
                  ) : overAllocated > 0 ? (
                    <Badge variant="dot" classes={{ badge: classes.customBadge }}>
                      <span style={{ fontWeight: "bold", marginRight: 2 }}>{hoursDone}</span>
                      {` / ${hoursAllocated}`}
                    </Badge>
                  ) : (
                    <React.Fragment>
                      <span style={{ fontWeight: "bold", marginRight: 2 }}>{hoursDone}</span>
                      {` / ${hoursAllocated}`}
                    </React.Fragment>
                  )}
                </Box>
              </Tooltip>
            </Box>
            <Box
              style={{
                fontSize: "1rem",
                width: "100%",
                overflow: "hidden",
                margin: "auto",
              }}
              id={`${props.project.id}-Outer`}
            >
              <Box className={classes.lineItemBox} style={{ color: openAccordion ? "#fff" : "#000" }}>
                <SpecialBox id={`${props.project.id}-Inner`}>
                  <span style={{ fontWeight: "bold" }}>
                    {loadingText ? (
                      <Box display="flex" alignItems="center">
                        <i className="fas fa-cog fa-spin" style={{ marginRight: 3 }} />
                        Loading...
                      </Box>
                    ) : (
                      props.project.companyName
                    )}
                  </span>{" "}
                  | {props.name} {props.type !== "Internal" && `(${props.type})`}
                  {props.project &&
                    props.project.deliverables &&
                    props.project.deliverables.length > 0 &&
                    ((props.project && props.project.requested) || props.project.archived ? (
                      <span style={{ fontWeight: "bold", fontSize: "0.7rem", marginLeft: 15 }}>(Completed)</span>
                    ) : (
                      <span style={{ fontWeight: "bold", fontSize: "0.7rem", marginLeft: 15 }}>{`${formatDate(
                        new Date(props.project.startDate).toDateString()
                      )} - ${formatDate(new Date(props.project.endDate).toDateString())}`}</span>
                    ))}
                </SpecialBox>
              </Box>
            </Box>
          </AccordionSummaryC>
          <Box style={{ backgroundColor: "white", bottomBorder: "2px solid black" }}>
            <AccordionDetails
              style={{
                backgroundColor: "#fff",
                border: "2px solid rgba(0,0,0,.1)",
                display: "flex",
                flexDirection: "column",
                borderRadius: 0,
                padding: 0,
                maxHeight: 300,
                overflow: "auto",
              }}
            >
              {props.project.description && props.project.description.length > 0 ? (
                <Box display="flex" pb={2} pt={1} flexDirection="column">
                  <Box style={{ fontWeight: "bold" }}>Project Description:</Box>
                  <Box>{props.project.description}</Box>
                </Box>
              ) : null}
              <Box display="flex" flexDirection="column" width="100%">
                {props.project && props.project.workElements && props.project.workElements.length > 0 ? (
                  props.project.workElements.map((workElement, index) => {
                    return (
                      <WorkElementItem
                      setStartRefresh={props.setStartRefresh}
                        loadingText={loadingText}
                        setLoadingText={setLoadingText}
                        number={index}
                        color={props.project && props.project.color}
                        setCalculateTimeLoad={setCalculateTimeLoad}
                        key={index}
                        oldProject={(props.project && props.project.requested) || props.project.archived}
                        workElement={workElement}
                        getWorkElementTasks={props.getWorkElementTasks}
                        createTimeEntryTask={props.createTimeEntryTask}
                        timeEntries={props.project.timeEntries}
                        deleteTimeEntry={props.deleteTimeEntry}
                        updateTimeEntry={props.updateTimeEntry}
                        companyProjectId={props.project.id}
                        preFillAddTimeDialog={props.preFillAddTimeDialog}
                        setRefresh={props.setRefresh}
                        refresh={props.refresh}
                      />
                    );
                  })
                ) : (
                  <React.Fragment>
                    <TimeEntryHeader internal />
                    {props.project.timeEntries.map((item, index) => {
                      return (
                        <TimeEntryItem
                          key={item.id}
                          item={item}
                          index={index}
                          {...props}
                          internal
                          loadingText={loadingText}
                          setLoadingText={setLoadingText}
                        />
                      );
                    })}
                  </React.Fragment>
                )}
              </Box>

              {props.project && props.project.deliverables && props.project.deliverables.length > 0 && (
                <Button
                  style={{
                    marginTop: 5,
                    marginLeft: 15,
                    width: 130,
                    height: 20,
                    padding: 5,
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    background: "rgba(0,0,0,.05)",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeliverablesOpen(!deliverablesOpen);
                  }}
                >
                  {deliverablesOpen ? "Hide Deliverables" : "Show Deliverables"}
                </Button>
              )}
              <Box m={1} fontWeight="bold" display="flex" alignItems="center" flexWrap="wrap">
                {props.project && props.project.deliverables && (
                  <React.Fragment>
                    {deliverablesOpen &&
                      props.project.deliverables.map((item, index) => (
                        <Tooltip title={item.description || ""}>
                          <Box
                            key={index}
                            style={{
                              fontSize: "0.7rem",
                              margin: 5,
                              padding: "3px 5px",
                              borderRadius: 50,
                              whiteSpace: "nowrap",
                              cursor: "default",
                              backgroundColor: "rgba(35,115,216,.1)",
                            }}
                          >
                            {item.name}
                          </Box>
                        </Tooltip>
                      ))}
                  </React.Fragment>
                )}
              </Box>
            </AccordionDetails>
          </Box>
        </Accordion>
      </div>
    </Box>
  );
}
