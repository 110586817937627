import React from "react";
import DemographicsView from "./DemographicsView";
import ErrorBoundary from "../../components/ErrorPages/ErrorBoundary";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  postCompanyFrameworkDesign,
  loadStatesList,
  loadCountriesList,
  loadIndustriesList,
  loadDataTypesList,
  loadOrgImpactsList,
  loadCompany,
} from "../../store/actions";
import {
  countriesListSelector,
  statesListSelector,
  industriesListSelector,
  dataTypesListSelector,
  orgImpactsListSelector,
  demographicsTakenFlag,
} from "./DemographicsSelector";

const mapStateToProps = (state) => {
  return {
    countries: countriesListSelector(state),
    states: statesListSelector(state),
    industries: industriesListSelector(state),
    dataTypes: dataTypesListSelector(state),
    orgImpacts: orgImpactsListSelector(state),
    demographicsTakenFlag: demographicsTakenFlag(state),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    postCompanyFrameworkDesign: (payload) => dispatch(postCompanyFrameworkDesign(payload)),
    loadStatesList: () => dispatch(loadStatesList()),
    loadCountriesList: () => dispatch(loadCountriesList()),
    loadIndustriesList: () => dispatch(loadIndustriesList()),
    loadDataTypesList: () => dispatch(loadDataTypesList()),
    loadOrgImpactsList: () => dispatch(loadOrgImpactsList()),
    loadCompany: (id) => dispatch(loadCompany(id)),
  };
};

const DemographicsContainer = (props) => (
  <ErrorBoundary history={props.history}>
    <DemographicsView {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, dispatchToProps)(withRouter(DemographicsContainer));
