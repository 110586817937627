import React from "react";
import DataTypes from "./views/DataTypes";
import Industries from "./views/Industries";
import OrgImpacts from "./views/OrgImpacts";
import Countries from "./views/Countries";
import States from "./views/States";
import Objectives from "./views/Objectives";
import WorkElements from "./views/WorkElements";
import Deliverables from "./views/Deliverables";
import Frame from "../../components/Frame/Frame";
import ProjectTypes from "./views/ProjectTypes";
import TimeEntryTasks from "./views/TimeEntryTasks";

const MetadataRouter = (props) => {
  const [value, setValue] = React.useState(0);
  const tabs = [
    "Project Types",
    "Work Elements",
    "Deliverables",
    "Time Entry Tasks",
    // "Data Types",
    // "Organization Impacts",
    "Industries",
    "Countries",
    "States",
    // "Objectives",
  ];

  const tabsView = () => {
    switch (value) {
      case 0:
        return <ProjectTypes {...props} />;
      case 1:
        return <WorkElements {...props} />;
      case 2:
        return <Deliverables {...props} />;
      case 3:
        return <TimeEntryTasks {...props} />;
      // case 4:
      //   return <DataTypes {...props} />;
      // case 5:
      //   return <OrgImpacts {...props} />;
      case 4:
        return <Industries {...props} />;
      case 5:
        return <Countries {...props} />;
      case 6:
        return <States {...props} />;
      // case 9:
      //   return <Objectives {...props} />;
    }
  };

  return (
    <React.Fragment>
      <Frame tabs={tabs} value={value} setValue={setValue} goBack={() => props.history.goBack()}>
        {tabsView()}
      </Frame>
    </React.Fragment>
  );
};

export default MetadataRouter;
