import { makeStyles, fade } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    height: "90vh",
    borderRadius: 20,
    // boxShadow: "1px 1px 10px 10px rgba(1,22,39,.1)",
    background: "white",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "40%",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  circle: {
    color: "#fff",
    cursor: "pointer",
    width: theme.spacing(7),
    height: theme.spacing(7),
    transition: "0.4s ease-in-out",
    "&:hover": {
      boxShadow: "1px 1px 10px 10px rgba(1,22,39,.1)",
      transition: "0.4s ease-in-out",
    },
  },
}));
