import { createMuiTheme } from "@material-ui/core";
import { colors as COLORS, colors } from "./colors";
import { settings } from "./settings";

export const lightTheme = createMuiTheme({
  root: {
    borderRadius: settings.radius,
    // border: `${"1px solid"} ${colors.GRAY}`,
    color: COLORS.BLACK,
    background: "transparent",
    transition: settings.transition,
    fontFamily: "Lato",
    typography: {
      fontFamily: "Lato",
    },
  },
  mainBackground: {
    backgroundColor: colors.WHITE,
  },
  palette: {
    primary: { main: colors.SAVBLUE },
    secondary: { main: colors.GREEN },
  },
  typography: {
    fontFamily: "Lato",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        borderRadius: "12px",
      },
    },
    MuiPaper: {
      root: {
        fontFamily: "Lato",
        color: "black",
      },
      rounded: {
        borderRadius: 15,
      },
      elevation2: {
        // boxShadow: "3px 3px 10px 7px rgba(1,22,39,.2)",
        boxShadow: "none",
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "black",
      },
    },
    MuiDialogContent: {
      root: {
        color: "black",
      },
    },
  },
  dialogHeader: {
    backgroundColor: colors.DARKHEADER,
  },
  radius: settings.radius,
  shadow: settings.shadow,
  logo: settings.logo,
  background: colors.WHITE,
  center: "0 auto",
  colors: COLORS,
  transition: settings.transition,
});
