import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import ProjectRequestsHeader from "../components/ProjectRequestsHeader";
import { useStyles } from "../styles/CustomTable.styles";

export default function ProjectRequests(props) {
  const classes = useStyles();
  const [requests, setRequests] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (!!refresh) {
      fetchRequests();
    }
  }, [refresh]);

  const fetchRequests = async () => {
    const result = await props.getRequests("all", "PROJECT");
    await props.getRequestsCount("all", "PROJECT");
    const dbData = (result.response.entities && result.response.entities.requests) || [];
    const requestData = Object.values(dbData);
    setRequests(requestData);
    setRefresh(false);
  };

  const approveRequest = async (requestId) => {
    const result = await props.updateRequest(requestId, { approved: true });
    setRefresh(true);
    return result;
  };

  const denyRequest = async (requestId) => {
    const result = await props.removeRequest(requestId);
    setRefresh(true);
    return result;
  };

  return (
    <Box className={classes.scrollBox}>
      <ProjectRequestsHeader />
      {requests.map((request, index) => {
        return (
          <Grid
            key={request.id}
            container
            alignItems="center"
            justify="center"
            style={{ backgroundColor: index % 2 === 0 ? "white" : "lightgray" }}
          >
            <Grid item xs={2}>
              <Box display="flex">
                <Tooltip title="Approve Request">
                  <IconButton onClick={() => approveRequest(request.id)}>
                    <i style={{ color: "#0E8C64" }} className="fal fa-check-circle" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deny Request">
                  <IconButton onClick={() => denyRequest(request.id)}>
                    <i style={{ color: "#EB6B6C" }} className="fal fa-times-circle" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>{`${request.user.firstName} ${request.user.lastName}`}</Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                {request.companyProject && request.companyProject.company && request.companyProject.company.name}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>{request.companyProject && request.companyProject.name}</Box>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
