import React from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllPermissions,
  getPermissionsCount,
  savePermission,
  updatePermission,
  deletePermission,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import { Grid, Box } from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";

const columns = [
  { title: "Name", field: "name" },
  { title: "Primary (Account Owner)", field: "primary", type: "boolean" },
];

const Permissions = (props) => {
  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s", marginTop: "0.5rem" }}>
      <AuthComponent />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getPermissionsCount}
            paginationCall={props.getAllPermissions}
            title="Permissions"
            entity="permissions"
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => props.savePermission({ ...rowData })}
            editFunction={(newData, oldData) => props.updatePermission(newData.id, { ...newData })}
            deleteFunction={(rowData) => props.deletePermission(rowData.id)}
            options={{ search: true, paging: true, pageSize: constants.DEFAULT_PAGE_LIMIT }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.entities.roles,
    pageInfo: state.pagination.roles,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getAllPermissions: (page, limit, refresh) => dispatch(getAllPermissions(page, limit, refresh)),
    getPermissionsCount: () => dispatch(getPermissionsCount()),
    savePermission: (data) => dispatch(savePermission(data)),
    updatePermission: (id, data) => dispatch(updatePermission(id, data)),
    deletePermission: (id) => dispatch(deletePermission(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Permissions));
