import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function Alert(props) {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    props.setAlert({ open: false, type: "" });
  };

  if (props.open) {
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ background: "#011627", color: "white" }}>
            {props.type} {props.selected.length > 1 ? `${props.selected.length} Projects` : "1 Project"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.type === "Delete"
                ? "These projects cannot be recovered after deleting them. Are you sure you want to continue?"
                : props.type === "Archive"
                ? "Archiving projects will remove it from this view. Are you sure you want to continue?"
                : "Un-Archiving projects will remove it from this view. Are you sure you want to continue?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ background: "#011627", color: "white" }}>
            <Button onClick={handleClose} style={{ color: "white", fontWeight: "bold" }}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                setLoading(true);
                await props.confirmAction();
                setLoading(false);
              }}
              style={{ color: "white", fontWeight: "bold" }}
              autoFocus
            >
              {loading ? <i class="fas fa-cog fa-spin" /> : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return null;
}
