import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadPolicies,
  fetchPoliciesCount,
  savePolicy,
  updatePolicyById,
  deletePolicyById,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { Grid } from "@material-ui/core";

const columns = [
  {
    title: "Domain",
    field: "name",
  },
  {
    title: "Description",
    field: "description",
  },
];

const Policies = (props) => {
  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchPoliciesCount}
            paginationCall={props.loadPolicies}
            title="Domains"
            entity={"policies"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(newData) =>
              props.savePolicy({
                name: newData.name || null,
                description: newData.description || null,
                icon: newData.icon || null,
              })
            }
            editFunction={(newData, oldData) =>
              props.updatePolicyById(newData.id, {
                name: newData.name || null,
                description: newData.description || null,
                icon: newData.icon || null,
              })
            }
            deleteFunction={(oldData) => props.deletePolicyById(oldData.id)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    policies: state.entities.policies,
    pageInfo: state.pagination.policies,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadPolicies: (page, limit, refresh) => dispatch(loadPolicies(page, limit, refresh)),
    fetchPoliciesCount: () => dispatch(fetchPoliciesCount()),
    savePolicy: (payload) => dispatch(savePolicy(payload)),
    updatePolicyById: (id, payload) => dispatch(updatePolicyById(id, payload)),
    deletePolicyById: (id) => dispatch(deletePolicyById(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Policies));
