import React from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadStates, fetchStatesCount, postState, updateStateById, deleteStateById } from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { Grid } from "@material-ui/core";

const columns = [
  {
    title: "Name",
    field: "name",
  },
];

const States = (props) => {
  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchStatesCount}
            paginationCall={props.loadStates}
            title="States"
            entity={"states"}
            columns={columns}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => props.postState({ ...rowData })}
            editFunction={(newData, oldData) => props.updateStateById(newData.id, { ...newData })}
            deleteFunction={(rowData) => props.deleteStateById(rowData.id)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    states: state.entities.states,
    pageInfo: state.pagination.states,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadStates: (page, limit, refresh) => dispatch(loadStates(page, limit, refresh)),
    fetchStatesCount: () => dispatch(fetchStatesCount()),
    postState: (data) => dispatch(postState(data)),
    updateStateById: (id, data) => dispatch(updateStateById(id, data)),
    deleteStateById: (id) => dispatch(deleteStateById(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(States));
