import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box, Grid, IconButton, InputBase, TextField, Tooltip, Popover, Button } from "@material-ui/core";
import FrameTool from "./FrameTool";
import xlsx from "xlsx";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import BaseButton from "../../../components/BaseButton";

export default function TableFrame(props) {
  const { selected, setSearch, classes, setViewType, viewType } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [openDatePopover, setOpenDatePopover] = useState(null);
  const [loading, setLoading] = useState(false);

  const viewBy = ["Account", "Resource", "Archives"];
  const open = Boolean(openDatePopover);

  const generateReport = async (startDate, endDate) => {
    try {
      setLoading(true);
      const result = await props.getProjectReport(new Date(startDate).getTime(), new Date(endDate).getTime());
      const data = result.response.entities.lists.projectReport.list;
      const worksheet = xlsx.utils.json_to_sheet(data.timeEntries);
      worksheet["!cols"] = [
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 200 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 120 },
        { wpx: 100 },
      ];

      const worksheet2 = xlsx.utils.json_to_sheet(data.projectCompletion);
      worksheet2["!cols"] = [
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 200 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 120 },
        { wpx: 100 },
      ];

      const worksheet3 = xlsx.utils.json_to_sheet(data.allocationForecast);
      worksheet3["!cols"] = [
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 200 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 120 },
        { wpx: 100 },
      ];

      let new_workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(new_workbook, worksheet, "Time Entries");
      xlsx.utils.book_append_sheet(new_workbook, worksheet2, "Project Completion");
      xlsx.utils.book_append_sheet(new_workbook, worksheet3, "Allocation Forecast");

      xlsx.writeFile(new_workbook, `Time Entry Report ${new Date().toDateString()}.xlsx`);
      setLoading(false);
      setOpenDatePopover(null);
      return new_workbook;
    } catch (e) {}
  };

  return (
    <Box height="70px" width="100%" style={{ display: "flex", alignItems: "center", background: "#f0f4f7" }}>
      <Popover
        open={open}
        anchorEl={openDatePopover}
        onClose={() => {
          setOpenDatePopover(null);
          setStartDate(new Date());
          setEndDate(null);
        }}
      >
        <Box p={3} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              InputAdornmentProps={{ position: "start" }}
              id="date-picker-inline"
              label="Start Date"
              fullWidth
              autoOk={true}
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
                if (e > endDate) setEndDate(null);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              disabled={!startDate}
              InputAdornmentProps={{ position: "start" }}
              id="date-picker-inline"
              shouldDisableDate={(date) => date <= startDate}
              label="End Date"
              fullWidth
              autoOk={true}
              value={endDate}
              onChange={(e) => setEndDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <br />

          <Button
            onClick={() => generateReport(startDate.getTime(), endDate.getTime())}
            style={{
              width: "100%",
              marginTop: 10,
              color: "black",
              fontWeight: "bold",
              backgroundColor: !endDate || !startDate || endDate < startDate ? "rgba(0,0,0,0.1)" : "inherit",
            }}
            disabled={!endDate || !startDate || endDate < startDate}
          >
            {loading ? (
              <i className={"fal fa-cog fa-spin"} />
            ) : !endDate || !startDate || endDate < startDate ? (
              "Select Date"
            ) : (
              "Generate Report"
            )}
          </Button>
        </Box>
      </Popover>

      <Grid container>
        <Grid item xs={12} sm={3}>
          <Box
            ml={2}
            fontSize="1rem"
            fontWeight="bold"
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            Project Roster
            {viewType === "Account" ? (
              <Box ml={2} mr={1} style={{ height: 10, width: 3, borderRadius: 50, background: "#2373D8" }} />
            ) : null}
            {selected.length > 0 && viewType === "Account" && props.editMode ? (
              <React.Fragment>
                <FrameTool
                  tip="Save Changes"
                  light
                  icon="check-circle"
                  color={"#0E8C64"}
                  onClick={() => props.updateSelectedProjects()}
                />
                <FrameTool
                  tip={!props.editMode ? "Edit" : "Cancel Changes"}
                  light
                  icon="ban"
                  color={props.editMode ? "black" : "black"}
                  onClick={() => props.setEditMode(!props.editMode)}
                />
              </React.Fragment>
            ) : selected.length > 0 && viewType === "Account" ? (
              <React.Fragment>
                <FrameTool
                  tip={props.editMode ? "Disable Edit Mode" : "Edit"}
                  light
                  icon="edit"
                  color={props.editMode ? "#2373D8" : "black"}
                  onClick={() => props.setEditMode(!props.editMode)}
                />
                <FrameTool
                  tip="Archive"
                  light
                  icon="archive"
                  onClick={() => props.setAlert({ open: true, type: "Archive" })}
                />
              </React.Fragment>
            ) : selected.length > 0 && viewType === "Archives" ? (
              <FrameTool
                tip="Un-Archive"
                light
                icon="folder-times"
                onClick={() => props.setAlert({ open: true, type: "Un-Archive" })}
              />
            ) : viewType === "Account" ? null : null}
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 16 }}>
            <Box className={`${classes.search}`}>
              <div className={classes.searchIcon}>
                <i className="fas fa-search" />
              </div>
              <InputBase
                placeholder="Search…"
                onChange={(e) => setSearch(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </Box>
            <Box ml={2} mr={1} style={{ height: 10, width: 3, borderRadius: 50, background: "#2373D8" }} />

            <Tooltip title={"Generate Report"}>
              <IconButton onClick={(e) => setOpenDatePopover(e.currentTarget)} style={{ color: "black" }}>
                <i className={"fal fa-file-user"} />
              </IconButton>
            </Tooltip>

            <Box ml={1} mr={1} style={{ height: 10, width: 3, borderRadius: 50, background: "#2373D8" }} />

            <Box ml={2}>
              <Autocomplete
                size="small"
                options={viewBy}
                getOptionLabel={(option) => option}
                defaultValue={viewBy[0]}
                onChange={(e, newValue) => {
                  props.setData([]);
                  setViewType(newValue);
                  props.setSelected([]);
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} style={{ width: 300, borderRadius: 50 }} variant="outlined" label="View by:" />
                )}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
