/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const BASE_URL = "";
const ADMIN_URL = "/hub";

export const GET_EMAIL_TEMPLATES_REQUEST = "GET_EMAIL_TEMPLATES_REQUEST";
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const GET_EMAIL_TEMPLATES_FAILURE = "GET_EMAIL_TEMPLATES_FAILURE";

const getEmailTemplates = () => ({
  [CALL_API]: {
    types: [GET_EMAIL_TEMPLATES_REQUEST, GET_EMAIL_TEMPLATES_SUCCESS, GET_EMAIL_TEMPLATES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/emails/templates`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.EMAIL_TEMPLATE_ARRAY,
  },
});

export const fetchEmailTemplates = () => (dispatch) => {
  return dispatch(getEmailTemplates());
};

export const POST_EMAIL_TEMPLATE_REQUEST = "POST_EMAIL_TEMPLATE_REQUEST";
export const POST_EMAIL_TEMPLATE_SUCCESS = "POST_EMAIL_TEMPLATE_SUCCESS";
export const POST_EMAIL_TEMPLATE_FAILURE = "POST_EMAIL_TEMPLATE_FAILURE";

const postEmailTemplate = (data) => ({
  [CALL_API]: {
    types: [POST_EMAIL_TEMPLATE_REQUEST, POST_EMAIL_TEMPLATE_SUCCESS, POST_EMAIL_TEMPLATE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/emails/template`,
      method: "POST",
      body: data,
      auth: true,
    },
    schema: Schemas.EMAIL_TEMPLATE,
  },
});

export const insertEmailTemplate = (data) => (dispatch) => {
  return dispatch(postEmailTemplate(data));
};

export const PUT_EMAIL_TEMPLATE_REQUEST = "PUT_EMAIL_TEMPLATE_REQUEST";
export const PUT_EMAIL_TEMPLATE_SUCCESS = "PUT_EMAIL_TEMPLATE_SUCCESS";
export const PUT_EMAIL_TEMPLATE_FAILURE = "PUT_EMAIL_TEMPLATE_FAILURE";

const updateTemplateById = (id, data) => ({
  [CALL_API]: {
    types: [PUT_EMAIL_TEMPLATE_REQUEST, PUT_EMAIL_TEMPLATE_SUCCESS, PUT_EMAIL_TEMPLATE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/emails/template/${id}`,
      method: "PUT",
      body: data,
      auth: true,
    },
    schema: Schemas.EMAIL_TEMPLATE,
  },
});

export const changeTemplateById = (id, data) => (dispatch) => {
  return dispatch(updateTemplateById(id, data));
};

export const SEND_USERS_EMAIL_REQUEST = "SEND_USERS_EMAIL_REQUEST";
export const SEND_USERS_EMAIL_SUCCESS = "SEND_USERS_EMAIL_SUCCESS";
export const SEND_USERS_EMAIL_FAILURE = "SEND_USERS_EMAIL_FAILURE";

const newUsersEmail = (template, emails) => ({
  [CALL_API]: {
    types: [SEND_USERS_EMAIL_REQUEST, SEND_USERS_EMAIL_SUCCESS, SEND_USERS_EMAIL_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/emails/individuals/launch/${template}`,
      method: "POST",
      body: { emails },
      auth: true,
    },
    schema: Schemas.SENT_EMAILS,
  },
});

export const sendUsersEmail = (template, emails) => (dispatch) => {
  return dispatch(newUsersEmail(template, emails));
};

export const SENT_EMAIL_COUNT_REQUEST = "SENT_EMAIL_COUNT_REQUEST";
export const SENT_EMAIL_COUNT_SUCCESS = "SENT_EMAIL_COUNT_SUCCESS";
export const SENT_EMAIL_COUNT_FAILURE = "SENT_EMAIL_COUNT_FAILURE";

const getSendEmailCount = () => ({
  [CALL_API]: {
    types: [SENT_EMAIL_COUNT_REQUEST, SENT_EMAIL_COUNT_SUCCESS, SENT_EMAIL_COUNT_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/emails/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const fetchSendEmailCount = () => (dispatch) => {
  return dispatch(getSendEmailCount());
};

export const SENT_EMAIL_REQUEST = "SENT_EMAIL_REQUEST";
export const SENT_EMAIL_SUCCESS = "SENT_EMAIL_SUCCESS";
export const SENT_EMAIL_FAILURE = "SENT_EMAIL_FAILURE";

const fetchSentEmails = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [SENT_EMAIL_REQUEST, SENT_EMAIL_SUCCESS, SENT_EMAIL_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.SENT_EMAILS_ARRAY,
  },
  refresh,
});

export const loadSentEmails = (page, limit, refresh) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(fetchSentEmails(`${ADMIN_URL}/emails/all?limit=${limit}`, refresh));
  }

  if (refresh && page) {
    return dispatch(fetchSentEmails(`${ADMIN_URL}/emails/all?limit=${limit}&page=${page}`, refresh));
  }

  if (page) {
    return dispatch(fetchSentEmails(`${ADMIN_URL}/emails/all?limit=${limit}&page=${page}`));
  }

  return dispatch(fetchSentEmails(`${ADMIN_URL}/emails/all?limit=${limit}&page=${page}`));
};

export const GET_EMAIL_TEMPLATES_LIST_REQUEST = "GET_EMAIL_TEMPLATES_LIST_REQUEST";
export const GET_EMAIL_TEMPLATES_LIST_SUCCESS = "GET_EMAIL_TEMPLATES_LIST_SUCCESS";
export const GET_EMAIL_TEMPLATES_LIST_FAILURE = "GET_EMAIL_TEMPLATES_LIST_FAILURE";

const fetchEmailTemplatesList = () => ({
  [CALL_API]: {
    types: [GET_EMAIL_TEMPLATES_LIST_REQUEST, GET_EMAIL_TEMPLATES_LIST_SUCCESS, GET_EMAIL_TEMPLATES_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/hubSpot/templates/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getEmailTemplatesList = () => (dispatch) => {
  return dispatch(fetchEmailTemplatesList());
};
