import { makeStyles, fade } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  scrollBoxLower: {
    color: "black",
    fontSize: "1rem",
    cursor: "pointer",
    fontFamily: "Lato",
    [theme.breakpoints.up("sm")]: {
      width: "1469px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  light: {
    backgroundColor: "rgba(51,92,129,0.05)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
    },
  },
  dark: {
    backgroundColor: "rgba(255,255,255, 1)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
    },
  },
}));
