import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  disabledItem: {
    padding: "1px 5px",
    color: "rgba(0,0,0,.2)",
  },
  hoverItem: {
    backgroundColor: "transparent",
    padding: "1px 5px",
    marginRight: 5,
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "2px 2px 10px 1px #c2cfd6",
    },
  },
  root: {
    width: "100%",
  },
  light: {
    backgroundColor: "rgba(51,92,129,0.05)",
    paddingTop: 2,
    paddingBottom: 2,
    // "&:hover": {
    //   backgroundColor: "rgba(51,92,129,0.1)",
    // },
  },
  dark: {
    backgroundColor: "rgba(255,255,255, 1)",
    paddingTop: 2,
    paddingBottom: 2,
    // "&:hover": {
    //   backgroundColor: "rgba(51,92,129,0.1)",
    // },
  },
  lineItemBox: {
    whiteSpace: "nowrap",
    height: "25px",
    position: "relative",
    color: "black",
    overflow: "hidden",
    transition: "0.4s ease-in-out",
  },
  mainBox: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: "20px 20px 0 0",
    },
  },
  mainTable: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: "0 0 20px 20px",
    },
  },
  customBadge: {
    backgroundColor: "#EB6B6C",
  },
  search: {
    position: "relative",
    borderRadius: 50,
    border: "1px solid transparent",
    backgroundColor: "rgba(51,92,129,.05)",
    "&:hover": {
      backgroundColor: "rgba(51,92,129,0.1)",
      border: "1px solid #2373D8",
    },
    "&:focus": {
      border: "1px solid #2373D8",
    },
    marginLeft: 10,
    width: "fit-content",
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(1),
    //   width: "auto",
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2373D8",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "25ch",
        border: "1px solid #2373D8",
        borderRadius: 50,
      },
    },
  },
}));
