import React from "react";
import { Grid, Box, FormControl, FormGroup } from "@material-ui/core";
import DemographicsCheckbox from "../components/DemographicsCheckbox";

export default function Drivers(props) {
  const classes = {};

  return (
    <Box mt={2}>
      <Box fontSize="1rem" fontWeight="bold">
        Who would care most if {props.company ? props.company.name : "your organization"}'s data was compromised?{" "}
        <span
          style={{
            fontSize: "0.8rem",
            color:
              props.demographicsErrors.orgImpacts ||
              props.demographics.orgImpacts.filter((orgImpact) => orgImpact.checked).length >= 3
                ? "red"
                : "rgba(1,22,39,.6)",
          }}
        >
          {props.demographicsErrors.orgImpacts
            ? "(Select at least one option)"
            : props.demographics.orgImpacts.filter((orgImpact) => orgImpact.checked).length >= 3
            ? "(Max selected)"
            : "(Choose 3)"}
        </span>
      </Box>
      <Box p={2}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Grid container>
              {props.demographics.orgImpacts &&
                props.demographics.orgImpacts.map((orgImpact, index) => {
                  return (
                    <Grid key={index} item xs={4}>
                      <DemographicsCheckbox
                        title={orgImpact.label}
                        target="orgImpacts"
                        handleChange={props.updateDemographicsState}
                        checked={orgImpact.checked}
                        autoChecked={orgImpact.autoChecked}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </FormGroup>
        </FormControl>
      </Box>
      <Box fontSize="1rem" fontWeight="bold">
        What types of data does {props.company ? props.company.name : "your organization"} store?{" "}
        <span
          style={{
            fontSize: "0.8rem",
            color: props.demographicsErrors.dataTypes ? "red" : "rgba(1,22,39,.6)",
          }}
        >
          {props.demographicsErrors.dataTypes ? "(At least one data type is required)" : "(Select at least one option)"}
        </span>
      </Box>
      <Box p={2}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Grid container>
              {props.demographics.dataTypes &&
                props.demographics.dataTypes.map((dataType, index) => {
                  return (
                    <Grid key={index} item xs={4}>
                      <DemographicsCheckbox
                        title={dataType.label}
                        target="dataTypes"
                        handleChange={props.updateDemographicsState}
                        checked={dataType.checked}
                        disabled={dataType.autoChecked}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
