/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const SAVE_MILESTONE_REQUEST = "SAVE_MILESTONE_REQUEST";
export const SAVE_MILESTONE_SUCCESS = "SAVE_MILESTONE_SUCCESS";
export const SAVE_MILESTONE_FAILURE = "SAVE_MILESTONE_FAILURE";

const createMilestone = (payload) => ({
  [CALL_API]: {
    types: [SAVE_MILESTONE_REQUEST, SAVE_MILESTONE_SUCCESS, SAVE_MILESTONE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/milestones/`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.MILESTONES_ARRAY,
  },
});

export const saveMilestone = (payload) => (dispatch) => {
  return dispatch(createMilestone(payload));
};

export const UPDATE_MILESTONE_REQUEST = "UPDATE_MILESTONE_REQUEST";
export const UPDATE_MILESTONE_SUCCESS = "UPDATE_MILESTONE_SUCCESS";
export const UPDATE_MILESTONE_FAILURE = "UPDATE_MILESTONE_FAILURE";

const changeMilestone = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_MILESTONE_REQUEST, UPDATE_MILESTONE_SUCCESS, UPDATE_MILESTONE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/milestones/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.MILESTONES,
  },
});

export const updateMilestone = (id, payload) => (dispatch) => {
  return dispatch(changeMilestone(id, payload));
};

export const DELETE_MILESTONE_REQUEST = "DELETE_MILESTONE_REQUEST";
export const DELETE_MILESTONE_SUCCESS = "DELETE_MILESTONE_SUCCESS";
export const DELETE_MILESTONE_FAILURE = "DELETE_MILESTONE_FAILURE";

const removeMilestone = (id) => ({
  [CALL_API]: {
    types: [DELETE_MILESTONE_REQUEST, DELETE_MILESTONE_SUCCESS, DELETE_MILESTONE_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/milestones/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.MILESTONES,
  },
});

export const deleteMilestone = (id) => (dispatch) => {
  return dispatch(removeMilestone(id));
};

export const GET_ALL_MILESTONES_REQUEST = "GET_ALL_MILESTONES_REQUEST";
export const GET_ALL_MILESTONES_SUCCESS = "GET_ALL_MILESTONES_SUCCESS";
export const GET_ALL_MILESTONES_FAILURE = "GET_ALL_MILESTONES_FAILURE";

const fetchAllMilestones = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_MILESTONES_REQUEST, GET_ALL_MILESTONES_SUCCESS, GET_ALL_MILESTONES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.MILESTONES_ARRAY,
  },
  refresh,
});

export const getAllMilestones = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllMilestones(
        `${ADMIN_URL}/milestones/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllMilestones(
        `${ADMIN_URL}/milestones/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllMilestones(
        `${ADMIN_URL}/milestones/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllMilestones(`${ADMIN_URL}/milestones/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`)
  );
};

export const GET_COUNT_MILESTONES_REQUEST = "GET_COUNT_MILESTONES_REQUEST";
export const GET_COUNT_MILESTONES_SUCCESS = "GET_COUNT_MILESTONES_SUCCESS";
export const GET_COUNT_MILESTONES_FAILURE = "GET_COUNT_MILESTONES_FAILURE";

const fetchMilestonesCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_MILESTONES_REQUEST, GET_COUNT_MILESTONES_SUCCESS, GET_COUNT_MILESTONES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/milestones/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getMilestonesCount = (search) => (dispatch) => {
  return dispatch(fetchMilestonesCount(search));
};
