// Base Imports
import React, { useState } from "react";
import { Box, Grid, TextField, Avatar, Button, Grow, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "../styles/Profile.styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CirclePicker } from "react-color-hook";

export default function ProfileSettings(props) {
  const classes = useStyles();
  const [userFirstName, setUserFirstName] = useState(props.user.firstName);
  const [userLastName, setUserLastName] = useState(props.user.lastName);
  const [passwordEdit, setPasswordEdit] = useState();
  const [newTitle, setNewTitle] = useState(props.user.jobTitle);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState(props.user.gender);
  const [handleText, setHandleText] = useState({ password: "", confirmPassword: "", oldPassword: "" });
  const [resetPasswordMatchError, setResetPasswordMatchError] = useState(false);
  const [resetPasswordReqError, setResetPasswordReqError] = useState(false);
  const [resetPasswordMatchOldPass, setResetPasswordMatchOldPass] = useState(false);
  const [loadingColors, setLoadingColors] = useState(false);

  const handleChange = () => {
    setShow((prev) => !prev);
  };

  const defaultColors = [
    "#214559",
    "#391285",
    "#0000aa",
    "#0f3b57",
    "#373e02",
    "#00626f",
    "#11574a",
    "#004953",
    "#3e6257",
    "#b4262a",
    "#d1001c",
    "#c14a09",
    "#7f4330",
    "#ba160c",
    "#415764",
    "#302621",
    "#aac2a1",
    "#9babbb",
  ];

  const CustomAvatar = withStyles({
    root: {
      background: props.user.color,
    },
  })(Avatar);

  const handleColorChange = async (color, event) => {
    try {
      setLoadingColors(true);
      let response = await props.updateUser(props.user.id, { color: color.hex });
      props.getAdminProfile();
      setShow(false);
      setTimeout(() => setLoadingColors(false), 1000);
      return response;
    } catch (err) {}
  };

  const updateUser = async () => {
    setLoading(true);
    try {
      let response = await props.updateUser(props.user.id, {
        firstName: userFirstName,
        lastName: userLastName,
        jobTitle: newTitle,
        gender: gender,
      });
      props.getAdminProfile();
      setTimeout(() => setLoading(false), 500);
      return response;
    } catch (err) {}
  };

  function checkPassword(str) {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const updatePassword = () => {
    setLoading(true);
    let mismatch =
      handleText.password !== "" &&
      handleText.confirmPassword !== "" &&
      handleText.password === handleText.confirmPassword;
    if (mismatch && checkPassword(handleText.password)) {
      props
        .updateUserPassword(props.user.id, {
          password: handleText.password,
          oldPassword: handleText.oldPassword,
        })
        .then((result) => {
          if (result.type === "UPDATE_PASS_USER_SUCCESS") props.handleClose();
          else setResetPasswordMatchOldPass(true);
        });
    } else {
      let matchError = false;
      let reqError = false;
      let oldPassError = false;
      if (!mismatch) matchError = true;
      if (!checkPassword(handleText.password)) reqError = true;

      setResetPasswordMatchError(matchError);
      setResetPasswordReqError(reqError);
    }
    setTimeout(() => setLoading(false), 500);
    setPasswordEdit(false);
  };

  const handleTextChange = (e, key) => {
    setHandleText({ ...handleText, [key]: e.target.value });
    setResetPasswordMatchError(false);
    setResetPasswordReqError(false);
    setResetPasswordMatchOldPass(false);
  };

  return (
    <Box pt={3} pb={2} display="flex" width="100%" alignItems="center" justifyContent="center" flexDirection="column">
      <Box className={classes.box} p={2}>
        <Box mb={4}>
          {loadingColors ? (
            <CircularProgress />
          ) : (
            <CustomAvatar variant="circular" className={classes.circle} onClick={handleChange}>
              {props.user.firstName[0]}
              {props.user.lastName[0]}
            </CustomAvatar>
          )}
        </Box>
        {show ? (
          <Grow in={show} style={{ transformOrigin: "0 0 0" }} {...(show ? { timeout: 1000 } : {})}>
            <Box mb={4}>
              <CirclePicker colors={defaultColors} color={props.user.color} onChangeComplete={handleColorChange} />
            </Box>
          </Grow>
        ) : null}
        <Grid container spacing={2}>
          {!passwordEdit && (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={userFirstName}
                  onChange={(e, newValue) => {
                    setUserFirstName(e.target.value);
                  }}
                  margin="none"
                  size="small"
                  label="First Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={userLastName}
                  onChange={(e) => {
                    setUserLastName(e.target.value);
                  }}
                  margin="none"
                  size="small"
                  label="Last Name"
                  variant="outlined"
                />
              </Grid>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <Autocomplete*/}
              {/*    margin="none"*/}
              {/*    size="small"*/}
              {/*    fullWidth*/}
              {/*    options={["Male", "Female", "Robot", "Alien", "Newt", "Rather not say"]}*/}
              {/*    value={gender}*/}
              {/*    getOptionLabel={(option) => option}*/}
              {/*    onChange={(e, newValue) => {*/}
              {/*      setGender(newValue);*/}
              {/*    }}*/}
              {/*    renderInput={(params) => (*/}
              {/*      <TextField InputLabelProps={{ shrink: true }} variant="outlined" {...params} label="Gender" />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</Grid>*/}

              <Grid item xs={12} sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={newTitle}
                  onChange={(e) => {
                    setNewTitle(e.target.value);
                  }}
                  margin="none"
                  size="small"
                  label="Title"
                  variant="outlined"
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            {passwordEdit ? (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ color: props.user.color }}
              >
                <Grid container spacing={2} alignItems="center" justify="center">
                  <Grid item xs={12}>
                    <Box>
                      <i className="fad fa-user-lock" style={{ marginRight: 5 }} /> Password:
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      name="password"
                      autoComplete="current-password"
                      label={resetPasswordReqError ? "Password Does Not Meet Requirements" : "Old Password"}
                      error={resetPasswordMatchError || resetPasswordReqError}
                      type="password"
                      value={handleText.oldPassword}
                      onChange={(e) => handleTextChange(e, "oldPassword")}
                      label="Current Password"
                      margin="none"
                      size="small"
                      variant="outlined"
                    />

                    {resetPasswordReqError ? (
                      <Box fontSize="0.7rem" color="red">
                        Your password must be at least 8 characters with a combination of at least one upper and lower
                        case letter, a number, and one of the following symbols: ! @ # $ % ^ & *
                      </Box>
                    ) : null}
                    {resetPasswordMatchOldPass ? (
                      <Box fontSize="0.7rem" color="red">
                        Your prior password does not match our records.
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label={resetPasswordReqError ? "Password Does Not Meet Requirements" : "New Password"}
                      error={resetPasswordMatchError || resetPasswordReqError}
                      type="password"
                      required
                      value={handleText.password}
                      onChange={(e) => handleTextChange(e, "password")}
                      fullWidth
                      margin="none"
                      size="small"
                      variant="outlined"
                    />
                    {resetPasswordReqError ? (
                      <Box fontSize="0.7rem" color="red">
                        Your password must be at least 8 characters with a combination of at least one upper and lower
                        case letter, a number, and one of the following symbols: ! @ # $ % ^ & *
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label={resetPasswordMatchError ? "Passwords Do Not Match" : "Confirm New Password"}
                      error={resetPasswordMatchError || resetPasswordReqError}
                      type="password"
                      required
                      value={handleText.confirmPassword}
                      onChange={(e) => handleTextChange(e, "confirmPassword")}
                      margin="none"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={"auto"}>
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <Button
                          onClick={updatePassword}
                          style={{ height: 35, backgroundColor: props.user.color, color: "#fff", fontWeight: "bold" }}
                        >
                          {loading ? <i className="fad fa-cog fa-spin" /> : "Save"}
                        </Button>
                      </Box>
                      <Button
                        onClick={() => setPasswordEdit(false)}
                        style={{ backgroundColor: props.user.color, color: "#fff", fontWeight: "bold" }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" style={{ color: props.user.color }}>
                <Box mr={1}>
                  <i className="fad fa-user-lock" style={{ marginRight: 5 }} /> Password:
                </Box>
                <Box mr={1}>*****</Box>
                <Box>
                  <Button
                    onClick={() => setPasswordEdit(true)}
                    style={{ backgroundColor: props.user.color, color: "#fff", fontWeight: "bold" }}
                  >
                    Change Password
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        {!passwordEdit && (
          <Box mt={4}>
            <Button
              onClick={updateUser}
              style={{
                border: `2px solid ${props.user.color}`,
                backgroundColor: "white",
                fontWeight: "bold",
                color: props.user.color,
                height: 35,
              }}
            >
              {loading ? <i className="fad fa-cog fa-spin" /> : "Update"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
