import { host, client } from "../config/api_config";
export const NOT_LOGGED_IN = "User is not logged in";
export const INVALID_TOKEN = "Invalid token";
export const TOKEN_REFRESH_FAILED = "Failed to refresh token";
export const INVALID_CREDENTIALS = "Username or password was incorrect. Please try again.";
let token = null;
let tokenRequest = null;

export function login(user) {
  return requestToken(user);
}

export function logout() {
  token = null;
  tokenRequest = null;
  localStorage.removeItem("auth_token");
  localStorage.clear();
  return false;
}

export async function getToken() {
  if (!token) {
    const savedToken = localStorage.getItem("auth_token");
    if (savedToken) {
      token = JSON.parse(savedToken);
    } else {
      logout();
      throw new Error(NOT_LOGGED_IN);
    }
  }
  if (!token.access_token) {
    logout();
    throw new Error(INVALID_TOKEN);
  }
  if (token.expires_on && token.expires_on < Date.now()) {
    await refreshToken(token).catch(() => {
      logout();
      throw new Error(TOKEN_REFRESH_FAILED);
    });
  }
  return token;
}

export async function isLoggedIn() {
  try {
    await getToken();
    return !!token;
  } catch (error) {
    if (error.message === NOT_LOGGED_IN) {
      return false;
    }
  }
  return false;
}
export function requestToken(user) {
  const endpoint = "/oauth/token?role=ADMIN";
  const clientToken = btoa(`${client.id}:${client.secret}`);
  const request = fetch(host + endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${clientToken}`,
    },
    body: JSON.stringify({
      grant_type: "password",
      username: user.username,
      password: user.password,
      scope: "read",
    }),
    credentials: "include",
  }).then(saveTokenFromResponse);

  tokenRequest = request;
  return request;
}

export async function refreshToken(expiredToken) {
  if (tokenRequest) return tokenRequest;
  const endpoint = "/oauth/token";
  const clientToken = btoa(`${client.id}:${client.secret}`);
  const request = fetch(host + endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Basic ${clientToken}`,
    },
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: expiredToken.refresh_token,
      scope: "read",
    }),
    credentials: "include",
  })
    .then(checkStatus)
    .then(saveTokenFromResponse);

  tokenRequest = request;
  return request;
}

function checkStatus(response) {
  let error;
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 403) {
    error = new Error(INVALID_CREDENTIALS);
  } else {
    error = new Error(response.statusText);
  }
  error.response = response;
  throw error;
}

async function saveTokenFromResponse(response) {
  const newToken = await response.json();
  if (!newToken.access_token) {
    return newToken;
  }
  newToken.expires_on = Date.now() + newToken.expires_in * 1000;
  token = newToken;
  localStorage.setItem("auth_token", JSON.stringify(newToken));
  tokenRequest = null;
  return newToken;
}
