/* eslint-disable max-len */
import { CALL_API, Schemas } from "../middleware/api";

const ADMIN_URL = "/hub";

export const SAVE_RESPONSIBILITY_REQUEST = "SAVE_RESPONSIBILITY_REQUEST";
export const SAVE_RESPONSIBILITY_SUCCESS = "SAVE_RESPONSIBILITY_SUCCESS";
export const SAVE_RESPONSIBILITY_FAILURE = "SAVE_RESPONSIBILITY_FAILURE";

const createResponsibility = (payload) => ({
  [CALL_API]: {
    types: [SAVE_RESPONSIBILITY_REQUEST, SAVE_RESPONSIBILITY_SUCCESS, SAVE_RESPONSIBILITY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/responsibilities`,
      method: "POST",
      auth: true,
      body: payload,
    },
    schema: Schemas.RESPONSIBILITIES_ARRAY,
  },
});

export const saveResponsibility = (payload) => (dispatch) => {
  return dispatch(createResponsibility(payload));
};

export const UPDATE_RESPONSIBILITY_REQUEST = "UPDATE_RESPONSIBILITY_REQUEST";
export const UPDATE_RESPONSIBILITY_SUCCESS = "UPDATE_RESPONSIBILITY_SUCCESS";
export const UPDATE_RESPONSIBILITY_FAILURE = "UPDATE_RESPONSIBILITY_FAILURE";

const changeResponsibility = (id, payload) => ({
  [CALL_API]: {
    types: [UPDATE_RESPONSIBILITY_REQUEST, UPDATE_RESPONSIBILITY_SUCCESS, UPDATE_RESPONSIBILITY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/responsibilities/${id}`,
      method: "PUT",
      auth: true,
      body: payload,
    },
    schema: Schemas.RESPONSIBILITIES,
  },
});

export const updateResponsibility = (id, payload) => (dispatch) => {
  return dispatch(changeResponsibility(id, payload));
};

export const DELETE_RESPONSIBILITY_REQUEST = "DELETE_RESPONSIBILITY_REQUEST";
export const DELETE_RESPONSIBILITY_SUCCESS = "DELETE_RESPONSIBILITY_SUCCESS";
export const DELETE_RESPONSIBILITY_FAILURE = "DELETE_RESPONSIBILITY_FAILURE";

const removeResponsibility = (id) => ({
  [CALL_API]: {
    types: [DELETE_RESPONSIBILITY_REQUEST, DELETE_RESPONSIBILITY_SUCCESS, DELETE_RESPONSIBILITY_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/responsibilities/${id}`,
      method: "DELETE",
      auth: true,
    },
    schema: Schemas.RESPONSIBILITIES,
  },
});

export const deleteResponsibility = (id) => (dispatch) => {
  return dispatch(removeResponsibility(id));
};

export const GET_ALL_RESPONSIBILITIES_REQUEST = "GET_ALL_RESPONSIBILITIES_REQUEST";
export const GET_ALL_RESPONSIBILITIES_SUCCESS = "GET_ALL_RESPONSIBILITIES_SUCCESS";
export const GET_ALL_RESPONSIBILITIES_FAILURE = "GET_ALL_RESPONSIBILITIES_FAILURE";

const fetchAllResponsibilities = (nextPageUrl, refresh) => ({
  page: "page",
  [CALL_API]: {
    types: [GET_ALL_RESPONSIBILITIES_REQUEST, GET_ALL_RESPONSIBILITIES_SUCCESS, GET_ALL_RESPONSIBILITIES_FAILURE],
    reqInfo: {
      endpoint: nextPageUrl,
      method: "GET",
      auth: true,
    },
    schema: Schemas.RESPONSIBILITIES_ARRAY,
  },
  refresh,
});

export const getAllResponsibilities = (page, limit, refresh, searchTerm, sort, by) => (dispatch, getState) => {
  if (refresh) {
    return dispatch(
      fetchAllResponsibilities(
        `${ADMIN_URL}/responsibilities/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (refresh && page) {
    return dispatch(
      fetchAllResponsibilities(
        `${ADMIN_URL}/responsibilities/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`,
        refresh
      )
    );
  }

  if (page) {
    return dispatch(
      fetchAllResponsibilities(
        `${ADMIN_URL}/responsibilities/all?limit=${limit}&page=${page}&search=${searchTerm}&sort=${sort}&by=${by}`
      )
    );
  }

  return dispatch(
    fetchAllResponsibilities(
      `${ADMIN_URL}/responsibilities/all?limit=${limit}&search=${searchTerm}&sort=${sort}&by=${by}`
    )
  );
};

export const GET_COUNT_RESPONSIBILITIES_REQUEST = "GET_COUNT_RESPONSIBILITIES_REQUEST";
export const GET_COUNT_RESPONSIBILITIES_SUCCESS = "GET_COUNT_RESPONSIBILITIES_SUCCESS";
export const GET_COUNT_RESPONSIBILITIES_FAILURE = "GET_COUNT_RESPONSIBILITIES_FAILURE";

const fetchResponsibilitiesCount = (search) => ({
  [CALL_API]: {
    types: [GET_COUNT_RESPONSIBILITIES_REQUEST, GET_COUNT_RESPONSIBILITIES_SUCCESS, GET_COUNT_RESPONSIBILITIES_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/responsibilities/count`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.COUNT,
  },
});

export const getResponsibilitiesCount = (search) => (dispatch) => {
  return dispatch(fetchResponsibilitiesCount(search));
};

export const RESPONSIBILITY_LIST_REQUEST = "RESPONSIBILITY_LIST_REQUEST";
export const RESPONSIBILITY_LIST_SUCCESS = "RESPONSIBILITY_LIST_SUCCESS";
export const RESPONSIBILITY_LIST_FAILURE = "RESPONSIBILITY_LIST_FAILURE";

const fetchResponsibilityList = () => ({
  [CALL_API]: {
    types: [RESPONSIBILITY_LIST_REQUEST, RESPONSIBILITY_LIST_SUCCESS, RESPONSIBILITY_LIST_FAILURE],
    reqInfo: {
      endpoint: `${ADMIN_URL}/responsibilities/list`,
      method: "GET",
      auth: true,
    },
    schema: Schemas.LIST,
  },
});

export const getResponsibilityList = () => (dispatch) => {
  return dispatch(fetchResponsibilityList());
};
