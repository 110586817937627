export const dataSet = {
  TITLES: {
    user: { name: "User", size: 2 },
    access: { name: "Access", size: 4 },
    roles: { name: "Roles", size: 4 },
  },
  MENU_TABS: {
    user: "User Information",
    organizations: "Organizations",
    projects: "Projects",
    roles: "Roles",
    characteristics: "Characteristics",
    metadata: "Metadata",
    frameworks: "Frameworks",
    hubspot: "Hubspot",
  },
};
