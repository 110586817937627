import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Box } from "@material-ui/core";
import EditTemplate from "../components/EditTemplate";

const useStyles = makeStyles({});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  return (
    <div>
      <Drawer anchor="right" open={props.open} onClose={() => props.setOpen(false)}>
        <Box width={375} display="flex" flexDirection="column" alignItems="center">
          <EditTemplate
            loading={props.loading}
            projectQueue={props.projectQueue}
            projectQueueList={props.projectQueueList}
            getProjectQueueItemById={props.getProjectQueueItemById}
            setOpen={props.setOpen}
          />
        </Box>
      </Drawer>
    </div>
  );
}
