import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Switch, FormControlLabel } from "@material-ui/core";
import { useStyles } from "../styles/TableStyles.styles";
import InputMask from "react-input-mask";
import Skeleton from "@material-ui/lab/Skeleton";

export default function BillingInfo(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.setState({ ...props.state, [event.target.name]: event.target.checked });
  };

  return (
    <Box style={{ pointerEvents: props.archives ? "none" : "inherit" }} className={classes.area}>
      <Box className={classes.titles}>Billing Information</Box>
      <Box ml={"10px"} mt={1}>
        <FormControlLabel
          control={
            <Switch
              checked={props.state.checkedA}
              onChange={(e) => {
                if (!props.changesMade) props.setChangesMade(true);
                handleChange(e);
              }}
              name="checkedA"
            />
          }
          label="Same as Client Information"
        />
      </Box>
      <Box mt={2} mb={2} ml={"10px"} mr={"10px"} alignSelf="flex-start" width="90%">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={30} />
            ) : (
              <TextField
                value={props.state.checkedA ? props.contactName : props.billingName}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.state.checkedA ? props.setContactName(e.target.value) : props.setBillingName(e.target.value);
                }}
                size="small"
                label="Contact Name"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={30} />
            ) : (
              <TextField
                value={props.state.checkedA ? props.contactTitle : props.billingTitle}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.state.checkedA ? props.setContactTitle(e.target.value) : props.setBillingTitle(e.target.value);
                }}
                size="small"
                label="Contact Title"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={30} />
            ) : (
              <TextField
                value={props.state.checkedA ? props.contactEmail : props.billingEmail}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.state.checkedA ? props.setContactEmail(e.target.value) : props.setBillingEmail(e.target.value);
                }}
                size="small"
                label="Contact Email"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {props.loading ? (
              <Skeleton variant="text" animation="wave" variant="rect" height={30} />
            ) : (
              <InputMask
                mask="999-999-9999"
                value={props.state.checkedA ? props.contactPhone : props.billingPhone}
                onChange={(e) => {
                  if (!props.changesMade) props.setChangesMade(true);
                  props.state.checkedA ? props.setContactPhone(e.target.value) : props.setBillingPhone(e.target.value);
                }}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    size="small"
                    label="Contact Phone"
                    fullWidth
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </InputMask>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
