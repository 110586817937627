import React from "react";
import { Grid } from "@material-ui/core";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getComponents,
  fetchComponentsCount,
  createComponent,
  updateComponent,
  deleteComponent,
} from "../../../store/actions";
import { constants, humanizeLabels } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { MultiLineEdit, Selection } from "../../../components/MaterialTable/Inputs";

const getColumns = (documents = []) => [
  {
    title: "Name",
    field: "name",
    editComponent: (props) => <MultiLineEdit {...props} />,
  },
  {
    title: "Order",
    field: "order",
    type: "numeric",
  },
  {
    title: "Tip",
    field: "tip",
    editComponent: (props) => <MultiLineEdit {...props} />,
  },
  {
    title: "Document Types",
    field: "documentTypes",
    render: (row) => (
      <div>
        {Array.isArray(row.documentTypes) ? row.documentTypes.map((type) => humanizeLabels(type)).join(", ") : null}
      </div>
    ),
    editComponent: (props) => <Selection multiple target={null} options={documents} {...props} />,
  },
];

function Components(props) {
  const postComponent = async (rowData) => {
    try {
      let response = null;
      if (rowData.name && rowData.name.length > 0)
        response = await props.createComponent({ ...rowData, documentTypes: rowData.documentTypes.split(",") });
      return response;
    } catch (err) {}
  };

  const changeComponent = async (rowData, oldData) => {
    try {
      const payload = { ...rowData };

      const documentTypes = Array.isArray(rowData.documentTypes)
        ? rowData.documentTypes
        : rowData.documentTypes.split(",");
      const response = await props.updateComponent(rowData.id, {
        ...payload,
        documentTypes,
      });
      return response;
    } catch (err) {}
  };

  const removeComponent = async (rowData) => {
    const response = await props.deleteComponent(rowData.id);
    return response;
  };

  const columns = getColumns(
    Object.values(constants.DOCUMENT_TYPES).map((c) => ({
      value: c,
      label: humanizeLabels(c),
    }))
  );

  return (
    <Grid container spacing={2}>
      <AuthComponent />
      <Grid item xs={12}>
        <MaterialTable
          backendPagination
          totalCount={0}
          fetchCount={props.fetchComponentsCount}
          paginationCall={props.getComponents}
          title="Components"
          entity="components"
          addable={props.create}
          addFunction={(rowData) => postComponent(rowData)}
          editable={props.update}
          editFunction={(newData, oldData) => changeComponent(newData, oldData)}
          deletable={props.delete}
          deleteFunction={(rowData) => removeComponent(rowData)}
          columns={columns}
          options={{
            search: true,
            paging: true,
            pageSize: constants.DEFAULT_PAGE_LIMIT,
          }}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: state.entities.classes,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getComponents: (page, limit, refresh, searchTerm, sort, by) =>
      dispatch(getComponents(page, limit, refresh, searchTerm, sort, by)),
    fetchComponentsCount: (searchTerm) => dispatch(fetchComponentsCount(searchTerm)),
    createComponent: (payload) => dispatch(createComponent(payload)),
    updateComponent: (id, payload) => dispatch(updateComponent(id, payload)),
    deleteComponent: (id) => dispatch(deleteComponent(id)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Components));
