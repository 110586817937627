import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonLoader = (props) => {
  let childrenArray = [];
  if (typeof props.children !== "array") {
    childrenArray.push(props.children);
  } else {
    childrenArray = props.children;
  }
  return (
    <div style={{ width: "100%", paddingTop: "1rem" }}>
      <ContentLoader
        animate={true}
        height={props.height || 250}
        width={props.width || 600}
        speed={props.speed || 2}
        primaryColor={props.primaryColor || "#EDEDED"}
      >
        {childrenArray.map((child) => child)}
      </ContentLoader>
    </div>
  );
};

export default SkeletonLoader;
