const normalizeString = (str) => str.trim().toLowerCase();
const checkErrorTypes = (item) => item === null || item === undefined || item === "" || item.length === 0;

export const generatePayload = (args) => {
  try {
    let currentState = args.demographics;
    currentState.countryIds = args.countries
      .filter((country) => normalizeString(country.label) === normalizeString(currentState.countryIds))
      .map((country) => country.value);

    currentState.stateIds = args.states
      .filter((state) =>
        currentState.stateIds.map((name) => normalizeString(name)).includes(normalizeString(state.label))
      )
      .map((state) => state.value);

    currentState.primaryIndustryId = args.industries
      .filter((industry) => normalizeString(industry.label) === normalizeString(currentState.primaryIndustryId))
      .map((industry) => industry.value)[0];

    currentState.industryIds = currentState.industryIds
      ? args.industries
          .filter((industry) =>
            currentState.industryIds.map((name) => normalizeString(name)).includes(normalizeString(industry.label))
          )
          .map((industry) => industry.value)
      : [];

    currentState.dataTypes = currentState.dataTypes
      .filter((dataType) => dataType.checked)
      .map((dataType) => dataType.id);

    currentState.orgImpacts = currentState.orgImpacts
      .filter((orgImpact) => orgImpact.checked)
      .map((orgImpact) => orgImpact.id);

    return currentState;
  } catch (err) {}
};

export const generateDemographicsPayload = (demographics, key, value, item = null) => {
  let currentDemographics = demographics;
  switch (key) {
    case "orgImpacts":
      if (value && currentDemographics.orgImpacts.filter((orgImpact) => orgImpact.checked).length >= 3) {
        return { ...currentDemographics };
      }
      let targetOrgImpact = currentDemographics.orgImpacts.find((orgImpact) => orgImpact.label === item);
      let targetDataType = currentDemographics.dataTypes.find(
        (dataType) => dataType.id === targetOrgImpact.dataTypes[0].dataTypeId
      );
      targetOrgImpact.checked = value;
      targetDataType.checked = value;
      targetOrgImpact.autoChecked = value;
      targetDataType.autoChecked = value;
      return { ...currentDemographics };
    case "dataTypes":
      currentDemographics.dataTypes.find((dataType) => dataType.label === item).checked = value;
      return { ...currentDemographics };
    case "objectives":
      if (value.length > 3) return { ...currentDemographics };
      return { ...currentDemographics, [key]: value };
    default:
      return { ...currentDemographics, [key]: value };
  }
};

export const populateDemographicsAnswers = (args) => {
  try {
    let currentState = args.demographics;
    currentState.countryIds = currentState.countryIds.map(
      (countryId) => args.countries.find((country) => country.value === countryId).label
    )[0];

    currentState.stateIds = currentState.stateIds.map(
      (stateId) => args.states.find((state) => state.value === stateId).label
    );

    currentState.primaryIndustryId = args.industries.find(
      (industry) => industry.value === currentState.primaryIndustryId
    ).label;

    currentState.industryIds = currentState.industryIds.map(
      (industryId) => args.industries.find((industry) => industry.value === industryId).label
    );

    currentState.dataTypes = args.dataTypes.map((dataType) => ({
      ...dataType,
      checked: currentState.dataTypes.includes(dataType.id),
    }));

    currentState.orgImpacts = args.orgImpacts.map((orgImpact) => ({
      ...orgImpact,
      checked: currentState.orgImpacts.includes(orgImpact.id),
    }));

    return currentState;
  } catch (err) {}
};

export const errorChecker = (demographics) => {
  const noDataTypes = !demographics.dataTypes.some((dataType) => dataType.checked);
  const noOrgImpacts = !demographics.orgImpacts.some((orgImpact) => orgImpact.checked);
  const objectivesLessThanThree = checkErrorTypes(demographics.objectives) || demographics.objectives.length !== 3;
  const noPrimaryIndustry = checkErrorTypes(demographics.primaryIndustryId);
  const noCountries = checkErrorTypes(demographics.countryIds);
  const noStates = checkErrorTypes(demographics.stateIds);

  return {
    dataTypes: noDataTypes,
    orgImpacts: noOrgImpacts,
    primaryIndustryId: noPrimaryIndustry,
    jobTitle: false,
    objectives: objectivesLessThanThree,
    countryIds: noCountries,
    stateIds: noStates,
  };
};
