import { makeStyles, fade } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formBox: {
    display: "flex",
    alignItems: "center",
    width: "90%",
    height: "100%",
    flexDirection: "column",
    boxShadow: "0px 0px 20px 5px rgba(51,92,129,0.2) ",
    borderRadius: 5,
    background: "white",
    overflow: "auto",
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
  titleBox: {
    background: "#F5F5F5",
    borderRadius: 10,
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  titles: {
    textTransform: "uppercase",
    marginLeft: 10,
    alignSelf: "flex-start",
    fontSize: "1.2rem",
    fontWeight: "bold",
    borderBottom: "3px solid #2373D8",
  },
  area: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10,
    padding: 20,
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
}));
