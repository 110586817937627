import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadHubSpotLists, fetchHubSpotListCount, saveHubSpotList } from "../../../store/actions";
import { constants } from "../../../utils/common";
import { Grid } from "@material-ui/core";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";

const columns = [
  {
    title: "Name",
    field: "name",
    editable: "never",
  },
  {
    title: "List ID",
    field: "listId",
    editable: "never",
  },
];

const HubSpotLists = (props) => {
  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.fetchHubSpotListCount}
            paginationCall={props.loadHubSpotLists}
            title="HubSpot Lists"
            entity={"hubSpotLists"}
            columns={columns}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
            rowClickFunction={(e, row) => props.history.push(`/hubSpotLists/${row.id}/edit`)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hubSpotLists: state.entities.hubSpotLists,
    pageInfo: state.pagination.hubSpotLists,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loadHubSpotLists: (page, limit, refresh) => dispatch(loadHubSpotLists(page, limit, refresh)),
    fetchHubSpotListCount: () => dispatch(fetchHubSpotListCount()),
    saveHubSpotList: (data) => dispatch(saveHubSpotList(data)),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(HubSpotLists));
