import React, { useEffect, useState } from "react";
import AuthComponent from "../../../components/AuthComponent/AuthComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllObjectives,
  getObjectivesCount,
  saveObjective,
  updateObjective,
  deleteObjective,
  getProjectsList,
} from "../../../store/actions";
import { constants } from "../../../utils/common";
import MaterialTable from "../../../components/MaterialTable/MaterialTable";
import { Grid, Select, MenuItem, Input } from "@material-ui/core";

const columns = (projects = []) => [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Projects (First Priority to Last)",
    field: "projects",
    render: (row) => (
      <ol style={{ minWidth: "50px" }}>
        {row.projects && Array.isArray(row.projects)
          ? row.projects.map((project, index) => <li>{project.project.name}</li>)
          : null}
      </ol>
    ),
    editComponent: (props) => {
      return (
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={convertMultiple(props.value)}
          onChange={(e) => props.onChange(e.target.value.join(","))}
          input={<Input />}
        >
          {projects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {props.value && convertMultiple(props.value).findIndex((selected) => selected === project.id) !== -1
                ? `(${convertMultiple(props.value).findIndex((selected) => selected === project.id) + 1}) ${
                    project.name
                  }`
                : project.name}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
];

const convertMultiple = (value) => {
  return value && value.length > 0 && Array.isArray(value) && value.some((val) => val.hasOwnProperty("project"))
    ? value.map((val) => val.project.id)
    : !Array.isArray(value) && value
    ? value.split(",")
    : [];
};

const Objectives = (props) => {
  useEffect(() => {
    props.getProjectsList();
  }, []);

  const addObjective = async (rowData) => {
    try {
      const payload = {
        ...rowData,
        projects: rowData.projects ? rowData.projects.split(",") : [],
      };
      const response = await props.saveObjective(payload);
      return response;
    } catch (err) {}
  };

  const editObjective = async (newData, oldData) => {
    const payload = {
      ...newData,
      projects:
        newData.projects && Array.isArray(newData.projects)
          ? newData.projects.map((project) => project.projectId)
          : newData.projects
          ? newData.projects.split(",")
          : [],
    };
    const response = await props.updateObjective(newData.id, { ...payload });
    return response;
  };

  const deleteObjective = async (rowData) => {
    const response = await props.deleteObjective(rowData.id);
    return response;
  };

  return (
    <div style={{ WebkitAnimationDuration: "0.5s", animationDuration: "0.5s" }}>
      <AuthComponent />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            backendPagination
            fetchCount={props.getObjectivesCount}
            paginationCall={props.getAllObjectives}
            title="Objectives"
            entity={"objectives"}
            columns={columns(props.projects)}
            addable={props.create}
            editable={props.update}
            deletable={props.delete}
            addFunction={(rowData) => addObjective(rowData)}
            editFunction={(newData, oldData) => editObjective(newData, oldData)}
            deleteFunction={(rowData) => deleteObjective(rowData)}
            options={{
              search: true,
              paging: true,
              pageSize: constants.DEFAULT_PAGE_LIMIT,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: (state.entities.lists && state.entities.lists.projects && state.entities.lists.projects.list) || [],
  };
};

const dispatchToProps = (dispatch) => {
  return {
    getAllObjectives: (page, limit, refresh) => dispatch(getAllObjectives(page, limit, refresh)),
    getObjectivesCount: () => dispatch(getObjectivesCount()),
    saveObjective: (payload) => dispatch(saveObjective(payload)),
    updateObjective: (id, payload) => dispatch(updateObjective(id, payload)),
    deleteObjective: (id) => dispatch(deleteObjective(id)),
    getProjectsList: () => dispatch(getProjectsList()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(withRouter(Objectives));
